import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, DatePicker, Row, Col, message, Dropdown, MenuProps, Select, SelectProps, Radio, InputNumber, Spin, Checkbox, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import moment from 'moment';
import { getRequest, postRequest, putRequest } from "../../../utils/handler/apiHandler";
import TagsInput from "../../../components/TagInput";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { masterSelector, setProspect } from "../../../features/master/masterSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { titleCase } from "../../../utils/helpers/titleCase";
import numToWords from "../../../utils/helpers/numToWords";
import { isArray } from "lodash";
const { TextArea } = Input;
const { Option } = Select;

const EditCreditRequest = () => {
    const [form] = Form.useForm();
    // const payload = useLocation().state;
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    // const prospect = useAppSelector(masterSelector).prospect;
    const [payload, setPayload] = useState<any>([]);
    const [prospect, setProspect] = useState<any>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [makeMaster, setMakeMaster] = useState([]);
    const [modelMaster, setModelMaster] = useState([]);
    const [city, setCity] = useState<any>([]);
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [master, setMaster] = useState<any>({});
    const [tenure, setTenure] = useState<any>([]);
    const [businessSegment, setBusinessSegment] = useState([]);
    // const itemInputsModel = JSON.parse(form.getFieldsValue(true)?.makeModels);
    const [vehicleLocation, setVehicleLocation] = useState<any>();
    const [signatories, setSignatories] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
    const [entityName, setEntityName] = useState<any>("")
    const [checked, setChecked] = useState<any>();
    const [investment, setInvestment] = useState<any>(form.getFieldsValue(true).averageInvestment);
    const [fee, setFee] = useState<any>(form.getFieldsValue(true).managementFee);
    const [potential, setPotential] = useState<any>(form.getFieldsValue(true).currentYearPotential);
    const [otherSegment, setOtherSegment] = useState<boolean>(form.getFieldsValue(true).businessSegment === "Other");

    const handleClientId = (value: any) => {
        const id = prospect.findIndex((obj: any) => {
            return obj?.name === value
        })
        form.setFieldsValue({
            clientId: prospect[id]?.clientId,
            businessSegment: prospect[id]?.segment.name
        })
        setOtherSegment(prospect[id]?.businessSegment === "Other");
    };

    const prospectToRender = (options: any[], identifier: any) => {
        if(isArray(options))
        return options?.map((value: any) => (
            <Option key={value.prospectId} value={value?.[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const optionsRenderer = (options: any[], identifier: any) => {
        if(isArray(options))
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.id}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const onFinish = () => {
        const data = {
            clientId: form.getFieldsValue(true).clientId,
            prospectName: form.getFieldsValue(true).prospectName,
            entityName: form.getFieldsValue(true).entityName,
            legalBusinessName: checked ? form.getFieldsValue(true).legalBusinessName : form.getFieldsValue(true).entityName,
            isOtherSegment: form.getFieldsValue(true).businessSegment === "Other",
            businessSegment: form.getFieldsValue(true).businessSegment === "Other" ? form.getFieldsValue(true).other : form.getFieldsValue(true).businessSegment,
            dateOfApplication: dayjs(form.getFieldsValue(true).dateOfApplication).format("YYYY-MM-DD"),
            authorizeSignatories: JSON.stringify(signatories),
            isCommercialMeetingHeld: form.getFieldsValue(true).isCommercialMeetingHeld,
            productAgreed: form.getFieldsValue(true).productAgreed,
            type: form.getFieldsValue(true).type,
            entityDescription: form.getFieldsValue(true).entityDescription,
            averageInvestment: form.getFieldsValue(true).averageInvestment,
            interestRateOffered: form.getFieldsValue(true).interestRateOffered,
            fleetProjection: form.getFieldsValue(true).fleetProjection,
            managementFee: form.getFieldsValue(true).managementFee,
            rvPercentage: form.getFieldsValue(true).residualValuePercentage,
            currentYearPotential: form.getFieldsValue(true).currentYearPotential,
            leaseTenure: form.getFieldsValue(true).leaseTenure,
            mileage: form.getFieldsValue(true).mileage,
            vehicleLocations: JSON.stringify(form.getFieldsValue(true).vehicleLocation),
            makeModels: JSON.stringify(form.getFieldsValue(true).model),
            status: "Pending"
        };

        setLoader(true);
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-request/${payload?.creditRequestId}`, data).then(res => {
            setLoader(false)
            navigation("/dashboard/credit");
        });
    };

    const page = useLocation().pathname.split("/").pop();
    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/credit-request?creditRequestId=${page}`).then(res => {
            const data = res.data[0];            
            setPayload(data);
            form.setFieldsValue({
                authorizedSignatories: JSON.parse(data.authorizedSignatories), 
                averageInvestment: data.averageInvestment,
                businessSegment: data.businessSegment,
                clientId: data.clientId,
                createdBy: data.createdBy,
                createdByName: data.createdByName,
                creditRequestId: data.creditRequestId,
                creditStatus: data.creditStatus,
                currentYearPotential: data.currentYearPotential,
                dateOfApplication: dayjs(data.dateOfApplication, "YYYY-MM-DD"),
                entityDescription: data.entityDescription,
                entityName: data.entityName,
                fleetProjection: data.fleetProjection,
                interestRateOffered: data.interestRateOffered,
                isCommercialMeetingHeld: data.isCommercialMeetingHeld,
                leaseTenure: data.leaseTenure,
                legalBusinessName: data.legalBusinessName,
                makeModels: JSON.parse(data.makeModels),
                model: JSON.parse(data.makeModels),
                managementFee: data.managementFee,
                mileage: data.mileage,
                productAgreed: data.productAgreed,
                prospectName: data.prospectName,
                residualValuePercentage: data.residualValuePercentage,
                type: data.type,
                updatedBy: data.updatedBy,
                updatedByName: data.updatedByName,
                vehicleLocation: JSON.parse(data.vehicleLocations)
            });
            setVehicleLocation(JSON.parse(res.data[0].vehicleLocations));
            setSignatories(JSON.parse(res.data[0].authorizedSignatories));
            // setSignatories(res.data[0].entityName.trim() == res.data[0].legalBusinessName.trim() ? false : true);
            if(res.data[0].entityName.trim() == res.data[0].legalBusinessName.trim()) setChecked(false);
            else setChecked(true);
        });
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities`).then(res => {
            setCity(res.data)
        });
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/all-masters`).then(res => {
          setMaster(res.data)
        });
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/tenures`, {}).then(res => {
            setTenure(res.data);
        });
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
            setBusinessSegment(res.data);
        });
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospect?clientId=${payload?.clientId}`).then(res => {
            setProspect(res.data);
        });
    }, []);
    console.log(form.getFieldsValue(true).authorizedSignatories);
    
    const optionsToRender = (options: any[], identifier: any) => {
        return options?.map((value: any) => (
          <Option key={value.id} value={value?.[identifier]}>
            {value?.[identifier]}
          </Option>
        ));
      };


    function disabledDate(current: any) {
    // Can not select days future dates
    return current && current > moment().endOf('day');
    }

    const selectedLocation = (tags: any) => {
      setVehicleLocation(tags);
      console.log(tags);
    };
    const selectedSignatories = (tags: any) => {
      setSignatories(tags);
    };

    const handleBusinessName = (value: any) => {
        setChecked(value.target.checked)
        form.setFieldsValue({
            legalBusinessName: payload.legalBusinessName
        })
        if(value.target.checked)
        form.setFieldsValue({
            legalBusinessName: payload.legalBusinessName
        })
        else
        form.setFieldsValue({
            legalBusinessName: payload.entityName
        })
    }

    useEffect(() => {
        if(!checked)
        form.setFieldsValue({ 
            entityName: entityName,
            legalBusinessName: entityName 
        })
    }, [entityName])
    
    // useEffect(() => {
    //     form.setFieldsValue({ 
    //         entityName: payload.entityName,
    //         legalBusinessName: payload.legalBusinessName 
    //     });
    //     if(payload.entityName.trim() == payload.legalBusinessName.trim()) setChecked(false);
    //     else setChecked(true)
    // }, [])

    const vehicleToRender = (options: any[]) => {
        return options?.map((value: any, id: any) => (
            <Option key={id} value={value}>
            {value}
            </Option>
        ));
    };
    const handleChange = (value: any, payload: any, key: any) => {
        if(payload == "make") {
            let makeModel = form.getFieldsValue(true).model;
            makeModel[key].model = [];
            setMake(value);
        }
        if(payload == "model")
            setModel(value)
    };
    const getMakeList = async (key: any) => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/master/makers`).then(res => {
            setMakeMaster(res.data);
        });
    }
    const getModelList = async (make: any) => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/master/model?make=${make}`).then(res => {
            setModelMaster(res.data);
        });
    }

    return (
        <React.Fragment>
        {contextHolder}
        <Form 
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="form-container"
            onKeyDown={(e)=> e.key == "Enter" ? e.preventDefault(): ''}
        >
            <Row className="">
                <Col className="form-column">
                    <Form.Item
                        label="Prospect Name"
                        name="prospectName"
                        rules={[{ required: true, message: 'Please enter prospect name!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Prospect Name"
                            onChange={e => handleClientId(e)}
                        >
                        {prospectToRender(prospect, "name")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: 0}}
                        label="Entity Name"
                        name="entityName"
                        rules={[{ required: true, message: 'Please enter entity name!' }]}
                    >
                        <Input onChange={(e) => setEntityName(e.target.value)} placeholder="Entity Name" />
                    </Form.Item>
                    <Checkbox style={{marginBottom: 22}} checked={checked} onClick={(value) => handleBusinessName(value)}>Is child</Checkbox>

                    <Form.Item
                        label="Date of application"
                        name="dateOfApplication"
                        rules={[{ required: true, message: 'Please enter date of joining!' }]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%"}}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Commercial Physical Meeting"
                        name="isCommercialMeetingHeld"
                        rules={[{ required: true, message: 'Please enter commercial meeting!' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Authorized Signatories"
                        name="authorizedSignatories"
                        rules={[ 
                          {
                              validator: async () => {
                              if (signatories.length < 1) {
                                  return Promise.reject(new Error('At least 1 Signatories'));
                              }
                              },
                          },
                          ]}
                    >
                        <TagsInput selectedTags={selectedSignatories} placeholder="Authorized Signatories" tags={signatories} />
                    </Form.Item>

                    <Tooltip title={titleCase(numToWords(form.getFieldsValue(true).averageInvestment))} placement="bottomLeft">
                    <Form.Item
                        label="Average Investment Value per vehicle"
                        name="averageInvestment"
                        rules={[{ required: true, message: 'Please enter average investment!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Average Investment" onChange={(e) => setInvestment(e)} />
                    </Form.Item>
                    </Tooltip>
                    <Form.Item
                        label="Fleet Projection"
                        name="fleetProjection"
                        rules={[{ required: true, message: 'Please enter fleet projection!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Fleet Projection" />
                    </Form.Item>
                    <Tooltip title={titleCase(numToWords(form.getFieldsValue(true).managementFee))} placement="bottomLeft">
                    <Form.Item
                        label="Management Fee Offered"
                        name="managementFee"
                        rules={[{ required: true, message: 'Please enter current management fee!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Management Fee" onChange={(e) => setFee(e)} />
                    </Form.Item>
                    </Tooltip>
                    <Form.Item
                        label="Vehicle Location"
                        name="vehicleLocation"
                        rules={[{ required: true, message: 'Please enter vehicle location!' }]}
                    >
                        {/* <TagsInput selectedTags={selectedLocation} placeholder="Vehicle Location" tags={initialValues.vehicleLocations}/> */}
                        <Select
                            showSearch={true}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Vehicle Location(s)"
                            value={form.getFieldsValue(true).vehicleLocation}
                        >
                        {optionsToRender(city, "cityName")}
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="form-column">
                <div style={{display: "flex", alignItems: "flex-end"}}>
                    <Form.Item
                        style={{width: "100%"}}
                        label="Business Segment"
                        name="businessSegment"
                        rules={[{ required: true, message: 'Please enter business segment!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Business Segement"
                            onChange={(e) => setOtherSegment(e === "Other")}
                        >
                        {optionsToRender(businessSegment, "name")}
                        </Select>
                    </Form.Item>
                    {
                        otherSegment
                        ? <Form.Item
                            className="secondary-field"
                            name='other'
                            rules={[{ required: true, message: 'Please enter Business Segment' }]}
                        >
                            <Input placeholder="Other business segment" />
                        </Form.Item>
                        : null
                    }
                    </div>
                    <Form.Item
                        label="Brand Name"
                        name="legalBusinessName"
                        rules={[{ required: true, message: 'Please enter Brand name!' }]}
                    >
                        <Input disabled={!checked} placeholder="Brand Name" value={entityName} style={{marginBottom: 20}} />
                    </Form.Item>
                    <Form.Item
                        label="Product Agreed"
                        name="productAgreed"
                        rules={[{ required: true, message: 'Please enter product agreed!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Product Agreed"
                            // onChange={handleChange}
                        >
                        {optionsToRender(master?.product_agreed, "productAgreed")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Lease Term"
                        name="leaseTenure"
                        rules={[{ required: true, message: 'Please enter lease term!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Lease Term"
                            // onChange={handleChange}
                        >
                          {optionsToRender(tenure, "inMonth")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Interest Rate Offered"
                        name="interestRateOffered"
                        rules={[{ required: true, message: 'Please enter interest rate offered!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Interest Rate Offered" />
                    </Form.Item>
                    <Form.Item
                        label="RV Percentage (%)"
                        name="residualValuePercentage"
                        rules={[{ required: true, message: 'Please enter residual value percentage!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Residual Value Percentage" />
                    </Form.Item>

                    {/* <Tooltip title={titleCase(numWords(form.getFieldsValue(true).currentYearPotential))} placement="bottomLeft"  color="green"> */}
                    <Form.Item
                        label="Total Potential"
                        name="currentYearPotential"
                        rules={[{ required: true, message: 'Please enter current year potential!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Total Potential" onChange={(e) => setPotential(e)} />
                    </Form.Item>
                    {/* </Tooltip> */}
                    <Form.Item
                        label="Mileage"
                        name="mileage"
                        rules={[{ required: true, message: 'Please enter Mileage!' }]}
                    >
                        <InputNumber style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Mileage" />
                    </Form.Item>
                    <Form.Item
                        label="Entity Description"
                        name="entityDescription"
                        // rules={[{ required: true, message: 'Please input your contact number!' }]}
                    >
                        <TextArea rows={6} placeholder="Entity Description" />
                    </Form.Item>
                </Col>

                <Divider />
                
                <Form.List name="model">
                    {(fields, { add, remove }) => (
                        <>
                        {fields?.map(({ key, name, ...restField }) => (
                            <div className="form-column-spoc">
                            <Form.Item
                                {...restField}
                                label="Make"
                                name={[name, 'make']}
                                rules={[{ required: true, message: 'Please enter make!' }]}
                            >
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    onClick={() => getMakeList(key)}
                                    onChange={(value) => handleChange(value, "make", key)}
                                >
                                {vehicleToRender(makeMaster)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                {...restField}
                                label="Model"
                                name={[name, 'model']}
                                rules={[{ required: true, message: 'Please enter model!' }]}
                            >
                                <Select
                                    showSearch={true}
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    // placeholder="Model"
                                    onClick={() => getModelList(form.getFieldsValue(true).model[key].make)}
                                    onChange={(value) => handleChange(value, "model", key)}
                                >
                                {vehicleToRender(modelMaster)}
                                </Select>
                            </Form.Item>

                            <Button 
                                    type="primary" 
                                    danger 
                                    style={{maxWidth: "5rem"}} 
                                    onClick={() => remove(name)}>
                                        Delete
                                </Button>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            >
                            Add Model
                            </Button>
                        </Form.Item>
                        </>
                    )}
                </Form.List>
            </Row>

        <Form.Item>
        <Button type="primary" htmlType="submit">
            Submit
            {loader ? <Spin indicator={antIcon} /> : ""}
        </Button>
        </Form.Item>
        </Form>
        </React.Fragment>
    );
};

export default EditCreditRequest;