import { Input, Menu} from "antd";
import { useEffect, useState } from "react";
import { getRequest } from "../../../utils/handler/apiHandler";
import debounce from 'lodash/debounce';

export default function SingleFilterDropdown({ placeholder, dropdownOptions, fetchApiUrl, setKey, setOptions, selectedOption, setSelectedOption, width=240, isKeyItems }:any) {

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchPage, setSearchPage] = useState(0);
    const [searchValue, setSearchValue] = useState<any>('');

    const handleSearch = (value: any) => {
        setSearchValue(value);
        debouncedSearch(value, 1) ; // Reset the page when a new search starts
    };

    const handlePopupScroll = (e: any) => {
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            fetchBusinessNameOptions(searchValue, searchPage + 1); // Load more options
        }
    };

    const fetchBusinessNameOptions = async (name: string, page: number = 1) => {
        setSearchLoading(true);
        try {
            const api = fetchApiUrl(name, page)
            const res = await getRequest(api);
            if (res.status === 200) {
                if(isKeyItems) {
                    setOptions((prev:any) => page === 0 ? res.data.items : [...prev, ...res.data.items]);
                } else {
                    setOptions((prev:any) => page === 0 ? res.data.data : [...prev, ...res.data.data]);
                }
                setSearchPage(page);
            } else {
                console.error('Error fetching Brand Names:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Brand Names:', error);
        } finally {
            setSearchLoading(false);
        }
    };

    useEffect(() => {
        setOptions([]);
    }, [searchValue])

    const debouncedSearch = debounce(fetchBusinessNameOptions, 500);


    return <div style={{ padding: 4 }}>
        <Input.Search onClick={() => {setSelectedOption(null);}} placeholder={placeholder} value={selectedOption != null ? selectedOption : searchValue} onChange={(e : any) => {setSearchValue(e.target.value);}} loading={searchLoading} onSearch={handleSearch} /> 
        <div style={{ maxHeight: 200, width: width, overflow: "scroll", marginTop: 4 }} onScroll={(e) => {searchValue && handlePopupScroll(e);}}>
            <Menu>
                {dropdownOptions.map((option : any) => {
                    return <Menu.Item onClick={() => {setKey(option.value); setSelectedOption(option.text);}}  style={{textAlign: "left", borderRadius : 7}} key={option.value}>{option.text}</Menu.Item>
                })}
            </Menu>
        </div>
    </div>
}