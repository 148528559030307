import { Button, Card, Modal, message } from "antd";
import { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as Caution } from "./../../../../../utils/icons/Caution.svg";
import { ReactComponent as CloseBlue } from "./../../../../../utils/icons/CloseBlue.svg";
import { ReactComponent as CheckWhite } from "./../../../../../utils/icons/CheckWhite.svg";

interface StartServiceConfirmationModalProps{
    startServiceFlag: number,
    setStartServiceFlag: (flag: number) => void,
    handleStartServiceDateConfirm: () => void,
    isPickupAndDrop: boolean,
}

const StartServiceConfirmationModal: React.FC<StartServiceConfirmationModalProps> = ({ startServiceFlag, setStartServiceFlag,
    handleStartServiceDateConfirm, isPickupAndDrop  }) => {
    
    return (
        <Modal 
            open={startServiceFlag === 1} 
            onCancel={()=>setStartServiceFlag(0)}
            width='30%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', justifyContent:'center', marginTop:'2rem'}}>
                    <Caution/>
                </div>
                
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',  
                        alignItems: 'center',     
                        margin: '1rem 0',          
                        fontWeight: '500',
                        fontSize: '0.875rem',
                        color: '#333',
                        width: '100%',           
                    }}
                    >
                    <div style={{ textAlign: 'center', width:'content-fit' }}>
                        <p> Are you sure you want to start the service? </p>
                    </div>
                </div>



                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', marginBottom:'0.8rem' }}>
                    <Button style={{width:'45%', color:'#1777FF', borderColor: '#1777FF', backgroundColor:'#E0EDFF', height:'2.2rem'}}
                        onClick={()=> setStartServiceFlag(0)}>
                        <CloseBlue style={{marginRight:'0.4rem'}} />
                        <span>
                            Cancel
                        </span>
                    </Button>
                    <Button style={{width:'45%', color:'#FFF', borderColor: '#2C66E3', backgroundColor:'#2C66E3', height:'2.2rem'}}
                        onClick={()=> setStartServiceFlag(2)}
                        >
                        <CheckWhite style={{marginRight:'0.4rem'}} />
                        <span>
                            Confirm 
                        </span>
                    </Button>                    
                </div>
            </div>
        </Modal>
    );
};

export default StartServiceConfirmationModal;
