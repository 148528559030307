import { Form, Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../../../utils/handler/apiHandler";
import { getIssuesAndSubIssuesLogsTableColumns } from "../Utils/getIssuesAndSubIssuesLogsTableColumns";
import  "./issueLogTableStyle.css";
import { getJobCardLogsTableColumns } from "../Utils/getJobCardLogsTableColumns";

interface ShowIssueAndSubissueHistoryDetailsProps{
    issueAndSubissue: any,
    rev: any,
    jobCardId: number,
    ticketId: string,
    setFetchTicketDataCnt: (cnt:number) => void,
    jobCardHistory?: boolean
    ticketStatusId?: number  
}

const { Title } = Typography;

const ShowIssueAndSubissueHistoryDetails: React.FC<ShowIssueAndSubissueHistoryDetailsProps> = 
      ({issueAndSubissue, rev, jobCardId, ticketId, setFetchTicketDataCnt, jobCardHistory, ticketStatusId})=>{
    const [tableHeight, setTableHeight] = useState<number>(500);
    const [form] = Form.useForm();
    const [normalIssueAndSubissue, setNormalIssueAndSubissue] = useState<any> ([]);
    const [formattedIssueAndSubissue, setFormattedIssueAndSubissue] = useState<any> ([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      if (!rev) return;

      const fetchIssueAndSubissueHistoryLogs = async () => {
          try {
              setLoader(true);
              const response = await getRequest(
                  `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/audit/${jobCardHistory ? "actual-" : ""}sub-issue-job-card-details?rev=${rev}&jobCardId=${jobCardId}`
              );

              if (response.status !== 200) {
                  message.error(response.msg || "Failed to fetch issueAndSubissueHistoryLog for rev.");
              }
              const items = response?.data?.items;
              setNormalIssueAndSubissue(items);
              setFormattedIssueAndSubissue(items?.map((item:any)=>{
                const unitPrice = item?.unitPrice ? Number(item?.unitPrice) : 0;
                const quantity = item?.quantity ? Number(item?.quantity) : 0;
                const basePrice = unitPrice * quantity;

                const discountedAmount = item.discountedAmount ? Number(item.discountedAmount) : 0;
                const taxableAmount = basePrice - discountedAmount;

                const gst = item?.gst ? Number(item?.gst) : 0;
                const gstAmount = Math.round((gst/100) * taxableAmount * 100) / 100;

                    const amountPayable = taxableAmount + gstAmount;
                return {
                  issue: item?.issue,
                  subissue: item?.subissue,
                  reportedAt: item?.reportedAt,
                  status: item?.status?.label,
                  discountedAmount: item?.discountedAmount,
                  discountedAmountMod: item?.discountedAmountMod,
                  statusColor: item?.status?.color,
                  backgroundColor: item?.status?.bgColor,
                  updatedAt: item?.updatedAt,
                  issueMod: item?.issueIdStrMod,
                  subissueMod: item?.subIssueIdStrMod,
                  reportedAtMod: item?.reportedAtMod,
                  statusMod: item?.statusMod,
                  workType: item?.workType,
                  workTypeMod: item?.workTypeMod,
                  quantity: item?.quantity,
                  quantityMod: item?.quantityMod,
                  unitPrice: item?.unitPrice,
                  unitPriceMod: item?.unitPriceMod,
                  // estimatedAmount: item?.estimatedAmount,
                  // estimatedAmountMod: item?.estimatedAmountMod,
                  amountPayable: amountPayable,
                  amountPayableMod: item?.amountPayableMod,
                  isRecoverable: item?.isRecoverable,
                  isRecoverableMod: item?.isRecoverableMod,
                  basePrice: basePrice,
                  taxableAmount: taxableAmount,
                  basePriceMod: item?.basePriceMod,
                  gstAmount: gstAmount,
                  gstAmountMod: item?.gstAmountMod,
                  gst: item?.gst,
                  gstMod: item?.gstMod,
                  workDone: item?.workDone,
                  workDoneMod: item?.workDoneMod,
                }
              }))
          } catch (error) {
              message.error("An error occurred while fetching issueAndSubissueHistoryLogs for rev.");
          } finally {
              setLoader(false);
          }
      };

      fetchIssueAndSubissueHistoryLogs();
  }, [rev]);

  

    const columns = !jobCardHistory ?  getIssuesAndSubIssuesLogsTableColumns() : getJobCardLogsTableColumns(ticketStatusId);
  
    return (
      <div style={{marginBottom:'1rem'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
            <hr style={{ border: '0.01rem solid rgba(175, 175, 175, 1)', borderWidth: '1px' }} />
            <span style={{fontWeight:'700', fontSize:'16px', color:'#242424', padding:'1rem 1.5rem 1rem 1.5rem'}}>Issues & Sub - Issues</span>
        </div>
        <>
        <Table
          columns={columns}
          dataSource={formattedIssueAndSubissue || []}
          scroll={jobCardHistory ? {x: 2500} : { x: 'max-content', y: 0.80 * tableHeight }}
          pagination={false}
          size="middle"
          className="no-divider-table"
          components={{
            header: {
              wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
            },
          }}
        />

        </>
      </div>
    );
}
export default ShowIssueAndSubissueHistoryDetails;