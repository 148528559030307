import React, { useState, useEffect, FC } from 'react';
import { Select, message } from 'antd';
import { useDebounce } from 'use-debounce';
import axios from 'axios';
import AddNewLocalVendor from './AddNewLocalVendor';
import AddNewWorkshop from './AddNewWorkshop';

const { Option } = Select;

interface CustomSelectSearchForWorkshopNameProps {
  value: string;
  onChange: (value: string) => void;
  workshopOptions: any[];
  setWorkshopOptions: (workshopOptions: any[]) => void;
  workshopCity: string;
  isLocalVendor: boolean,
  selectedWorkshopDetailsData: any;
}

const CustomSelectSearchForWorkshopName: FC<CustomSelectSearchForWorkshopNameProps> = ({
  value,
  onChange,
  workshopOptions,
  setWorkshopOptions,
  workshopCity,
  isLocalVendor,
  selectedWorkshopDetailsData,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500); // Debounced search term
  const [isLoading, setIsLoading] = useState(false);
  const [showAddLocalVendorModal, setShowAddLocalVendorModal] = useState(false);
  const [showAddOtherWorkshopModal, setShowAddOtherWorkshopModal] = useState(false);

  // Trigger API call when debouncedSearchTerm changes
  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchWorkshops(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  // Fetch workshops based on the search term
  const fetchWorkshops = async (search: string) => {
    setIsLoading(true);
    const encodedFilter = encodeURIComponent(`name|like|${search}&city|eq|${workshopCity}&isLocalVendor|eqb|false&active|eqb|true`);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop?filterAnd=${encodedFilter}`
      );
      setWorkshopOptions(response?.data?.items || []);
    } catch (error) {
      message.error('Failed to fetch workshops.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle selection change
  const handleChange = (value: string) => {
    if (value === 'Local Vendor') {
      handleAddLocalVendorWorkshop();
      return;
    }
    if (value === 'Other Workshop') {
      handleAddOtherWorkshop();
      return;
    }
    onChange(value); // Pass selected value back to the parent
  };

  const handleAddLocalVendorWorkshop = () => setShowAddLocalVendorModal(true);
  const handleAddOtherWorkshop = () => setShowAddOtherWorkshopModal(true);
  const handleCloseModal = () => setShowAddLocalVendorModal(false);
  const handleCloseAddOtherModal = () => setShowAddOtherWorkshopModal(false);

  return (
    <>
      <Select
        value={value}
        onChange={handleChange}
        showSearch
        loading={isLoading}
        filterOption={false} // Disable default filtering
        placeholder="Search for a workshop"
        notFoundContent={isLoading ? 'Loading...' : null}
        style={{ width: '100%' }}
        onSearch={(value) => setSearchTerm(value)}
      >
        {workshopOptions.map((workshop) => (
          <Option key={workshop.id} value={workshop.id}>
            {workshop.name}
          </Option>
        ))}
        <Option key="add-local-vendor" value="Local Vendor">
          Local Vendor
        </Option>
        <Option key="add-new-workshop" value="Other Workshop">
          Other
        </Option>
      </Select>

      {/* Modal for adding a new workshop */}
      {showAddLocalVendorModal && (
        <AddNewLocalVendor onClose={handleCloseModal} onChange={onChange} workshopCity={workshopCity} isLocalVendor={isLocalVendor} selectedWorkshopDetailsData={selectedWorkshopDetailsData}/>
      )}
      {showAddOtherWorkshopModal && (
        <AddNewWorkshop onClose={handleCloseAddOtherModal} onChange={onChange} workshopCity={workshopCity} />
      )}
    </>
  );
};

export default CustomSelectSearchForWorkshopName;
