import { CheckOutlined, CloseOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Modal, Spin, Typography, Upload } from "antd"
import axios from "axios";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { CSSProperties } from "@mui/styled-engine";
import { store } from "../../../../app/store";
import { getRequest, handleFileDownload, putRequest } from "../../../../utils/handler/apiHandler";
import { ReactComponent as TickMarkIcon } from "../../SVGs/tick_mark_icon.svg";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import _ from "lodash";

interface PropType {
    isPayments: boolean;
    setVisible: (visible: boolean) => void,
    visible: boolean,
    prospectId: number,
    clientQuoteId: any,
    signedCopy: boolean,
    quoteId: any,
    ocLoader: boolean,
    setOcLoader: (ocLoader: boolean) => void;
}

const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;

export default function OcModal({ isPayments, setVisible, visible, prospectId, clientQuoteId, signedCopy, quoteId, ocLoader, setOcLoader }: PropType) {
    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [fileList, setFileList] = useState<any>([]);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [fileUrl, setFileUrl] = useState<null | string>(null);
    const [clientData, setClientData] = useState<any>([]);
    const auth = useAppSelector(authSelector);
    const [modal, modalContex] = Modal.useModal();
    const [clientQuoteStatusId, setClientQuoteStatusId] = useState<any>(null);
    const accessToken = store.getState().auth.accessToken;

    const handleUploadRequest = async (file:any) => {
        setUploadLoader(true);
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadQuoteFile `;
        const formData:any = new FormData();
        formData.append('file', file.file);
        formData.append("prospectId",prospectId);
        formData.append("type", "ocf")

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log("responsexx", response)
                if (response.status === 200) {
                    setFileUrl(response.data.DocumentUrl)
                    // message.success(response.data.msg)
                }
                else {
                    console.log("error in uploading file")
                }
                setUploadLoader(false);
                // setFileList([])
            })
        } catch (error:any) {
            setUploadLoader(false);
            console.log("error msg sss", error.response)
            const errorMessage = error.response.data.errors?.[0] || "Error occured while uploading"
            console.error('Upload error:', errorMessage);
            message.error(errorMessage)
        }
    };

    const handleDelete = (file: any) => {
        setUploadLoader(false);

        const tempFileList = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileList.push(fileList[i]);
            }
        }
        setFileList(tempFileList);
        setFileUrl(null)
    }

    const handleStatus = (uploadingSigned:boolean) => {
        try {
            setOcLoader(true)
            if(!uploadingSigned) {
                putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${clientQuoteId}?statusId=12`, null).then(res => {
                    setOcLoader(false)
                    window.location.reload();
                })
            } else {
                saveURL();
            }
        } catch(err) {
            setOcLoader(false);
        }
    }

    const handleSubmit = async (uploadingSigned:boolean) => {
        setVisible(false);
        modal.confirm({
            title: <div>
                <img width={65} src={`${__dirname}assets/warning-icon.png`} />
                <div style={{fontWeight: 400, marginTop: 20}}>
                    {uploadingSigned ? "Are you sure you want to upload signed OC?" : "Are you sure you want to confirm the quote and generate OC draft?"}  
                </div>
            </div>,
            // content: 'Once deleted changes cannot be reverted',
            okText: 'Confirm',
            okType: 'danger',
            centered: true,
            cancelText: 'Cancel',
            icon: null,
            onOk: async () => {
                handleStatus(uploadingSigned)
            },
            bodyStyle: {
                textAlign: "center",
            },
            footer: (child)=> {
                return <div style={{display: "flex", justifyContent: "center"}}>
                {child}
                </div>
            },
            okButtonProps: { style: { width: 140, marginTop: 10 }, type: "primary", danger: false, block: true, icon: <CheckOutlined /> },
            cancelButtonProps: { className: "button-override primary", ghost: true, style: { width: 140, marginTop: 10 }, type: "primary", onClick: () => { Modal.destroyAll() }, block: true, icon: <CloseOutlined /> }
        })
    }

    const saveURL = async () => {
        setSubmitting(true)
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${clientQuoteId}?statusId=12`;
        try {
            const response = await putRequest(url, {
                "isSignedOcUploaded": true,
                "ocUrl": fileUrl
              });
            setSubmitting(false)
            Modal.destroyAll()
            handleClose()
            setTimeout(() => window.location.reload(), 1000);
        } catch(e) {
            console.log(e)
        }
    }

    const handleClose = () =>{
        setVisible(false);
        setFileList([]);
        setFileUrl(null)
    }


    // Generate OC BLOB file preview 
    const fetchPdf = async (url: string) => {
        try {
            // Make the request to fetch the PDF file using Axios
            const response = await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",  // You can remove Content-Type if not needed
                },
                responseType: 'arraybuffer', // This ensures that the response is in binary format
            });

            // Create a Blob from the arraybuffer response
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a temporary URL for the Blob
            const pdfBlobUrl: any = URL.createObjectURL(blob);

            // Set the URL to state to display in the iframe
            setPdfUrl(pdfBlobUrl);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    useEffect(() => {
        clientQuoteId && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/${`quoteservice/client-quote/${quoteId}${isPayments ? `?isPayment=true` : ""}`}`).then(res => {
            setClientData(res.data)
            fetchPdf(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/generateOcf/${res.data?.clientQuoteId}`);
          })
    }, [clientQuoteId]);

    const downloadFile = () => {
        handleFileDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/generateOcf/${clientQuoteId}`, `ClientQuote_OCF_${clientQuoteId}`, 'pdf').then(res => {
            console.log(res);
        })
    }

    return (
        <>
        {clientData?.quoteStatus?.id === 11 ? <Modal 
            destroyOnClose 
            open={visible} 
            title={<span style={{fontSize: 14, fontWeight: 500 }}>Review Order Confirmation Draft</span>}
            styles={{
                header: {
                    fontSize: 12
                },
                body: {
                    marginTop: 20
                }
            }} 
            onCancel={handleClose}
            width={1000}
            footer={<>
                <Button
                ghost
                danger
                type="primary"
                icon={<CrossIcon />}
                style={{marginLeft: 10}}
                onClick={_ => setVisible(false)}
                className="button-override danger"
                >
                    Close
                </Button>
                <Button
                ghost
                type="primary"
                icon={<DownloadIcon />}
                style={{marginLeft: 10}}
                onClick={downloadFile}
                className="button-override primary"
                >
                    Download Draft OC
                </Button>
                <Button
                type="primary"
                icon={<TickMarkIcon />}
                style={{marginLeft: 10}}
                loading={ocLoader}
                onClick={_ => {setVisible(false); handleSubmit(false)}}
                className="button-override"
                >
                Confirm
                </Button>
            </>}
            >
            {pdfUrl ? (
                <iframe
                    src={pdfUrl}
                    width="100%"
                    height="600px"
                    title="PDF Viewer"
                ></iframe>
            ) : (
                <p>Loading PDF...</p>
            )}

        </Modal>

        : <Modal destroyOnClose open={visible} title="Review Order Confirmation Draft" styles={{
            header: {
                fontSize: 12
            },
            body: {
                marginTop: 20
            }
        }} onCancel={handleClose} footer={null}>
            <Upload
                // disabled={fileList.length > 0}
                accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                customRequest={(file) => handleUploadRequest(file)}
                beforeUpload={(file) => {
                    setFileList([file]);
                    { handleUploadRequest({ file }) }
                    console.log("upload file", file)
                    return false
                }}
                fileList={fileList}
                onRemove={handleDelete}
                listType="picture"
                multiple
            >
                <Button
                    size="large"
                    type="dashed"
                    //  disabled={fileList.length > 0}
                    icon={uploadLoader ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                    style={{ width: "472px" }}
                >Upload Order Confirmation Draft</Button>
            </Upload>

            <div style={{display: "flex", justifyContent: "flex-end", gap: 10, marginTop: 20}}>
                <Button onClick={handleClose} loading={submitting} disabled={submitting} type="default">Cancel</Button>
                <Button onClick={()=>handleSubmit(true)} loading={submitting} 
                disabled={submitting || !fileUrl}
                 type="primary">Upload</Button>
            </div>
        </Modal>}

        {modalContex}
        </>
    )
}

const confirmBodyStyles: React.CSSProperties ={
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    background: "red"
}