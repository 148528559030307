import { Button, Card, DatePicker, Modal, message } from "antd";
import { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as ArrowLeft } from "./../../../../../utils/icons/arrow-left.svg";
import { ReactComponent as CheckIcon } from "./../../../../../utils/icons/CheckIconConfigurable.svg";

interface ServiceArrivalTimeConfirmationModalProps{
    startServiceFlag: number,
    setStartServiceFlag: (flag: number) => void,
    arrivalTime: Date | null,
    setArrivalTime: (date: Date | null) => void,
    handleStartServiceDateConfirm: ()=>void,
}

const ServiceArrivalTimeConfirmationModal: React.FC<ServiceArrivalTimeConfirmationModalProps> = ({ startServiceFlag, setStartServiceFlag
        ,arrivalTime , setArrivalTime, handleStartServiceDateConfirm }) => {
    
    return (
        <Modal 
            open={startServiceFlag === 2} 
            onCancel={ ()=> {
                setStartServiceFlag(0);
                setArrivalTime(null)
                }
            }
            width='30%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', marginTop:'1rem'}}>
                    <p style={{ fontWeight: '700', fontSize: '1rem', color:'#404040' }}>
                        Enter arrival time of vehicle at workshop
                    </p>
                </div>
                <div style={{ display:'flex', flexDirection:'column', alignItems:'center', margin:'1rem 0rem', fontWeight: '500', fontSize: '0.875rem', color:'#333' }}>
                    <div style={{width:'100%'}}>
                    <DatePicker 
                        showTime format="YYYY-MM-DD hh:mm a" 
                        onChange={(date: Date) => setArrivalTime(date)} 
                        style={{ width: '100%' }} 
                        value = {arrivalTime}
                        />
                     </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', marginBottom:'0.8rem' }}>
                    <Button style={{width:'45%', color:'#1777FF', borderColor: '#1777FF', backgroundColor:'#E0EDFF', height:'2.2rem'}}
                        onClick={()=> {
                            setStartServiceFlag(0);
                            setArrivalTime(null)
                            }
                        }>
                        <ArrowLeft style={{marginRight:'0.4rem'}} />
                        <span>
                            Go Back
                        </span>
                    </Button>

                    <Button 
                        style={{
                            width: '45%',
                            color: arrivalTime ? '#FFF' : '#AFAFAF',
                            borderColor: arrivalTime ? '#2C66E3' : '#CCC',
                            backgroundColor: arrivalTime ? '#2C66E3' : '#CCC',
                            height: '2.2rem'
                        }}
                        onClick={() => {
                            setStartServiceFlag(0);
                            handleStartServiceDateConfirm();
                        }}
                        disabled={!arrivalTime} // Disable button when arrivalTime is not set
                    >
                        <CheckIcon style={{ marginRight: '0.4rem' }} color={arrivalTime ? '#fff' : '#AFAFAF'} />
                        <span>Confirm</span>
                    </Button>

                </div>
            </div>
        </Modal>
    );
};

export default ServiceArrivalTimeConfirmationModal;
