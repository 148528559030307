import React, { useContext, useEffect, useRef, useState } from 'react';
import type { GetRef, InputRef, TableProps } from 'antd';
import { Button, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { getRequest } from '../../../../../../utils/handler/apiHandler';
import moment from 'moment';
import { DownOutlined, ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from './../../../../../../utils/icons/DeleteIconConfigurable.svg';
import { ReactComponent as EditPenIcon } from './../../../../../../utils/icons/EditPenIconConfigurable.svg';
import "./job-card-styles.css"
import { OptionType } from '../JobCard';
import EditIssues from './EditIssues';
import axios from 'axios';
import _ from "lodash";
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

// interface Item {
//     key: string;
//     name: string;
//     age: string;
//     address: string;
// }

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof IssueDataType;
    record: IssueDataType;
    handleSave: (record: IssueDataType) => void;
    statuses: [] | OptionType[],
    workTypes: [] | OptionType[],
    edittingForm: boolean,
    ticketStatusId: number
}

export const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    statuses,
    workTypes,
    edittingForm = true,
    ticketStatusId,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (!edittingForm) {
            form.resetFields()
        }
    }, [record])

    // const toggleEdit = () => {
    //     setEditing(!editing);
    //     form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    // };

    const save = async (dataIndex?: string) => {
        if(dataIndex === "quantity" || dataIndex === "unitPrice" || dataIndex === "discountedAmount" || dataIndex === "gst"){
            let quantity = form.getFieldValue("quantity");
            let unitPrice = form.getFieldValue("unitPrice");
            let basePrice = quantity * unitPrice
            form.setFieldsValue({ basePrice: basePrice });

            let discountedAmount = form.getFieldValue("discountedAmount");
            discountedAmount = discountedAmount ? Number(discountedAmount) : 0;
            basePrice = basePrice ? Number(basePrice) : 0;
            let taxableAmount = basePrice - discountedAmount;
            form.setFieldsValue({ taxableAmount: taxableAmount})

            let gst = form.getFieldValue("gst");
            gst = gst ? Number(gst) : 0;
            taxableAmount = taxableAmount ? Number(taxableAmount) : 0;
            let gstAmount = Math.round((gst/100) * taxableAmount * 100) / 100;
            form.setFieldsValue({ gstAmount:  gstAmount});

            form.setFieldsValue({ amountPayable: taxableAmount + gstAmount})
        } 
        
        try {
            const values = await form.validateFields();

            // toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable && !record.sumRow) {
        childNode =
            // editing ? (
            <>
                {dataIndex === "amountPayable" &&
                    <div style={{ position: "absolute", zIndex: 1000, width: 54 }}>
                        <Form.Item
                            initialValue={record["isRecoverable"] ? true : false}
                            style={{ margin: 0 }}
                            name={"isRecoverable"}
                        >
                            <Select
                                disabled={!edittingForm || ["Not Needed", "Added in New Ticket", "Not Resolved"].includes(record.status)}
                                onSelect={save}
                                ref={inputRef}
                                placeholder="Select Status"
                                size="small"
                                variant="borderless"
                                suffixIcon={<DownOutlined style={{ fontSize: 8, marginRight: 8, pointerEvents: 'none' }} />}
                                style={{ padding: 0, fontSize: 13 }}
                            >
                                <Select.Option value={true}>R</Select.Option>
                                <Select.Option value={false}>NR</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                }

                <Form.Item
                    initialValue={record[dataIndex]}
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[{
                        required: false,
                        // message: `${title} is required.` 
                        message: ""
                    }]}
                >
                    {dataIndex == "workType" &&
                        <Select
                            disabled={!edittingForm || ["Not Needed", "Added in New Ticket", "Not Resolved"].includes(record.status)}
                            onSelect={save} onBlur={()=>save()}
                            ref={inputRef}
                            placeholder="Select Work Type"
                            // size="large" 
                            // variant="borderless" 
                            suffixIcon={edittingForm && <DownOutlined style={{ fontSize: 8, marginRight: -5, pointerEvents: 'none' }} />}

                            style={{ padding: 0, fontSize: 13 }}
                        >
                            {workTypes.map((item) => {
                                return (<Select.Option value={item.label}>
                                    {item.label}
                                </Select.Option>)
                            })}
                        </Select>}


                    {(dataIndex === "quantity" || dataIndex === "unitPrice" || dataIndex === "taxableAmount" || dataIndex === "discountedAmount" || dataIndex === "basePrice" || dataIndex === "gstAmount") &&
                        <Input
                            className='input-number'
                            type="number"
                            disabled={!edittingForm || ["basePrice", "taxableAmount", "gstAmount"].includes(dataIndex) || ["Not Needed", "Added in New Ticket", "Not Resolved"].includes(record.status) || (["basePrice", "gst", "gstAmount"].includes(dataIndex) && record.status === "Not Resolved")}
                            prefix={(dataIndex === "quantity") ? null : "₹"} placeholder={`Enter ${title}`}
                            // suffix={(dataIndex === "gst") ? "%" : null}
                            style={{ fontSize: 13 }}
                            size="middle"
                            ref={inputRef}
                            onChange={()=>save(dataIndex)}
                        />
                    }
                    {
                        dataIndex === "gst" &&
                        <Select
                            disabled={!edittingForm || ["Not Needed", "Added in New Ticket", "Not Resolved"].includes(record.status) || (["basePrice", "gst", "gstAmount"].includes(dataIndex) && record.status === "Not Resolved")}
                            placeholder={`Enter ${title}`}
                            // suffix={(dataIndex === "gst") ? "%" : null}
                            style={{ fontSize: 13, width: 80 }}
                            size="middle"
                            ref={inputRef}
                            onChange={()=>save(dataIndex)}
                        >
                            {/* 0,5,12,18,28 */}
                            <Select.Option value={0}>0 %</Select.Option>
                            <Select.Option value={5}>5 %</Select.Option>
                            <Select.Option value={12}>12 %</Select.Option>
                            <Select.Option value={18}>18 %</Select.Option>
                            <Select.Option value={28}>28 %</Select.Option>
                        </Select>
                    }

                    {(dataIndex === "workDone") &&
                        <Input disabled={!edittingForm || ["Not Needed", "Added in New Ticket", "Not Resolved"].includes(record.status)} placeholder={`Enter ${title}`} style={{ fontSize: 13 }} size="middle" ref={inputRef} onChange={()=>save()} />
                    }

                    {dataIndex === "amountPayable" &&
                        <>
                            <Input disabled={true} prefix={"₹"} placeholder={`Enter ${title}`} style={{ fontSize: 13, paddingLeft: 40 }} size="middle" ref={inputRef} onChange={()=>save()} />
                        </>
                    }

                    {dataIndex == "status" &&
                        <>
                            <Select
                                className='status-select'
                                disabled={!edittingForm}
                                onSelect={save}
                                ref={inputRef}
                                placeholder="Select Status"
                                size="small"
                                variant="borderless"
                                suffixIcon={edittingForm && <DownOutlined style={{ fontSize: 8, position: "absolute", left: -18, pointerEvents: 'none' }} />}
                                style={{ padding: 0, fontSize: 13, position: "relative", width: "fit-content" }}
                            >
                                {statuses.map((item) => {
                                    return (<Select.Option style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "unset" }} value={item.label}>
                                        <Tag color={item.bgColor} style={{ color: item.color, padding: `3px ${!edittingForm ? "10px" : "30px"} 3px 10px` }} >
                                            {item.label}
                                        </Tag>
                                    </Select.Option>)
                                })}
                            </Select>
                        </>
                    }



                </Form.Item>
                {record?.needsAction && 
                <Tooltip title={<Typography.Text style={{color: "black"}}>Update this status</Typography.Text>} color='white'>
                    <ExclamationCircleOutlined style={{ position: "absolute", right: 11, top: 15, color: "red" }} />
                </Tooltip>
                
                }

            </>

    }

    return <td {...restProps}>{childNode}</td>;
};

export interface IssueDataType {
    "id": number,
    "subIssueId": number,
    "status": string,
    "serviceType": string,
    "isDemandedJob": boolean,
    "workType": string,
    "quantity": number,
    "unitPrice": number,
    // "estimatedAmount": number,
    "taxableAmount": number,
    "discountedAmount": number,
    "basePrice": number,
    "gst": string,
    "gstAmount": number,
    "amountPayable": number,
    "isRecoverable": boolean,
    "workDone": string,
    "issue": string,
    "subIssue": string,
    "reportedAt": string,
    "lastUpdatedAt": string,
    "sumRow"?: boolean,
    "needsAction"?: boolean

}

export interface saveDetailsParams {
    "id": number,
    "subIssueId": number,
    "serviceType": string,
    "status": string,
    "isDemandedJob": boolean,
    "workType": string,
    "quantity": number,
    "unitPrice": number,
    // "estimatedAmount": number,
    "taxableAmount": number,
    "discountedAmount": number,
    "basePrice": number,
    "gst": number,
    "gstAmount": number,
    "amountPayable": number,
    "isRecoverable": boolean,
    "workDone": string
}


interface PropType {
    jobCardId: number,
    saveDetails: (values: saveDetailsParams[]) => void,
    setShowWorkshopModal: (value: boolean) => void,
    statuses: [] | OptionType[],
    workTypes: [] | OptionType[],
    resetting: boolean,
    fullDetails: IssueDataType[],
    originalDetails: IssueDataType[]
    setFullDetails: (value: IssueDataType[]) => void,
    ticketId: string,
    getJobCardIssueItems: () => void,
    saving: boolean,
    ticketStatusId: number,
    setEdittingForm: (value: boolean) => void
    edittingForm: boolean,
    editingInvoiceForm: boolean,
    serviceStatusUpdateDate: string
}


type ColumnTypes = Exclude<TableProps<IssueDataType>['columns'], undefined>;

function IssueJobForm({ jobCardId, setShowWorkshopModal, saveDetails, statuses, workTypes, resetting, fullDetails, originalDetails, setFullDetails, ticketId, getJobCardIssueItems, saving, ticketStatusId, edittingForm, setEdittingForm, editingInvoiceForm, serviceStatusUpdateDate }: PropType) {

    const [count, setCount] = useState(2);
    const [showEditIssue, setShowEditIssue] = useState(false);
    const [jobId, setJobId] = useState<number>(0);
    const [issueId, setIssueId] = useState<string>("");
    const [subissueId, setSubissueId] = useState<string>("");
    const [valuesChanged, setValuesChanged] = useState(false);

    const editIssues = (payload: any) => {
        setIssueId(payload?.issueId)
        setSubissueId(payload?.subIssueId)
        setJobId(payload?.id)
        setShowEditIssue(true);
    }

    useEffect(() => {
        setValuesChanged(false);
    }, [originalDetails])

    const issueCreatedBeforeStatusChange = (reportedAt: string, serviceStatusUpdateDate: string): boolean => {

        // Convert both strings to Date objects
        const reportedAtDate = new Date(reportedAt);
        const serviceStatusUpdateDateObj = new Date(serviceStatusUpdateDate);

        reportedAtDate.setMilliseconds(0);
        serviceStatusUpdateDateObj.setMilliseconds(0);

        // Compare the two dates
        return reportedAtDate < serviceStatusUpdateDateObj;
    }

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Issue',
            dataIndex: 'issue',
        },
        {
            title: 'Sub Issue',
            dataIndex: 'subIssue',
        },
        {
            title: 'Work Type',
            dataIndex: 'workType',
            editable: true,
            width: 80
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            editable: true,
            width: 100
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            editable: true,
            width: 120
        },
        {
            title: 'Discounted Amount',
            dataIndex: 'discountedAmount',
            editable: true,
            width: 120
        },
        {
            title: 'Taxable Amount',
            dataIndex: 'taxableAmount',
            editable: true,
            width: 120
        },
        {
            title: 'Amount Payable',
            dataIndex: 'amountPayable',
            editable: true,
            width: 120
        },
        {
            title: 'Base Price',
            dataIndex: 'basePrice',
            editable: true,
        },
        {
            title: 'GST Percentage',
            dataIndex: 'gst',
            editable: true,
        },
        {
            title: 'GST Amount',
            dataIndex: 'gstAmount',
            editable: true,
        },
        {
            title: 'Work Done',
            dataIndex: 'workDone',
            editable: true,
        },

        {
            title: 'Status',
            dataIndex: 'status',
            editable: true,
        },

        {
            title: 'Reported At',
            dataIndex: 'reportedAt',
            width: 150,
            render: (text: string, record: any) => text != null ? `${moment(text).format("hh:mm A, DD MMM YYYY ")}` : record.id !== null ? <span style={{ fontSize: 12 }}>NA</span> : ""
        },
        {
            title: 'Last Updated At',
            dataIndex: 'lastUpdatedAt',
            width: 150
        },

        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) =>
                !record.sumRow && <div style={{ display: "flex", justifyContent: "flex-start" }}>


                    {issueCreatedBeforeStatusChange(record?.reportedAt, serviceStatusUpdateDate) ?
                        // Case when true: Disabled state with pointer on hover
                        <div>
                            <Tooltip placement="bottom" title={"Delete Issue"}>
                                <a
                                    style={{ color: 'grey', cursor: 'not-allowed' }}
                                >
                                    <DeleteIcon style={{ marginRight: '0.1rem', color: "grey" }} />

                                </a>
                            </Tooltip>

                            <span style={{ marginRight: '0.2rem', color: 'grey', marginLeft: '0.2rem' }}>| </span>

                            <Tooltip placement="bottom" title={"Edit Issue"}>
                                <a
                                    style={{ color: 'grey', cursor: 'not-allowed' }}
                                >
                                <EditPenIcon style={{ marginTop: '4px', color: "grey" }} />
                                </a>
                            </Tooltip>
                        </div>
                        :
                        <>
                            <Tooltip placement="bottom" title={"Delete Issue"}>
                                <Popconfirm
                                    title="Are you sure to delete this issue?"
                                    onConfirm={() => deleteIssues(record.id)}
                                >
                                    <DeleteIcon style={{ marginRight: '0.6rem', color: "red", marginTop: 4 }} />
                                </Popconfirm>
                            </Tooltip>
                            <span style={{ marginRight: '0.6rem', color: 'grey' }}>| </span>
                            <Tooltip placement="bottom" title={"Edit Issue"}>
                                <EditPenIcon onClick={() => editIssues(record)} style={{ marginRight: '0.6rem', marginTop: '4px', color: "rgb(44, 102, 227)" }} />
                            </Tooltip>
                        </>}
                </div>,
        },
    ];

    const deleteIssues = async (jobId: number) => {
        try {
            const response: any = await axios.delete(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-job-card-issue?id=${jobId}`);
            if (response.status !== 200) {
                message.error(response.msg || "Failed to  delete  issue.");
            }
        } catch (error) {
            message.error("An error occurred while delete Issue.");
        } finally {
            getJobCardIssueItems()
        }
    }

    

    const handleSave = (row: IssueDataType) => {
        console.log(row);
        const newData = [...fullDetails];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        
        const params = newData.filter((item) => item.id !== null);
        const originalParams = originalDetails.filter((item) => item.id !== null);
        const changedValues = params.filter((item, index) => !_.isEqual(item, originalParams[index]));

        if (changedValues.length > 0) {
            setValuesChanged(true);
        } else {
            setValuesChanged(false);
        }
        setFullDetails(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: IssueDataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                statuses,
                workTypes,
                edittingForm,
                ticketStatusId
            }),
        };
    });

    const onCloseEditIssue = () => {
        setShowEditIssue(false);
    }


    const mapDetails = (details: any[]) =>
        details.map(({ id, subIssueId, serviceType, status, isDemandedJob, workType, quantity, unitPrice, taxableAmount, discountedAmount, basePrice, gst, gstAmount, amountPayable, isRecoverable, workDone }) => ({
            id,
            subIssueId,
            serviceType,
            status,
            isDemandedJob,
            workType,
            quantity: Number(quantity),
            unitPrice: Number(unitPrice),
            // estimatedAmount: Number(estimatedAmount),
            taxableAmount: Number(taxableAmount),
            discountedAmount: Number(discountedAmount),
            basePrice: Number(basePrice),
            gst,
            gstAmount: Number(gstAmount),
            amountPayable: Number(amountPayable),
            isRecoverable,
            workDone,
        })).filter((item) => item.id !== null);

    const handleSaveDetails = () => {
        const params = mapDetails(fullDetails);
        const originalParams = mapDetails(originalDetails);
    
        // Get changed values
        const changedValues = params.filter((item, index) => !_.isEqual(item, originalParams[index]));
    
        saveDetails(changedValues);
        setEdittingForm(false);
    };


    const handleCancelForm = () => {
        setEdittingForm(false);
        getJobCardIssueItems();
    }

    useEffect(() => {
        if (editingInvoiceForm && edittingForm) {
            handleCancelForm()
        }
    }, [editingInvoiceForm])

    return (
        <>
            <div style={{ marginTop: 16, background: "white", padding: 16, borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)" }}>
                {/* <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
            </Button> */}

                <Table<IssueDataType>
                    loading={resetting}
                    components={components}
                    rowClassName={(record) => record.id !== null ? 'editable-row' : 'editable-row sum-row'}
                    bordered
                    size='small'
                    pagination={false}
                    dataSource={fullDetails.filter((item) => edittingForm ? !item.sumRow : item)}
                    columns={
                        // ticketStatusId === 6 ? columns.filter((item) => item.dataIndex !== "gst" && item.dataIndex !== "gstAmount" && item.dataIndex !== "basePrice" && item.dataIndex !== "amountPayable") as ColumnTypes : 
                        columns as ColumnTypes}
                    scroll={{ x: 2000 }}
                />

                <div style={{ textAlign: "right", marginTop: 16 }}>
                    <Space>
                        {!edittingForm && ticketStatusId !== 9 && ticketStatusId !== 12 && <Button style={{ background: "rgba(23, 119, 255, 0.10)" }} ghost type="primary" onClick={() => setShowWorkshopModal(true)}>Edit Workshop</Button>}
                        {!edittingForm && ticketStatusId !== 12 && <Button icon={<EditPenIcon />} onClick={() => setEdittingForm(true)} type="primary">Edit Details</Button>}
                        {edittingForm && <Button onClick={() => handleCancelForm()} type="default">Cancel</Button>}
                        {edittingForm && <Button disabled={saving || !valuesChanged} loading={saving} onClick={handleSaveDetails} type="primary">Save Details</Button>}
                    </Space>
                </div>
            </div>
            {showEditIssue && (
                <EditIssues
                    getJobCardIssueItems={getJobCardIssueItems}
                    isJobCardIssue={true}
                    setShowEditIssue={setShowEditIssue} ticketId={ticketId} jobCardId={jobCardId}
                    issueId={issueId} subIssueId={subissueId} showEditIssue={showEditIssue}
                    onCloseEditIssue={onCloseEditIssue} jobId={jobId} />
            )}
        </>

    );
};

export default IssueJobForm;