import { Button, Col, DatePicker, Form, Row, Select, Typography, message } from "antd";
import { useEffect, useState } from "react";
import CustomSelectSearchForWorkshopName from './CustomSelectSearchForWorkshopName'; // Import the new custom select component
import { getRequest, postRequest } from "../../../../../../utils/handler/apiHandler";
import dayjs from "dayjs";
import { getFormatedDateTimeWithChangingTimeToLocale, getFormatedDateTimeWithoutChangingTime, getFormattedDateToDisplayWithoutChangingTimeZone } from "../../../../../../utils/helpers/utils";

interface WorkshopDetailsFormProps {
  workshopDetailsData: any;
  setWorkshopDetailsData: (data: any) => any;
  setFetchTicketDataCnt: (cnt:number) => void,
  ticketId: string,
  editable: boolean,
  setEditable: (flag: boolean) => void,
  showCancelWorkshop: boolean,
  setShowCancelWorkshop: (flag: boolean) => void,
  closeModal?: () => void;
}

const { Option } = Select;
const { Title } = Typography;

const WorkshopDetailsForm: React.FC<WorkshopDetailsFormProps> = ({ workshopDetailsData, setWorkshopDetailsData, 
    setFetchTicketDataCnt, ticketId, editable, setEditable, showCancelWorkshop, setShowCancelWorkshop, closeModal }) => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [selectedScheduleServiceSlotDate, setSelectedScheduleServiceSlotDate] = useState<any>(null);
  const [selectedWorkshopId, setSelectedWorkshopId] = useState<any>();
  const [selectedWorkshopCityName, setSelectedWorkshopCityName] = useState<any>();
  const [selectedServiceSite, setSelectedServiceSite] = useState<any>();
  const [selectedWorkshopDetailsData, setSelectedWorkshopDetailsData] = useState<any>({});
  const [serviceSiteList, setServiceSiteList] = useState<any[]> ([]);
  const [originalData, setOriginalData] = useState<any>(null);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false); // Loader state for API calls
  const [cities, setCities] = useState<any[]>([]); 
  const [workshopOptions, setWorkshopOptions] = useState<any[]>([]); // Holds the options for the Select dropdown
  
  useEffect(() => {
      fetchCities('');
      setSelectedWorkshopDetailsData(workshopDetailsData);
      setSelectedWorkshopCityName(workshopDetailsData?.workshopCity);
      setSelectedScheduleServiceSlotDate(workshopDetailsData?.scheduleServiceSlot 
          ? getFormatedDateTimeWithChangingTimeToLocale(workshopDetailsData?.scheduleServiceSlot)
          : null);
      setSelectedServiceSite(workshopDetailsData?.serviceSite);
      if(originalData === null || (originalData && Object.values(originalData)?.every(value => !value))){
        setOriginalData({
          workshopName: workshopDetailsData?.workshopName,
          workshopId: workshopDetailsData?.workshopId,
          serviceSite: workshopDetailsData?.serviceSite,
          scheduleServiceSlot: workshopDetailsData?.scheduleServiceSlot 
            ? getFormatedDateTimeWithChangingTimeToLocale(workshopDetailsData?.scheduleServiceSlot) 
            : null,
        })
      }
      fetchServiceSiteList();   
  }, [workshopDetailsData]);

  useEffect(() => {
    
    const localScheduleServiceSlot = originalData?.scheduleServiceSlot 
      ? getFormatedDateTimeWithoutChangingTime(originalData?.scheduleServiceSlot)
      : null;

    const localSelectedScheduleServiceSlotDate = selectedScheduleServiceSlotDate
      ? getFormatedDateTimeWithoutChangingTime(selectedScheduleServiceSlotDate)
      : null;

    // Check if the two dates are the same
    const isDataChanged = !localScheduleServiceSlot || !localSelectedScheduleServiceSlotDate 
      ? false // Return false if any date is null or undefined
      : localSelectedScheduleServiceSlotDate != localScheduleServiceSlot;

    const workShopChanged = (selectedWorkshopId ? selectedWorkshopId : selectedWorkshopDetailsData?.workshopId) !== originalData?.workshopId;
    const serviceSiteChanged = selectedServiceSite !== originalData?.serviceSite;

    if((isDataChanged || workShopChanged || serviceSiteChanged) && originalData){
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [selectedScheduleServiceSlotDate, selectedWorkshopId, selectedServiceSite])

  useEffect(()=>{
    if(editable){
      const scheduleServiceSlotDate = selectedWorkshopDetailsData?.scheduleServiceSlot
      ? dayjs(selectedWorkshopDetailsData?.scheduleServiceSlot).subtract(5, 'hour').subtract(30, 'minute')
      : null;

      form.setFieldsValue({
        workshopName: selectedWorkshopDetailsData?.workshopName,
        workshopId: selectedWorkshopDetailsData?.workshopId,
        serviceSite: selectedWorkshopDetailsData?.serviceSite,
        scheduleServiceSlot: scheduleServiceSlotDate,
    });
    }
  },[editable, selectedWorkshopDetailsData]);

  // Function to fetch cities based on search term
  const fetchCities = async (search: string) => {
    setIsLoading(true); // Show loader while fetching
    // const encodedFilter = encodeURIComponent(`city|like|${search}`);
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop/distinct-city?city=${search}`
      );
      setCities(response?.data?.items || []); // Update options with response data
    } catch (error) {
      message.error('Failed to fetch workshops.'); // Show error message on failure
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const fetchServiceSiteList = async () => {
    setLoader(true); // Show loader while fetching
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=ServiceSite`
      );
      setServiceSiteList(response?.data || []); // Update options with response data
    } catch (error) {
      message.error('Failed to fetch serviceSiteList.'); // Show error message on failure
    } finally {
      setLoader(false); // Hide loader
    }
  };
  
  // const submitWorkshopDetailForm = async () => {
  //   setLoader(true);
  //   setShowCancelWorkshop(false);
  //   setEditable(false);
  //   try {
  //     const payload = {
  //       scheduleDate: selectedScheduleServiceSlotDate ? 
  //         getFormatedDateTimeWithoutChangingTime(form.getFieldValue('scheduleServiceSlot')):
  //         null,
  //       workshopId: selectedWorkshopId ? selectedWorkshopId : null,
  //       serviceSite: selectedServiceSite ? form.getFieldValue('serviceSite') : null,
  //     };
  
  //     const response = await postRequest(
  //       `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/workshop-detail?ticketId=${ticketId}`,
  //       payload
  //     );
  //   } catch (error) {
  //     message.error('Failed to Submit workshop details');
  //   } finally {
  //     setLoader(false);
  //     if(closeModal){
  //       closeModal();
  //     }
  //     window.location.reload();
  //     // setFetchTicketDataCnt(Math.random());
  //   }
  // };
  const submitWorkshopDetailForm = async () => {
    setLoader(true);
    setShowCancelWorkshop(false);
    setEditable(false);
    try {
      // Check if scheduleServiceSlotDate has changed
      const localScheduleServiceSlot = originalData?.scheduleServiceSlot
        ? getFormatedDateTimeWithoutChangingTime(originalData?.scheduleServiceSlot)
        : null;
  
      const localSelectedScheduleServiceSlotDate = selectedScheduleServiceSlotDate
        ? getFormatedDateTimeWithoutChangingTime(selectedScheduleServiceSlotDate)
        : null;
  
      // Compare dates and send null if no change in scheduleServiceSlotDate
      const scheduleDate = (localSelectedScheduleServiceSlotDate !== localScheduleServiceSlot)
        ? getFormatedDateTimeWithoutChangingTime(form.getFieldValue('scheduleServiceSlot'))
        : null;
  
      const payload = {
        scheduleDate, // Will be null if no change
        workshopId: selectedWorkshopId ? selectedWorkshopId : null,
        serviceSite: selectedServiceSite ? form.getFieldValue('serviceSite') : null,
      };
  
      const response = await postRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/workshop-detail?ticketId=${ticketId}`,
        payload
      );
    } catch (error) {
      message.error('Failed to Submit workshop details');
    } finally {
      setLoader(false);
      if (closeModal) {
        closeModal();
      }
      window.location.reload();
      // setFetchTicketDataCnt(Math.random());
    }
  };
  
  const handleWorkshopChange = (selectedWorkshopId: string) => {
    setSelectedWorkshopId(selectedWorkshopId);
    fetchWorkshopDataWithId(selectedWorkshopId);
  };

  const handleWorkshopCityChange = (selectedWorkshopCityName: string) => {
    setSelectedWorkshopCityName(selectedWorkshopCityName);
    handleWorkshopChange('-1');
    fetchWorkshopOptionsWithCityName(selectedWorkshopCityName);
  };

  const fetchWorkshopOptionsWithCityName = async (selectedWorkshopCityName: string) => {
    setLoader(true); // Show loader while fetching
    const filter = encodeURIComponent(`city|like|${selectedWorkshopCityName}&isLocalVendor|eqb|false&active|eqb|true`);
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop?filterAnd=${filter}`
      );
      setWorkshopOptions(response?.data?.items || []);
    } catch (error) {
      message.error('Failed to fetch workshops by City.'); // Show error message on failure
    } finally {
        setLoader(false); // Hide loader
    }
  };

  const fetchWorkshopDataWithId = async (selectedWorkshopId: string) => {
    setLoader(true); // Show loader while fetching
    const filter = encodeURIComponent(`id|eq|${selectedWorkshopId}&active|eqb|true`);
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop?filterAnd=${filter}`
      );
      setWorkshopDetailsDataFromResponse(response?.data?.items[0] || [], selectedWorkshopId);
    } catch (error) {
      message.error('Failed to fetch workshops by id.'); // Show error message on failure
    } finally {
        setLoader(false); // Hide loader
    }
  };
  const setWorkshopDetailsDataFromResponse = (data: any, selectedWorkshopId: string) => {
    
    let newScheduleServiceSlot: any = null;
    if(selectedScheduleServiceSlotDate){
      newScheduleServiceSlot = dayjs(selectedScheduleServiceSlotDate).add(5, 'hour').add(30, 'minute');
    }

    setSelectedWorkshopDetailsData((prevData: any) => ({
      ...prevData,
      workshopId: selectedWorkshopId,
      workshopName: data?.name,
      spocName: data?.spoc,
      isLocalVendor: data?.isLocalVendor,
      spocNumber: data?.contact,
      emailId: data?.email,
      workshopCity: data?.city,
      address: data?.address,
      scheduleServiceSlot: newScheduleServiceSlot,
      serviceSite: selectedServiceSite,
    }));
    
  };

  const handleScheduleServiceSlot = (value:any)=>{
    setSelectedScheduleServiceSlotDate(value); 
  }

  const handleServiceSiteChange = (value:any)=>{
    setSelectedServiceSite(value);
  }

  useEffect(() => {
    form.setFieldValue("workshopCity",selectedWorkshopDetailsData?.workshopCity  )
    fetchWorkshopOptionsWithCityName(selectedWorkshopDetailsData?.workshopCity);
  }, [cities]);

  return (
    <>
      <div>
        <Form
          name="dynamic_form_nest_item"
          form={form}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
        >
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col className="detail-description-item" style={{width:'33%'}}>
              <Typography className="description-label">
                Schedule Service Slot
              </Typography>
              {editable ? (
                <Form.Item
                  name="scheduleServiceSlot"
                  className="form-label"
                  style={{ margin: 0, fontSize: 12 }}
                  required={true}
                  
                >
                  <DatePicker value = {selectedScheduleServiceSlotDate} showTime format="YYYY-MM-DD hh:mm a" style={{width:'100%'}} onChange={(date) => handleScheduleServiceSlot(date)}/>
                </Form.Item>
              ) : (
                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                  <span style={{ display: loader ? 'none' : '' }}>
                    {loader ? '' : selectedWorkshopDetailsData?.scheduleServiceSlot ? 
                      getFormattedDateToDisplayWithoutChangingTimeZone(selectedWorkshopDetailsData?.scheduleServiceSlot)
                      : 'NA'}
                  </span>
                </Typography>
              )}
            </Col>
            {
              editable && 
              <Col className="detail-description-item" style={{width:'33%'}}>
              <Typography className="description-label">
                Workshop City
              </Typography>
              {editable ? (
                <Form.Item
                  name="workshopCity"
                  className="form-label"
                  style={{ margin: 0, fontSize: 12 }}
                  required={true}
                >
                  <Select
                    onChange={handleWorkshopCityChange}
                    value={selectedWorkshopDetailsData?.workshopCity || 'Delhi'} // Ensure undefined if not set
                    showSearch={true}
                    placeholder="Search Workshop City"
                  >
                    {cities && cities.length > 0 ? (
                      cities.map((city) => (
                        <Option key={city.city} value={city.city}>
                          {city.city}
                        </Option>
                      ))
                    ) : (
                      <Option value="" disabled>No cities available</Option> // Optional: Show a default option if no cities
                    )}
                  </Select>
                  </Form.Item>
                ) : (
                  <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                    {loader ? '' : selectedWorkshopDetailsData?.workshopCity ? 
                      selectedWorkshopDetailsData?.workshopCity
                      : 'NA'}
                  </Typography>
                )}
              </Col>

            }
            

            <Col className="detail-description-item" style={{width:'33%'}}>
              <Typography className="description-label">
                Workshop Name
              </Typography>
              {editable ? (
                <Form.Item
                  name="workshopName"
                  className="form-label"
                  style={{ margin: 0, fontSize: 12 }}
                  required={true}
                >
                  <CustomSelectSearchForWorkshopName
                    value={selectedWorkshopDetailsData?.workshopId}
                    workshopCity={selectedWorkshopCityName}
                    workshopOptions = {workshopOptions}
                    setWorkshopOptions = {setWorkshopOptions}
                    onChange={handleWorkshopChange}
                    isLocalVendor = {selectedWorkshopDetailsData?.isLocalVendor}
                    selectedWorkshopDetailsData = {selectedWorkshopDetailsData}
                  />
                </Form.Item>
              ) : (
                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                  {loader ? '' : selectedWorkshopDetailsData?.workshopName ? 
                    (selectedWorkshopDetailsData?.isLocalVendor ? `Local Vendor - ${selectedWorkshopDetailsData?.workshopName}` :  selectedWorkshopDetailsData?.workshopName)
                    : 'NA'}
                </Typography>
              )}
            </Col>
            {
              !editable && 
              <Col className="detail-description-item" style={{width:'33%'}}>
                <Typography className="description-label">
                  Service Site
                </Typography>
                
                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                  {loader ? '' : selectedWorkshopDetailsData?.serviceSite ? 
                    selectedWorkshopDetailsData?.serviceSite
                    : 'NA'}
                </Typography>
              </Col>
            }
            
          </Row>
          <Row>
            {
              editable && 
              
                <Col className="detail-description-item" style={{ width:'33%'}}>
                  <Typography className="description-label">
                    Service Site
                  </Typography>
                    <Form.Item
                        name="serviceSite"
                        className="form-label"
                        style={{ margin: 0, fontSize: 12 }}
                        required={true}
                    >
                      
                        <Select
                          value={selectedWorkshopDetailsData?.serviceSite}
                          onChange={handleServiceSiteChange}
                          loading={loader}
                          placeholder="Search Service Site"
                          notFoundContent={loader ? 'Loading...' : null}
                          // style={{width:'100%'}}
                        >
                          {serviceSiteList.map((serviceSite) => (
                            <Option key={serviceSite.label} value={serviceSite.label}>
                              {serviceSite.label}
                            </Option>
                          ))}
                        </Select>
                    </Form.Item>
                </Col>
            }
            {
              selectedWorkshopDetailsData?.workshopId &&
              <>
                <Col className="detail-description-item" style={{ width:'33%', marginLeft: editable ? '0.3rem': '0rem'}}>
                    <Typography className="description-label">
                      SPOC Name
                    </Typography>
                    <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                      {loader ? '' : selectedWorkshopDetailsData?.spocName}
                    </Typography>
                </Col>

                <Col className="detail-description-item" style={{ width:'33%', marginLeft: editable ? '0.3rem': '0.2rem'}}>
                  <Typography className="description-label">
                    SPOC Number
                  </Typography>
                  <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                    {loader ? '' : selectedWorkshopDetailsData?.spocNumber}
                  </Typography>
                </Col>
                {
                  !editable && 
                  <Col className="detail-description-item"  style={{ width:'33%', marginLeft:'0.4rem'}}>
                    <Typography className="description-label">
                      Email ID
                      <span style={{ color: '#B2B2B2', marginLeft: '4px' }}>(optional)</span>
                    </Typography>
                    <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                      {loader ? '' : selectedWorkshopDetailsData?.emailId}
                    </Typography>
                  </Col>
                }
              </>
            }
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
              
          {selectedWorkshopDetailsData?.workshopId && (
            <>
                {
                  editable && 
                  <Col className="detail-description-item" style={{ width:'33%'}}>
                    <Typography className="description-label">
                      Email ID
                      <span style={{ color: '#B2B2B2', marginLeft: '4px' }}>(optional)</span>
                    </Typography>
                    <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                      {loader ? '' : selectedWorkshopDetailsData?.emailId}
                    </Typography>
                  </Col>
                }               

                
                {
                  !editable && 
                  <Col className="detail-description-item" style={{ width:'33%'}}>
                    <Typography className="description-label">
                      Workshop City
                    </Typography>
                    <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                      {loader ? '' : selectedWorkshopDetailsData?.workshopCity}
                    </Typography>
                  </Col>
                }
                

                <Col className="detail-description-item" style={{ width:'66.5%'}}>
                  <Typography className="description-label">
                    Address
                  </Typography>
                  <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                    {loader ? '' : selectedWorkshopDetailsData?.address}
                  </Typography>
                </Col>
              
            </>
          )}
          
          </Row>
          {
                editable && 
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
                  <Button 
                    disabled={buttonDisabled}
                    onClick={submitWorkshopDetailForm}
                    type="primary"
                  >
                    Save Workshop Details
                  </Button>
                </div>
          }
          
        </Form>
      </div>

    </>
  );
};

export default WorkshopDetailsForm;
