import { CheckCircleFilled, CloseOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { style } from "@mui/system";
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Steps, Typography } from "antd";
import { useEffect, useState } from "react";
import BasicDetailsForm from "./BasicDetailsForm";
import ClientDetailsForm from "./ClientDetailsForm";
import ParentDetailsForm from "./ParentDetailsForm";
import ContractDetailsForm from "./ContractDetailsForm";
import AgreementDetailsForm from "./AgreementDetailsForm";
import debounce from "lodash/debounce";
import { getRequest } from "../../../utils/handler/apiHandler";
import { ProspectDetailsType } from "./types/Prospect";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const formSteps = [
    {
        name: "BasicDetails",
        value: "Basic Details"
    },
    {
        name: "AdditionalClientDetails",
        value: "Additional Client Details"
    },
    {
        name: "ParentsDetails",
        value: "Parent Details"
    },
    {
        name: "ContractDetails",
        value: "Contract Details"
    },
    {
        name: "AgreementDetails",
        value: "Agreement Details"
    },
    {
        name: "Agreement Details",
        value: "On Boarding Completed"
    }
]

enum formStepsEnum {
    BasicDetails,
    AdditionalClientDetails,
    ParentsDetails,
    ContractDetails,
    AgreementDetails,
}

const SuccessIcon = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: 36 }}>
            <CheckCircleFilled color="green" style={{ fontSize: 32, color: "#02B51F" }} />
        </div>
    )
}

const InProgressIcon = () => {
    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: 36 }}>
        <div style={{ border: "1px solid #2C66E3", borderRadius: "100%" }}>
            <div style={{ borderRadius: "100%", background: "#2C66E3", height: 22, width: 22, margin: 4 }}></div>
        </div>

    </div>
}

const PendingIcon = () => {
    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: 36 }}>
        <div style={{ background: "#86A4E3", borderRadius: "100%" }}>
            <div style={{ borderRadius: "100%", height: 22, width: 22, margin: 4 }}></div>
        </div>
    </div>
}


export default function OnBoardNewClient() {

    const [showForm, setShowForm] = useState(false)
    const [showProspectExistsModal, setShowProspectExistsModal] = useState(false)
    const [showNotFoundModal, setShowNotFound] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [prospectId, setProspectId] = useState(null)
    const [prospectDetails, setProspectDetails] = useState<ProspectDetailsType | null>(null)
    const [docTypes, setDocTypes] = useState([])
    const [prospectStep, setProspectStep] = useState("1")
    const navigate = useNavigate()

    // const handleSelection = (value: string) => {
    //     console.log("value", value);
    //     if (value === "KLRE222132332") {
    //         setShowProspectExistsModal(true)
    //     } else if (value === "KLRE222132331") {
    //         setShowNotFound(true)
    //     } else {
    //         setShowForm(true)
    //     }
    //     // setShowForm(true)
    // }

    useEffect(() => {
        if (prospectId) {
            checkSanctionLetter()
        }
    }, [prospectId])

    // useEffect(() => {
    //     prospectDetails?.clientOnBoardingId && getDocTypes()
    // }, [prospectDetails])


    const checkSanctionLetter = async () => {
        setLoading(true)
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/searchSanctionLetter?prospectId=${prospectId}`);
            console.log(response.data)
            if (response.data) {
                getProspectDetailsAndNavigate()
            } else {
                getProspectDetails()
                setShowNotFound(true)
            }
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    }

    const getDocTypes = async () => {
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/getAgreementDocs?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`);
            console.log("response.data doc types", response.data)
            setDocTypes(response.data)

        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    const getProspectDetailsAndNavigate = async () => {
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/searchClientByProspectId?prospectId=${prospectId}`);
            console.log("response.data", response.data)
            setProspectDetails(response.data)
            if (response.data.clientStateStatus == "On Boarding Completed") {
                setShowProspectExistsModal(true)
            } else {
                viewDetails(response.data)
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    const getProspectDetails = async () => {
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/searchClientByProspectId?prospectId=${prospectId}`);
            console.log("response.data", response.data)
            setProspectDetails(response.data)
            // if (response.data.clientStateStatus == "On Boarding Completed") {
            //     setShowProspectExistsModal(true)
            // } else {
            //     viewDetails(response.data)
            // }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }


    const viewDetails = (prospectDetails: any) => {
        console.log("clientStateStatus",)
        const savedStep: any = formSteps.find((i: any) => i.value == prospectDetails?.clientStateStatus)
        setCurrentStep(parseInt(formStepsEnum[savedStep?.name]) + 1)
        setShowProspectExistsModal(false)
        setShowNotFound(false)
        setShowForm(true)
    }

    const handleSearch = debounce((value) => {
        if (value.length >= 1) {
            setSearchQuery(value);
            fetchData(0, value);
        }
    }, 500);

    const handleScroll = (e: any) => {
        const { target } = e;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight && hasMore && !loading) {
            fetchData(page + 1, searchQuery); // Load next page
        }
    };

    const fetchData = async (page: any, query: any) => {
        setLoading(true);
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/prospectDetails?page=${page}&size=10&request=${query}`);
            console.log("response.data", response.data)
            const fetchedData = response.data.data;
            setHasMore(fetchedData.length > 0); // Check if more data exists
            setData(page === 0 ? fetchedData : [...data, ...fetchedData]);
            setPage(page);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        setProspectStep(formStepsEnum[formSteps.find((i: any) => i.value === prospectDetails?.clientStateStatus)?.name as any] + 1)
    }, [prospectDetails])

    return (
        <div className="parent-container">
            <div className="container-layout">
                <div>
                    <Typography style={{ fontSize: 20, fontWeight: 700, marginBottom: 4 }}>Onboard New Client</Typography>

                    <Typography.Text style={{ fontSize: 18 }} type="secondary">Enter the details below and click submit to initiate client onboarding</Typography.Text>
                </div>
                

                <Steps
                    style={{ paddingTop: 28 }}
                    current={1}
                    items={[
                        {
                            title: <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                <Typography.Text style={{ fontSize: 12 }} type="secondary">Step 1</Typography.Text>
                                <Typography style={{ fontSize: 16, lineHeight: 1.2 }}>Basic Details</Typography>
                            </div>,
                            status: currentStep >= 1 ? "finish" : "wait",
                            icon: currentStep === 1 ? <InProgressIcon /> : currentStep > 1 ? <SuccessIcon /> : <PendingIcon />,
                            onClick: () => { if(parseInt(prospectStep) >= 1){getProspectDetails(); setCurrentStep(1)} },
                        },
                        {
                            title: <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                <Typography.Text style={{ fontSize: 12 }} type="secondary">Step 2</Typography.Text>
                                <Typography style={{ fontSize: 16, lineHeight: 1.2 }}>Additional Client Details</Typography>
                            </div>,
                            status: currentStep >= 2 ? "finish" : "wait",
                            icon: currentStep === 2 ? <InProgressIcon /> : currentStep > 2 ? <SuccessIcon /> : <PendingIcon />,
                            onClick: () => { if (parseInt(prospectStep) >= 2) { getProspectDetails(); setCurrentStep(2) } },
                        },
                        {
                            title: <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                <Typography.Text style={{ fontSize: 12 }} type="secondary">Step 3 (Optional)</Typography.Text>
                                <Typography style={{ fontSize: 16, lineHeight: 1.2 }}>Parent Details</Typography>
                            </div>,
                            status: currentStep >= 3 ? "finish" : "wait",
                            icon: currentStep === 3 ? <InProgressIcon /> : currentStep > 3 ? <SuccessIcon /> : <PendingIcon />,
                            onClick: () => { if (parseInt(prospectStep) >= 3) { getProspectDetails(); setCurrentStep(3) } }
                        },

                        {
                            title: <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                <Typography.Text style={{ fontSize: 12 }} type="secondary">Step 4</Typography.Text>
                                <Typography style={{ fontSize: 16, lineHeight: 1.2 }}>Contract Details</Typography>
                            </div>,
                            status: currentStep >= 4 ? "finish" : "wait",
                            icon: currentStep === 4 ? <InProgressIcon /> : currentStep > 4 ? <SuccessIcon /> : <PendingIcon />,
                            onClick: () => { if (parseInt(prospectStep) >= 4) { getProspectDetails(); setCurrentStep(4) } }

                        },
                        {
                            title: <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                <Typography.Text style={{ fontSize: 12 }} type="secondary">Step 5</Typography.Text>
                                <Typography style={{ fontSize: 16, lineHeight: 1.2 }}>Agreement Details</Typography>
                            </div>,
                            status: currentStep >= 5 ? "finish" : "wait",
                            icon: currentStep === 5 ? <InProgressIcon /> : currentStep > 5 ? <SuccessIcon /> : <PendingIcon />,
                            // onClick: () => { if (parseInt(prospectStep) >= 5) { getProspectDetails(); setCurrentStep(5) } }
                        },
                    ]}
                />



                <div style={{ marginTop: 28 }}>

                    {!showForm &&
                        <>
                            <Typography.Text strong style={{ fontSize: 16 }}>Basic Details</Typography.Text>

                            <div style={{
                                background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                            }}>
                                <Typography style={{ fontSize: 14, fontWeight: 500 }}>Prospect</Typography>
                                <Select
                                    showSearch
                                    placeholder="Enter prospect ID or name to search"
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onPopupScroll={handleScroll} // Infinite scroll on dropdown scroll
                                    notFoundContent={loading ? <Spin size="small" /> : null}
                                    style={{ width: 300 }}
                                    onSelect={(value: any) => {
                                        if (prospectId == value) {
                                            checkSanctionLetter()
                                        } else {
                                            setProspectId(value)
                                        }
                                    }}
                                >
                                    {data.map((item: any) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.id} - {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div></>}

                    {showForm &&

                        <>
                            {currentStep == 1 && <BasicDetailsForm  disabled={parseInt(prospectStep) > 1} prospectId={prospectId} formSteps={formSteps} currentStep={currentStep} prospectDetails={prospectDetails} setCurrentStep={(value:any)=>{getProspectDetails();setCurrentStep(value)}} />}
                            {currentStep == 2 && <ClientDetailsForm  disabled={parseInt(prospectStep) > 2} prospectId={prospectId} prospectDetails={prospectDetails} formSteps={formSteps} currentStep={currentStep} setCurrentStep={(value:any)=>{getProspectDetails();setCurrentStep(value)}} />}
                            {currentStep == 3 && <ParentDetailsForm  disabled={parseInt(prospectStep) > 3} docTypes={docTypes} prospectId={prospectId} prospectDetails={prospectDetails} formSteps={formSteps} currentStep={currentStep} setCurrentStep={(value:any)=>{getProspectDetails();setCurrentStep(value)}} />}
                            {currentStep == 4 && <ContractDetailsForm  disabled={parseInt(prospectStep) > 4} setCurrentStep={(value:any)=>{getProspectDetails();setCurrentStep(value)}} getDocTypes={getDocTypes} prospectId={prospectId} docTypes={docTypes} prospectDetails={prospectDetails} />}
                            {currentStep == 5 && <AgreementDetailsForm  disabled={parseInt(prospectStep) > 5} docTypes={docTypes} getDocTypes={getDocTypes} prospectDetails={prospectDetails} setCurrentStep={(value:any)=>{getProspectDetails();setCurrentStep(value)}} prospectId={prospectId} />}
                        </>
                    }

                </div>
            </div>

            <Modal footer={
                <div style={{ display: "flex", gap: 12, marginTop: 24, marginBottom: 16 }}>
                    <Button size="large" style={{ ...modalButtonStyle, width: "50%" }} onClick={() => setShowProspectExistsModal(false)}>
                        <CloseOutlined /> Close
                    </Button>
                    <Button onClick={()=>navigate(`/dashboard/client-onboarding/${prospectDetails?.clientOnBoardingId}`)} size="large" style={{ ...modalButtonStyle, width: "50%" }}>
                        <EyeOutlined /> View Client Details
                    </Button>
                </div>
            }
                centered style={{ textAlign: "center" }}

                closable={false} title={<div style={{ marginTop: 20 }}><img width={65} src={`${__dirname}assets/warning-icon.png`} /></div>} open={showProspectExistsModal}>

                {/* <img width={65} src="/assets/warning-icon.png" /> */}
                <Typography style={{ fontSize: 14 }}><b>{prospectDetails?.prospect.name}</b> is already a client with<br /> following details</Typography>

                <div style={{ borderRadius: 6, overflow: "hidden", marginTop: 20 }}>
                    <div style={{ display: "flex", textAlign: "left", fontWeight: 500, borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}>
                        <div style={{ flex: 1, padding: "15px 12px", background: "rgba(206, 230, 250, 1)" }}>
                            Client ID
                        </div>
                        <div style={{ flex: 1, padding: "15px 12px", background: "rgba(246, 250, 255, 1)" }}>
                            {prospectDetails?.clientOnBoardingId}
                        </div>
                    </div>
                    <div style={{ display: "flex", textAlign: "left", fontWeight: 500, borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}>
                        <div style={{ flex: 1, padding: "15px 12px", background: "rgba(206, 230, 250, 1)" }}>
                            Brand Name
                        </div>
                        <div style={{ flex: 1, padding: "15px 12px", background: "rgba(246, 250, 255, 1)" }}>
                            {prospectDetails?.prospect.legalBusinessName?.name}
                        </div>
                    </div>
                    <div style={{ display: "flex", textAlign: "left", fontWeight: 500 }}>
                        <div style={{ flex: 1, padding: "15px 12px", background: "rgba(206, 230, 250, 1)" }}>
                            Contract Validity
                        </div>
                        <div style={{ flex: 1, padding: "15px 12px", background: "rgba(246, 250, 255, 1)" }}>
                            {prospectDetails?.contractValidity ? dayjs(prospectDetails?.contractValidity).format("DD MMM YYYY") : ""}
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal footer={
            null
            }
                centered style={{ textAlign: "center" }}
                styles={{
                    footer: {
                        paddingInline: "10px",
                        marginBottom: 16
                    }
                }}
                closable={false} title={<div style={{ marginTop: 20 }}><img width={65} src={`${__dirname}assets/error-icon.png`} /></div>} open={showNotFoundModal}>

                {/* <img width={65} src="/assets/warning-icon.png" /> */}
                <Typography style={{ fontSize: 14 }}>Sanction Letter is not generated for<br />
                    <b>{prospectId} - {prospectDetails?.prospect.name}</b></Typography>

                    <div style={{ display: "flex", gap: 12, marginTop: 24 }}>
                    <Button autoFocus size="large" style={{ ...modalButtonStyle, width: "100%" }} onClick={() => setShowNotFound(false)}>
                        <CloseOutlined /> Close
                    </Button>
                </div>

            </Modal>
        </div>
    )
}

export const modalButtonStyle = {
    border: "solid 1px rgba(23, 119, 255, 1)",
    color: "rgba(23, 119, 255, 1)",
    background: "rgba(232, 241, 255, 1)"
}