import { Button, Card, Col, Divider, Empty, Form, Input, Modal, Row, Select, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "./../../../../../../utils/icons/EyeIcon.svg";
import { ReactComponent as EditPenIcon } from './../../../../../../utils/icons/EditPenIcon.svg';
import { putRequest } from "../../../../../../utils/handler/apiHandler";
import BasicDetailsHistoryModal from "./BasicDetailsHistoryModal";

interface VehicleBasicDetailProps {
    vehicleId: number;
    vehicleBasicDetailsData: any,
    setFetchTicketDataCnt: (cnt : number) => void,
    jobCardId: number,
    fetchTicketDataCnt: number,
    ticketStatusId: number,
}

const { Title } = Typography;

const VehicleBasicDetail: React.FC<VehicleBasicDetailProps> = ({ vehicleId, vehicleBasicDetailsData, 
        setFetchTicketDataCnt, jobCardId, fetchTicketDataCnt, ticketStatusId }) => {
    const [editable, setEditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [odometerReading, setOdometerReading] = useState<number>(vehicleBasicDetailsData?.odometerReading);
    const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
    const [disableShowVersionHistory, setDisableShowVersionHistory] = useState<boolean>(true);
    
    const [form] = Form.useForm();
    const CANCELLED_ID = 11, SERVICE_RESOLVED_ID = 12;
    

    useEffect(() => {
        if (vehicleBasicDetailsData?.odometerReading !== undefined) {
            setOdometerReading(vehicleBasicDetailsData?.odometerReading);
            // Call form.setFieldsValue only after the state has been updated
            form.setFieldsValue({
                odometerReading: vehicleBasicDetailsData?.odometerReading
            });
        }
    }, [vehicleBasicDetailsData, form]); // Ensure form is initialized and data is updated

    function getCurrentDateTime() {
        const now = new Date();
    
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const submitOdometerReading = async () => {
        if (!odometerReading) {
            message.error("Please enter a valid odometer reading.");
            return;
        }
    
        try {
            setLoader(true);
            const response = await putRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle?id=${vehicleId}`,
                {
                    "odometerUpdateDate": getCurrentDateTime(), // Use current date-time in ISO format
                    "currentOdometerReading": odometerReading,
                }
            );
    
            setFetchTicketDataCnt(Math.random());
            if (response.status !== 200) {
                message.error(response.msg || "Failed to update odometer reading.");
            } 
        } catch (error) {
            message.error("An error occurred while updating the odometer reading.");
        } finally {
            setLoader(false);
            setEditable(false);  // Close edit mode after submit
        }
    };
    const onCloseVersionHistory = () => {
        setShowVersionHistory(false);
    }

    return (
        <>
            <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                <Title style={{ fontWeight: 700 }} level={5}>
                    Basic Details
                </Title>
                {
                    !disableShowVersionHistory && 
                    <div style={{display:'flex', cursor: 'pointer'}} onClick={()=>setShowVersionHistory(true)}>
                        <EyeIcon style={{marginTop:'0.25rem'}}/>
                        <span style={{ marginLeft: '7px', marginRight:'10px', color: '#2C66E3', fontSize: '12px', fontWeight: '600' }}>Show Version History </span>
                    </div>
                }
            </div>

            <div className="container-inner-section-body-section">
                <Card bordered={false}>
                    <div>
                        <Form
                            name="dynamic_form_nest_item"
                            form={form}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Row className="detail-description-item-container">
                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Vehicle No.
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        <span style={{ display: loader ? "none" : "" }}>
                                            {loader ? "" : vehicleBasicDetailsData?.vehicleNo}
                                        </span>
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Asset No.
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : vehicleBasicDetailsData?.assetNo}
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Category
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : vehicleBasicDetailsData?.category}
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Make
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : vehicleBasicDetailsData?.make}
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Model
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : vehicleBasicDetailsData?.model}
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Variant
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : vehicleBasicDetailsData?.variant}
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    {!editable ? (
                                        <>
                                            <Typography className="description-label">
                                                Odometer Reading
                                            </Typography>
                                            <Typography
                                                className={`${loader ? "shimmer-ui" : ""}`}
                                                style={{ fontSize: 14 }}
                                            >
                                                {loader ? "" : odometerReading}
                                                {
                                                    !(ticketStatusId === CANCELLED_ID || ticketStatusId === SERVICE_RESOLVED_ID) &&
                                                        <EditPenIcon style={{ marginLeft: '7px' }} onClick={() => {
                                                        setEditable(true);
                                                        }
                                                    } />
                                                }
                                                
                                            </Typography>
                                        </>
                                    ) : (
                                        <Form.Item
                                            name="odometerReading"
                                            label="Odometer Reading"
                                            className="form-label"
                                            style={{ margin: 0, fontSize: 12 }}
                                        >
                                            <Input
                                                type="number"
                                                maxLength={8}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                placeholder="Odometer Reading"
                                                value={odometerReading}
                                                onChange={(e) => setOdometerReading(Number(e.target.value))}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Invoicing Date
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : vehicleBasicDetailsData?.invoicingDate}
                                    </Typography>
                                </Col>
                            </Row>
                            {
                                editable && 
                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Button style={{color:'#FF4D4F', backgroundColor:'rgba(255, 77, 79, 0.10)', 
                                        marginRight:'2rem', borderColor: '#FF4D4F'}} 
                                        onClick={()=>   {
                                            setEditable(false);
                                            setOdometerReading(vehicleBasicDetailsData?.odometerReading);
                                            form.setFieldsValue({
                                                odometerReading: vehicleBasicDetailsData?.odometerReading
                                            });
                                            }
                                        } loading={loader} type="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={submitOdometerReading} loading={loader} type="primary">
                                        Submit Odometer Reading
                                    </Button>
                                </div>
                            }
                            
                        </Form>
                    </div>
                </Card>
                <BasicDetailsHistoryModal showVersionHistory={showVersionHistory} onCloseVersionHistory={onCloseVersionHistory} 
                    jobCardId={jobCardId} vehicleId={vehicleId} vehicleBasicDetailsData={vehicleBasicDetailsData}
                    fetchTicketDataCnt={fetchTicketDataCnt} setDisableShowVersionHistory={setDisableShowVersionHistory}/>
        
            </div>
        </>
    );
};

export default VehicleBasicDetail;
