import { Col, Form, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { getFormattedDateToDisplayWithoutChangingTimeZone } from "../../../../../../utils/helpers/utils";

interface ShowWorkshopHistoryDetailsProps{
    workshop: any,
}

const { Title } = Typography;

const ShowWorkshopHistoryDetails: React.FC<ShowWorkshopHistoryDetailsProps> = ({workshop})=>{
    const [form] = Form.useForm();
    const [formattedWorkshop, setFormattedWorkshop] = useState<any> ({});
    useEffect(()=>{
        setFormattedWorkshop({
            scheduleServiceSlot: workshop?.scheduledDate,
            workshopName: workshop?.name,
            spocName: workshop?.spoc,
            spocNumber: workshop?.contact,
            emailId: workshop?.email,
            workshopCity: workshop?.city,
            serviceSite: workshop?.serviceSite,
            address: workshop?.address,
            scheduleServiceSlotMod: workshop?.scheduledDateMod,
            workshopNameMod: workshop?.workshopIdMod,
            spocNameMod: workshop?.spocMod,
            spocNumberMod: workshop?.contactMod,
            emailIdMod: workshop?.emailMod,
            workshopCityMod: workshop?.cityMod,
            serviceSiteMod: workshop?.serviceSiteMod,
            addressMod: workshop?.addressMod,
        })
    },[]);
    
    return (
      <div >
        <div style={{display:'flex', flexDirection:'column'}}>
            <hr style={{ border: '0.01rem solid rgba(175, 175, 175, 1)', borderWidth: '1px' }} />
            <span style={{fontWeight:'700', fontSize:'16px', color:'#242424', padding:'1rem 1.5rem 1rem 1.5rem'}}>Workshop Details</span>
        </div>
        <Form
          name="dynamic_form_nest_item"
          form={form}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          style={{padding:'0rem 1.5rem 1rem 1.5rem'}}
        >
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col className="detail-description-item">
                <Typography className="description-label">
                    Schedule Service Slot
                    {
                    formattedWorkshop?.scheduleServiceSlotMod &&
                    <span style={{marginLeft:'0.6rem', fontSize:'12px', fontWeight:'500', 
                            color:'rgba(255, 168, 0, 1)', backgroundColor:'rgba(255, 243, 224, 1)', borderRadius:'0.25rem', 
                            padding:'0.2rem 0.5rem 0.2rem 0.5rem'}}>
                            {'Changed'}
                    </span>
                    }
                </Typography>
            
                <Typography style={{ fontSize: 14 , color: formattedWorkshop?.scheduleServiceSlotMod ? '#FFA800':'rgba(64, 64, 64, 1)'}}>
                <span >
                    {getFormattedDateToDisplayWithoutChangingTimeZone(formattedWorkshop?.scheduleServiceSlot)}
                </span>
                </Typography>
            </Col>

            <Col className="detail-description-item">
              <Typography className="description-label">
                Workshop Name
                {
                    formattedWorkshop?.workshopNameMod &&
                    <span style={{marginLeft:'0.6rem', fontSize:'12px', fontWeight:'500', 
                                color:'rgba(255, 168, 0, 1)', backgroundColor:'rgba(255, 243, 224, 1)', borderRadius:'0.25rem', 
                                padding:'0.2rem 0.5rem 0.2rem 0.5rem'}}>
                                {'Changed'}
                    </span>
                }
              </Typography>
              
                <Typography  style={{ fontSize: 14 , color: formattedWorkshop?.workshopNameMod ? '#FFA800':'rgba(64, 64, 64, 1)'}}>
                 { formattedWorkshop?.workshopName}
                </Typography>
            </Col>

            <Col className="detail-description-item">
              <Typography className="description-label">
                Service Site
                {
                    formattedWorkshop?.serviceSiteMod &&
                    <span style={{marginLeft:'0.6rem', fontSize:'12px', fontWeight:'500', 
                            color:'rgba(255, 168, 0, 1)', backgroundColor:'rgba(255, 243, 224, 1)', borderRadius:'0.25rem', 
                            padding:'0.2rem 0.5rem 0.2rem 0.5rem'}}>
                            {'Changed'}
                    </span>
                }
                
              </Typography>
                <Typography  style={{ fontSize: 14, color: formattedWorkshop?.serviceSiteMod ? '#FFA800':'rgba(64, 64, 64, 1)' }}>
                  {formattedWorkshop?.serviceSite}
                </Typography>
            </Col>
          </Row>

          
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col className="detail-description-item">
                  <Typography className="description-label">
                    SPOC Name
                  </Typography>
                  <Typography  style={{ fontSize: 14 }}>
                    {formattedWorkshop?.spocName}
                  </Typography>
                </Col>

                <Col className="detail-description-item">
                  <Typography className="description-label">
                    SPOC Number
                  </Typography>
                  <Typography  style={{ fontSize: 14 }}>
                    {formattedWorkshop?.spocNumber}
                  </Typography>
                </Col>

                <Col className="detail-description-item">
                  <Typography className="description-label">
                    Email ID
                    <span style={{ color: '#B2B2B2', marginLeft: '4px' }}>(optional)</span>
                  </Typography>
                  <Typography  style={{ fontSize: 14 }}>
                    {formattedWorkshop?.emailId}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col className="detail-description-item" style={{ width: '37.5%' }}>
                  <Typography className="description-label">
                    Workshop City
                  </Typography>
                  <Typography  style={{ fontSize: 14 }}>
                    {formattedWorkshop?.workshopCity}
                  </Typography>
                </Col>

                <Col className="detail-description-item" style={{ width: '62.5%' }}>
                  <Typography className="description-label">
                    Address
                  </Typography>
                  <Typography  style={{ fontSize: 14 }}>
                    {formattedWorkshop?.address}
                  </Typography>
                </Col>
              </Row>
              
          
        </Form>
      </div>
    );
}
export default ShowWorkshopHistoryDetails;