
import { useNavigate, useLocation } from "react-router-dom";
import IssuesAndSubIssues from "./IssueAndSubIssues/IssuesAndSubIssues";
import WorkshopDetails from "./Workshop/WorkshopDetails";
import TicketDetails from "./Ticket/TicketDetails";
import { useEffect, useState } from "react";
import TicketCancelRemarkDetails from "./TicketCancelRemark/TicketCancelRemarkDetails";

interface IssueDetailsProps{
    workshopDetailsData: any,
    setWorkshopDetailsData: (data:any) => any,
    setTicketDetailsData: (data:any) => any,
    ticketDetailsData: any,
    issueSubissueTableData: any,
    fetchTicketDataCnt: number,
    setFetchTicketDataCnt: (cnt:number) => void,
    ticketId: string,
    jobCardId: number,
    ticketStatusId: number,
    serviceStatusUpdateDate: string,
    ticketRemark: any,
    setDisableHeaderActions: (flag: boolean) => void,
}

const IssueDetails: React.FC<IssueDetailsProps> = ({workshopDetailsData, setWorkshopDetailsData, setTicketDetailsData,
         ticketDetailsData, issueSubissueTableData, ticketId, fetchTicketDataCnt, setFetchTicketDataCnt, 
         jobCardId, ticketStatusId, serviceStatusUpdateDate, ticketRemark, setDisableHeaderActions}) => {
    const [showAddIssue, setShowAddIssue] = useState<boolean>(false); // State to control the visibility of AddIssues
    const [editableWorkshop, setEditableWorkshop] = useState(false);
    const [editableTicket, setEditableTicket] = useState(false);
    
    useEffect(()=>{
        if( ticketStatusId === 5 && issueSubissueTableData && issueSubissueTableData.length == 0 )  {
                setShowAddIssue(true);
        } else{
            setShowAddIssue(false);
        }
        if( ticketStatusId === 5 && !workshopDetailsData?.scheduleServiceSlot && !workshopDetailsData?.workshopName &&
            !workshopDetailsData?.serviceSite )  {
                setEditableWorkshop(true);
        } else{
            setEditableWorkshop(false);
        }
        setEditableTicket(false);
    },[fetchTicketDataCnt, ticketStatusId, workshopDetailsData, issueSubissueTableData]);

    useEffect(()=>{
        if(showAddIssue || editableWorkshop || editableTicket){
            setDisableHeaderActions(true);
        }else{
            setDisableHeaderActions(false);
        }
    },[showAddIssue,editableWorkshop, editableTicket]);
    
    return (
        <div >
            {
                // ticketStatusId is 10 for Cancelled and 13 for Service Resolved
                (ticketStatusId == 11 || ticketStatusId == 12 ) && (ticketRemark?.ticketRemark) && 
                <TicketCancelRemarkDetails ticketRemark={ticketRemark} />
            }
            <IssuesAndSubIssues issueSubissueTableData={issueSubissueTableData} 
                fetchTicketDataCnt={fetchTicketDataCnt} setFetchTicketDataCnt={setFetchTicketDataCnt}
                 jobCardId={jobCardId} ticketId={ticketId} showAddIssue={showAddIssue} setShowAddIssue={setShowAddIssue} editableWorkshop={editableWorkshop} 
                 editableTicket={editableTicket} serviceStatusUpdateDate={serviceStatusUpdateDate} ticketStatusId={ticketStatusId}/>
            <WorkshopDetails workshopDetailsData={workshopDetailsData} setWorkshopDetailsData={setWorkshopDetailsData} 
                fetchTicketDataCnt={fetchTicketDataCnt} setFetchTicketDataCnt={setFetchTicketDataCnt} 
                ticketId={ticketId} jobCardId={jobCardId} editable={editableWorkshop} setEditable={setEditableWorkshop}
                showAddIssue={showAddIssue} editableTicket={editableTicket} ticketStatusId={ticketStatusId}/>
            <TicketDetails ticketDetailsData={ticketDetailsData} setTicketDetailsData={setTicketDetailsData}
                fetchTicketDataCnt={fetchTicketDataCnt} setFetchTicketDataCnt={setFetchTicketDataCnt} ticketId={ticketId} jobCardId={jobCardId}
                editable={editableTicket} setEditable={setEditableTicket} showAddIssue={showAddIssue} editableWorkshop={editableWorkshop}   
                ticketStatusId={ticketStatusId}/>
        </div>
        
    );
}
export default IssueDetails;