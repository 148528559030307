import { Button, Card, DatePicker, Form, Input, Modal, message } from "antd";
import { useEffect, useState } from "react";

import { ReactComponent as CheckIcon } from "./../../../../../utils/icons/CheckIconConfigurable.svg";
import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as DisableCloseIcon } from "./../../../../../utils/icons/Disabled_Cross_Icon.svg";
import { ReactComponent as ArrowLeft } from "./../../../../../utils/icons/arrow-left.svg";
import { ReactComponent as CheckWhite } from "./../../../../../utils/icons/CheckWhite.svg";
import { margin } from "@mui/system";
import InvoiceDetailsFormForResolve from "../IssueDetails/Invoice/InvoiceDetailsFormForResolve";

interface ResolveTicketWithRemarkModalProps{
    closeTicketOrResolveFlag: number,
    setCloseTicketOrResolveFlag: (flag: number) => void,
    resolveTicketRemark: string,
    setResolveTicketRemark: (date: string) => void,
    handleResolveTicketWithRemarkConfirmation: (resolveTicketRemark: string)=>void,
    jobCardId: number,
    prospectId: number,
}

const ResolveTicketWithRemarkModal: React.FC<ResolveTicketWithRemarkModalProps> = ({ resolveTicketRemark, closeTicketOrResolveFlag, 
        setCloseTicketOrResolveFlag , setResolveTicketRemark, handleResolveTicketWithRemarkConfirmation,
        jobCardId, prospectId }) => {
    
    const [form] = Form.useForm();
    
    const handleDontResolve = ()=>{
        form.resetFields();
        setCloseTicketOrResolveFlag(0);
        setResolveTicketRemark('');
    }
    const handleResolve = (resolveTicketRemark: string)=>{
        setCloseTicketOrResolveFlag(0);
        handleResolveTicketWithRemarkConfirmation(resolveTicketRemark);
    }
    return (
        <Modal 
            open={closeTicketOrResolveFlag === 3} 
            onCancel={handleDontResolve}
            width='80%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', marginTop:'1rem'}}>
                    <p style={{ fontWeight: '700', fontSize: '1rem', color:'#404040' }}>
                        Are you sure you want to Resolve the Ticket?
                    </p>
                </div>
                
                <InvoiceDetailsFormForResolve handleDontResolve={handleDontResolve} handleResolve={handleResolve}
                 jobCardId={jobCardId} prospectId={prospectId} setResolveTicketRemark={setResolveTicketRemark}
                 form={form}/>
                
            </div>
        </Modal>
    );
};

export default ResolveTicketWithRemarkModal;
