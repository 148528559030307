import { Button, Card, Divider, Form, message, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getRequest, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { ReactComponent as FileIcon } from '../../Prospect/utils/SVGs/NoDocuments.svg';
import { ReactComponent as EditIcon } from '../../Prospect/utils/SVGs/edit_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import ParentEntityModal from "./ParentEntityModal";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

const { Option } = Select;

interface ParentAssessmentProps {
    parentLegalBusiness: any;
    creditRequestId : number;
    setIsParentAssessment: (isParentAssessment: boolean) => void;
    setChildView: (childView: boolean) => void;
}

const ParentAssessmentFrom : React.FC<ParentAssessmentProps> = ({parentLegalBusiness, creditRequestId, setIsParentAssessment, setChildView}) => {
    const navigate = useNavigate();
    const prospectId = useLocation().pathname.split("/").pop();
    const [apiToggle, setApiToggle] = useState<any>(false);
    const [parentLegalBusinessDetails, setParentLegalBusinessDetails] = useState<any>();
    const [parentLegalEntity, setParentLegalEntity] = useState<any>();
    const [showParentModal, setShowParentModal] = useState<boolean>(false);
    const [parentEntityName, setParentEntityName] = useState<string>(parentLegalBusiness != null ? JSON.parse(JSON.stringify(parentLegalBusiness))?.name : "");
    const [loader, setLoader] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [creditAssessmentData, setCreditAssessmentData] = useState<any>(null);
    const [isAddModal, setIsAddModal] = useState<boolean>(true);


      useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/legalEntities`).then(res => {
                setParentLegalBusinessDetails([...res.data, {id: null, name: "Other"}]);
            });
        } catch (err) {

        }
    }, []);

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
            setCreditAssessmentData(res.data);
        });
    }, [apiToggle]);

    const optionsToRender = (options: any[], identifier: any) => {
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const handleParentLegalBusinessUpdate = (i: any) => {
        console.log(i.value)
        if(i.value != "Other"){
            setSelectedValue(i.value);
            setParentLegalEntity({
                id: ~~i.key,
                name: i.value
            });
        }else{
            setSelectedValue("Other");
            setShowParentModal(true);
        }
    }

    const handleProceed = async () => {
        setLoader(true); 
        try {
            const payload: any = {
            addParent: true,
            parentEntity : {
                id : null,
                name: parentEntityName
            }
            }
            const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${creditRequestId}`, payload);
            if(res) setLoader(false);
            setShowParentModal(false)
            setApiToggle(!apiToggle);
        } catch(err) {
            console.log("Error: ", err);
        }
        }

        const handleDropdownProceed = async () => {
        setLoader(true); 
        try {
            const payload: any = {
            addParent: true,
            parentEntity : parentLegalEntity
            }

            const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${creditRequestId}`, payload);
            if(res) setLoader(false);
            setShowParentModal(false)
            setApiToggle(!apiToggle);
            setApiToggle(!apiToggle)
        } catch(err) {
            console.log("Error: ", err);
        }
    }

    const handleEdit = () => {
    setShowParentModal(true);
    setIsAddModal(false);
    }

    const handleParentAssessment = () => {
        try {
            const payload = {
                isParentEntityAssessment: true
            }
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${creditRequestId}`, payload).then(res => {
                message.destroy();
                setChildView(true); 
                setIsParentAssessment(true)
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`)
                window.location.reload();
            })
        } catch(err) {
            console.log(err);
        }
    }

    return ( 
        <React.Fragment>
            {
                creditAssessmentData?.parentLegalBusinessName === null &&
                <Card bordered={false} style={{marginTop: 20}}>
                    <div>
                        <Typography className="description-label">This prospect does not have any parent assigned to it select parent down below to start the process</Typography>
                        <div style={{display: "flex", alignItems: "end", width : '40%', paddingTop : 10}}>
                            {<Form.Item
                                name="legalBusinessName"
                                className='form-label'
                                style={{margin: 0, fontSize: 12, width: "100%"}}
                                rules={[{ required: true }]}
                            >
                                <Select className="select-input-override" value={selectedValue} showSearch={true} placeholder="Parent Brand Name" onChange={(e, i: any)=> {handleParentLegalBusinessUpdate(i)}}>
                                    {optionsToRender(parentLegalBusinessDetails, "name")}
                                </Select>
                            </Form.Item>}
                            {selectedValue !== "Other" && <Button disabled={_.isEmpty(selectedValue)} onClick={() => {handleDropdownProceed();}} style={{marginLeft: 10}} type="primary" className="button-override">Proceed <RightArrowIcon style={{marginLeft: 5}}/></Button>}
                        </div>
                    </div>
                </Card>
            }
            {
                creditAssessmentData?.parentLegalBusinessName != null &&
                <div style={{ display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#F6FAFF"}}>
                    <span style={{}}>
                        <FileIcon />
                    </span>
                    <p style={{fontSize: 14, fontWeight: 500, textAlign: "center", width: "40%", marginTop: 20}}>Parent has been added successfully for this prospect, 
                    To start credit assessment for the parent Click the button below
                    </p>
                    <span style={{display: "flex", marginTop: 20}}>
                        <Button icon={<EditIcon />} onClick={() => handleEdit()} style={{display: 'flex', color : '#1777FF', backgroundColor : 'rgba(23, 119, 255, 0.10)', border: '1px solid #1777FF', borderRadius: 4, justifyContent: 'center', alignItems: 'center'}}>Edit Parent Detail</Button>
                        <Button style={{display: 'flex', color : 'white', backgroundColor : '#1777FF', borderRadius : '4px', justifyContent: 'center', alignItems: 'center', marginLeft: 10}} onClick={_=> handleParentAssessment()}>Start Parent Credit assessment <RightArrowIcon style={{marginLeft: 5}}/></Button>
                    </span>
                </div>
            }


            <ParentEntityModal isAddModal={isAddModal} showParentModal={showParentModal} setShowParentModal={setShowParentModal} parentEntityName={parentEntityName} setParentEntityName={setParentEntityName} handler={() => handleProceed()} loader={loader} />
        </React.Fragment>
    );
}

export default ParentAssessmentFrom;