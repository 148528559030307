import { Card, Modal, message } from "antd";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../../../utils/handler/apiHandler";
import { getFormatDateV2, getFormatedDateTime } from "../../../../../../utils/helpers/utils";

import { ReactComponent as CloseIcon } from "./../../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as PipeIcon } from "./../../../../../../utils/icons/Pipe_Icon.svg";
import { ReactComponent as EyeIcon } from "./../../../../../../utils/icons/EyeIcon.svg";
import { ReactComponent as EyeHide } from "./../../../../../../utils/icons/EyeHide.svg";
import ShowIssueAndSubissueHistoryDetails from "./ShowIssueAndSubissueHistoryDetails";
import moment from "moment";

interface IssueAndSubissueHistoryModalProps{
    showVersionHistory: boolean,
    onCloseVersionHistory: () => void,
    jobCardId: number,
    ticketId: string,
    fetchTicketDataCnt: number,
    setFetchTicketDataCnt: (cnt:number) => void,
    setDisableShowVersionHistory: (cnt:boolean) => void,
    jobCardHistory?: true,
    ticketStatusId?: number,
}

const IssueAndSubissueHistoryModal: React.FC<IssueAndSubissueHistoryModalProps> = 
    ({ showVersionHistory, onCloseVersionHistory, jobCardId, ticketId, setFetchTicketDataCnt,
        fetchTicketDataCnt, setDisableShowVersionHistory, jobCardHistory, ticketStatusId }) => {
    const [issueAndSubissueHistoryLogs, setIssueAndSubissueHistoryLogs] = useState<any[]>([]);
    const [loader, setLoader] = useState(false);
    const [versionHistoryShowDetailsList, setVersionHistoryShowDetailsList] = useState<boolean[]>([]);
    useEffect(() => {
        if (!jobCardId) return;

        const fetchIssueAndSubissueHistoryLogs = async () => {
            try {
                setLoader(true);
                const response = await getRequest(
                    `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/audit/${jobCardHistory ? "actual-" : ""}sub-issue-job-card?jobCardId=${jobCardId}`
                );

                if (response.status !== 200) {
                    message.error(response.msg || "Failed to fetch issueAndSubissueHistoryLogs.");
                }
                const items = response?.data?.items;
                setIssueAndSubissueHistoryLogs(items);
                if(items.length !== 0){
                    setDisableShowVersionHistory(false);
                }
                setVersionHistoryShowDetailsList(items?.map((item:any)=> false));
            } catch (error) {
                message.error("An error occurred while fetching issueAndSubissueHistoryLogs.");
            } finally {
                setLoader(false);
            }
        };

        fetchIssueAndSubissueHistoryLogs();
    }, [jobCardId, fetchTicketDataCnt, showVersionHistory]);

    const toggleDetails = (index: number) => {
        setVersionHistoryShowDetailsList(prevState => {
          const newList = [...prevState]; // Create a copy of the current state
          newList[index] = !newList[index]; // Toggle the boolean value at the given index
          return newList;
        });
      }
    return (
        <Modal 
            destroyOnClose
            open={showVersionHistory} 
            onCancel={onCloseVersionHistory}
            width='80%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            styles={{
                content: {
                    background: "#F6FAFF",
                },
                header: {
                    background: "#F6FAFF",
                    textAlign: "left",
                },
                body: {
                    background: "#F6FAFF",
                    margin: 0,
                    padding: 0,
                    maxHeight: '40rem',  // Set a max height for the body
                    overflowY: 'auto',  // Enable vertical scrolling
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }
            }}
        >
            <div style={{ fontWeight: '700', fontSize: '1rem', marginBottom: '1rem' }}>
                Version History
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    issueAndSubissueHistoryLogs?.map((issueAndSubissue: any, index) => (
                        <div key={index} style={{ width: '100%' }}>
                            <Card style={{ marginTop: '1rem', width: '100%', padding:'0px' }} styles={{
                                body:{
                                    padding:'0px'
                                }
                            }}>
                                <div style={{display:'flex', justifyContent:'space-between', flexDirection:'column'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', padding:'1rem 1.5rem 1rem 1.5rem'}}>
                                
                                        <div style={{  display:'flex' }}>
                                            <span style={{fontSize:'14px', fontWeight:'600', color:'#404040'}}>
                                                Updated at : 
                                            </span>
                                            <span style={{marginLeft:'0.2rem', fontSize:'14px', fontWeight:'600', color:'#616161'}}>
                                                {moment(issueAndSubissue?.updatedAt).format("YYYY-MM-DD, hh:mm a")}
                                            </span>
                                            <PipeIcon style={{marginLeft:'0.6rem', marginTop:'0.22rem'}}/>
                                            <span style={{marginLeft:'0.6rem', fontSize:'14px', fontWeight:'600', color:'#404040'}}>
                                                Updated by : 
                                            </span>
                                            <span style={{marginLeft:'0.2rem', fontSize:'14px', fontWeight:'600', color:'#616161'}}>
                                                { issueAndSubissue?.updatedBy ? JSON.parse(issueAndSubissue?.updatedBy)?.name : 'NA'}
                                            </span>
                                            <span style={{marginLeft:'0.6rem', fontSize:'12px', fontWeight:'500', 
                                                color: issueAndSubissue?.serviceStatus?.color, 
                                                backgroundColor: issueAndSubissue?.serviceStatus?.bgColor ? issueAndSubissue?.serviceStatus?.bgColor : '#FFCCCB', 
                                                borderRadius:'0.25rem', 
                                                padding:'0.2rem 0.5rem 0.2rem 0.5rem'}}>
                                                {issueAndSubissue?.serviceStatus?.label || 'NA'}
                                            </span>
                                        </div>
                                        {versionHistoryShowDetailsList[index] ? (
                                            <div
                                                style={{ display: "flex", cursor: "pointer" }}
                                                onClick={() => toggleDetails(index)} // Use the toggleDetails function
                                            >
                                                <EyeHide style={{ marginTop: "0.25rem" }} />
                                                <span
                                                style={{
                                                    marginLeft: "0.5rem",
                                                    paddingRight: "0.8rem",
                                                    color: "#2C66E3",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                }}
                                                >
                                                Hide Details
                                                </span>
                                            </div>
                                            ) : (
                                            <div
                                                style={{ display: "flex", cursor: "pointer" }}
                                                onClick={() => toggleDetails(index)} // Use the toggleDetails function
                                            >
                                                <EyeIcon style={{ marginTop: "0.25rem" }} />
                                                <span
                                                style={{
                                                    marginLeft: "0.5rem",
                                                    paddingRight: "0.8rem",
                                                    color: "#2C66E3",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                }}
                                                >
                                                Show Details
                                                </span>
                                            </div>
                                            )
                                        }
                                    </div>
                                    {
                                        versionHistoryShowDetailsList[index] && 
                                        <ShowIssueAndSubissueHistoryDetails ticketStatusId={ticketStatusId} jobCardHistory={jobCardHistory} issueAndSubissue={issueAndSubissue}
                                            rev={issueAndSubissue?.rev} ticketId={ticketId} jobCardId={jobCardId}
                                            setFetchTicketDataCnt={setFetchTicketDataCnt}/>
                                    }
                                    
                                </div>
                            </Card>
                        </div>
                    ))
                }
            </div>
        </Modal>
    );
};

export default IssueAndSubissueHistoryModal;
