import { Tooltip, Select } from 'antd';
import { EditOutlined, EyeOutlined, BarsOutlined, FilterOutlined, MenuOutlined, ArrowUpOutlined, ArrowDownOutlined, CaretUpOutlined, CaretDownOutlined, SearchOutlined, EyeFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import CustomSelectFilter from '../../Prospect/utils/CustomSelectFilter';
import '../../Prospect/utils/CustomSelectFilter.css'; // Import custom CSS for styling
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg'; // Importing SVG file
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg'; // Importing SVG file
import UnionFilterIcon from "../../Prospect/utils/SVGs/UnionFilterIcon";
import SortUpperFadedIcon from '../../Prospect/utils/SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from '../../Prospect/utils/SVGs/SortLowerFadedIcon';
import { getFormattedDate } from '../../../../utils/helpers/utils';
import currencyFormatter from '../../../../utils/NumberFormater';

const { Option } = Select;

interface AxisState {
  XAxis: number;
  YAxis: number;
}

export const getAllCreditAssessmentRequestColumns = (
  statusList: any[],
  businessSegmentsList: any[],
  selectedStatus: string[],
  selectedBusinessSegments: string[],
  setSelectedStatus: (selectedStatus: string[]) => void,
  setSelectedBusinessSegments: (selectedBusinessSegments: string[]) => void,
  displayProspectSearchFilterScreen: boolean,
  setDisplayProspectSearchFilterScreen: (displayProspectSearchFilterScreen: boolean) => void,
  setDisplayOwnerSearchFilterScreen: (displayOwnerSearchFilterScreen: boolean) => void,
  setDisplayUpdatedByFilterScreen: (displayUpdatedBySearchFilterScreen: boolean) => void,
  editProspect: (payload: any) => void,
  agingSortingOrder: 'ascending' | 'descending' | null,
  handleUpdatedAtOrder: () => void,
  requestedAtOrder: 'ascending' | 'descending' | null,
  totalInvestmentOrder: 'ascending' | 'descending' | null,
  handleTotalInvestmentOrder: () => void,
  handleRequestedAtOrder: () => void,
  setMainCurrentPage: (currentPage: number) => void,
  selectedProspectsList: any[],
  selectedOwnersList: any[],
  selectedUpdatedByList: any[],
  setProspectOwnerAxisState: (axis: AxisState) =>void,
  setProspectNameAxisState: (axis: AxisState) =>void,
  setUpdatedByAxisState: (axis: AxisState) =>void,
): ColumnsType<any> => {
  
  const AgingSortIcon = agingSortingOrder === 'ascending' ? <CaretUpOutlined style={{ fontSize: '1.3rem'}} /> : <CaretDownOutlined style={{ fontSize: '1.3rem'}}/>;
  const FILTER_ICON_COLOR = '#1777FF';
  const UNICODE_FOR_INR_SYMBOL = '\u20B9';
  const filterIcon = () => (
    <UnionFilterIcon fill={selectedStatus.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const segmentFilterIcon = (filtered:any) => (
    <UnionFilterIcon fill={selectedBusinessSegments.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const sortRequestedAtFilterIcon = () => (
    requestedAtOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : requestedAtOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortUpdatedAtFilterIcon = () => (
    agingSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : agingSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortTotalInvestmentFilterIcon = () => (
    totalInvestmentOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : totalInvestmentOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const handleProspectSearchIconClick = (e:any) => {
    setDisplayProspectSearchFilterScreen(!displayProspectSearchFilterScreen);
    setProspectNameAxisState({'XAxis':e.clientX,'YAxis':e.clientY});
  };
  const handleOwnerSearchIconClick = (e:any) => {
    console.log(`e=%o. e.clientX=%o`,e,e.clientX);
    setProspectOwnerAxisState({'XAxis':e.clientX,'YAxis':e.clientY});
    setDisplayOwnerSearchFilterScreen(true);
  };
  const handleUpdatedByIconClick = (e:any) => {
    setDisplayUpdatedByFilterScreen(true);
    setUpdatedByAxisState({'XAxis':e.clientX,'YAxis':e.clientY});
  };
  
  return [
    {
      title: 'Request no.',
      dataIndex: 'creditRequestId',
      key: 'creditRequestId',
      width: 100,
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Prospect Name</span>
          <SearchOutlined onClick={(e)=>handleProspectSearchIconClick(e)} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'prospectName',
      key: 'prospectName',
      width: 160,
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <a href={`/dashboard/prospect/${record?.prospectId}`} className="table-text-style" style={{color : '#2C66E3', textDecoration : 'underline', width: 130, whiteSpace: 'wrap' }}>
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{}}>Prospect Owner</span>
            <SearchOutlined onClick={(e)=>handleOwnerSearchIconClick(e)} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedOwnersList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
          </div>
        ),
        dataIndex: 'prospectOwner',
        key: 'prospectOwner',
        width: 140,
        render: (text: string) => {
            const prospectOwnerName = JSON.parse(text)?.name;
            return (
                <Tooltip title={prospectOwnerName}>
                    <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {prospectOwnerName}
                    </div>
                </Tooltip>
            )
        }
    },
    {
        dataIndex: 'requestedAt',
        key: 'requestedAt',
        width: 170,
        render: (text: string) => text != null ? `${getFormattedDate(text)} ` : <span style={{fontSize: 12}}>NA</span>,
        sortDirections: ['ascend', 'descend'],
        title: (
          <div onClick={handleRequestedAtOrder} style={{ cursor: 'pointer', display:'flex' }}>
            <span style={{marginRight:'0.5rem'}}>Requested at</span> 
            {sortRequestedAtFilterIcon()}
          </div>
        ),
    },
    {
        dataIndex: 'updateAt',
        key: 'updateAt',
        width: 170,
        render: (text: string) => text != null ? `${getFormattedDate(text)}` : <span style={{fontSize: 12}}>NA</span>,
        sortDirections: ['ascend', 'descend'],
        title: (
          <div onClick={handleUpdatedAtOrder} style={{ cursor: 'pointer', display:'flex' }}>
            <span style={{marginRight:'0.5rem'}}>Updated at</span> 
            {sortUpdatedAtFilterIcon()}
          </div>
        ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Updated by</span>
          <SearchOutlined onClick={(e)=>handleUpdatedByIconClick(e)} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedUpdatedByList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'updateBy',
      key: 'updateBy',
      width: 140,
      render: (text: string) => {
        const updatedByName = JSON.parse(text)?.name;
        return (
            <Tooltip title={updatedByName}>
                <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {updatedByName}
                </div>
            </Tooltip>
        )
        },
    },
    {
      dataIndex: 'totalInvestmentValue',
      key: 'totalInvestmentValue',
      width: 190,
      // render: (text: string) => text != null ? <p>₹ {text}</p> : <span style={{ fontSize: 12 }}>NA</span>,
      render: (text: string) => text != null ? <p>{UNICODE_FOR_INR_SYMBOL} {text=="" ? "" : currencyFormatter(parseInt(text))}</p> : <span style={{ fontSize: 12 }}>NA</span>,
      sortDirections: ['ascend', 'descend'],
      title: (
          <div onClick={handleTotalInvestmentOrder} style={{ cursor: 'pointer', display: 'flex' }}>
              <span style={{ marginRight: '0.5rem' }}>Total Investment Value</span>
              {sortTotalInvestmentFilterIcon()}
          </div>
      ),
    },
  
    {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'statusName',
        width: 160,
        filterIcon: filterIcon, 
        filterDropdown: (
          <div style={{ padding: 8, width: 180 }}>
            <CustomSelectFilter
              itemsList={statusList}
              selectedItems={selectedStatus}
              setSelectedItems={setSelectedStatus}
              placeholder="Status"
              setMainCurrentPage={setMainCurrentPage}
            />
          </div>
        ),
        onFilter: (value: any, record: any) => record.stage.id === value,
        render: (text: any, record:any) => {
          return (
            <div style={{display: "flex", alignItems: "center"}}>
              <Tooltip title={text}>
                <div style={{ color: record?.statusColor, fontWeight: '500', backgroundColor: record?.statusBackgroundColor, padding: '0 8px', borderRadius: '4px', display: 'inline-block', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {text}
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      {
      title: 'Action',
      key: 'action',
      width: 120,
      align: "left",
      render: (text, record) => (
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          <Tooltip placement="bottom" title={"View Credit Assessment Request"}>
            <a onClick={() => editProspect(`/dashboard/prospect/${record.prospectId}?tab=3&subTab=2&creditRequestId=${record.creditRequestId}`)}>
              <EyeIcon style={{marginRight:'0.6rem'}}/>
              View Details
            </a>
          </Tooltip>
        </div>

      ),
    },
    
  ];
};
