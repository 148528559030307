import { Card, Typography, Form } from "antd";
import TicketForm from "./TicketForm";
import { useEffect, useState } from "react";

import { ReactComponent as CrossIcon } from './../../../../../../utils/icons/Cross_Icon.svg';
import { ReactComponent as EditIcon } from './../../../../../../utils/icons/EditPenIconConfigurable.svg';
import { ReactComponent as EyeIcon } from "./../../../../../../utils/icons/EyeIcon.svg";
import { ReactComponent as PipeIcon } from "./../../../../../../utils/icons/Pipe_Icon.svg";
import TicketHistoryModal from "./TicketHistoryModal";

interface TicketDetailsProps{
    ticketDetailsData: any,
    setTicketDetailsData: (data:any) => any,
    fetchTicketDataCnt: number,
    setFetchTicketDataCnt: (cnt:number) => void,
    ticketId: string,
    jobCardId: number,
    editable: boolean,
    setEditable: (flag: boolean) => void,
    showAddIssue: boolean,
    editableWorkshop: boolean,
    ticketStatusId:number,
}

const { Title } = Typography;
const TicketDetails: React.FC<TicketDetailsProps> = ({ticketDetailsData, setTicketDetailsData,
        fetchTicketDataCnt, setFetchTicketDataCnt, ticketId, jobCardId, editable, setEditable, showAddIssue, editableWorkshop
        , ticketStatusId}) => {
    
    const [showCancelTicketDetails, setShowCancelTicketDetails] = useState<boolean>(false);
    const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
    const [disableShowVersionHistory, setDisableShowVersionHistory] = useState<boolean>(true);
    const [initialToggleValue, setInitialToggleValue] = useState<any>({});
    const [form] = Form.useForm();
    const CANCELLED_ID = 11, SERVICE_RESOLVED_ID = 12;
    

    useEffect(()=>{
        setInitialToggleValue({
            replacementCar: ticketDetailsData?.replacementCar,
            pickAndDrop: ticketDetailsData?.pickAndDrop,
            roadSideAssistance: ticketDetailsData?.roadSideAssistance,
        });
    },[]);

    const onCloseVersionHistory = ()=>{
        setShowVersionHistory(false);
    }
    
    return (
        <div>
            <div style={{marginTop:'1.25rem', display: 'flex', justifyContent:'space-between'}}>
                <Title style={{ fontWeight: 700 }} level={5}>
                    Ticket Details  <span style={{ color: "lightgray", fontWeight: 600 }}>(optional)</span>
                </Title>
                <div style={{display:'flex'}}>

                    {
                        !disableShowVersionHistory && 
                        <>
                            <div style={{display:'flex', cursor: 'pointer'}} onClick={()=>setShowVersionHistory(true)}>
                                <EyeIcon style={{ marginTop:'0.25rem'}}/>
                                <span style={{ marginLeft: '0.5rem', paddingRight: '0.8rem', color: '#2C66E3', fontSize: '12px', 
                                    fontWeight: '600' }}>
                                        Show Version History 
                                </span>
                            </div>
                            <PipeIcon style={{ marginLeft:'0.4rem', marginTop:'0.05rem'}} />
                        </>
                    }
                    <div style={{marginLeft: '1rem'}}>
                            { !(ticketStatusId === CANCELLED_ID || ticketStatusId === SERVICE_RESOLVED_ID) &&  (editable ? 
                                (
                                <span
                                    style={{
                                        marginLeft: "0.5rem",
                                        marginRight: "'0.8rem'",
                                        color: "#FF4D4F",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setEditable(false);
                                        setShowCancelTicketDetails(true);
                                        setTicketDetailsData((prevState:any) => ({
                                            ...prevState,
                                            ...initialToggleValue,
                                        }));
                                    }}
                                >
                                    <CrossIcon style={{marginRight:'0.3rem'}}/>
                                    Cancel
                                </span>
                                )
                            : (
                                <span
                                    style={{
                                        marginRight: "10px",
                                        color: (!editableWorkshop && !showAddIssue) ? "#2C66E3" : 'grey',  // Adjust color based on condition
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        cursor: (!editableWorkshop && !showAddIssue) ? "pointer" : "not-allowed",  // Set cursor based on condition
                                        display: 'flex'
                                    }}
                                    onClick={(e) => {
                                        if (!editableWorkshop && !showAddIssue) {
                                            setEditable(true);
                                            setShowCancelTicketDetails(true);  // Show cancel ticket details instead of workshop
                                        }
                                    }}
                                >
                                    <EditIcon 
                                        style={{
                                            marginRight: '0.3rem', 
                                            marginTop: '0.15rem', 
                                            color: (!editableWorkshop && !showAddIssue) ? "#2C66E3" : 'grey',  // Adjust icon color
                                        }} 
                                    />
                                    Edit
                                </span>

                                ))
                            }
                    </div>
                </div>

            </div>
            <Card style={{ marginTop: '20px'}}>
                <div style={{minHeight:'200px'}}>
                    <TicketForm ticketDetailsData={ticketDetailsData} setTicketDetailsData={setTicketDetailsData} 
                        setFetchTicketDataCnt={setFetchTicketDataCnt} ticketId={ticketId} editable={editable} 
                        setEditable={setEditable} showCancelTicketDetails={showCancelTicketDetails} setShowCancelTicketDetails={setShowCancelTicketDetails}
                        form={form} ticketStatusId={ticketStatusId}/>
                </div>
            </Card>
            <TicketHistoryModal showVersionHistory={showVersionHistory} 
                onCloseVersionHistory={onCloseVersionHistory} jobCardId={jobCardId}
                fetchTicketDataCnt={fetchTicketDataCnt} setDisableShowVersionHistory={setDisableShowVersionHistory} ticketStatusId={ticketStatusId}/>
        </div>
        
    );
}

export default TicketDetails;