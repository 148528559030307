import { Button, Checkbox, Input, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getRequest } from "../../../utils/handler/apiHandler";
import debounce from 'lodash/debounce';
import { CheckOutlined } from "@ant-design/icons";

export default function FilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters,fetchApiUrl,setBusinessNameOptions, width=240, isKeyItems, page }:any) {

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchPage, setSearchPage] = useState(0);
    const [searchValue, setSearchValue] = useState<any>('');

    const handleSearch = (value: any) => {
        setSearchValue(value);
        debouncedSearch(value, page ? page : 0) ; // Reset the page when a new search starts
    };

    const handlePopupScroll = (e: any) => {
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            fetchBusinessNameOptions(searchValue, searchPage + 1); // Load more options
        }
    };

    const fetchBusinessNameOptions = async (name: string, pageProp: number = page ? page : 0) => {
        setSearchLoading(true);
        try {
            const api = fetchApiUrl(name, pageProp)
            const res = await getRequest(api);
            if (res.status === 200) {
                if(isKeyItems) {
                    setBusinessNameOptions((prev:any) => pageProp === (page ? page : 0) ? res.data.items : [...prev, ...res.data.items]);
                } else {
                    setBusinessNameOptions((prev:any) => pageProp === (page ? page : 0) ? res.data.data : [...prev, ...res.data.data]);
                }
                setSearchPage(pageProp);
            } else {
                console.error('Error fetching Brand Names:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Brand Names:', error);
        } finally {
            setSearchLoading(false);
        }
    };

    useEffect(() => {
        setBusinessNameOptions([]);
    }, [searchValue])

    const debouncedSearch = debounce(fetchBusinessNameOptions, 500);


    return <div style={{ padding: 4 }}>
        {fetchApiUrl && <Input.Search value={searchValue} onChange={(e : any) => {setSearchValue(e.target.value);}} loading={searchLoading} onSearch={handleSearch} /> }
        <div style={{ maxHeight: 200, width: width, overflow: "scroll", marginTop: 4 }} onScroll={(e) => {searchValue && handlePopupScroll(e);}}>
            {filters?.map((i:any, key:number) =>{
                const color = i?.color != null ? i?.color : 'black';
                const bgColor = i?.bgColor != null ? i?.bgColor : 'white';
                return(
                    <div key={key} className="item-item">
                        <Checkbox
                            checked={selectedKeys.find((y:any) => y == i.value) ? true : false}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedKeys([...selectedKeys, i.value as number])
                                } else {
                                    setSelectedKeys(selectedKeys.filter((x:any) => x !== i.value))
                                }
                                console.log(e.target.checked)
                            }}
                            // checked={selectedItemsInternal.includes(item[searchKey])}
                            // onChange={() => handleItemSelection(item[searchKey] as string)}
                            className="custom-checkbox"
                        />
                        <Tooltip title={i?.text}>
                            <span style={{color : color, backgroundColor : bgColor, borderRadius : 4, paddingRight : 4, marginLeft: '8px', maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {i.text}
                            </span>
                        </Tooltip>
                    </div>
                )}
            )}
        </div>
        {filters.length > 0 && <div style={{ display: "flex", gap: 8, paddingTop:4 }}>

            <Button
                block
                onClick={() => { setSelectedKeys([]); clearFilters; confirm({ closeDropdown: true }); setBusinessNameOptions([]); setSearchValue("");}}
                size="small"
            >
                Reset
            </Button>
            <Button
                block
                type="primary"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: true });
                }}
                icon={<CheckOutlined />}
            >
                Confirm
            </Button>

        </div>}
    </div>
}