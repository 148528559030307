import { CheckOutlined, CloseOutlined, DownloadOutlined, EyeFilled, FileOutlined, FileTextOutlined, InfoCircleOutlined, LeftOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Spin, Tooltip, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as PipeIcon } from '../../../../utils/icons/Pipe_Icon.svg';
import { ReactComponent as DisabledIcon } from '../../SVGs/disabled_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as CloudUploadIcon } from '../../SVGs/cloud_upload_icon.svg';
import { ReactComponent as PreviewDocIcon } from '../../SVGs/preview_doc_icon.svg';
import { ReactComponent as DownloadSheetIcon } from '../../SVGs/download_doc_icon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as AddIcon } from '../../Prospect/utils/SVGs/add_icon.svg';
import { ReactComponent as CashIcon } from '../../SVGs/CashIcon.svg';
import { useAppSelector } from "../../../../app/hooks";
import { menuSelector } from "../../../../features/menu/menuSlice";
import { userSelector } from "../../../../features/auth/userSlice";
import CancelRequestModal from "./CancelRequestModal";
import { getRequest, handleFileDownload, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import RequestedQuoteDetails from "./RequestedQuoteDetails";
import UploadModal from "./UploadModal";
import ETAModal from "./ETAModal";
import QuotePriceDetails from "./QuoteDetailsForm";
import CompleteQuoteDetails from "./CompleteQuoteDetails";
import { ReactComponent as TickMarkIcon } from "../../SVGs/tick_mark_icon.svg";
import axios from "axios";
import { authSelector } from "../../../../features/auth/authSlice";
import { ADMIN, SALES_MANAGER, SALES_HEAD, AS_MANAGER, AS_HEAD, RESTRICTED_ADMIN} from "../../constants";
import UploadModalDemo from "./UploadModalDemo";
import DocumentTableModal from "./DocumentTableModal";
import CreateClientQuote from "./CreateClientQuote";
import OcModal from "./OcModal";
import ViewBlobModal from "./ViewBlobModal";
import PaymentModal from "./PaymentModal";
import { ReactComponent as EyeIcon } from '../../../../utils/icons/view_icon.svg';
import PaymentHistoryModal from "./PaymentHistoryModal";
import ModalInput from "./ModalInput";

const { Title } = Typography;

interface RequestQuoteProps {
  isClientQuote?: boolean;
  isPayments?: boolean;
}

const RequestedQuote: React.FC<RequestQuoteProps> = ({ isClientQuote, isPayments }) => {
  const url: any = useLocation();
  const [form] = Form.useForm();
  const [cancelRemarkForm] = Form.useForm();
  const navigate = useNavigate();
  const auth = useAppSelector(authSelector);
  const requestNumber = url.pathname.split("/").pop();
  const [loader, setLoader] = useState(false);
  const [quoteRequestDetails, setQuoteRequestDetails] = useState<any>();
  const [quoteData, setQuoteData] = useState<any>();
  const [createClientQuoteModal, setCreateClientQuoteModal] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showETAModal, setShowETAModal] = useState(false);
  const [docViewer, setDocViewer] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadModalDemo, setUploadModalDemo] = useState(false);
  const [remark, setRemark] = useState("");
  const [eta, setEta] = useState("");
  const [cancelLoader, setCancelLoader] = useState<boolean>(false);
  const [backToOpsReviewLoader, setBackToOpsReviewLoader] = useState<boolean>(false);
  const [acceptLoader, setAcceptLoader] = useState<boolean>(false);
  const [proceedLoader,setProceedLoader] = useState<boolean>(false);
  const [etaLoader, setEtaLoader] = useState<boolean>(false);
  const [statusId, setStatusId] = useState<any>(null);
  const [isBackToOpsReview, setIsBackToOpsReview] = useState<boolean>(false);
  const [title, setTitle] = useState<any>("");
  const [subTitle, setSubTitle] = useState<any>("");
  const [piFiles, setPiFiles] = useState<any[]>([]);
  const [fileSelectionFlag, setFileSelectionFlag ] = useState<boolean>(false);
  const user: any = useAppSelector(userSelector);
  const [editable, setEditable] = useState<boolean>(false);
  const [quoteStatusId, setQuoteStatusId] = useState<number>(-1);
  const [clientData, setClientData] = useState<any>([]);
  const [additionalDetailsLog, setAdditionalDetailsLog] = useState([]);
  const [ocMoldalOpen, setOcModalOpen] = useState(false)
  const [showSignedOc, setShowSignedOc] = useState(false)
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [cancelRemark, setCancelRemark] = useState<string>("");
  const [modal, contextHolder] = Modal.useModal();
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [paymentTrancheList, setPaymentTrancheList] = useState<any>();
  const [assetCount, setAssetCount] = useState();
  const menuActions = useAppSelector(menuSelector).menuActions;
  const allowedActionsForSampleQuoteList: string[] | undefined = menuActions?.['Sample Quote'];
  const allowedActionsForSampleQuote = new Set(allowedActionsForSampleQuoteList);
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [assetNumber, setAssetNumber] = useState<any>();
  const [assetModal, setAssetModal] = useState<any>();
  const [assetLoader, setAssetLoader] = useState<boolean>(false);
  const [ocLoader, setOcLoader] = useState<boolean>(false);
  const [assetRes, setAssetRes] = useState<string | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const quoteId = isClientQuote ? url.pathname.split("/").pop() : parseInt(searchParams.get("quoteId") || "");
  // const clientQuoteId = url.pathname.split("/").pop();
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
  
  useEffect(() => {    
    setLoader(true);
    (async () => {
      isPayments && fetchAllRequestedQuoteTableData()
      if(quoteId) {
        fetchQuoteDataById(quoteId);
      }
      else {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteDetail/${requestNumber}`).then(res => {
          setQuoteRequestDetails(res.data);
          setQuoteStatusId(res.data?.quoteStatusId);
        });
      }
      setLoader(false);
    }
    )()
  }, []);

  const fetchQuoteDataById = async (quoteId :number) =>{
    try{
      let res;
      if(isClientQuote || isPayments) {
        res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${quoteId}${isPayments ? `?isPayment=true` : ""}`);
        setQuoteData(res.data);
      } else {
        res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/${quoteId}`);
        setQuoteData(res.data);
      }
      
      const apiUrl = (isClientQuote || isPayments) ? `quoteservice/client-quote/${quoteId}${isPayments ? `?isPayment=true` : ""}` : `contractservice/clientOnBoarding/searchClientByProspectId?prospectId=${res?.data?.prospectId}`

      if(res?.data?.prospectId) {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/${apiUrl}`).then(resp => {
          setClientData(resp.data);
          if(isClientQuote || isPayments) {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/securityProcessingFees?clientQuoteId=${resp.data?.clientQuoteId}`).then(res => {
                setPaymentInfo(res.data);
            });
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/audit/additional_detail/${resp.data?.additionalQuoteDetails?.id}`).then(response => {
              setAdditionalDetailsLog(response.data)
            })
            isPayments && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/payment-confirmation/paymentHistory?clientQuoteId=${resp?.data?.clientQuoteId}`).then(res => {
              setPaymentTrancheList(res?.data?.paymentConfirmationDetailTranches)
            })
        }
        })
      }
      if(res.status === 404) navigate("/dashboard/quote")
    } catch(err) {
      console.log("Error in fetching Quote by Id : " + quoteId, err);
      navigate("/dashboard/quote")
    }
  }

  useEffect(() => {
    paymentInfo?.showAssetAssign && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/assetsToAssign?clientQuoteId=${quoteId}`).then(res => {
      setAssetCount(res.data);
    });
  }, [paymentInfo])

  const handleCancelRequest = async (statusId: number) => {
    isBackToOpsReview ? setBackToOpsReviewLoader(true) : setCancelLoader(true);
    try {
      let payload = {};
       if(!isBackToOpsReview){
        payload = { statusChangeTypeId: statusId, rejectRemark: remark }
       }else{
        payload = { statusChangeTypeId: 2, backToOpsReviewRemark: remark}
       }
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${requestNumber}`, payload);
        setCancelLoader(false);
        setBackToOpsReviewLoader(false);
        setShowCancelModal(false);
        setTimeout(()=>window.location.reload(), 100);
    } catch(err) {
      console.log("Error: ", err);
    }
  }

  const handleProceed = async () => {
    setEtaLoader(true); 
    try {
      const payload: any = {
        statusChangeTypeId: statusId,
      }
      statusId === 2 ? payload["piEta"] = eta : statusId === 4 ? payload["eta"] = eta : null
      const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${requestNumber}`, payload);
      if(res) setEtaLoader(false);
      setShowCancelModal(false);
      setShowETAModal(false)
      setTimeout(()=>window.location.reload(), 100);
    } catch(err) {
      console.log("Error: ", err);
    }
  }

const downloadQuotePreviewExcel = async (record : any) => {  
  const downloadUrl = isClientQuote ? 
    `${clientData?.clientQuoteId}?isClientQuote=true` : `${record?.quoteId}`
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/excel/`+downloadUrl,
    {},
    {
        headers: {
            "Authorization": `Bearer ${auth.accessToken}`
        },
        responseType: 'arraybuffer'
    }
    );
    const url =  window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${record?.prospectName}_${record?.quoteNo || record?.clientQuoteNo}.xlsx`);
    document.body.appendChild(link);
    link.addEventListener('click', () => {
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 100);
    });
    link.click();
    document.body.parentNode?.removeChild(link);
} catch (error) {
    console.error('Error fetching dbData:', error);
}
}

const markQuotePending = async (statusId:any) => {
  try {
    setProceedLoader(true)
    setStatusId(statusId)
    console.log(statusId)
    const payload: any = {
      statusChangeTypeId: statusId,
    }
    const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${requestNumber}`, payload);
    setProceedLoader(false)
    setTimeout(()=>window.location.reload(), 100);
  } catch(err) {
    console.log("Error: ", err);
  }
}

const handleUpload = (fileList: any) => {
  setPiFiles(fileList);
};

const handleDownload = async (file: any) => {
  const url = clientData?.isSignedOcUploaded 
  ? clientData?.ocUrl
  : `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/generateOcf/${clientData?.clientQuoteId}`;
  const fileName = `ClientQuote_OCF_${clientData?.clientQuoteId}`;
  handleFileDownload(url, fileName, 'pdf')
};

const handleStatus = (status: number) => {
  try {
    setOcLoader(true);
    const value = cancelRemarkForm.getFieldValue("cancel-remark");
    const payload = status === 14 ? {
      cancelOrderRemarks: value
    } : {}
    
    putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${clientData?.clientQuoteId}?statusId=${status}`, payload).then(res => {
      status === 14 ? postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirementQuoteMapping?requestId=${clientData.requestId}&requirementId=${clientData.requirementId}&quoteId=${clientData?.sampleQuoteId}&statusChangeTypeId=${5}`, {}).then(e  => {
        setOcLoader(false);
        setTimeout(() => window.location.reload(), 100);
      })
      : (
        setOcLoader(false),
        setTimeout(() => window.location.reload(), 100)
      )

    })
  } catch (err) {
    console.log("Error: ", err);
  }
}
const handleConfirmOrder = (status: number, isCancel?: boolean) => {
  Modal.confirm({
    title: <div>
        {!isCancel && <img width={65} src={`${__dirname}assets/warning-icon.png`} />}

      { isCancel && <Typography style={{marginBottom: 10}}>Are you sure you want to cancel the order?</Typography>}
       { isCancel
       ? 
       <Form form={cancelRemarkForm}>
        <Form.Item name="cancel-remark">
          <Input className="input-override" onChange={e => setCancelRemark(e.target.value)} placeholder="Remark" />
        </Form.Item>
       </Form>
       : <div style={{fontWeight: 400, marginTop: 20}}>
            {clientData?.quoteStatus?.id == 12 && "Are you sure you want to confirm order?"}
            {clientData?.quoteStatus?.id == 13 && "Are you sure you want to confirm the payment?"}
            {(clientData?.quoteStatus?.id == 17 || clientData?.quoteStatus?.id == 19) && "Are you sure you want to re-request payment?"}
        </div>}
    </div>,
    // content: 'Once deleted changes cannot be reverted',
    okText: 'Confirm',
    okType: 'danger',
    centered: true,
    cancelText: 'Cancel',
    icon: null,
    onOk: async () => {
      handleStatus(status)
    },
    bodyStyle: {
        textAlign: isCancel ? "unset" : "center",
    },
    footer: (child)=> {
        return <div style={{display: "flex", justifyContent: "center"}}>
        {child}
        </div>
    },
    okButtonProps: { style: { width: 140, marginTop: 10 }, type: "primary", danger: false, block: true, icon: <CheckOutlined /> },
    cancelButtonProps: { style: { width: 140, marginTop: 10 }, type: "default", onClick: () => { Modal.destroyAll(); cancelRemarkForm.resetFields() }, block: true, icon: <CloseOutlined /> }
})
}

  const fetchAllRequestedQuoteTableData = async (): Promise<void> => {
    try {
        const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/payment-confirmation/paymentHistory?clientQuoteId=${quoteId}`;
        const res = await getRequest(getApi);
        console.log("paymentHistory", res.data)
    } catch (error) {
        console.error('Error fetching Requested data:', error);
    } finally {
    }
  };

  const handleAssetAssign = () => {
    setAssetLoader(true)
    try {
      putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/assignAsset?parentClientQuote=${clientData?.clientQuoteId}&assignmentNumber=${assetNumber}`, {}).then(res => {
        setAssetRes(res.data);
        setAssetLoader(false);
        setTimeout(() => window.location.reload(), 3000);
      })
    } catch (err) {
      setAssetLoader(false)
      console.log("Error: ", err);
    }
  }

  return ( 
  <React.Fragment>
    <div className="parent-container">
      <div className="container-layout">

      {ocLoader && <div style={{position: "absolute", top: 0, bottom: 0, right: 0, left: 0, backgroundColor: "white", opacity: 0.7, zIndex: 999}}>
        <div style={{height: "100vh", flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spin size="large" />
          <Typography style={{marginTop: 20, color: "#1677ff"}}>Loading...</Typography>
        </div>
      </div>}

      {/* HEADER SECTION START*/}
      <div className="container-header">
          <div className="container-header-title">
            <div className="container-header-title-section-one" style={{height: 30}}>
              <LeftOutlined style={{margin: 0, padding: 0}} onClick={_ => navigate(-1)} />
              <Tooltip title={""} placement="bottomLeft">
                <Title level={4} style={{maxWidth: 300, overflow: "hidden", width: loader ? 500 : "auto"}} className={loader ? "shimmer-ui" : ""}>
                    {quoteId ? <>{(isClientQuote || isPayments) ? "Master Quote No. :": "Quote No. :"} {quoteData?.clientQuoteNo || quoteData?.quoteNo}</> : <>Request No. {requestNumber}</>}
                </Title>
              </Tooltip>
              <div style={{
                backgroundColor: quoteRequestDetails?.statusBackgroundColor || clientData?.quoteStatus?.backgroundColor || quoteData?.statusBackgroundColor, 
                color: quoteRequestDetails?.statusTextColor || clientData?.quoteStatus?.textColor || quoteData?.statusTextColor, 
                padding: 8, 
                borderRadius: 4, 
                fontWeight: 500, 
                fontSize: 12, 
                marginLeft: 10}}
              >{quoteRequestDetails?.quoteStatus || clientData?.quoteStatus?.name || (((!isPayments && !isClientQuote && quoteData?.quoteStatusId) === null) ? quoteData?.quoteStatus : "Quote Completed")}</div>
            </div>
            <div className="container-header-title-section-two">
                <Typography style={{width: "auto"}}>
                    Prospect Name: <a className="link" href={`/dashboard/prospect/${quoteData?.prospectId || quoteRequestDetails?.prospectId}`}>{quoteRequestDetails?.prospectName || quoteData?.prospectName}</a>
                </Typography>
                {
                  (quoteData?.requirementNumber != null || quoteRequestDetails?.requirementNumber) && <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                }
                {
                  quoteData?.requirementNumber != null && <Typography style={{width: "auto"}}>Requirement: Requirement {quoteData?.requirementNumber}</Typography>
                }
                {
                  (quoteData?.requirementNumber == null && quoteRequestDetails?.requirementNumber != null) && <Typography style={{width: "auto"}}>Requirement: Requirement {quoteRequestDetails?.requirementNumber}</Typography>
                }


                {
                  ((quoteData?.piEta != null || quoteRequestDetails?.piEta) && quoteRequestDetails?.quoteStatusId === 2) && <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                }
                {
                  ((quoteData?.piEta == null && quoteRequestDetails?.piEta != null) && quoteRequestDetails?.quoteStatusId === 2) && <Typography style={{width: "auto"}}>ETA for PI: {quoteRequestDetails?.piEta}</Typography>
                }

                {
                  ((quoteData?.eta != null || quoteRequestDetails?.eta) && quoteRequestDetails?.quoteStatusId === 4) && <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                }
                {
                  ((quoteData?.eta == null && quoteRequestDetails?.eta != null) && quoteRequestDetails?.quoteStatusId === 4) && <Typography style={{width: "auto"}}>ETA for Sample Quote: {quoteRequestDetails?.eta}</Typography>
                }

                <div className="container-header-action">
            </div>
            </div>
          </div>
          {!(isClientQuote || isPayments) && <div className="container-header-action">
            {
              ((quoteRequestDetails?.quoteStatusId === 1 || quoteRequestDetails?.quoteStatusId === 8) && (user.role === SALES_MANAGER || user.role === SALES_HEAD)) && <Button
                ghost
                danger
                type="primary"
                icon={<DisabledIcon />}
                style={{marginLeft: 10}}
                disabled={quoteRequestDetails?.quoteStatusId === 6}
                onClick={_ => setShowCancelModal(true)}
                className={quoteRequestDetails?.quoteStatusId === 6 ? "button-override disable" : "button-override danger"}
              >
                  Cancel Request
              </Button>
            }
            {
              (quoteRequestDetails?.proformaDocId === null && (quoteRequestDetails?.quoteStatusId === 2 && allowedActionsForSampleQuote?.has('uploadPerformaInvoice'))) && 
              <Upload
              accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
              multiple={true}
              onChange={(info) => {setUploadModalDemo(true) ; setFileSelectionFlag(!fileSelectionFlag); handleUpload(info.fileList); }}
              showUploadList={false}
              fileList={[]}
              beforeUpload={() => false}
              >
                <Button
                  type="primary"
                  icon={<CloudUploadIcon />}
                  style={{marginLeft: 10}}
                  disabled={quoteRequestDetails?.quoteStatusId === 6}
                  onClick={() => {}}
                  className="button-override"
                >
                    Upload Proforma Invoice
                </Button>
              </Upload>
            }
            {
              (((quoteRequestDetails?.quoteStatusId === 2 && quoteRequestDetails?.proformaDocId !== null) || quoteRequestDetails?.quoteStatusId === 3) ) && 
              <>
                {
                  allowedActionsForSampleQuote?.has('reUploadPerformaInvoice') &&
                  <Upload
                  accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                  multiple={true}
                  onChange={(info) => {setUploadModalDemo(true); setUploadModalDemo(true) ; setFileSelectionFlag(!fileSelectionFlag); handleUpload(info.fileList); }}
                  showUploadList={false}
                  fileList={[]}
                  beforeUpload={() => false}
                  >
                    <Button
                      ghost
                      type="primary"
                      icon={<AddIcon />}
                      style={{marginLeft: 10}}
                      onClick={() => {}}
                      className="button-override primary"
                    >
                        Add more Proforma Invoice
                    </Button>
                  </Upload>
                }
                {
                    allowedActionsForSampleQuote?.has('viewPerformaInvoice') &&
                    <Button
                    ghost
                    type="primary"
                    icon={<PreviewDocIcon />}
                    style={{marginLeft: 10}}
                    onClick={_ => setDocViewer(true)}
                    className="button-override primary"
                  >
                      View Proforma Invoice
                  </Button>
                }
              </>
            }
            {
              quoteRequestDetails?.quoteStatusId === 4 && 
              <>
              {
                allowedActionsForSampleQuote?.has('cancelRequest') &&
                <Button
                  ghost
                  danger
                  type="primary"
                  icon={<DisabledIcon />}
                  style={{marginLeft: 10}}
                  disabled={quoteRequestDetails?.quoteStatusId === 6}
                  onClick={_ => setShowCancelModal(true)}
                  className={quoteRequestDetails?.quoteStatusId === 6 ? "button-override disable" : "button-override danger"}
                >
                    Cancel Request
                </Button>
              }
              
              {
                allowedActionsForSampleQuote?.has('viewPerformaInvoice') &&
                <Button
                  ghost
                  type="primary"
                  icon={<PreviewDocIcon />}
                  style={{marginLeft: 10}}
                  onClick={_ => setDocViewer(true)}
                  className="button-override primary"
                >
                    View Proforma Invoice
                </Button>
              }
              </>
            }
            {
              (!isClientQuote && quoteData?.proformaId && allowedActionsForSampleQuote?.has('viewPerformaInvoice')) &&
              <Button
                ghost
                type="primary"
                icon={<PreviewDocIcon />}
                style={{marginLeft: 10}}
                onClick={_ => setDocViewer(true)}
                className="button-override primary"
              >
                  View Proforma Invoice
              </Button>
            }
            {
              quoteData?.quoteId && allowedActionsForSampleQuote?.has('downloadSheet') &&
              <Button
                ghost
                type="primary"
                icon={<DownloadSheetIcon />}
                style={{marginLeft: 10}}
                onClick={_ => downloadQuotePreviewExcel(quoteData)}
                className="button-override primary"
              >
                  Download Sheet
              </Button>
            }
            { 
            ((clientData?.clientStateStatus === "On Boarding Completed" || clientData?.quoteStatus?.id < 11 || clientData?.quoteStatus?.id == 14) && !(quoteData?.quoteStatus === "Client Quote Created")) &&
              searchParams.get("quoteId") && 
                <Button
                  type="primary"
                  icon={<TickMarkIcon />}
                  style={{marginLeft: 10}}
                  onClick={_ => setCreateClientQuoteModal(true)}
                  className="button-override"
                >
                    Create Client Quote
                </Button>
              }
          </div>}


          {isClientQuote && <div className="container-header-action">
            {(clientData?.quoteStatus?.id >= 12 && clientData?.quoteStatus?.id < 16 && !(clientData?.quoteStatus?.id === 14)) &&
              <Button
                ghost
                danger
                type="primary"
                icon={<CrossIcon />}
                style={{marginLeft: 10}}
                onClick={_ => handleConfirmOrder(14, true)}
                className="button-override danger"
              >
                  Cancel Order
              </Button>
            }
            {
              (clientData?.quoteStatus?.id == 11) &&
              <Button
                ghost
                type="primary"
                icon={<DownloadSheetIcon />}
                style={{marginLeft: 10}}
                onClick={_ => downloadQuotePreviewExcel(quoteData)}
                className="button-override primary"
              >
                  Download Sheet
              </Button>
            }
            {!(clientData.clientFileDetails?.length > 0) && clientData?.quoteStatus?.id < 12 &&
              <Upload
              accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
              multiple={true}
              onChange={(info) => {setUploadModalDemo(true) ; setFileSelectionFlag(!fileSelectionFlag); handleUpload(info.fileList); }}
              showUploadList={false}
              fileList={[]}
              beforeUpload={() => false}
              >
                <Button
                  type="primary"
                  ghost
                  icon={<FileTextOutlined />}
                  style={{marginLeft: 10}}
                  // disabled={quoteRequestDetails?.quoteStatusId === 6}
                  onClick={() => {}}
                  className="button-override primary"
                >
                    Upload PI
                </Button>
              </Upload>
            }
            {
                clientData?.quoteStatus?.id <= 12 &&
                <Button
                ghost
                type="primary"
                icon={<EyeIcon />}
                style={{marginLeft: 10}}
                onClick={_ => setDocViewer(true)}
                className="button-override primary"
              >
                  View PI
              </Button>
              
            }
            {
              (allowedActionsForSampleQuote?.has('reUploadPerformaInvoice') && clientData.clientFileDetails?.length > 0 && clientData?.quoteStatus?.id < 12 ) &&
              <Upload
              accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
              multiple={true}
              onChange={(info) => {setUploadModalDemo(true); setUploadModalDemo(true) ; setFileSelectionFlag(!fileSelectionFlag); handleUpload(info.fileList); }}
              showUploadList={false}
              fileList={[]}
              beforeUpload={() => false}
              >
                <Button
                  ghost
                  type="primary"
                  icon={<AddIcon />}
                  style={{marginLeft: 10}}
                  onClick={() => {}}
                  className="button-override primary"
                >
                    Add more PI
                </Button>
              </Upload>
            }
            {clientData?.quoteStatus?.id < 12 && <Button
                ghost
                type="primary"
                icon={<FileTextOutlined />}
                style={{marginLeft: 10}}
                onClick={_ => setCreateClientQuoteModal(true)}
                className="button-override primary"
              >
                  Revise Quote
              </Button>}
            {!(clientData?.quoteStatus?.id >= 12) && <Button disabled={clientData?.clientFileDetails?.length === 0} onClick={()=>setOcModalOpen(true)} className="button-override" style={{marginLeft: 10}} icon={<CheckOutlined />} type="primary">Generate OC Draft</Button>}
            {clientData?.ocUrl && clientData?.quoteStatus?.id >= 12 && <Button onClick={()=>setShowSignedOc(true)} className="button-override primary" style={{marginLeft: 10}} icon={<EyeFilled />} type="primary" ghost>View {clientData?.isSignedOcUploaded ? `Signed` : ""} OC</Button>}
            { clientData?.quoteStatus?.id >= 16 && <Button
                ghost
                type="primary"
                icon={<PreviewDocIcon />}
                style={{marginLeft: 10}}
                onClick={_ => setShowPaymentHistory(true)}
                className="button-override primary"
              >
                  Transaction Details
              </Button> }
              { (((clientData?.quoteStatus?.id >= 17 && clientData?.quoteStatus?.id < 18) || clientData?.quoteStatus?.id === 19) && !clientData?.fullPaymentReceived) && <Button
                ghost
                type="primary"
                icon={<CashIcon />}
                style={{marginLeft: 10}}
                onClick={_ => handleConfirmOrder(16)}
                className="button-override primary"
              >
                Request Payment
              </Button> }
              { paymentInfo?.showAssetAssign && <Button
                type="primary"
                icon={<TickMarkIcon />}
                style={{marginLeft: 10}}
                onClick={_=> setAssetModal(true)}
                className="button-override"
              >
                  Asset Assign
              </Button> }
            {(clientData?.quoteStatus?.id >= 12 && !(clientData?.quoteStatus?.id === 14)) && !clientData?.isSignedOcUploaded && <Button onClick={handleDownload} className="button-override primary" style={{marginLeft: 10}} icon={<DownloadOutlined />} type="primary" ghost>Download OC</Button>}
            {(clientData?.quoteStatus?.id >= 12 && !(clientData?.quoteStatus?.id === 14)) && !clientData?.isSignedOcUploaded && <Button onClick={()=>setOcModalOpen(true)} className="button-override" style={{marginLeft: 10}} icon={<UploadOutlined />} type="primary">Upload {!clientData?.isSignedOcUploaded ? `Signed` : ""} OC</Button>}
            
            {<>
                { (clientData?.quoteStatus?.id === 12) && clientData?.isSignedOcUploaded &&
                  <Button
                    type="primary"
                    icon={<TickMarkIcon />}
                    style={{marginLeft: 10}}
                    onClick={_ => handleConfirmOrder(13)}
                    className="button-override"
                  >
                      Confirm Order
                  </Button>
                }
                { (clientData?.quoteStatus?.id === 13 ) &&
                  <Button
                    type="primary"
                    icon={<CashIcon />}
                    style={{marginLeft: 10}}
                    onClick={_ => handleConfirmOrder(15)}
                    className="button-override"
                  >
                      Request Payment Confirmation
                  </Button>
                }
              </>}
          </div>}

          {isPayments && <div>
            {
              quoteId && (clientData?.quoteStatus?.id > 15) &&
              <Button
                ghost
                type="primary"
                icon={<PreviewDocIcon />}
                style={{marginLeft: 10}}
                onClick={_ => setShowPaymentHistory(true)}
                className="button-override primary"
              >
                  Payment History
              </Button>
            } 
            <Button
              ghost
              type="primary"
              icon={<PreviewDocIcon />}
              style={{marginLeft: 10}}
              onClick={()=>setShowSignedOc(true)}
              className="button-override primary"
            >
                View Signed OC
            </Button>
            {((clientData?.quoteStatus?.id === 20 || clientData?.quoteStatus?.id === 19 || (clientData?.quoteStatus?.id >= 15 && clientData?.quoteStatus?.id <= 17)) && !clientData?.fullPaymentReceived) && <Button
              type="primary"
              icon={<TickMarkIcon />}
              style={{marginLeft: 10}}
              onClick={_ => setPaymentModal(true)}
              className="button-override"
            >
                {paymentTrancheList?.length === 0 ? "Confirm Payment" : "Add more Tranche"}
            </Button>}
          </div>}
      </div>
      {/* HEADER SECTION END */}


      {/* QUOTE REQUEST SECTION ONE START */}
      {!quoteId && 
      <RequestedQuoteDetails 
        details={quoteRequestDetails} 
        loader={loader} 
        setShowRejectModal={setShowRejectModal} 
        setShowETAModal={setShowETAModal} 
        setStatusId={setStatusId}
        acceptLoader={acceptLoader}
        user={user}
        setTitle={setTitle}
        setSubTitle={setSubTitle}
        allowedActionsForSampleQuote={allowedActionsForSampleQuote}
      />}
      {/* QUOTE REQUEST SECTION ONE END */}
      { quoteRequestDetails?.proformaDocId !== null && 
      <div style={{display:"flex", justifyContent:"flex-end"}}>
      {
        (quoteRequestDetails?.quoteStatusId === 2 && (user.role === AS_MANAGER || user.role === AS_HEAD ||
            user.role === ADMIN || user.role === RESTRICTED_ADMIN))
        && <Button
        type="primary"
        style={{marginLeft: 10}}
        onClick={_ => markQuotePending(3)}
        icon={proceedLoader && <Spin indicator={antIcon}/>}
        className="button-override"
        >
          Move to Quote Pending
          <span> {<RightArrowIcon style={{marginLeft:"0.5rem"}}/>}</span>
        </Button>
      }
      </div>}

      {/* ADD NEW COMPONENTS HERE... */}
      {
        (quoteRequestDetails?.quoteStatusId === 4 && (user.role === AS_MANAGER || user.role === AS_HEAD || user.role === ADMIN || user.role === RESTRICTED_ADMIN)) && <>
          <QuotePriceDetails isClientQuote={isClientQuote || false} details={quoteRequestDetails} loader={loader} form={form} 
            data={quoteRequestDetails} isEdit={false} setEditable={setEditable} allowedActionsForSampleQuote={allowedActionsForSampleQuote}/>
        </>
      }
      {
        (quoteRequestDetails?.quoteStatusId === 5 || quoteData) && 
        <CompleteQuoteDetails clientQuoteId={clientData?.clientQuoteId} additionalDetailsLog={additionalDetailsLog} quoteData={quoteData} loader={loader} requestDetails={quoteRequestDetails} 
          user={user} allowedActionsForSampleQuote={allowedActionsForSampleQuote} data={clientData} isPayments={isPayments || false} isClientQuote={isClientQuote || false} />
      }

      {/* MODAL COMPONENTS START */}
      <CancelRequestModal title={"Are you sure you want to cancel the Sample Quote Request"} subTitle="" type="Cancel" showModal={showCancelModal} setShowModal={setShowCancelModal} remark={remark} setRemark={setRemark} cancel={() => handleCancelRequest(6)} cancelLoader={cancelLoader} backToOpsReviewLoader={backToOpsReviewLoader} isBackToOpsReview ={isBackToOpsReview} setIsBackToOpsReview = {setIsBackToOpsReview} quoteStatusId={quoteStatusId}/>
      <CancelRequestModal title={"Are you sure you want to reject the Sample Quote Request"} subTitle="" type="Reject" showModal={showRejectModal} setShowModal={setShowRejectModal} remark={remark} setRemark={setRemark} cancel={() => handleCancelRequest(7)} cancelLoader={cancelLoader} backToOpsReviewLoader={backToOpsReviewLoader} isBackToOpsReview ={isBackToOpsReview} setIsBackToOpsReview = {setIsBackToOpsReview} quoteStatusId={quoteStatusId}/>
      {/* old upload modal for single file */}
      <UploadModal title={"Upload Proforma Invoice"} statusChangeTypeId={2} requestId={requestNumber} showModal={uploadModal} setShowModal={setUploadModal} />
      <UploadModalDemo clientQuoteId={clientData?.clientQuoteId} isClientQuote={isClientQuote} prospectId={clientData?.prospectId} title={"Upload Proforma Invoice"} statusChangeTypeId={quoteRequestDetails?.quoteStatusId === 2 ? 3 : 2} requestId={requestNumber} showModal={uploadModalDemo} setShowModal={setUploadModalDemo} files={piFiles} setFiles={setPiFiles} fileSelectionFlag={fileSelectionFlag}/>
      <ETAModal title={title} subTitle={subTitle} showModal={showETAModal} setShowModal={setShowETAModal} eta={eta} setEta={setEta} handler={() => handleProceed()} etaLoader={etaLoader} buttonText="Proceed" />
      {/* <DocumentViewerModal title={""} docURI={quoteRequestDetails?.proformaDocId || quoteData?.proformaId} showModal={docViewer} setShowModal={setDocViewer} /> */}
      <DocumentTableModal isClientQuote={isClientQuote} clientQuoteFileList={clientData?.clientFileDetails} isModalVisible={docViewer} setIsModalVisible={setDocViewer} requestId={requestNumber}/>
      <CreateClientQuote isClientQuote={isClientQuote} clientData={clientData} quoteData={quoteData} showModal={createClientQuoteModal} setShowModal={setCreateClientQuoteModal} files={files} setFiles={setFiles} />
      <OcModal ocLoader={ocLoader} setOcLoader={setOcLoader} isPayments={isPayments || false} quoteId={quoteId} signedCopy={clientData?.ocUrl ? true : false} clientQuoteId={clientData?.clientQuoteId} prospectId={clientData?.prospectId} visible={ocMoldalOpen} setVisible={setOcModalOpen} />
      <ViewBlobModal clientQuote={clientData} visible={showSignedOc} setVisible={setShowSignedOc} handleDownload={handleDownload} />
      <PaymentModal paymentInfo={paymentInfo} paymentTrancheList={paymentTrancheList} setPaymentTrancheList={setPaymentTrancheList} paymentModal={paymentModal} setPaymentModal={setPaymentModal} data={quoteRequestDetails} clientData={clientData} />
      <PaymentHistoryModal 
        visible={showPaymentHistory} 
        setVisible={setShowPaymentHistory} 
        quoteId={quoteId}
        clientData={clientData}
      />
      <ModalInput title="Assign asset to the client" textType="number" message={assetNumber} setMessage={setAssetNumber} open={assetModal} setOpen={setAssetModal} tooltip={true} tooltipMessage={`Upto ${assetCount} assets can be assigned!`} handleConfirm={handleAssetAssign} max={assetCount} assetRes={assetRes} loader={assetLoader} />
      {/* MODAL COMPONENTS END */}
      </div>
    </div>
    {contextHolder}
  </React.Fragment> 
  )
}

export default RequestedQuote;