import { CheckOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Input, Space, Typography } from "antd"
import { useEffect, useState } from "react"
import { postRequest } from "../../../../utils/handler/apiHandler"
import moment from "moment"
import ReactQuill from "react-quill"
import getFormattedText from "../../../../utils/helpers/htmlToFormattedText"

export default function DocumentRemarks({ docType, selectedDocType, getDocTypes, setSelectedDocType, allowedActionsForClient, creditRequestId, prospectId }: any) {
    const [addNewRemark, setAddNewRemark] = useState(false)
    const [remarks, setRemarks] = useState<any>(selectedDocType.remark)
    const [updating, setUpdating] = useState(false)


    const handleUpdateRemarks = async () => {
        setUpdating(true)

        const docParams =
        {
            id: selectedDocType.id,
            // prospectId: prospectId,
            // creditRequestId: creditRequestId,
            remark: remarks || null, // Default or from selectedDocType
        };
        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/addCreditDoc?creditRequestId=${creditRequestId}`, docParams).then((res: any) => {
            console.log(res)
            getDocTypes()
            setAddNewRemark(false)
            // setAddNewDoc(false)
            // handleShowDocs(selectedDocType.id)
        }).catch(e => {
            console.log(e)
        })
        setUpdating(false)
    }

    useEffect(() => {
        const updatedDoc = docType.find((i: any) => i.id == selectedDocType.id)
        setSelectedDocType(updatedDoc)
    }, [docType])

    const handleRemarksChange = (value: any) => {
        setRemarks(value);
    }

    return (
        addNewRemark ? <>
            <Space style={{ width: "100%", textAlign: "left" }} size="large" direction="vertical">
                {/* <Input.TextArea defaultValue={remarks} onChange={(e) => setRemarks(e?.target?.value)} rows={4} /> */}
                <ReactQuill
                    theme="snow"
                    className="credit-analysis-remarks"
                    defaultValue={remarks}
                    //   value={creditAnalysis}
                    onChange={handleRemarksChange}
                    placeholder={"Enter Remarks"}
                    style={{ display: "flex", flexDirection: "column-reverse", height: "200px", maxHeight: '200px', marginTop: 10, marginBottom: 20 }}
                    modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            ['code-block'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'align': [] }],
                            ['link', true],
                            ['clean'],
                            [{ 'header': [1, 2, false] }],
                        ],
                    }}
                />
                <div style={{ textAlign: "right" }}>
                    <Button loading={updating} onClick={handleUpdateRemarks} disabled={remarks == "" || !remarks || updating} type="primary" icon={<CheckOutlined />}>Save</Button>
                </div>
            </Space>
        </>
            :
            <>
                {
                    !selectedDocType.remark ?
                        <Space size="large" direction="vertical">
                            <img width={255} src={`${__dirname}assets/empty-remarks.png`} />
                            <Typography style={{ color: "#616161" }}>No remarks added currently</Typography>
                            <Button onClick={() => setAddNewRemark(true)} type="primary" icon={<PlusOutlined />}>Add New Remark</Button>
                        </Space> :
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div>
                                <Typography.Text strong>Remarks Added At - {moment(selectedDocType.remarksUpdatedAt).format('h:mm A, DD MMM YYYY')}</Typography.Text>
                            </div>
                            {/* <Typography.Text>{selectedDocType?.remark}</Typography.Text> */}
                            <div style={{overflow: "auto", maxHeight: "400px"}}>
                                {
                                    getFormattedText(selectedDocType?.remark || "")
                                }
                            </div>

                            <div style={{ textAlign: "right", marginTop: 20 }}>
                                <Button onClick={() => setAddNewRemark(true)} type="primary" icon={<EditOutlined />}>Edit</Button>
                            </div>
                        </div>
                }
            </>
    )
}