import { Button, InputNumber, Select, Spin, Typography } from "antd";
import { ReactComponent as TickIcon } from '../../Dashboard/SVGs/tick_icon.svg';
import { ReactComponent as EditIcon } from '../../Dashboard/SVGs/edit_icon_vehicle.svg';
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../utils/handler/apiHandler";
import { getDaysCount, getOrdinalSuffix } from "../../../utils/helpers/utils";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface CreateTicketModalProps {
    loader: boolean;
    ticketId: number;
    setShowCreateTicketModal : (show :boolean ) => void;
}
const { Option } = Select;
const alertTypeOptions = [{ id: '1', name: 'Km Based' },{ id: '2', name: 'Time Based' }];

const CreateAlertTicketModal : React.FC<CreateTicketModalProps> = ({loader, ticketId, setShowCreateTicketModal}) => {
        const [data, setData] = useState<any>();
        const [loading, setLoading] = useState<boolean>(false);
        const [acceptLoader, setAcceptLoader] = useState<boolean>(false);
        const [odometerEditClick, setOdometerEditClick] = useState<boolean>(false);
        const [ticketCriteriaEditClick, setTicketCriteriaEditClick] = useState<boolean>(false);
        const [odometerValueOld, setOdometerValueOld] = useState<any>();
        const [odometerValueNew, setOdometerValueNew] = useState<any>();
        const [alertCriteria, setAlertCriteria] = useState<any>();

        useEffect(() => {
            try{
                setLoading(true);
                const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/create-ticket-response-alert-page?ticketId=${ticketId}`;
                getRequest(getApi).then(res => {
                    if(res.status === 200){
                        setData(res.data);
                        setAlertCriteria(res?.data?.currentServiceCriteriaType);
                        setOdometerValueOld(res?.data?.odometer);
                        setOdometerValueNew(res?.data?.odometer);
                    }else{
                        console.error('Error fetching Status Count Data:', res.msg);
                    }
                })
        
            }catch(err){
                console.log("Error in fetching Status count Data : ", err);
            }finally {
                setLoading(false);
            }
                
        }, []);

        const handleProceed = () => {
            try{
                let url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/create-ticket-from-alert-page?ticketId=${ticketId}&criteriaType=${alertCriteria}`;
                if(odometerValueOld != odometerValueNew){
                    url = url + `&odometer=${odometerValueNew}`;
                }
                setAcceptLoader(true);
                postRequest(url, {}).then(res => {
                    setShowCreateTicketModal(false);
                    window.location.reload();
                })
            }catch(err){
                console.log("Error in creating ticket from alert : ", err);
            }finally{
                setAcceptLoader(false);
            }
        }


        return(
            <Spin spinning={loading} size="default">
            <div style={{display: 'flex', flexDirection: 'column', alignItems : 'start'}}>
                <h4>Vehicle Details</h4>
                <p>Update Details to Create a Ticket</p>
                    <div style={{display : 'flex', justifyContent: 'space-between', width : '100%', marginTop : "15px", marginBottom : "20px", textAlign: "left"}}>
                        <div className="requirement-card-info" style={{}}>
                            <Typography className="description-label">Odometer Reading</Typography>
                            {!odometerEditClick &&
                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                        <span style={{display: loader?"none":""}}>{
                                            loader ? "" :  <span>{data?.odometer != null ? data?.odometer : "NA"} 
                                           {" "}<EditIcon onClick={() => {setOdometerEditClick(!odometerEditClick);}}/></span> 
                                        }</span>
                                    </Typography> 
                            }
                            {odometerEditClick &&
                                <span><InputNumber value={odometerValueNew} onChange={(val) => {setOdometerValueNew(val);}} placeholder="Odometer Reading" style={{minWidth : 140}} /> 
                                {" "}<CloseCircleOutlined onClick={() => {setOdometerEditClick(!odometerEditClick); setOdometerValueNew(data?.odometer)}}/></span> 
                            }
                        </div>
                        <div className="requirement-card-info" style={{}}>
                            <Typography className="description-label">Ticket Criteria</Typography>
                            {!ticketCriteriaEditClick &&
                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                        <span style={{display: loader?"none":""}}>{
                                            loader ? "" :  <span>{data?.currentServiceCriteriaType != null ? data?.currentServiceCriteriaType : "NA"} 
                                           {" "}<EditIcon onClick={() => {setTicketCriteriaEditClick(!ticketCriteriaEditClick); setAlertCriteria(data?.currentServiceCriteriaType);}}/></span> 
                                        }</span>
                                    </Typography> 
                            }
                            {ticketCriteriaEditClick &&
                                <span>                          
                                    <Select value={alertCriteria} onChange={(value) => {setAlertCriteria(value);}} style={{ width: 130 }} >
                                        {alertTypeOptions.map((value: any) => (
                                            <Option key={value?.id} value={value?.name}>
                                                {value?.name}
                                            </Option>
                                        ))}
                                    </Select> 
                                    {" "}<CloseCircleOutlined style={{marginLeft :3}} onClick={() => {setTicketCriteriaEditClick(!ticketCriteriaEditClick); setAlertCriteria(data?.currentServiceCriteriaType);}}/>
                                </span> 
                            }
                        </div>
                        <div className="requirement-card-info">
                            <Typography className="description-label">Upcoming Service</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" :  <span>{alertCriteria != null ? `${getOrdinalSuffix(data?.serviceNumber)} |  ${alertCriteria === "Time Based" ? (data?.currentServiceDate != null ? `${getDaysCount(data?.currentServiceDate)} days` : "NA") : (data?.currentOdometerCriteria != null ? `${data?.currentOdometerCriteria} km` : "NA")} ` : "NA"} </span> 
                                    }</span>
                                </Typography> 
                        </div>
                        <div className="requirement-card-info">
                            <Typography className="description-label">Last Service Date</Typography>
                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                <span style={{display: loader?"none":""}}>{
                                    loader ? "" : data?.lastServiceDate != null ? data?.lastServiceDate : "NA"
                                }</span>
                            </Typography> 
                        </div>
                    </div>
                
                <div style={{display : 'flex', justifyContent : 'flex-end', width : '100%'}}>
                    <Button
                        type="primary"
                        onClick={() => {handleProceed();}}
                        icon={acceptLoader ? <Spin indicator={antIcon} /> : <TickIcon style={{marginLeft: 10}}/>}
                        style={{marginLeft: 10}}
                        className="button-override"
                        > Proceed
                    </Button>
                </div>

            </div>
            </Spin>

        );
}

export default CreateAlertTicketModal;