import { Button, Card, Col, Form, Row, Typography, message } from "antd";
import { useEffect, useState } from "react";
import ContractualDetailsList from "./ContractualDetailsList";
import ServiceTakenDetails from "./ServiceTakenDetails";

interface ContractualDetailsProps {
    ticketNumber: string;
    contractualDetailsData: any;
}

const { Title } = Typography;

const ContractualDetails: React.FC<ContractualDetailsProps> = ({ ticketNumber, contractualDetailsData }) => {
    const [editable, setEditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [form] = Form.useForm();

    return (
        <>
            <Title style={{ marginTop: '20px', fontWeight: 700 }} level={5}>
                Contractual Details
            </Title>

            <div className="container-inner-section-body-section">
                <Card bordered={false}>
                    <div>
                        <Form
                            name="dynamic_form_nest_item"
                            form={form}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Row className="detail-description-item-container">
                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Registration City
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        <span style={{ display: loader ? "none" : "" }}>
                                            {loader ? "" : contractualDetailsData?.registrationCity}
                                        </span>
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        User City
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : contractualDetailsData?.userCity}
                                    </Typography>
                                </Col>

                                <Col className="detail-description-item">
                                    <Typography className="description-label">
                                        Maintenance Scope
                                    </Typography>
                                    <Typography
                                        className={`${loader ? "shimmer-ui" : ""}`}
                                        style={{ fontSize: 14 }}
                                    >
                                        {loader ? "" : contractualDetailsData?.maintenanceScope }
                                    </Typography>
                                </Col>
                            </Row>
                            
                            <ContractualDetailsList contractualDetailsList = {contractualDetailsData?.contractualDetailsList} />
                            
                        </Form>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ContractualDetails;
