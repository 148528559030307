import { Tabs } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import IssueDetails from './IssueDetails/IssueDetails';
import TicketDetailsHeader from './Header/TicketsDetailsHeader';
import TicketsDetails from './VehicleDetails/VehicleDetails';
import { useEffect, useState } from 'react';
import { useFetchVehicleAndTicketDetails } from './Utils/useFetchVehicleAndTicketDetails';
import { useTicketAndVehicleDetails } from './Utils/useTicketAndVehicleDetails'; // Import the custom hook
import JobCard from './IssueDetails/JobCard';
import dayjs from 'dayjs';

interface TicketsPageLayoutProps {}

const TabPane = Tabs.TabPane;

const TicketsPageLayout: React.FC<TicketsPageLayoutProps> = () => {
  const navigate = useNavigate();
  const [fetchTicketDataCnt, setFetchTicketDataCnt] = useState<number> (1);
  const [disableHeaderActions, setDisableHeaderActions] = useState<boolean>(false);
    
  // const [jobCard, setJobCard] = useState<number> (0);
  const { ticketId, vehicleId } = useParams();
  const location = useLocation();
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab'); // Get 'tab' query parameter

  // State to manage the active tab
  const [activeKey, setActiveKey] = useState(initialTab || '1');

  // Use the custom hook to fetch vehicle and ticket details
  const { vehicleDetails, ticketDetails, issueDetails, loader } = useFetchVehicleAndTicketDetails(vehicleId || '', ticketId || '', fetchTicketDataCnt);

  const invoiceDetails = {
    "invoiceStatus": ticketDetails?.jobCard?.invoiceStatus,
    // "remarks": ticketDetails?.jobCard?.invoiceRemark
  }
  const [editingInvoiceForm, setEditingInvoiceForm] = useState(!Object.values(invoiceDetails).every(value => value !== null && value !== undefined && value !== ""))
  const [edittingForm, setEdittingForm] = useState(false)

  // Use the custom hook for data transformation
  const {
    ticketHeaderDetails,
    vehicleBasicDetailsData,
    contractualDetailsData,
    vehicleRamarksList,
    workshopDetailsData,
    setWorkshopDetailsData,
    setTicketDetailsData,
    ticketDetailsData,
    issueSubissueTableData,
    jobCardId,
    ticketStatusId,
    serviceStatusUpdateDate,
    ticketRemark,
  } = useTicketAndVehicleDetails(vehicleDetails, ticketDetails, issueDetails);
  const [disableScheduleService, setDisableScheduleService] = useState<boolean> (true);

  useEffect(()=>{
    if(issueSubissueTableData  && issueSubissueTableData.length > 0 && isWorkshopFormComplete()){
      setDisableScheduleService(false);
    }
    else{
      setDisableScheduleService(true);
    }
  },[issueSubissueTableData, workshopDetailsData]);
  
  const isWorkshopFormComplete = () => {
    // Check all fields except for emailId
    return Object.entries(workshopDetailsData).every(([key, value]) => {
      // Skip emailId field
      if (key === 'emailId') return true;
      return value !== undefined && value !== null;
    });
  };

  return (
    <div style={{ padding: '1.5rem', minHeight: '100vh', backgroundColor: '#f6faff' }}>
      <TicketDetailsHeader
        editingInvoiceForm={editingInvoiceForm}
        edititngForm={edittingForm}
        ticketId= {ticketId||''}
        ticketHeaderDetails= {ticketHeaderDetails}
        setFetchTicketDataCnt={setFetchTicketDataCnt}
        jobCardId={jobCardId}
        prospectId={vehicleDetails?.prospect?.prospectId}
        disableScheduleService={disableScheduleService}
        isPickupAndDrop = {ticketDetailsData?.pickAndDrop}
        ticketDetails={ticketDetails}
        actualPickup={ticketDetailsData?.actualPickup}
        deliverySchedule={ticketDetailsData?.deliverySchedule}
        disableHeaderActions={disableHeaderActions}
      />
      <div className="container-body">
        <Tabs activeKey={activeKey} onChange={setActiveKey} className="tab" onTabClick={(e) => navigate(`?tab=${e}`)}>
          <TabPane tab={<span className="tab-heading">Vehicle Details</span>} key="1">
            <TicketsDetails
              vehicleBasicDetailsData={vehicleBasicDetailsData}
              contractualDetailsData={contractualDetailsData}
              vehicleRamarksList={vehicleRamarksList}
              vehicleId={vehicleDetails?.vehicleDetails?.id}
              setFetchTicketDataCnt={setFetchTicketDataCnt}
              jobCardId={jobCardId}
              fetchTicketDataCnt={fetchTicketDataCnt}
              ticketStatusId={ticketStatusId}
            />
          </TabPane>
          <TabPane tab={<span className="tab-heading">Issue Details</span>} key="2">
            <IssueDetails
              workshopDetailsData={workshopDetailsData}
              setWorkshopDetailsData={setWorkshopDetailsData}
              setTicketDetailsData={setTicketDetailsData}
              ticketDetailsData={ticketDetailsData}
              issueSubissueTableData={issueSubissueTableData}
              fetchTicketDataCnt={fetchTicketDataCnt}
              setFetchTicketDataCnt={setFetchTicketDataCnt}
              ticketId={ticketId||''}
              jobCardId={jobCardId}
              ticketStatusId={ticketStatusId}
              serviceStatusUpdateDate={serviceStatusUpdateDate}
              ticketRemark={ticketRemark}
              setDisableHeaderActions={setDisableHeaderActions}
            />
          </TabPane>
          {[12, 8, 9]?.includes(ticketHeaderDetails?.ticketStatusId) && <TabPane tab={<span className="tab-heading">Job Card</span>} key="3">
            <JobCard
              setEditingInvoiceForm={setEditingInvoiceForm}
              setEdittingForm={setEdittingForm}
              editingInvoiceForm={editingInvoiceForm}
              edittingForm={edittingForm}
              serviceStatusUpdateDate={serviceStatusUpdateDate}
              ticketStatusId={ticketHeaderDetails?.ticketStatusId}
              workshopDetailsData={workshopDetailsData}
              setWorkshopDetailsData={setWorkshopDetailsData}
              ticketDetailsData={ticketDetailsData}
              issueSubissueTableData={issueSubissueTableData}
              setFetchTicketDataCnt={setFetchTicketDataCnt}
              fetchTicketDataCnt={fetchTicketDataCnt}
              ticketId={ticketId || ''}
              jobCardId={jobCardId}
              ticketDetails={ticketDetails}
              prospectId={vehicleDetails?.prospect?.prospectId}
            />
          </TabPane>}
        </Tabs>
      </div>
    </div>
  );
};

export default TicketsPageLayout;
