import { Button, Card, message, Modal, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../../../utils/handler/apiHandler";
import { useDebounce } from 'use-debounce';
import { ReactComponent as CloseIcon } from "./../../../../../../utils/icons/CloseIconBlack.svg";

interface EditIssuesProps {
    setFetchTicketDataCnt?: (cnt: number) => void;
    showEditIssue: boolean,
    setShowEditIssue: (value: boolean) => void,
    ticketId: string,
    jobCardId: number,
    issueId: string,
    subIssueId: string,
    onCloseEditIssue: () => void,
    jobId: number,
    isJobCardIssue?: boolean,
    getJobCardIssueItems?: () => void
}

export default function EditIssues({ setFetchTicketDataCnt, showEditIssue,  setShowEditIssue,
     ticketId, jobCardId, issueId, subIssueId, onCloseEditIssue, jobId, isJobCardIssue, getJobCardIssueItems }: EditIssuesProps) {
    const [issuesList, setIssuesList] = useState<any[]>([]);
    const [subissuesList, setSubissuesList] = useState<any[]>([]);
    const [newIssue, setNewIssue] = useState<string>("");
    const [newSubIssue, setNewSubIssue] = useState<string>("");
    const [loader, setLoader] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // Holds the raw search term entered by user
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500); // Debounced search term to prevent excessive API calls

    useEffect( () => {
        const fetchIssues = async () => {
            try {
                setLoader(true);
                // const filter = encodeURIComponent('name|like|issue');
                const response = await getRequest(
                    // `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/issue?filterAnd=${filter}`
                    `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/issue`
                );

                if (response.status !== 200) {
                    message.error(response.msg || "Failed to fetch Issues list.");
                }
                setIssuesList(response?.data?.items);
                setNewIssue(issueId);
                setNewSubIssue(subIssueId);
            } catch (error) {
                message.error("An error occurred while fetching Issues list.");
            } finally {
                setLoader(false);
            }
        };
        fetchIssues();
    }, []);

    // UseEffect hook to trigger API call when debouncedSearchTerm changes
    useEffect(() => {
        if (debouncedSearchTerm) {
            fetchIssuesForSearchedTerm(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    useEffect(()=>{
        if(!newIssue) return;
        fetchSubissueForIssueId(newIssue);
    },[newIssue]);

    const saveIssueAndSubissue = async () => {
        if (!newIssue && !newSubIssue) {
            message.error("Please enter a valid Issue and Subissue.");
            return;
        }

        try {
            const paylaod = [{
                id: jobId,
                subIssueId: newSubIssue,
            }]
            setLoader(true);
            const encodedRemark = encodeURIComponent(newIssue);
            const response = await postRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/${isJobCardIssue ? "actual-" : ""}job-card-issue?ticketId=${ticketId}`,
                paylaod
            );

            if(!isJobCardIssue){
                setFetchTicketDataCnt && setFetchTicketDataCnt(Math.random());
            } else {
                getJobCardIssueItems && getJobCardIssueItems()
            }
            if (response.status !== 201) {
                message.error(response.msg || "Failed to add Issue and Subissue");
            }
        } catch (error) {
            message.error("An error occurred while adding Issue and Subissue");
        } finally {
            setLoader(false);
            setShowEditIssue(false);
        }
    };

    // Function to fetch Issues List based on search term
    const fetchIssuesForSearchedTerm = async (search: string) => {
        if (search.trim().length < 1) {
            setIssuesList([]); // Clear options if search term is too short
            return;
        }

        setLoader(true); // Show loader while fetching
        try {
            const filter = encodeURIComponent(`name|like|${search}`);
            const response = await getRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/issue?filterAnd=${filter}`
            );
            setIssuesList(response?.data?.items || []); // Update options with response data
        } catch (error) {
            message.error('Failed to fetch Issues List based on search term.'); // Show error message on failure
        } finally {
            setLoader(false); // Hide loader
        }
    };
    const handleIssueSelection = (issueId: string) => {
        setNewIssue(issueId);
        setNewSubIssue('');
        fetchSubissueForIssueId(issueId);
    };

    const fetchSubissueForIssueId = async (issueId: string) => {
        setLoader(true); // Show loader while fetching
        const filter = encodeURIComponent(`issue.id|eq|${issueId}`);
        try {
            const response = await getRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/sub-issue?filterAnd=${filter}`
            );
            setSubissuesList(response?.data?.items || []);
        } catch (error) {
            message.error('Failed to fetch subissues by id.'); // Show error message on failure
        } finally {
            setLoader(false); // Hide loader
        }
    };

    const handleSubissueSelection = (subissueId: string) => {
        setNewSubIssue(subissueId);
    };
    return (
        <Modal
        open={showEditIssue} 
        onCancel={onCloseEditIssue}
        width='80%'
        footer={null} // Removes default OK and Cancel buttons
        closeIcon={<CloseIcon  />} // Custom close icon with red color
        styles={{
            content: {
                background: "#F6FAFF",
            },
            header: {
                background: "#F6FAFF",
                textAlign: "left",
            },
            body: {
                background: "#F6FAFF",
                margin: 0,
                padding: 0,
                maxHeight: '40rem',  // Set a max height for the body
                overflowY: 'auto',  // Enable vertical scrolling
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }
        }}
        title="Edit Issue & Sub-Issues"
        centered
        >
            <Card style={{ width: "100%", marginBottom: "0.6rem" }}>
            <div style={{ display: 'flex'}}>
                <div style={{width:'20rem'}}>
                    <Typography className="description-label"  style={{fontWeight:'500', fontSize:'14px'}}>
                        Issue
                    </Typography>
                    <Select
                        style={{ width: '90%' }}
                        onChange={handleIssueSelection}
                        showSearch
                        loading={loader}
                        filterOption={false} // Disable default filtering; we control the options ourselves
                        placeholder={'Select Issue'}
                        notFoundContent={loader ? 'Loading...' : null}
                        value={newIssue}
                        onSearch={(value) => setSearchTerm(value)} // Update searchTerm as the user types
                    >
                        {issuesList.map((issue) => (
                            <Select.Option key={issue.id} value={issue.id}>
                                {issue.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div style={{width:'100%'}}>
                    <Typography className="description-label"  style={{fontWeight:'500', fontSize:'14px'}}>
                        Sub-Issue
                    </Typography>
                    <Select style={{ width: '100%' }} placeholder={'Select Subissue'}
                        onChange={handleSubissueSelection}
                        value={newSubIssue}
                    >
                        {subissuesList.map((subissue) => (
                            <Select.Option key={subissue.id} value={subissue.id}>
                                {subissue.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    style={{ color: 'rgba(255, 255, 255, 1)', backgroundColor: '#1777FF', borderColor: '#1777FF' }}
                    onClick={saveIssueAndSubissue}
                >
                    Save Issues & Sub - Issues
                </Button>
            </div>
        </Card>
        </Modal>
        
    )
}