import { Button, Card, Modal, message } from "antd";

import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as Caution } from "./../../../../../utils/icons/Caution.svg";
import { ReactComponent as CloseRed } from "./../../../../../utils/icons/Cross_Icon.svg";
import { ReactComponent as CheckWhite } from "./../../../../../utils/icons/CheckWhite.svg";

interface CloseTicketCancelOrResolveModelProps{
    closeTicketOrResolveFlag: number,
    setCloseTicketOrResolveFlag: (flag: number) => void,
}

const CloseTicketCancelOrResolveModel: React.FC<CloseTicketCancelOrResolveModelProps> = ({ closeTicketOrResolveFlag, setCloseTicketOrResolveFlag }) => {
    
    return (
        <Modal 
            open={closeTicketOrResolveFlag === 1} 
            onCancel={()=>setCloseTicketOrResolveFlag(0)}
            width='30%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', justifyContent:'center', marginTop:'2rem'}}>
                    <Caution/>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',  
                        alignItems: 'center',     
                        margin: '1rem 0',          
                        fontWeight: '500',
                        fontSize: '0.875rem',
                        color: '#333',
                        width: '100%',           
                    }}
                    >
                    <div style={{ textAlign: 'center', width:'content-fit' }}>
                        <p>Select the appropriate reason for closing the ticket.</p>
                    </div>
                </div>
                
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', marginBottom:'0.8rem' }}>
                    <Button style={{width:'45%', color:'#FF4D4F', borderColor: '#FF4D4F', backgroundColor:'rgba(255, 77, 79, 0.10)'
                        ,height:'2.2rem'}}
                        onClick={()=> setCloseTicketOrResolveFlag(2)}>
                        <CloseRed style={{marginRight:'0.4rem'}} />
                        <span>
                            Close Ticket
                        </span>
                    </Button>

                    <Button style={{width:'45%', color:'#FFF', borderColor: '#2C66E3', backgroundColor:'#2C66E3', height:'2.2rem'}}
                        onClick={()=> setCloseTicketOrResolveFlag(3)}>
                        <CheckWhite style={{marginRight:'0.4rem'}} />
                        <span>
                            Resolve 
                        </span>
                    </Button>
                    
                </div>
            </div>
        </Modal>
    );
};

export default CloseTicketCancelOrResolveModel;
