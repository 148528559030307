import { Button, InputNumber, Select, Spin, Typography } from "antd";
import { ReactComponent as TickIcon } from '../../Dashboard/SVGs/tick_icon.svg';
import { ReactComponent as EditIcon } from '../../Dashboard/SVGs/edit_icon_vehicle.svg';
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { getRequest, postRequest } from "../../../utils/handler/apiHandler";
import SingleFilterDropdown from "./SingleFilterDropdown";
import { getDaysCount, getOrdinalSuffix } from "../../../utils/helpers/utils";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface CreateTicketModalProps {
    loader: boolean;
    setShowCreateTicketModal : (show :boolean ) => void;
    showVehicleDetailPage : boolean;
    setShowVehicleDetailPage : (show :boolean ) => void;
}

const { Option } = Select;
const ticketTypeOptions = [{ id: '1', name: 'PMS' },{ id: '2', name: 'GMS' }];
const alertTypeOptions = [{ id: '1', name: 'Km Based' },{ id: '2', name: 'Time Based' }];

const CreateManualTicketModal : React.FC<CreateTicketModalProps> = ({loader, setShowCreateTicketModal, showVehicleDetailPage, setShowVehicleDetailPage}) => {
        const [data, setData] = useState<any>();
        const [vehicleData, setVehicleData] = useState<any>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [acceptLoader, setAcceptLoader] = useState<boolean>(false);
        const [vehicleId, setVehicleId] = useState<any>();
        const [selectedOption, setSelectedOption] = useState<any>(null);
        const [odometerEditClick, setOdometerEditClick] = useState<boolean>(false);
        const [ticketTypeEditClick, setTicketTypeEditClick] = useState<boolean>(false);
        const [odometerValueOld, setOdometerValueOld] = useState<any>();
        const [odometerValueNew, setOdometerValueNew] = useState<any>();
        const [ticketType, setTicketType] = useState<any>("PMS");
        const [alertCriteria, setAlertCriteria] = useState<any>('Km Based');
        

        const handleProceed = () => {
            try{
                const prospectId = getProspectId();
                const payload = {
                    vehicleId : vehicleId,
                    odometer : odometerValueOld === odometerValueNew ? null : odometerValueNew,
                    serviceType : ticketType,
                    prospectId : prospectId,
                    serviceNumber : data?.serviceNumber,
                    currentServiceCriteriaType : alertCriteria || null
                }
                setAcceptLoader(true);
                postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/create-ticket-from-main-page`, payload).then(res => {
                    setShowCreateTicketModal(false);
                    window.location.reload();
                })
            }catch(err){
                console.log("Error in creating ticket from main page : ", err);
            }finally{
                setAcceptLoader(false);
            }
        }

        const getProspectId = () => {
            if(vehicleData === null || vehicleData.length === 0){
                return null;
            }else{
                for(let i=0;i<vehicleData.length ;i++){
                    if(selectedOption.split("|")[0].trim() === vehicleData[i]?.prospect?.name){
                        return vehicleData[i]?.prospect?.prospectId;
                    }
                }
                return null;
            }
        }

        const fetchVehicleDetails = () => {
            try{
                setAcceptLoader(true);
                getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/create-ticket-response-main-ticket-page?vehicleId=${vehicleId}`).then(res => {
                    if(res.status === 200){
                        setData(res.data);
                        setShowVehicleDetailPage(!showVehicleDetailPage);
                        setOdometerValueOld(res?.data?.odometer);
                        setOdometerValueNew(res?.data?.odometer);
                    }else{
                        console.error('Error fetching vehicle detail:', res.msg);
                    }
                })
            }catch(err){
                console.log("Error in fetching vehicle details : ", err);
            }finally{
                setAcceptLoader(false);
            }
        }

        return(
            <Spin spinning={loading} size="default">
            {!showVehicleDetailPage && <div style={{display: 'flex', flexDirection: 'column', alignItems : 'start'}}>
                <h4>Vehicle Details</h4>
                <p>Search Vehicles to fetch Details</p>
                    <div style={{display : 'flex', justifyContent: 'space-between', width : '100%', marginTop : "15px", marginBottom : "20px"}}>
                            <SingleFilterDropdown
                                selectedOption = {selectedOption}
                                setSelectedOption = {setSelectedOption}
                                width = {350}
                                setKey={setVehicleId}
                                placeholder={"Search by vehicle no. or client legal name"}
                                dropdownOptions={vehicleData.map((option: any) => ({
                                    text: `${option.prospect.name} | ${option.vehicleDetails.registrationNumber}`,
                                    value: option.vehicleDetails.id,
                                }))}
                                fetchApiUrl={(name:any, page:any) => {return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/create-ticket-response?page=${page}&size=10${name && `&filterOr=${encodeURIComponent(`registrationNumber|like|${name}&prospect.name|like|${name}`)}`}`}}
                                setOptions={setVehicleData}
                                isKeyItems={true}
                            />
                    </div>
                
                <div style={{display : 'flex', justifyContent : 'flex-end', width : '100%'}}>
                    <Button
                        type="primary"
                        onClick={() => {fetchVehicleDetails()}}
                        disabled = {!vehicleData || vehicleData.length === 0}
                        icon={acceptLoader ? <Spin indicator={antIcon} /> : <TickIcon style={{marginLeft: 10}}/>}
                        style={{marginLeft: 10}}
                        className="button-override"
                        > Proceed
                    </Button>
                </div>
            </div>}
            { showVehicleDetailPage &&
                <div style={{display: 'flex', flexDirection: 'column', alignItems : 'start'}}>
                    <h4>{selectedOption}</h4>
                    <p style={{fontFamily : 'roboto', fontWeight : 500, color :'rgba(97, 97, 97, 1)'}} >Enter information to create ticket.</p>
                        <div style={{display : 'flex', justifyContent: 'space-between', width : '100%', marginTop : "15px", marginBottom : "20px", textAlign: "left"}}>
                            <div className="requirement-card-info" style={{}}>
                                <Typography className="description-label">Ticket Type</Typography>
                                {!ticketTypeEditClick &&
                                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                            <span style={{display: loader?"none":""}}>{
                                                loader ? "" :  <span>{ticketType!= null ? ticketType : "NA"} 
                                                {" "}<EditIcon onClick={() => {setTicketTypeEditClick(!ticketTypeEditClick);}}/></span> 
                                            }</span>
                                        </Typography> 
                                }
                                {ticketTypeEditClick &&
                                    <span>                          
                                        <Select value={ticketType} onChange={(value) => {setTicketType(value);}} style={{ width: 130 }} >
                                            {ticketTypeOptions.map((value: any) => (
                                                <Option key={value?.id} value={value?.name}>
                                                    {value?.name}
                                                </Option>
                                            ))}
                                        </Select> 
                                        {" "}<CloseCircleOutlined style={{marginLeft :3}} onClick={() => {setTicketTypeEditClick(!ticketTypeEditClick);}}/>
                                    </span> 
                                }
                            </div>
                            {ticketType === "PMS" &&  <div className="requirement-card-info">
                                <Typography className="description-label">Service Criteria</Typography>
                                    <span>                          
                                        <Select value={alertCriteria} onChange={(value) => {setAlertCriteria(value);}} style={{ width: 140 }} >
                                            {alertTypeOptions.map((value: any) => (
                                                <Option key={value?.id} value={value?.name}>
                                                    {value?.name}
                                                </Option>
                                            ))}
                                        </Select> 
                                    </span> 
                            </div>}

                            {ticketType === "PMS" && <div className="requirement-card-info">
                                <Typography className="description-label">Upcoming Service</Typography>
                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                        <span style={{display: loader?"none":""}}>{
                                            loader ? "" :  <span>
                                            {getOrdinalSuffix(data?.serviceNumber)} |{" "}
                                            {alertCriteria
                                              ? alertCriteria === "Time Based"
                                                ? `${getDaysCount(data?.currentServiceDate)} days`
                                                : `${data?.currentOdometerCriteria} km`
                                              : "NA"}
                                          </span>
                                        }</span>
                                    </Typography> 
                            </div>}

                            <div className="requirement-card-info" style={{}}>
                                <Typography className="description-label">Odometer Reading</Typography>
                                {!odometerEditClick &&
                                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                            <span style={{display: loader?"none":""}}>{
                                                loader ? "" :  <span>{data?.odometer != null ? data?.odometer : "NA"} 
                                                {" "}<EditIcon onClick={() => {setOdometerEditClick(!odometerEditClick);}}/></span> 
                                            }</span>
                                        </Typography> 
                                }
                                {odometerEditClick &&
                                    <span><InputNumber value={odometerValueNew} onChange={(val) => {setOdometerValueNew(val);}} placeholder="Odometer Reading" style={{marginLeft : 10, minWidth : 120}} /> 
                                    {" "}<CloseCircleOutlined onClick={() => {setOdometerEditClick(!odometerEditClick); setOdometerValueNew(data?.odometer)}}/></span> 
                                }
                            </div>
                            <div className="requirement-card-info">
                                <Typography className="description-label">Last Service Date</Typography>
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                                    <span style={{display: loader?"none":""}}>{
                                        loader ? "" : data?.lastServiceDate != null ? data?.lastServiceDate : "NA"
                                    }</span>
                                </Typography> 
                            </div>
                        </div>
                    
                    <div style={{display : 'flex', justifyContent : 'flex-end', width : '100%'}}>
                        <Button
                            type="primary"
                            onClick={() => {handleProceed();}}
                            icon={acceptLoader ? <Spin indicator= {antIcon}/> : <TickIcon style={{marginLeft: 10}}/>}
                            style={{marginLeft: 10}}
                            className="button-override"
                            > Proceed
                        </Button>
                    </div>
                </div>
            }
            </Spin>

        );
}

export default CreateManualTicketModal;