import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, DownloadOutlined, EditOutlined, EyeInvisibleFilled, EyeOutlined, FileOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Empty, Input, Modal, Row, Skeleton, Space, Tabs, Tooltip, Typography, Upload } from "antd";
import { getRequest, getRequestForDownload, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import "./upload.css"
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { modalButtonStyle } from "../../Client/OnboardNewClient";
import ViewDocumentModal from "../../Client/ViewDocumentModal";
import DocumentRemarks from "./DocumentRemarks";

export default function CreditDocuments({ creditRequestId, prospectId, creditRequestStatusId }: any) {

    const [docType, setDocTypes] = useState([])
    const [showDocsModal, setShowDocsModal] = useState<boolean>(false)
    const [documents, setDocuments] = useState({})
    const [showAddNewModal, setShowAddNewModal] = useState(false)
    const [newField, setNewField] = useState("")
    const [submittingNew, setSubmittingNew] = useState(false)
    const [addNewDoc, setAddNewDoc] = useState(false)
    const [fileList, setFileList] = useState<any>([])
    const [fileUrls, setFileUrls] = useState<any>([])
    const [selectedDocType, setSelectedDocType] = useState<any>(null)
    const [selectedId, setSelectedId] = useState<number | null>(null)
    const [editingItem, setEditingItem] = useState<any>(null)
    const [udpatingValitidy, setUpdateValidity] = useState(false)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null)
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [ongoingUploads, setOngoingUploads] = useState(0);
    const [deleting, setDeleting] = useState<null | number>(null)

    const auth = useAppSelector(authSelector);


    const getDocTypes = async () => {
        setLoading(true)
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/getCreditDocs?creditRequestId=${creditRequestId}`);
            console.log("response.data doc types", response.data)
            setDocTypes(response.data)
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false)
    }

    const handleShowDocs = async (id: number, addingNew?: boolean) => {
        try {
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/getCreditUploadedDocs?creditDocumentId=${id}`);
            console.log("response.data", response.data)
            setDocuments(response.data)
            if(addingNew) setAddNewDoc(true)
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setSelectedId(id)
        setShowDocsModal(true)
    }

    useEffect(() => {
        getDocTypes()
    }, [])

    const handleAddNewDoc = () => {
        console.log("add new", newField)
        setShowAddNewModal(false)
        addDocTypes()
    }

    useEffect(() => {
        setNewField("")
    }, [showAddNewModal])

    const addDocTypes = async () => {
        setSubmittingNew(true)
        let typesToAdd =
        {
            "id": null,
            "prospectId": prospectId,
            "documentName": newField,
            "creditRequestId": creditRequestId,
        }

        try {
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/addCreditDoc?creditRequestId=${creditRequestId}`, typesToAdd).then(res => {
                console.log(res)
                setSubmittingNew(false)
                setShowAddNewModal(false)
                getDocTypes()
            })
        } catch (e) {
            console.log(e)
            setSubmittingNew(false)
        }

    }


    const handleUploadRequest = async (file: any) => {
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadCreditDoc?prospectId=${prospectId}`;
        const formData = new FormData();
        formData.append('file', file.file);
        setUploading(true)
        setOngoingUploads((prev) => prev + 1);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                console.log("response?.data", response?.data)

                if (response.status === 200) {
                    setFileUrls((prevState: any) => [...prevState, response?.data?.DocumentUrl])
                    setFileList((prevState: any) => [...prevState, file.file]);
                }
                else {
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    setUploading(false);
                }
                return newOngoingUploads;
            });
        }
    };

    const handleDelete = (file: any) => {

        const tempFileList = [];
        const tempFileUrls: string[] = []
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileUrls.push(fileUrls?.[i])
                tempFileList.push(fileList[i]);
            }
        }
        setFileUrls(tempFileUrls)
        setFileList(tempFileList);
    }
    const handleDownloadAllClick = async () => {
        try {
            const res = await getRequestForDownload(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/downloadAll?creditDocumentId=${selectedId}`,
                'application/zip'
            );
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `downloadedDocumentId${selectedId}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading all documents:', error);
        }
    };

    const handleUploadDoc = async () => {
        const docParams = fileUrls.map((url: any) => {
            const fileName = url.substring(url.lastIndexOf('/') + 1).split('.')[0]; // Get file name without extension
            const fileType = url.split('.').pop(); // Get file extension

            return {
                fileName: fileName,
                url: url,
                type: fileType,
                creditDocId: selectedId,
                "active": true
            };
        })

        console.log(docParams);

        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/uploadCreditDocDetails?creditDocumentId=${selectedId}`, docParams).then((res: any) => {
            console.log(res)
            setAddNewDoc(false)
            setFileList([])
            setFileUrls([])
            // update docType item where id = selectedId and updates its filesCount to +1
            setDocTypes((prevState: any) => {
                return prevState.map((item: any) => {
                    if (item.id === selectedId) {
                        return { ...item, filesCount: item.filesCount + fileUrls.length }
                    }
                    return item
                })
            })

            handleShowDocs(selectedDocType.id)
        })
    }

    const handleUpdateName = async (item: any) => {
        setUpdateValidity(true)
        const inputElement = document.getElementById(`name-${item.id}`) as HTMLInputElement;

        if (inputElement) {
            const inputValue: string = inputElement.value;
            console.log(inputValue);
            const docParams = {
                "documentName": inputValue,
                "id": item.id,
            };
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/addCreditDoc?creditRequestId=${creditRequestId}`, docParams).then((res: any) => {
                console.log(res)
                getDocTypes()
                setEditingItem(null)
            }).catch(e => {
                console.log(e)
            })
        } else {
            console.error('Input element not found');
        }
        setUpdateValidity(false)
    }

    const handleDeleteDocType = async (id: number) => {
        setDeleting(id)
        try {
            await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/deleteCreditDoc?id=${id}`, {
            }).then((res: any) => {
                console.log(res)
                setDocTypes((prevState: any) => {
                    return prevState.filter((item: any) => item.id !== id)
                })
            })
        } catch (e) {
            console.log(e)
        } finally {
            setDeleting(null)
        }
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <Typography.Text strong style={{ fontSize: 16 }}>Credit Documents</Typography.Text>
                </div>
                <div>
                    <Button block style={modalButtonStyle} onClick={() => setShowAddNewModal(true)}>
                        <PlusOutlined /> Add New Document
                    </Button>
                </div>
            </div>

            <div style={{ boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", padding: 20, marginTop: 20, borderRadius: 8 }}>
                <Row style={{ padding: "0px 20px", borderRadius: 12 }}>
                    <Col span={7}>
                        <Typography.Text strong>Document</Typography.Text>
                    </Col>
                    {/* <Col span={5}>
                        <Typography.Text strong>Validity</Typography.Text>
                    </Col> */}
                    <Col span={5}>
                        <Typography.Text strong>Updated at</Typography.Text>
                    </Col>
                    <Col span={6}>
                        <Typography.Text strong>Updated By</Typography.Text>
                    </Col>
                    <Col span={6}>
                        <Typography.Text strong>Action</Typography.Text>
                    </Col>
                </Row>

                {loading ? <>
                    <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                    <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                    <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                    <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />
                    <Skeleton active={true} paragraph={false} loading={true} style={{ marginTop: 10 }} />

                </> : <>
                    {!docType || docType.length == 0 ? <Empty style={{ marginTop: 20 }} /> : docType?.map((item: any) => {
                        return <Row style={{ background: "#E6F2FD", padding: "12px 20px", borderRadius: 12, marginTop: 8, alignItems: "center" }}>
                            <Col span={7}>
                                <div style={{ display: "flex" }}>
                                        {editingItem?.id != item.id && item?.documentName}
                                        {editingItem?.id != item.id && <EditOutlined onClick={() => setEditingItem(item)} style={{ color: "#2C66E3", cursor: "pointer", marginLeft: 8 }} />}
                                        {editingItem?.id == item.id && <Input style={{width: 170}} id={`name-${item.id}`} size="small" defaultValue={item?.documentName} />}
                                        {editingItem?.id == item.id && <Space style={{ marginLeft: 8 }} size="small">
                                            <Button disabled={udpatingValitidy} size="small" onClick={() => setEditingItem(null)} type="text" icon={<CloseOutlined style={{ fontSize: 12, width: "fit-content", color: "#FF4D4F" }} />} />

                                            <Divider style={{padding: 0}} type="vertical" />

                                            <Button loading={udpatingValitidy} disabled={udpatingValitidy} size="small" onClick={() => handleUpdateName(item)} type="text" icon={<CheckOutlined style={{ fontSize: 12, width: "fit-content", color: "#02B51F" }} />} />


                                        </Space>}
                                    </div>
                                </Col>
                            {/* <Col span={5}>
                                <div style={{ display: "flex" }}>
                                    {editingItem?.id != item.id && item.validity}
                                    {editingItem?.id != item.id && <EditOutlined onClick={() => setEditingItem(item)} style={{ color: "#2C66E3", cursor: "pointer", marginLeft: 8 }} />}
                                    {editingItem?.id == item.id && <DatePicker id={`validity-${item.id}`} size="small" defaultValue={item.validity ? dayjs(item.validity, "YYYY-MM-DD") : null} />}
                                    {editingItem?.id == item.id && <Space style={{ marginLeft: 8 }} size="small">
                                        <Button disabled={udpatingValitidy} size="small" onClick={() => setEditingItem(null)} type="text" icon={<CloseOutlined style={{ fontSize: 12, color: "#FF4D4F" }} />} />

                                        <Divider type="vertical" />

                                        <Button loading={udpatingValitidy} disabled={udpatingValitidy} size="small" onClick={() => handleUpdateValidity(item)} type="text" icon={<CheckOutlined style={{ fontSize: 12, color: "#02B51F" }} />} />


                                    </Space>}
                                </div>
                            </Col> */}
                            <Col span={5}>
                                <Typography.Text>{dayjs(item.updatedAt, "YYYY-MM-DD").format("DD MMM YYYY")}</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <Typography.Text>{JSON.parse(item?.updatedBy)?.name}</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                    <div onClick={() => { setSelectedDocType(item); handleShowDocs(item?.id, true)}} style={{ display: "flex", gap: 4, alignItems: "center", cursor: "pointer" }}>
                                        <PlusOutlined style={{ color: "#2C66E3", fontSize: 13, fontWeight: "bold" }} />
                                        <Typography.Text style={{ color: "#1777FF" }}>{item?.filesCount > 0 ? "Add Files" : "Upload"}</Typography.Text>
                                    </div>
                                    {item?.filesCount > 0 && <>
                                        <Divider style={{ border: "solid 1px rgba(175, 175, 175, 1)", height: 15, marginTop: 2 }} type="vertical" />
                                    <div onClick={() => { setSelectedDocType(item); handleShowDocs(item?.id) }} style={{ display: "flex", gap: 4, alignItems: "center", cursor: "pointer" }}>
                                        <img width={10} style={{ marginTop: -2 }} src={`${__dirname}assets/document.png`} />
                                        <Typography.Text style={{ color: "#1777FF" }}>View Files</Typography.Text>
                                    </div>
                                    </>}
                                    <>
                                        <Divider style={{ border: "solid 1px rgba(175, 175, 175, 1)", height: 15, marginTop: 2 }} type="vertical" />
                                        <Button loading={deleting === item?.id} disabled={deleting === item?.id || creditRequestStatusId === 6} onClick={()=>handleDeleteDocType(item?.id)} icon={<DeleteFilled style={{color: creditRequestStatusId === 6 ? "grey" : "red"}} />} type="text" size="small" style={{ color: "red" }} />
                                    </>
                                </div>
                            </Col>
                        </Row>
                    })}
                </>}
            </div>


            <Modal
                className="credit-documents-upload-modal"
                destroyOnClose
                centered style={{ textAlign: "center" }}
                footer={null}
                onCancel={() => { setShowDocsModal(false); setAddNewDoc(false); setFileList([]); setFileUrls([]) }}
                closable={true}
                open={showDocsModal}
                styles={{
                    content: {
                        background: "#F6FAFF",
                    },
                    body: {
                        background: "#F6FAFF",
                        margin: 0,
                        padding: 0
                    }
                }}
                width={600}
            >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: 20 }}>
                    <div>
                        <Typography.Text strong>
                            Documents
                        </Typography.Text>
                    </div>
                    {!addNewDoc && <div style={{ display: "flex", gap: 8 }}>
                        {Object?.keys(documents).length !== 0 && <Button onClick={() => handleDownloadAllClick()} style={modalButtonStyle}>
                            <DownloadOutlined /> Download All
                        </Button>}

                        <Button onClick={() => setAddNewDoc(true)} style={modalButtonStyle}>
                            <PlusOutlined /> Add New Doc
                        </Button>
                    </div>}
                </div>
                <br />
                {addNewDoc &&
                    <div style={{width: "100%"}}>
                        <Upload
                            accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                            style={{ width: "100%", display: "block" }}
                            customRequest={(file) => handleUploadRequest({ file })}
                            beforeUpload={(file) => {
                                handleUploadRequest({ file })
                                return false
                            }}
                            fileList={fileList}
                            onRemove={handleDelete}
                            itemRender={(originNode, file, fileList2, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                <div style={{ width: 150, textAlign: "left" }}>
                                    <Typography.Text ellipsis>
                                        <Tooltip title={file.name}>
                                            {file.name}
                                        </Tooltip>
                                    </Typography.Text>
                                </div>
                                <div style={{ display: "flex", gap: 10 }}>
                                    {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <div onClick={() => {
                                        const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                        setPreviewFile({
                                            "fileName": file.name,
                                            "type": file.type as string,
                                            "url": fileUrls[itemIndex],
                                        })
                                    }} style={{ cursor: "pointer" }}>
                                        <EyeOutlined />
                                    </div> :
                                        <EyeInvisibleFilled style={{ color: "grey" }} />
                                    }

                                    <Button disabled={uploading} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                        <DeleteFilled style={{ color: "red" }} />
                                    </Button>
                                </div>


                            </div>}
                            listType="text"
                            multiple={true}
                        >

                                <Button
                                    loading={uploading}
                                    disabled={uploading}
                                    size="large"
                                    block
                                    type="dashed"
                                    style={{ width: "100%" }}
                                    icon={<UploadOutlined />}
                                >
                                    Select Files
                                </Button>

                        </Upload>

                        <div style={{ textAlign: "right" }}>
                            <Button disabled={uploading} onClick={() => { setFileList([]); setFileUrls([]); setAddNewDoc(false) }} style={{ ...modalButtonStyle, color: "red", borderColor: "red" }}>
                                <CloseOutlined /> Cancel
                            </Button>
                            <Button
                                disabled={fileList.length == 0 || uploading}
                                onClick={handleUploadDoc} style={{ marginTop: 20, marginLeft: 8 }} type="primary">Upload</Button>

                        </div>
                    </div>}

                {!addNewDoc && <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: 'Documents',
                    children: <Files creditRequestStatusId={creditRequestStatusId} documents={documents} setPreviewFile={setPreviewFile} handleShowDocs={handleShowDocs} setDocTypes={setDocTypes} />,
                    // children: <></>
                },
                {
                    key: '2',
                    label: 'Remarks',
                    children: <DocumentRemarks creditRequestId={creditRequestId} prospectId={prospectId} setSelectedDocType={setSelectedDocType} getDocTypes={getDocTypes} docType={docType} selectedDocType={selectedDocType} />,
                },]} />}


            </Modal>

            <Modal title="Enter Document Name" footer={
                <div style={{ display: "flex", gap: 12, marginTop: 24 }}>
                    <Button type="primary" icon={<CheckOutlined />} loading={submittingNew} disabled={submittingNew || !newField} size="large" style={{ width: "100%" }} onClick={handleAddNewDoc}>
                        Save
                    </Button>
                </div>
            }
                centered style={{ textAlign: "center" }}
                styles={{
                    footer: {
                        paddingInline: "10px",
                        marginBottom: 16
                    },
                    header: {
                        textAlign: "left"
                    }
                }}
                onCancel={() => { setShowAddNewModal(false); setNewField("") }}
                closable={true}
                open={showAddNewModal}
                destroyOnClose
            >

                <Typography style={{ fontSize: 14, marginTop: 40 }}>
                    <Input placeholder="Enter agreement name" size="large" onChange={(e) => setNewField(e.target.value)} />
                </Typography>

            </Modal>

            <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />

        </>
    )
}

const handleDownlaod = async (url: string) => {
    console.log(url)
    const fileName = url.substring(url.lastIndexOf('/') + 1).replace(/%20/g, ' ')

    try {
        const response = await fetch(url);
        const blob = await response.blob();
        console.log(blob)
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);  // Specify the file name dynamically

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Create a click event
        const event = new MouseEvent('click');
        link.dispatchEvent(event);

        // Clean up: remove the anchor element and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};



const Files = ({ documents, setPreviewFile, handleShowDocs, setDocTypes, creditRequestStatusId }: any) => {

    const [deleting, setDeleting] = useState<null | number>(null)


    const handleDeleteFile = async (id: number, docId: number) => {
        setDeleting(id)
        try {
            await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/deleteCreditUploadedDoc?id=${id}`, {
            }).then((res: any) => {
                handleShowDocs(docId)
                console.log(res)
                setDocTypes((prevState: any) => {
                    return prevState.map((item: any) => {
                        if (item.id === docId) {
                            return { ...item, filesCount: item.filesCount - 1 }
                        }
                        return item
                    })
                })
            })
        } catch (e) {
            console.log(e)
        } finally {
            setDeleting(null)
        }
    } 

    return (
        <>

            <div style={{ maxHeight: 300, overflow: "scroll" }}>
                {Object.entries(documents)?.length === 0 ? <Empty /> : Object.entries(documents).map(([date, docs]: any) => (
                    <div style={{ textAlign: "left" }} key={date}>
                        <Typography.Text style={{ marginTop: 8, display: "block" }} strong>{dayjs(date, "YYYY-MM-DD").format("DD MMM YYYY")}</Typography.Text>
                        <div>
                            {docs?.map((doc: any) => (
                                <div style={{ background: "white", padding: 12, display: "flex", justifyContent: "space-between", borderRadius: 12, marginTop: 12, boxShadow: "0px 4px 24px 0px #0000000D" }} key={doc.id}>
                                    <div style={{ width: 150 }}>
                                        <Typography.Text ellipsis>
                                            <Tooltip title={`${doc.fileName}.${doc.type}`}>
                                                {doc.fileName}.{doc.type}
                                            </Tooltip>
                                        </Typography.Text>
                                    </div>
                                    <Space size="small">
                                        <Button size="small" type="text" style={{ color: "#2C66E3" }} onClick={() => handleDownlaod(doc.url)}><SaveOutlined /> Download</Button>
                                        <div>
                                            {["jpeg", "jpg", "png", "pdf"].includes(doc.type) ? <div onClick={() => setPreviewFile({
                                                "fileName": doc.fileName,
                                                "type": doc.type,
                                                "url": doc.url,
                                            })} style={{ cursor: "pointer" }}><EyeOutlined style={{ color: "#2C66E3" }} /> <Typography.Text style={{ color: "#2C66E3" }}>Preview</Typography.Text></div> :
                                                <div>
                                                    <EyeInvisibleFilled style={{ color: "grey" }} />
                                                </div>
                                            }
                                        </div>
                                        <Button loading={deleting === doc?.id} disabled={deleting === doc?.id || creditRequestStatusId === 6} onClick={()=>handleDeleteFile(doc.id, doc.creditDocId)} icon={<DeleteFilled style={{color: creditRequestStatusId === 6 ? "grey" : "red"}} />} type="text" size="small" style={{ color: "red" }} />
                                    </Space>
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}