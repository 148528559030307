import React, { useEffect, useState } from "react";
import { Button, Card, Col, Descriptions, Divider, Row, Tag, Typography } from "antd";
import { getRequest, putRequest } from "../../../../utils/handler/apiHandler";
import currencyFormatter from "../../../../utils/NumberFormater";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as DisableIcon } from '../../SVGs/disabled_icon.svg';
import { useAppSelector } from "../../../../app/hooks";
import { userSelector } from "../../../../features/auth/userSlice";
import CancelRequestModal from "../../Quote/components/CancelRequestModal";
import ETAModal from "../../Quote/components/ETAModal";
import dayjs from "dayjs";
import RemarkModal from "./RemarkModal";
import _ from "lodash";

interface CreditAssessmentDetailsLayoutProps {
    allowedActionsForProspect: Set<string>;
}
const CreditAssessmentDetailsLayout: React.FC<CreditAssessmentDetailsLayoutProps> = ({ allowedActionsForProspect }) => {
    const title = "Credit Assessment ETA";
    const subTitle = "Enter the time, it will take you to complete the credit assessment";
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const creditRequestId = query.get('creditRequestId');


    const user = useAppSelector(userSelector);
    const prospectId = useLocation().pathname.split("/").pop();
    const [apiToggle, setApiToggle] = useState<any>(true);
    const [data, setData] = useState<any>(null);
    const [showCancelModal, setShowCancelModal] = useState<any>(false);
    const creditRoleAccess = user.role === "GEVL-CREDIT-HEAD" || user.role === "GEVL-CREDIT-MANAGER" || user.role === "GEVL-ADMIN" || user.role === "GEVL-RESTRICTED-ADMIN";
    // const salesRoleAccess = user.role === "GEVL-SALES-HEAD" || user.role === "GEVL-SALES-MANAGER" || user.role === "GEVL-ADMIN";
    const [showETAModal, setShowETAModal] = useState<boolean>(false);
    const [cancelLoader, setCancelLoader] = useState<boolean>(false);
    const [showBypassModal, setShowBypassModal] = useState<boolean>(false);
    const [cancelRemark, setCancelRemark] = useState<any>("");
    const [isBackToOpsReview, setIsBackToOpsReview] = useState<boolean>(false);
    const [backToOpsReviewLoader, setBackToOpsReviewLoader] = useState<boolean>(false);
    const [eta, setEta] = useState("");
    const [committeeLoader, setCommitteeLoader] = useState<boolean>(false);
    const [showCommitteeModal, setShowCommitteeModal] = useState<boolean>(false);
    const [etaLoader, setEtaLoader] = useState<boolean>(false);
    const [bypassLoader, setBypassLoader] = useState<boolean>(false);
    const [remark, setRemark] = useState<string>("");
    const [showRejectBypassModal, setShowRejectBypassModal] = useState<boolean>(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        try {
            if(creditRequestId) {
                getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit?creditRequestId=${creditRequestId}`).then(res => {
                    setData(res.data);
                });
            } else {
                getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                    setData(res.data);
                });
            }
        } catch (err) {

        }
    }, [apiToggle]);

    const convertInstantToISO = (date: any) => {
        const isoDate = new Date(date).toISOString()
        return isoDate;
    }

    const handleCancelCreditRequest = () => {
        const payload = {
            statusChangeTypeId : 8,
            statusChangeReason : cancelRemark
        }
        setCancelLoader(true);
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
            setCancelLoader(false);
            setShowCancelModal(false);
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
            window.location.reload();
        })
    }

    const handleStartAssessment = () => {
        try {
            const payload = {
                statusChangeTypeId: 2,
                creditAssessmentEta: eta
            }

            setEtaLoader(true);
            navigate("/dashboard/prospect/190?tab=3&subTab=2")
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
                setShowETAModal(false);
                setEtaLoader(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            })
        } catch (err) {
            console.log(err);
        }
    }


    const handleBypassAssessmentReject = () => {
        const payload = {
            statusChangeTypeId: 10,
            byPassRemark: remark
        }
        setBypassLoader(true)
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
            setShowBypassModal(false);
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
            window.location.reload();
        })
    }


    const handleBypassAssessment = () => {
        const payload = {
            statusChangeTypeId: 7,
            byPassRemark: remark
        }
        setBypassLoader(true)
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
            setShowBypassModal(false);
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
            window.location.reload();
        })
    }

    const handleCommitteeEta = () => {
        try {
            const payload = {
                statusChangeTypeId : 4,
                moveToCommitteeEta: eta
            }
            setCommitteeLoader(true);
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
                setCommitteeLoader(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            });
            console.log(payload);
        } catch(err) {
            console.log(err);
        }
    }

    return ( 
        <React.Fragment>
            {
                data && <>
                <span style={{display: "flex", alignItems: "center"}}>
                <Typography style={{fontSize: 14, fontWeight: 500}}>Credit Request</Typography>
                <Tag style={{marginLeft: 10, fontSize: 12, fontWeight: 500, color: data?.creditRequestStatusMaster?.color, backgroundColor: data?.creditRequestStatusMaster?.backgroundColor, border: "none"}}>{data.creditRequestStatusMaster?.name}</Tag>
                </span>
                { data?.creditRequestStatusMaster?.id === 8 && <Card bordered={false} style={{width: "100%", marginTop: 10}}>
                    Remark: {data?.statusChangeReason}
                </Card>}
                <Row style={{width: "100%", marginTop: 10}}>
                    <Card className="credit-description">
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Brand Name</span>} data={data?.legalBusinessName?.name} bgColor="#CEE6FA" border={true} />
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Type</span>} data={data?.type} bgColor="#CEE6FA" border={true} />
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Current Year Potential</span>} data={data?.currentYearFleetRequirement} bgColor="#CEE6FA" border={false} />
                    </Card>
                    <Card className="credit-description">
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Business Segment</span>} data={data?.segment?.name} bgColor="#CEE6FA" border={true} />
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Commercial Meeting</span>} data={data?.isCommercialMeetingHeld ? "Held" : "Not Held"} bgColor="#CEE6FA" border={false} />
                    </Card>
                    <Card className="credit-description">
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Application Date</span>} data={dayjs(convertInstantToISO(data.createdAt)).format("YYYY-MM-DD")} bgColor="#CEE6FA" border={true} />
                        <DescriptionItem label={<span style={{fontWeight: 500}}>Total Investment Value</span>} data={currencyFormatter(data.totalInvestmentValue)} bgColor="#CEE6FA" border={false} />
                    </Card>
                </Row>
                <Row style={{width: "100%"}}>
                    {
                        data.entityDescription && <Card className="credit-description" style={{width: "49%", padding: 20}}>
                        <Typography style={{fontWeight: 500}}>Entity Description</Typography>
                        <Typography>{data?.entityDescription}</Typography>
                    </Card>
                    }
                    {
                        data.remark && <Card className="credit-description" style={{width: "48%", padding: 20}}>
                        <Typography style={{fontWeight: 500}}>Additional Remarks</Typography>
                        <Typography>{data?.remark}</Typography>
                    </Card>
                    }
                    
                </Row>
                <Typography style={{fontSize: 14, fontWeight: 500, marginBottom: 10, marginTop: 10}}>Requirement Details</Typography>
                <Row style={{width: "100%", flexWrap: "nowrap", overflow: "scroll"}}>
                    {/* Iterate over all requirements */}
                    {
                        data?.creditRequestRequirements && data?.creditRequestRequirements.map((requirement: any, key: number) => {
                            return <RequirementCard key={key} data={requirement}/>
                        })
                    }
                </Row>

                {data.creditRequestStatusMaster?.id == 7 && <Row>
                    <Card bordered={false} style={{width: "100%", marginBottom: 10}}><strong>Bypass Remark:</strong> {data?.bypassRemark}</Card>
                </Row>}

                <Row style={{display: "flex", justifyContent: "flex-end"}}>
                    {
                    (!(data.creditRequestStatusMaster?.id === 8 || data.creditRequestStatusMaster?.id === 7) && allowedActionsForProspect.has('cancelReview')) && <Button
                    ghost
                    danger
                    icon={<CrossIcon />}
                    className="button-override danger"
                    style={{marginLeft: 10}}
                    onClick={_=> setShowCancelModal(true)}
                    >Cancel Credit Request</Button>
                    }
                    {
                    (creditRoleAccess && (data?.creditRequestStatusMaster?.id !== 8 && data.creditRequestStatusMaster?.id !== 7)) && <Button
                    type="primary"
                    style={{marginLeft: 10}}
                    className="button-override"
                    onClick={_=> setShowETAModal(true)}
                    >Start Credit Assessment
                    <RightArrowIcon style={{marginLeft: 8}}/>
                    </Button>
                    }
                    {
                    (creditRoleAccess && data.creditRequestStatusMaster?.id == 7) && <>
                    <Button
                    type="primary"
                    style={{marginLeft: 10}}
                    className="button-override secondary"
                    icon={<CrossIcon />}
                    onClick={() => setShowRejectBypassModal(true)}
                    >Reject Assessment Bypass
                    </Button>
                    <Button
                    type="primary"
                    onClick={_=> setShowCommitteeModal(true)}
                    style={{marginLeft: 10}}
                    className="button-override"
                    >
                        Move to Committee Review
                        <RightArrowIcon style={{marginLeft: 8}} />
                    </Button>
                    </>
                    }
                </Row>
                <CancelRequestModal title={"To cancel credit request, Enter the reason for the same."} subTitle="Enter reason for cancelling request" type="Cancel" showModal={showCancelModal} setShowModal={setShowCancelModal} remark={cancelRemark} setRemark={setCancelRemark} cancel={handleCancelCreditRequest} cancelLoader={cancelLoader} backToOpsReviewLoader={backToOpsReviewLoader} isBackToOpsReview ={isBackToOpsReview} setIsBackToOpsReview = {setIsBackToOpsReview} quoteStatusId = {-1}/>
                </>
            }
            <ETAModal showModal={showCommitteeModal} setShowModal={setShowCommitteeModal} title="Committee Review ETA" subTitle="Enter the time, it will take you to complete the committee review" eta={eta} setEta={setEta} handler={handleCommitteeEta} etaLoader={committeeLoader} buttonText="Start Committee Review" />
            <ETAModal title={title} subTitle={subTitle} showModal={showETAModal} setShowModal={setShowETAModal} eta={eta} setEta={setEta} handler={handleStartAssessment} etaLoader={etaLoader} buttonText="Proceed to Credit Assessment" />
            <RemarkModal title="Bypass Assessment" subTitle="Enter reason for bypass" btnText="Bypass Assessment" loader={bypassLoader} showModal={showBypassModal} setShowModal={setShowBypassModal} remark={remark} setRemark={setRemark} handler={handleBypassAssessment} type="bypass" />
            <RemarkModal title="Reject Bypass Assessment" subTitle="Enter reason for rejecting bypass" btnText="Reject Bypass" loader={bypassLoader} showModal={showRejectBypassModal} setShowModal={setShowRejectBypassModal} remark={remark} setRemark={setRemark} handler={handleBypassAssessmentReject} type="bypass" />
            </React.Fragment>
     );
}

export default CreditAssessmentDetailsLayout;

interface DescriptionItemProps {
    label: any;
    data: any;
    bgColor: string
    border: boolean;
}
const DescriptionItem: React.FC<DescriptionItemProps> = ({ label, data, bgColor, border }) => {
    return <>
    <div className="" style={{display: "flex", width: "100%"}}>
        <div style={{backgroundColor: bgColor, width: "50%", padding: 10}}>{label}</div>
        <div style={{padding: 10, width: "50%", borderBottom: border ? "1px #F0F0F0 solid" : "none"}}>{data}</div>
    </div>
    </>
}

interface RequirementCardProps {
    data: any
}
const RequirementCard: React.FC<RequirementCardProps> = ({ data }) => {
    return <>
        <Card className="credit-description" style={{minWidth: 400, padding: 20}}>
            <Typography style={{fontSize: 14}}><span style={{fontWeight: 500}}>Vehicle Category:  </span>{data && JSON.parse(data.vehicleCategory).name}</Typography>
            <Typography style={{fontSize: 12}}><span style={{fontWeight: 500}}>Total value:  </span>{currencyFormatter(data.totalValue)}</Typography>
            <Divider style={{margin: "10px 0"}} />
            <div style={{display: "flex", flexWrap: "wrap", width: "100%"}}>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Tenure</Typography>
                    <Typography style={{fontSize: 14}}>{JSON.parse(data?.leaseTerm)?.inMonth} Month</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12,  fontWeight: 500}}>No. Of Vehicle</Typography>
                    <Typography style={{fontSize: 14}}>{data.noOfVehicles}</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Interest Rate Offered</Typography>
                    <Typography style={{fontSize: 14}}>{_.isNumber(data?.interestRateOffered) ? `${data?.interestRateOffered}%` : "NA"}</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Investment Value per Vehicle</Typography>
                    <Typography style={{fontSize: 14}}>{currencyFormatter(~~data.investmentValuePerVehicle)}</Typography>
                </div>
            </div>
        </Card>
    </>
}