import { EyeOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, message, Modal, Space, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import WorkshopDetailsForm from "./Workshop/WorkshopDetailsForm";
import InvoiceDetailsForm from "./Invoice/InvoiceDetailsForm";
import AddIssues from "./IssueAndSubIssues/AddIssues";
import { ReactComponent as CrossIcon } from './../../../../../utils/icons/Cross_Icon.svg';
import IssueJobForm, { IssueDataType, saveDetailsParams } from "./IssueAndSubIssues/IssueJobForm";
import { getRequest, postRequest } from "../../../../../utils/handler/apiHandler";
import IssueAndSubissueHistoryModal from "./IssueAndSubIssues/IssueAndSubissueHistoryModal";
import moment from "moment";
import dayjs from "dayjs";
import { ReactComponent as PipeIcon } from "./../../../../../utils/icons/Pipe_Icon.svg";

interface JobCardProps {
    workshopDetailsData: any,
    setWorkshopDetailsData: (data: any) => any,
    ticketDetailsData: any,
    issueSubissueTableData: any,
    setFetchTicketDataCnt: (cnt: number) => void,
    fetchTicketDataCnt: number,
    ticketId: string,
    jobCardId: number,
    prospectId: number,
    ticketDetails: any,
    ticketStatusId: number,
    serviceStatusUpdateDate: string
    edittingForm: boolean,
    setEdittingForm: (value: boolean) => void,
    editingInvoiceForm: boolean,
    setEditingInvoiceForm: (value: boolean) => void
}

export interface OptionType {
    "id": number,
    "label": string,
    "color": string,
    "bgColor": string
}

export default function JobCard({
    workshopDetailsData, setWorkshopDetailsData, setFetchTicketDataCnt, ticketId, jobCardId, prospectId,
    ticketDetails, fetchTicketDataCnt, ticketStatusId, serviceStatusUpdateDate, edittingForm, setEdittingForm, editingInvoiceForm, setEditingInvoiceForm
}: JobCardProps) {

    const [showWorkshopModal, setShowWorkshopModal] = useState(false)
    const [editable, setEditable] = useState(false);
    const [showCancelWorkshop, setShowCancelWorkshop] = useState<boolean>(false);
    const [showAddIssue, setShowAddIssue] = useState(false);
    const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
    const [disableShowVersionHistory, setDisableShowVersionHistory] = useState<boolean>(true);
    const [statuses, setStatuses] = useState<[] | OptionType[]>([])
    const [workTypes, setWorkTypes] = useState<[] | OptionType[]>([])
    const [resetting, setResetting] = useState(false)
    const [fullDetails, setFullDetails] = useState<IssueDataType[]>([]);
    const [originalDetails, setOriginalDetails] = useState<IssueDataType[]>([]);
    const [selectedIssues, setSelectedIssues] = useState<any[]>([]);
    const [issueComponents, setIssueComponents] = useState<any[]>([]);

    const [saving, setSaving] = useState(false)


    // useEffect(() => {
    //     const issueSubissueTableData = fullDetails.filter((item) => !item.sumRow);
    //     if ((!issueSubissueTableData || issueSubissueTableData.length === 0) &&
    //         (!issueComponents || issueComponents.length === 0)) {
    //         addNewIssueComponent();
    //     }
    // }, [showAddIssue]);

    const addNewIssueComponent = () => {
        setIssueComponents([
            ...issueComponents,
            { id: Date.now() }, // Unique ID for each issue component
        ]);
        setShowAddIssue(true); // Show AddIssue component when new issue is added
    };

    const getStatuses = async () => {
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=SubIssueStatus`).then((res) => {
            console.log("res", res)
            setStatuses(res.data)
            getWorkTypes()
        }).catch((err) => {
            console.log(err)
        })
    }

    const getWorkTypes = async () => {
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=WorkType`).then((res) => {
            console.log("resxx", res)
            setWorkTypes(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getStatuses()
    }, [])

    const saveAllIssuesAndSubissues = async () => {
        // Check if all issues and subissues are selected
        setIssueComponents([]);
        setShowAddIssue(false);
        console.log("selectedIssues", selectedIssues);

        // Validate that issueId and subIssueId are present for each issue
        const invalidSelection = selectedIssues.some((item) =>
            !item.issueId || !Array.isArray(item.subIssueId) || item.subIssueId.length === 0
        );
        if (invalidSelection || selectedIssues.length < issueComponents.length) {
            message.error("Please select both Issue and Subissue for all entries.");
            return;
        }

        try {
            // Create payload by iterating over each issue and its subIssues
            const payload = selectedIssues.flatMap((item) =>
                item.subIssueId.map((subIssue: any) => ({
                    subIssueId: subIssue,
                    status: 'Reported',
                }))
            );

            // Send the request
            const response = await postRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-job-card-issue?ticketId=${ticketId}`,
                payload
            );

            // Check response status
            if (response.status !== 201) {
                message.error(response.msg || "Failed to add Issue and Subissue");
            }
        } catch (error) {
            message.error("An error occurred while adding Issue and Subissue");
        } finally {
            setShowVersionHistory(false);
            getIssueItems()
        }
    };

    // useEffect(()=>{
    //     deleteME()
    // }, [])

    // const deleteME = async () =>{
    //     const response = await postRequest(
    //         `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/transfer-demanded-job-card-issue?ticketId=${ticketId}`
    //     );
    // }

    const onCloseVersionHistory = () => {
        setShowVersionHistory(false);
    }

    const handleSaveDetails = async (values: saveDetailsParams[]) => {
        console.log(values, "ola")
        const payload = values.filter((item) => item.id !== null)
        setSaving(true)
        try {
            const response = await postRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-job-card-issue?ticketId=${ticketId}`,
                payload
            );
            // call this after 2 seconds
            setTimeout(() => {
                getIssueItems()
            }, 100)
        } catch (e) {
            console.log(e)
        }
        setSaving(false)
    }

    useEffect(() => {
        if (jobCardId) {
            getIssueItems()
        }
    }, [jobCardId])

    const getIssueItems = async () => {
        setResetting(true)
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-sub-issue-job-card?filterAnd=${encodeURIComponent(`jobCard.id|eq|${jobCardId}&active|eqb|true`)}`).then((res) => {
            console.log("resxxvv", res.data.actualJob.items)
            if (res.data.actualJob.items.length > 0) {
                const issueItems = res.data.actualJob.items.map((item: any) => {
                    const unitPrice = item?.unitPrice ? Number(item?.unitPrice) : 0;
                    const quantity = item?.quantity ? Number(item?.quantity) : 0;
                    const basePrice = unitPrice * quantity;

                    const discountedAmount = item.discountedAmount ? Number(item.discountedAmount) : 0;
                    const taxableAmount = basePrice - discountedAmount;

                    const gst = item?.gst ? Number(item?.gst) : 0;
                    const gstAmount = Math.round((gst/100) * taxableAmount * 100) / 100;

                    const amountPayable = taxableAmount + gstAmount;

                    return {
                        "id": item?.id,
                        "subIssueId": item?.subIssueMaster?.id,
                        "serviceType": item?.serviceType,
                        "status": item?.status,
                        "isDemandedJob": !!item?.isDemandedJob,
                        "workType": item?.workType,
                        "quantity": item?.quantity,
                        "unitPrice": item?.unitPrice,
                        // "estimatedAmount": item?.estimatedAmount,
                        // taxabble amount = baseprice - discountAmount conver to number and 0 if undefined or null
                        "taxableAmount": taxableAmount,
                        "discountedAmount": item?.discountedAmount,
                        "basePrice": basePrice,
                        "gst": item?.gst,
                        "gstAmount": gstAmount,
                        "amountPayable": amountPayable,
                        "isRecoverable": item?.isRecoverable,
                        "workDone": item?.workDone,
                        "issue": item?.subIssueMaster?.issue?.name,
                        "issueId": item?.subIssueMaster?.issue?.id,
                        "subIssue": item?.subIssueMaster?.name,
                        "reportedAt": moment(item?.reportedAt).format('h:mm:ss A, D MMM YYYY'),
                        "lastUpdatedAt": moment(item?.auditInfo?.updatedAt).format('h:mm A, D MMM YYYY')
                    }
                })
                setOriginalDetails(issueItems)
                setFullDetails(issueItems.concat([
                    {
                        id: null,
                        subIssueId: null,
                        serviceType: null,
                        status: null,
                        isDemandedJob: null,
                        workType: null,
                        quantity: null,
                        unitPrice: null,
                        // estimatedAmount: "₹ " + res?.data?.estimatedAmount,
                        discountedAmount: "₹ " + res?.data?.discountedAmount,
                        basePrice: "₹ " + res?.data?.basePrice,
                        gst: null,
                        gstAmount: "₹ " + res?.data?.gstAmount,
                        amountPayable: <div style={{display: "flex", alignItems: "center"}}>
                            {"₹ " + (parseInt(res?.data?.nonRecoverableAmount) + parseInt(res?.data?.recoverableAmount))+" "}

                            <Tooltip title={
                                <>
                                    <Typography.Text style={{fontSize: 12}}><span style={{fontSize: 10, color: "grey"}}>Rrecoverable:</span> ₹{res?.data?.recoverableAmount}</Typography.Text>
                                    <br/>
                                    <Typography.Text style={{fontSize: 12}}><span style={{fontSize: 10, color: "grey"}}>Non Rrecoverable:</span> ₹{res?.data?.nonRecoverableAmount}</Typography.Text>
                                </>
                            } color={"white"}>
                                <InfoCircleOutlined style={{color: "grey", fontSize: 11, marginLeft: 2}} />
                            </Tooltip>
                        </div>,
                        isRecoverable: null,
                        workDone: null,
                        issue: "Total",
                        subIssue: null,
                        reportedAt: null,
                        lastUpdatedAt: null,
                        sumRow: true
                    },
                ])
                )
            }

        }).catch((err) => {
            console.log(err)
        })
        setResetting(false)
    }

    return (
        <>
            {/* <Space>
            </Space> */}
            <div style={{ display: "flex", marginTop: 20, justifyContent: 'space-between' }}>
                <div>
                    <Typography.Text style={{ fontSize: 16, fontWeight: "bold", color: "#464646" }}>Issues and Sub - Issues</Typography.Text>
                </div>
                <div>
                    <Space size="small">
                        {!(issueComponents && issueComponents.length) && <Button onClick={() => setShowVersionHistory(true)} style={{ color: "#2C66E3", fontWeight: "bold", fontSize: 12 }} icon={<EyeOutlined />} type="text">Show version history</Button>}
                        {ticketStatusId !== 9 && ticketStatusId !== 12 && <>
                            {!(issueComponents && issueComponents.length) && <span style={{ fontSize: 16, color: "grey" }}>|</span>}
                            <div style={{display: "flex"}}>
                                {

                                    <div style={{ marginLeft: '1rem', display: 'flex' }}>
                                        <span
                                            style={{
                                                marginRight: '0.6rem',
                                                marginLeft: '0.6rem',
                                                color: '#2C66E3',
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                cursor: "pointer"
                                            }}
                                            onClick={(e: any) => {
                                                addNewIssueComponent(); // Call the function only if clickable
                                            }}
                                        >
                                            + Add More
                                        </span>

                                    </div>
                                }

                                {
                                    (issueComponents && issueComponents.length) ?
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <PipeIcon style={{ marginLeft: '0.5rem', marginRight: '0.8rem', marginTop: '0.05rem' }} />
                                            </div>
                                            <div
                                                style={{
                                                    marginLeft: "0.5rem",
                                                    marginRight: "'0.8rem'",
                                                    color: "#FF4D4F",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setIssueComponents([]);
                                                    setShowAddIssue(false);
                                                }}
                                            >
                                                <CrossIcon style={{ marginRight: '0.3rem' }} />
                                                Cancel
                                            </div>
                                        </div>
                                        : ''

                                }
                            </div>
                        </>}
                    </Space>


                </div>
            </div>

            {
                issueComponents.length !== 0 && ticketStatusId !== 9 && ticketStatusId !== 12 &&
                <Card style={{marginTop: 20}}>
                    {
                        issueComponents.map((component, index) => (
                            <AddIssues
                                key={component.id}
                                selectedIssues={selectedIssues}
                                setSelectedIssues={setSelectedIssues}
                                index={index} // Pass index to identify each dynamically added issue-subissue pair
                                ticketId={ticketId}
                            />
                        ))
                    }
                    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{
                                color: 'rgba(255, 255, 255, 1)',
                                backgroundColor: '#1777FF',
                                borderColor: '#1777FF',
                            }}
                            onClick={saveAllIssuesAndSubissues}
                        >
                            Save Issues & Sub - Issues
                        </Button>
                    </div>

                </Card>
            }


            <IssueJobForm
                originalDetails={originalDetails}
                serviceStatusUpdateDate={serviceStatusUpdateDate}
                edittingForm={edittingForm}
                setEdittingForm={setEdittingForm}
                ticketStatusId={ticketStatusId}
                getJobCardIssueItems={getIssueItems}
                ticketId={ticketId}
                resetting={resetting}
                fullDetails={fullDetails}
                setFullDetails={setFullDetails}
                statuses={statuses}
                workTypes={workTypes}
                setShowWorkshopModal={setShowWorkshopModal}
                saveDetails={handleSaveDetails}
                jobCardId={jobCardId}
                saving={saving}
                editingInvoiceForm={editingInvoiceForm}
            />
            {(ticketStatusId === 9 || ticketStatusId === 12)
                &&
                <InvoiceDetailsForm
                    getUpdatedData={()=>setFetchTicketDataCnt(Math.random())}
                    ticketStatusId={ticketStatusId}
                    ticketDetails={ticketDetails}
                    prospectId={prospectId}
                    jobCardId={jobCardId}
                    setEditingInvoiceForm={setEditingInvoiceForm}
                    editingInvoiceForm={editingInvoiceForm}
                    edittingForm={edittingForm}
                />}

            <Modal destroyOnClose width={1000} footer={null} open={showWorkshopModal} onCancel={() => setShowWorkshopModal(false)} title="Workshop Details">
                <WorkshopDetailsForm closeModal={() => setShowWorkshopModal(false)} workshopDetailsData={workshopDetailsData} setWorkshopDetailsData={setWorkshopDetailsData}
                    setFetchTicketDataCnt={setFetchTicketDataCnt} ticketId={ticketId} editable={true}
                    setEditable={setEditable} showCancelWorkshop={false} setShowCancelWorkshop={setShowCancelWorkshop} />
            </Modal>

            <IssueAndSubissueHistoryModal ticketStatusId={ticketStatusId} showVersionHistory={showVersionHistory}
                onCloseVersionHistory={onCloseVersionHistory} jobCardId={jobCardId}
                ticketId={ticketId} setFetchTicketDataCnt={setFetchTicketDataCnt}
                jobCardHistory={true}
                fetchTicketDataCnt={fetchTicketDataCnt} setDisableShowVersionHistory={setDisableShowVersionHistory} />

        </>
    )
}