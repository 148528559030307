import { Tooltip, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { getFormattedDate } from '../../../../../../utils/helpers/utils';

const { Option } = Select;

export const getIssuesAndSubIssuesLogsTableColumns = (): ColumnsType<any> => {
  
  return [
    {
      title: (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <span style={{}}>Issue</span>
        </div>
      ),
      dataIndex: 'issue',
      key: 'issue',
      width: '15%',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap', color: record?.issueMod
          ? "#FFA800"
          : "rgba(64, 64, 64, 1)" }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          <span style={{}}>Sub Issue</span>
        </div>
      ),
      dataIndex: 'subissue',
      key: 'subissue',
      width: '18%',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap', color: record?.subissueMod
          ? "#FFA800" : "rgba(64, 64, 64, 1)" }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
        title: (
          <div style={{ display: 'flex'}}>
            <span style={{}}>Reported At</span>
          </div>
        ),
        dataIndex: 'reportedAt',
        key: 'reportedAt',
        width: '18%',
        render: (text: string, record: any) => (
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap', color: record?.issueMod
          ? "#FFA800" : "rgba(64, 64, 64, 1)" }}>
            {text != null ? 
              `${getFormattedDate(text)} ` :
               <span style={{fontSize: 12}}>NA</span>
            }
          </div>
        )
         
    },


    {
        title: (
          <div style={{ display: 'flex'}}>
            <span style={{}}>Status</span>
          </div>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        render: (text: any, record: any) => {
          const [color, backgroundColor ] = [record?.statusColor, record?.backgroundColor];
          return (
            <div style={{display: "flex", alignItems: "center"}}>
              <Tooltip title={text}>
                <div style={{ color: color, fontWeight: '500', backgroundColor: backgroundColor, padding: '0 8px', 
                  borderRadius: '4px', display: 'inline-block', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap' }}>
                  {text}
                </div>
              </Tooltip>
            </div>
          );
        },
    },
    
    {
        title: (
          <div style={{ display: 'flex'}}>
            <span style={{}}>Last Updated At</span>
          </div>
        ),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: '18%',
        render: (text: string, record: any) => (
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap', color: record?.issueMod
          ? "#FFA800" : "rgba(64, 64, 64, 1)" }}>
            {text != null ? 
              `${getFormattedDate(text)} ` :
               <span style={{fontSize: 12}}>NA</span>
            }
          </div>
        ),
        
        sortDirections: ['ascend', 'descend'],
        
    },

    
    
  ] ;
};
