import { Card, Input, Typography, message } from "antd";
import { useState } from "react";
import { ReactComponent as CheckIcon } from "./../../../../../utils/icons/Check_Icon.svg";
import { ReactComponent as EmptyIcon } from "./../../../../../utils/icons/not_supported.svg";
import { Empty } from 'antd';
import { getFormattedDate } from "../../../../../../utils/helpers/utils";


interface TicketCancelRemarkDetailsProps {
    ticketRemark: any;
}

const { Title } = Typography;

const TicketCancelRemarkDetails: React.FC<TicketCancelRemarkDetailsProps> = ({ ticketRemark }) => {
    const [loader, setLoader] = useState(false);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                }}
            >
                <Title style={{ fontWeight: 700 }} level={5}>
                    Closing Remark
                </Title>
                
            </div>

            <Card style={{ width: "100%" }}>
              
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        marginTop: "0.1rem",
                        justifyContent: "space-between",
                    }}
                >
                    <span style={{fontSize:'0.75rem', fontWeight: "600", width:'65%' }}>
                        {ticketRemark?.ticketRemark ? ticketRemark?.ticketRemark : 'NA'}
                    </span>
                    <span style={{fontSize:'0.875rem', fontWeight: "500" }}>
                        {`${ticketRemark?.ticketRemarkUpdatedAt ? getFormattedDate(ticketRemark?.ticketRemarkUpdatedAt) : 'NA'}  
                        ${"\u00A0|\u00A0"} ${ticketRemark?.ticketRemarkUpdatedBy ? JSON.parse(ticketRemark?.ticketRemarkUpdatedBy)?.name : 'NA'}`}
                    </span>
                </div>
            </Card>
        </>
    );
};

export default TicketCancelRemarkDetails;
