import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Input, InputNumber, Modal, Typography } from 'antd';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as SuccessIcon } from '../../SVGs/SuccessIcon.svg';
import { useEffect, useState } from 'react';
import _ from "lodash";

interface ModalInputProps {
    title: string;
    textType?: string;
    message: any;
    setMessage: (message: any) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    tooltip?: boolean;
    tooltipMessage?: string;
    handleConfirm: () => void;
    max?: any;
    assetRes: string | null;
    loader?: boolean;
}

const ModalInput: React.FC<ModalInputProps> = ({title, textType, message, setMessage, open, setOpen, tooltip, tooltipMessage, handleConfirm, max, assetRes, loader}) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    useEffect(() => {
        setDisabled(!((textType == typeof message) && (message>0 && message<=max)))
    }, [message]);

    useEffect(() => {
        setMessage(null);
    }, [open])

    return <Modal
        centered={true}
        title={_.isNull(assetRes) ? title : ""}
        open={open}
        onCancel={_=> {setOpen(false); setMessage(null);}}
        closable={_.isNull(assetRes) ? true : false}
        footer={<>
            {_.isNull(assetRes) && <Button disabled={disabled} type="primary" className="button-override" style={{width: "100%"}} onClick={handleConfirm} loading={loader} icon={<TickIcon />}>Confirm</Button>}
        </>}
        width={400}
    >
        {
            _.isNull(assetRes)
            ? <>
                {tooltip && <Alert message={tooltipMessage} type="warning" showIcon style={{marginBottom: 20}} />}
                {textType === "number" ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <InputNumber
                        value={message}
                        status={(disabled && message) ? "error" : ""}
                        style={{width: "100%"}} 
                        className='input-number-override' 
                        onChange={e => setMessage(e || 0)}
                    />
                </div> :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Input style={{width: "100%"}} className='input-override' onChange={e => setMessage(e)} />
                </div>
            }
            </>
            : <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <SuccessIcon style={{marginBottom: 20}}/>
                <Typography>Asset assigned!</Typography>
                <Typography>{assetRes || "jhsbfkjskjd"}</Typography>
            </div>
        }
        
    </Modal>
}

export default ModalInput;