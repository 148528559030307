import { ArrowRightOutlined, DeleteFilled, DeleteOutlined, EyeInvisibleFilled, EyeOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Select, Space, Spin, Switch, Tooltip, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { modalButtonStyle } from "./OnboardNewClient";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import axios from "axios";
import { useSelector } from "react-redux";
import { getRequest, postRequest, putRequest } from "../../../utils/handler/apiHandler";
import SelectSearch from "../../../utils/SelectSearch";
import ViewDocumentModal from "./ViewDocumentModal";
import { handleDownlaod } from "./OnboardingDetail";
import ShowCertificates from "./ShowCertificates";
import SelectSearch2 from "./SelectSearch2";
import "./hideError.css"
const HARYANA_STATE_ID: number = 6;

export default function ParentDetailsForm({ setCurrentStep, prospectDetails, prospectId, formSteps, currentStep, docTypes, disabled }: any) {
    const [form] = Form.useForm();
    const [cities, setCitites] = useState([])
    const [states, setStates] = useState([])
    const [fileList, setFileList] = useState<any[]>([]);
    const [communicationFileList, setCommunicationFileList] = useState<any[]>([]);
    const [submitting, setSubmitting] = useState(false)
    const [showCertificates, setShowCertificates] = useState(null)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null)

    const [uploadLoader, setUploadLoader] = useState<any>({
        reg: false,
        comm: {
            index: null
        }
    });


    const [filesUrls, setFileUrls] = useState<any>({
        "registration-files": {
            urls: [],
            uids: []
        },
        "communication-files": []
    })

    const auth = useAppSelector(authSelector);

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities`).then((res: any) => {
            console.log("cities", res.data)
            setCitites(res.data)
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/states`).then((res: any) => {
                console.log("states", res.data)
                setStates(res.data)
            });
        });
    }, [])

    const handleCommunicatoinDelete = (file: any, key: number) => {

        const tempFileList: any = [];
        const oldFileList = communicationFileList.find((x: any) => key == x.key).files

        for (let i = 0; i < oldFileList.length; i++) {
            if (oldFileList[i]?.uid !== file?.uid) {
                tempFileList.push(oldFileList[i]);
            }
        }

        const tempCommunicationArr = filesUrls?.["communication-files"].map((item: any) => {
            if (item.key == key) {
                return {
                    ...item,
                    urls: item.urls.filter(((i: string, j: number) => j !== item.uids.indexOf(file.uid))),
                    uids: item.uids.filter((i: any) => i !== file.uid)
                }
            } else {
                return item
            }
        })
        setFileUrls({ ...filesUrls, "communication-files": tempCommunicationArr })
        setCommunicationFileList(communicationFileList.map((i: any) => i.key == key ? {
            key: key,
            files: tempFileList
        } : i));;
    }

    const handleDelete = (file: any) => {

        const tempFileList = [];
        const tempFileUrls: string[] = []
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileUrls.push(filesUrls?.["registration-files"]?.urls[i])
                tempFileList.push(fileList[i]);
            }
        }
        setFileUrls({ ...filesUrls, "registration-files": { ...filesUrls["registration-files"], urls: tempFileUrls, } })
        setFileList(tempFileList);
    }

    const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;

    const changeLoaderState = (loading: boolean, type: string, key?: number) => {
        if (loading) {
            if (type == "Registration Address") {
                setUploadLoader((prevState: any) => { return { ...prevState, reg: true } })
            } else if (type == "Communication Address") {
                setUploadLoader((prevState: any) => {
                    return {
                        ...prevState, comm: {
                            index: key
                        }
                    }
                })
            }
        } else {
            if (type == "Registration Address") {
                setUploadLoader((prevState: any) => { return { ...prevState, reg: false } })
            } else if (type == "Communication Address") {
                setUploadLoader((prevState: any) => {
                    return {
                        ...prevState, comm: {
                            index: null
                        }
                    }
                })
            }
        }

    }

    const handleUploadRequest = async (file: any, type: string, key?: any) => {
        changeLoaderState(true, type, key)
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadAgreementDoc?prospectId=${prospectId}&isAddressDoc=true`;
        const formData = new FormData();
        formData.append('file', file.file);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                console.log("response?.data", response?.data)

                if (response.status === 200) {
                    if (type == "Registration Address") {
                        setFileUrls((prevState: any) => {
                            const newUrls = [...prevState["registration-files"].urls, response?.data?.DocumentUrl]
                            const newUids = [...prevState["registration-files"].uids, file?.file?.uid]

                            const combinedArr = newUids.map((newI, index) => {
                                return {
                                    uid: newI,
                                    url: newUrls[index]
                                }
                            })

                            const newSortedObj = combinedArr.sort((a, b) => parseInt(a.uid.split('-').pop()) - parseInt(b.uid.split('-').pop()));

                            console.log("newSortedObj", newSortedObj)
                            return {
                                ...prevState,
                                "registration-files": {
                                    urls: newSortedObj.map(i => i.url),
                                    uids: newSortedObj.map(i => i.uid)
                                }
                            }
                        })
                    } else if (type == "Communication Address") {

                        setFileUrls((prevState: any) => {
                            return {
                                ...prevState, "communication-files": prevState["communication-files"].length == 0 ? [{
                                    key: 0,
                                    urls: [response.data.DocumentUrl],
                                    uids: [file?.file?.uid]
                                }] :
                                    prevState["communication-files"].find((x: any) => x.key == key) ? prevState["communication-files"].map((item: any, index: number) => {

                                        const newObj = [...item.urls, response.data.DocumentUrl].map((newItem: any, index: number) => {
                                            return {
                                                url: newItem,
                                                uid: [...item.uids, file?.file?.uid][index]
                                            }
                                        })

                                        const sortedObj = newObj.sort((a, b) => parseInt(a.uid.split('-').pop()) - parseInt(b.uid.split('-').pop()));


                                        return item.key == key ? {
                                            key: key,
                                            urls: sortedObj.map((sortItem: any) => sortItem.url),
                                            uids: sortedObj.map((sortItem: any) => sortItem.uid)
                                        } : item
                                    }) : [...prevState["communication-files"], {
                                        key: key,
                                        urls: [response.data.DocumentUrl],
                                        uids: [file?.file?.uid]
                                    }]
                            }
                        })
                    }
                }
                else {
                    console.log("error in uploading file")
                }
                changeLoaderState(false, type, key);
            })
        } catch (error) {
            changeLoaderState(false, type, key);
            console.error('Upload error:', error);
        }
    };

    useEffect(() => {
        console.log('filesxxx', filesUrls)
    }, [filesUrls])



    const handleSubmit = async () => {

        const formValues = form.getFieldsValue()
        console.log("formValues", formValues)
        let registrationAddress:any = [];
        let communtionAddress:any = [];
        let isValid = true;


        const isRegAnyFieldFilled = formValues["registration-address"].address || formValues["registration-address"].pinCode || formValues["registration-address"].entityGst || formValues["registration-address"].cityId || formValues["registration-address"].stateId || filesUrls["registration-files"]?.urls?.length > 0;

        if(isRegAnyFieldFilled) {
            if (!formValues["registration-address"].address || !formValues["registration-address"].pinCode || !formValues["registration-address"].stateId || !formValues["registration-address"].cityId) {
                isValid = false;
    
                // Set error for each invalid field in communication address
                form.setFields([
                    {
                        name: ["registration-address","address"],
                        errors: formValues["registration-address"].address ? [] : ["Address is required"],
                    },
                    {
                        name: ["registration-address","pinCode"],
                        errors: formValues["registration-address"].pinCode ? [] : ["Pin Code is required"],
                    },
                    {
                        name: ["registration-address","stateId"],
                        errors: formValues["registration-address"].stateId ? [] : ["State is required"],
                    },
                    {
                        name: ["registration-address","cityId"],
                        errors: formValues["registration-address"].cityId ? [] : ["City is required"],
                    },
                ]);
            } else {
                registrationAddress = [{
                    "address": formValues["registration-address"].address,
                    "pinCode": formValues["registration-address"].pinCode,
                    "addressType": "Registration Address",
                    "allowDisable": true,
                    "isParentAddress": true,
                    "entityGst": formValues["registration-address"].entityGst,
                    "cityId": formValues["registration-address"].cityId,
                    "stateId": formValues["registration-address"].stateId,
                    "url": filesUrls["registration-files"]?.urls
                }]
            }
        }

        

        formValues["communication-address"].forEach((item: any, key: number) => {
            const isAnyFieldFilled = item.address || item.pinCode || item.entityGst || item.cityId || item.stateId || filesUrls["communication-files"][key]?.urls?.length > 0;


            if (isAnyFieldFilled) {
                if (!item.address || !item.pinCode || !item.stateId || !item.cityId) {
                    isValid = false;
        
                    // Set error for each invalid field in communication address
                    form.setFields([
                        {
                            name: ["communication-address",key,"address"],
                            errors: item.address ? [] : ["Address is required"],
                        },
                        {
                            name: ["communication-address",key,"pinCode"],
                            errors: item.pinCode ? [] : ["Pin Code is required"],
                        },
                        {
                            name: ["communication-address",key,"stateId"],
                            errors: item.stateId ? [] : ["State is required"],
                        },
                        {
                            name: ["communication-address",key,"cityId"],
                            errors: item.cityId ? [] : ["City is required"],
                        },
                    ]);
                } else {
                    communtionAddress.push({
                        "address": item.address,
                        "pinCode": item.pinCode,
                        "addressType": "Communication Address",
                        "allowDisable": true,
                        "isParentAddress": true,
                        "entityGst": item.entityGst,
                        "cityId": item.cityId,
                        "stateId": item.stateId,
                        "url": filesUrls["communication-files"][key]?.urls
                    });
                }
            }
        });

        if (!isValid) {
            return; // Stop form submission if validation fails
        }

        setSubmitting(true)
        if (registrationAddress?.length > 0) {
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/addAddressDetails?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`, registrationAddress).then(res => {
                console.log(res)
            })
        }
        if (communtionAddress?.length > 0) {
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/addAddressDetails?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`, communtionAddress).then(res => {
                console.log(res)
            })
        }


        const payload = {
            "parentLegalBusinessName": formValues.parentLegalBusinessName,
            "parentEntityName": formValues.parentEntityName,
            "parentEntityPan": formValues.parentEntityPan,
            "parentEntityCin": formValues.parentEntityCin,
            // clientStateStatus: formSteps[currentStep - 1].value,
            // creditLimit: prospectDetails?.creditLimit,
        }
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateDetails/${prospectDetails?.clientOnBoardingId}`, payload).then(res => {
            console.log(res)
            setSubmitting(false)
            if (res?.status == 200) {
                setCurrentStep(4)
            }
        })



        // let params:any = [{
        //     "address": formValues["registration-address"].address,
        //     "pinCode": formValues["registration-address"].pinCode,
        //     "addressType":  "Registration Address",
        //     "contactNumber": "",
        //     "allowDisable": true,
        //     "isParentAddress": true,
        //     "entityGst": formValues["registration-address"].entityGst,
        //     "cityId":formValues["registration-address"].cityId,
        //     "stateId":formValues["registration-address"].cityId,
        //     "url": files.find((i)=>i.name=="Registration Address")?.files
        // }]

        // console.log("cassad",files.find((i)=>i.name=="Invoice Address"))

        // if(formValues["invoice-address"].length > 0){
        //     formValues["invoice-address"].map((i:any, key:number)=>{
        //         params.push({
        //             "address": i.address,
        //             "pinCode": i.pinCode,
        //             "addressType":  "Invoice Address",
        //             "contactNumber": "",
        //             "allowDisable": true,
        //             "isParentAddress": true,
        //             "entityGst": i.entityGst,
        //             "cityId":i.cityId,
        //             "stateId":i.cityId,
        //             "url": files.find((i)=>i.name=="Invoice Address")?.files[key]
        //         })
        //     })
        // }

        // console.log("params", params)

    }



    useEffect(() => {
        console.log('heya', prospectDetails?.prospect)

        if (!disabled) {
            prospectDetails && form.setFieldsValue({
                parentEntityName: prospectDetails?.prospect?.parentLegalBusinessName?.entityName,
                parentLegalBusinessName: prospectDetails?.prospect?.parentLegalBusinessName?.name,
                "communication-address": [
                    {
                        "address": "",
                        "entityGst": "",
                        "pinCode": "",
                        "cityId": null,
                        "stateId": null
                    }
                ],
            })
        } else {
            const commAddress = prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && i.isParentAddress);
            const regAddress = prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && i.isParentAddress)


            prospectDetails && form.setFieldsValue({
                parentEntityName: prospectDetails?.prospect?.parentLegalBusinessName?.entityName,
                parentLegalBusinessName: prospectDetails?.prospect?.parentLegalBusinessName?.name,
                parentEntityPan: prospectDetails?.parentEntityPan,
                parentEntityCin: prospectDetails?.parentEntityCin,
                "registration-address": {
                    "address": regAddress[0]?.address,
                    "entityGst": regAddress[0]?.entityGst,
                    "pinCode": regAddress[0]?.pinCode,
                    "cityId": regAddress[0]?.city?.id,
                    "stateId": regAddress[0]?.state?.id,
                },

                "communication-address": commAddress?.map((i: any) => {
                    return {
                        "first": "",
                        "address": i?.address,
                        "entityGst": i?.entityGst,
                        "pinCode": i?.pinCode,
                        "cityId": i?.city?.id,
                        "stateId": i?.state?.id,
                    }
                }),
                // "spocRequests": {
                //     "name": "asdasd",
                //     "designation": "asdasda",
                //     "contactNumber": "adsasdasd"
                // }
            })
        }
    }, [prospectDetails])

    const makeAddressSame = (checked: boolean, field: string) => {
        const formValues = form.getFieldsValue()
        form.setFieldValue([field, 0, "address"], checked ? formValues["registration-address"].address : "")
        form.setFieldValue([field, 0, "entityGst"], checked ? formValues["registration-address"].entityGst : "")
        form.setFieldValue([field, 0, "pinCode"], checked ? formValues["registration-address"].pinCode : "")
        form.setFieldValue([field, 0, "cityId"], checked ? formValues["registration-address"].cityId : null)
        form.setFieldValue([field, 0, "stateId"], checked ? formValues["registration-address"].stateId : null)

        const fileField = field == "invoice-address" ? "invoice-files" : "communication-files"


        if (filesUrls[fileField].length == 0) {
            if (checked) {
                setFileUrls({
                    ...filesUrls, [`${fileField}`]: [{
                        key: 0,
                        uids: filesUrls["registration-files"].uids,
                        urls: filesUrls["registration-files"].urls,
                    }]
                })
            }
        } else {
            setFileUrls({
                ...filesUrls, [`${fileField}`]: filesUrls[fileField].map((i: any, key: number) => {
                    if (key == 0) {
                        return {
                            key: i.key,
                            uids: checked ? filesUrls["registration-files"].uids : [],
                            urls: checked ? filesUrls["registration-files"].urls : [],
                        }
                    } else {
                        return i
                    }
                })
            })
        }

        if (fileField == "communication-files") {
            if (communicationFileList.length == 0) {
                if (checked) {
                    setCommunicationFileList([{ files: fileList, key: 0 }])
                }
            } else {
                setCommunicationFileList(communicationFileList.map((i: any, key: number) => {
                    if (key == 0) {
                        return {
                            key: i.key,
                            files: checked ? fileList : []
                        }
                    } else {
                        return i
                    }
                }))

            }
        }
    }

    useEffect(() => {
        console.log("filesUrlsyyyy", filesUrls, communicationFileList, fileList)
    }, [communicationFileList])


    const handleCityIdSelect = async (cityId: any, name: any) => {
        const cityDetail: any = cities.find((city: any) => city.id == cityId);
        const gevlStateDetail: any = states.find((state: any) => state.stateCode == cityDetail.stateCode);
        if (gevlStateDetail) {
            form.setFieldValue(name, gevlStateDetail.id)
        }
        else {
            form.setFieldValue(name, HARYANA_STATE_ID)
        }
    }

    return (
        <>

            <Form disabled={disabled} form={form} onFinish={handleSubmit} layout="vertical" name="client-details">
                <Typography.Text strong style={{ fontSize: 16 }}>Parent Entity Basic Details </Typography.Text>

                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Parent Entity Name" name="parentEntityName">
                                <Input placeholder="Enter Parent Entity Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Parent Brand Name" name="parentLegalBusinessName">
                                <Input placeholder="Enter Parent Brand Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item rules={[
                                { len: 10, message: 'PAN No. must be 10 characters!' },
                                { pattern: /^[A-Z0-9]+$/, message: 'only alphabets and numbers allowed!' }
                            ]} label="Entity PAN no." name="parentEntityPan">
                                <Input placeholder="Enter Entity PAN no." />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<>Entity CIN no.</>} name="parentEntityCin">
                                <Input placeholder="Enter Entity CIN no." />
                            </Form.Item>
                        </Col>
                    </Row>

                </div>

                <Typography.Text strong style={{ fontSize: 16, paddingTop: 20, display: "block" }}>Registered Address</Typography.Text>

                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>
                        <Col span={16}>
                            <Form.Item
                                label="Address" name={["registration-address", "address"]}>
                                <Input.TextArea rows={5} placeholder="Enter Address" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="GST No." name={["registration-address", "entityGst"]}>
                                        <Input placeholder="Enter GST No" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    {
                                        disabled ?
                                            <Space onClick={() => setShowCertificates(prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && i.isParentAddress)[0]?.id)} style={{ cursor: "pointer" }}>
                                                {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Registration Address" && i.isParentAddress)[0] && <>
                                                    <img width={9} src={`${__dirname}assets/document.png`} />
                                                    <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                                </>}
                                            </Space>
                                            : <Form.Item
                                                label="Upload Certificate">
                                                <Upload
                                                    style={{ width: "100%", display: "block" }}
                                                    accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                                    customRequest={(file) => handleUploadRequest({ file }, "Registration Address")}
                                                    beforeUpload={(file) => {
                                                        setFileList((prevState) => [...prevState, file]);
                                                        handleUploadRequest({ file }, "Registration Address")
                                                        return false
                                                    }}
                                                    fileList={fileList}
                                                    onRemove={handleDelete}
                                                    itemRender={(originNode, file, fileList, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                                        <div style={{ width: 150 }}>
                                                            <Typography.Text ellipsis>
                                                                <Tooltip title={file.name}>{file.name}</Tooltip>
                                                            </Typography.Text>
                                                        </div>
                                                        <div style={{ display: "flex", gap: 10 }}>
                                                            {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ?
                                                                <Button
                                                                    // loading={uploadLoader.reg}
                                                                    // disabled={uploadLoader.reg}
                                                                    onClick={() => {
                                                                        const itemIndex = fileList.findIndex((i) => i.uid === file.uid);
                                                                        console.log("fileList", fileList)
                                                                        console.log("fileList", file)
                                                                        setPreviewFile({
                                                                            "fileName": file.name,
                                                                            "type": file.type as string,
                                                                            "url": filesUrls["registration-files"]?.urls[itemIndex],
                                                                        })
                                                                    }} size="small" type="text" icon={<EyeOutlined />} /> :
                                                                <Button
                                                                    // disabled={uploadLoader.reg}
                                                                    // loading={uploadLoader.reg}
                                                                    size="small" type="text" icon={<EyeInvisibleFilled style={{ color: "grey" }} />} />
                                                            }

                                                            <Button disabled={uploadLoader.reg} size="small" type="text" onClick={actions.remove} style={{ cursor: "pointer" }}>
                                                                <DeleteFilled style={{ color: "red" }} />
                                                            </Button>
                                                        </div>


                                                    </div>}
                                                    listType="text"
                                                    multiple={true}
                                                >

                                                    <Button
                                                        type="default"
                                                        disabled={uploadLoader.reg}
                                                        style={{ width: "100%", display: "block", border: "1px solid rgba(44, 102, 227, 1)", color: "rgba(44, 102, 227, 1)" }}
                                                        icon={uploadLoader.reg ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                                    >Upload File</Button>
                                                </Upload>

                                            </Form.Item>}
                                </Col>
                            </Row>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' }
                                ]}
                                label="Pincode" name={["registration-address", "pinCode"]}>
                                <Input placeholder="Enter Pincode" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* <Form.Item
                                label="City" name={["registration-address", "cityId"]}>
                                <Select placeholder="Enter City">
                                    {cities?.map((city: any) =>
                                        <Select.Option value={city.id}>
                                            {city.cityName}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item> */}
                            <Form.Item className="hide-second-error" style={{ width: '100%', marginRight: '20px' }}
                                label="City"
                                name={["registration-address", "cityId"]}
                            >
                                <SelectSearch2 name={["registration-address", "cityId"]} placeholder={"City"} options={cities.map((id: any) => {
                                    return {
                                        id: id?.id,
                                        name: id?.cityName,
                                    }
                                })
                                }
                                    onSelect={(value) => {

                                        form.setFields([
                                            {
                                                name: ["registration-address", "cityId"],
                                                errors: [],
                                            },
                                            {
                                                name: ["registration-address", "stateId"],
                                                errors: [],
                                            },
                                        ]);
                                        handleCityIdSelect(value, ["registration-address", "stateId"])
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>

                            <Form.Item className="hide-second-error" style={{ width: '100%', marginRight: '20px' }}
                                label="State"
                                name={["registration-address", "stateId"]}
                            >
                                <SelectSearch2 name={["registration-address", "stateId"]} placeholder={"State"} options={Array.isArray(states) ? states.map((id: any) => {
                                    return {
                                        id: id?.id,
                                        name: id?.stateName,
                                    }
                                }) : []
                                } />
                            </Form.Item>
                        </Col>
                    </Row>

                </div>

                <Form.List name="communication-address">
                    {(fields, { add, remove }) => (
                        <>

                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 28 }}>
                                <div>
                                    <Typography.Text strong style={{ fontSize: 16 }}>Communication Address <span style={{ color: "#AFAFAF" }}>(Optional)</span></Typography.Text>
                                </div>
                                {!disabled && <div style={{ display: "flex", alignItems: "center" }}>

                                    <div style={{ display: "flex", gap: 2, alignItems: "center", color: "#616161" }}>
                                        <Typography style={{ fontSize: 12 }}>Same as Registered Address :</Typography>
                                        <Switch onChange={(checked) => makeAddressSame(checked, "communication-address")} />
                                    </div>
                                    <Divider type="vertical" style={{ height: 32 }} />
                                    <Form.Item style={{ margin: 0 }}>
                                        <Button block style={modalButtonStyle} onClick={() => add()}>
                                            <PlusOutlined /> Add New Address
                                        </Button>
                                    </Form.Item>

                                </div>}
                            </div>

                            {fields.map(({ key, name, ...restField }) => (
                                <div style={{
                                    position: "relative",
                                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                                }}>
                                    <Row gutter={12}>

                                        <Col span={16}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'address']} label="Address">
                                                <Input.TextArea rows={5} placeholder="Enter Address" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item {...restField} label="GST No." name={[name, "entityGst"]}>
                                                        <Input placeholder="Enter GST No" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    {
                                                        disabled ?
                                                            <Space onClick={() => setShowCertificates(prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && i.isParentAddress)[name]?.id)} style={{ cursor: "pointer" }}>
                                                                {prospectDetails?.addressDetails?.filter((i: any) => i.addressType == "Communication Address" && i.isParentAddress)[name] && <>
                                                                    <img width={9} src={`${__dirname}assets/document.png`} />
                                                                    <Typography style={{ color: "rgb(23, 119, 255" }}>View Certificates</Typography>
                                                                </>}
                                                            </Space>
                                                            : <Form.Item
                                                                {...restField}
                                                                name={[name, 'first']} label="Upload Certificate">

                                                                <Upload
                                                                    style={{ width: "100%", display: "block" }}
                                                                    accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                                                    customRequest={(file) => handleUploadRequest(file, "Communication Address", key)}
                                                                    beforeUpload={(file) => {
                                                                        setCommunicationFileList((prevState) => {
                                                                            if (prevState.find((x: any) => x.key == key)) {
                                                                                return prevState.map((i: any) => i.key == key ? {
                                                                                    key: key,
                                                                                    files: [...i.files, file]
                                                                                } : i)

                                                                            } else {
                                                                                return [...prevState, {
                                                                                    key: key,
                                                                                    files: [file]
                                                                                }]
                                                                            }
                                                                        })
                                                                        handleUploadRequest({ file }, "Communication Address", key)
                                                                        return false
                                                                    }}
                                                                    fileList={communicationFileList.find((x: any) => x.key == key)?.files || []}
                                                                    onRemove={(file) => handleCommunicatoinDelete(file, key)}
                                                                    listType="text"
                                                                    multiple={true}
                                                                    itemRender={(originNode, file, fileList, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                                                        <div style={{ width: 150 }}>
                                                                            <Typography.Text ellipsis>
                                                                                <Tooltip title={file.name}>{file.name}</Tooltip>
                                                                            </Typography.Text>
                                                                        </div>
                                                                        <div style={{ display: "flex", gap: 10 }}>
                                                                            {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ?
                                                                                <Button
                                                                                    // loading={uploadLoader.comm.index == key}
                                                                                    disabled={uploadLoader.comm.index == key}
                                                                                    onClick={() => {
                                                                                        const itemIndex = fileList.findIndex((i) => i.uid === file.uid);
                                                                                        setPreviewFile({
                                                                                            "fileName": file.name,
                                                                                            "type": file.type as string,
                                                                                            "url": filesUrls["communication-files"].filter((i: any) => i.key == key)[0].urls[itemIndex],
                                                                                        })
                                                                                    }} size="small" type="text" icon={<EyeOutlined />} />
                                                                                :
                                                                                <Button
                                                                                    disabled={uploadLoader.comm.index == key}
                                                                                    // loading={uploadLoader.comm.index == key}
                                                                                    size="small" type="text" icon={<EyeInvisibleFilled style={{ color: "grey" }} />} />
                                                                            }

                                                                            <Button disabled={uploadLoader.comm.index == key} size="small" type="text" onClick={actions.remove} style={{ cursor: "pointer" }}>
                                                                                <DeleteFilled style={{ color: "red" }} />
                                                                            </Button>
                                                                        </div>


                                                                    </div>}
                                                                >

                                                                    <Button
                                                                        type="default"
                                                                        disabled={uploadLoader.comm.index == key}
                                                                        style={{ width: "100%", display: "block", border: "1px solid rgba(44, 102, 227, 1)", color: "rgba(44, 102, 227, 1)" }}
                                                                        icon={uploadLoader.comm.index == key ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                                                    >Upload File</Button>
                                                                </Upload>

                                                            </Form.Item>}
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                {...restField}
                                                rules={[
                                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' }
                                                ]}
                                                name={[name, 'pinCode']} label="Pincode">
                                                <Input placeholder="Enter Pincode" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item 
                                                className="hide-second-error"
                                                {...restField}
                                                style={{ width: '100%', marginRight: '20px' }}
                                                label="City"
                                                name={[name, 'cityId']}
                                            >
                                                <SelectSearch2 name={[name, 'cityId']} placeholder={"City"} options={cities.map((id: any) => {
                                                    return {
                                                        id: id?.id,
                                                        name: id?.cityName,
                                                    }
                                                })
                                                } onSelect={(value) => handleCityIdSelect(value, ["communication-address", name, "stateId"])} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                className="hide-second-error"
                                                {...restField}
                                                style={{ width: '100%', marginRight: '20px' }}
                                                label="State"
                                                name={[name, 'stateId']}
                                            >
                                                <SelectSearch2 name={[name, 'stateId']} placeholder={"State"} options={Array.isArray(states) ? states.map((id: any) => {
                                                    return {
                                                        id: id?.id,
                                                        name: id?.stateName,
                                                    }
                                                }) : []
                                                } />
                                            </Form.Item>
                                        </Col>
                                        {!(fields.length == 1) && <div style={{ position: "absolute", right: 16, top: 10 }}>
                                            {!disabled && <DeleteFilled style={{ color: "red" }} onClick={() => {
                                                setCommunicationFileList(communicationFileList.filter((i: any) => i.key !== name))
                                                setFileUrls({ ...filesUrls, "communication-files": filesUrls["communication-files"].filter((i: any) => i.key !== name) })
                                                remove(name)
                                                remove(name)
                                            }} />}
                                        </div>}
                                    </Row>
                                </div>
                            ))}

                        </>
                    )}
                </Form.List>
                {!disabled && <div style={{ textAlign: "right", marginTop: 28 }}>
                    <Button loading={submitting} disabled={submitting} htmlType="submit" size="large" type="primary">Proceed <RightOutlined /></Button>
                </div>}

            </Form>

            {disabled && <div style={{ textAlign: "right", marginTop: 28, paddingBottom: 20 }}>
                <Button onClick={() =>
                    setCurrentStep(4)
                } size="large" type="primary">Proceed <RightOutlined /></Button>
            </div>}

            <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />

            <ShowCertificates setShowCertificates={setShowCertificates} showCertificates={showCertificates} prospectDetails={prospectDetails} handleDownlaod={handleDownlaod} setPreviewFile={setPreviewFile} />


        </>

    )
}