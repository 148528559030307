import { Card, Typography } from "antd";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../../utils/handler/apiHandler";

const { Title } = Typography;

interface ServiceTakenDetailsProps{
    vehicleId: number,
}

const ServiceTakenDetails: React.FC<ServiceTakenDetailsProps> = ({vehicleId}) => {
    const [serviceTakenList, setServiceTakenList] = useState<string[]> ([]);

    useEffect(() => {
        const fetchServiceTakenList = async () => {
            const encodedFilter = encodeURIComponent(`id|eq|${vehicleId}`);
            
            // Run both requests in parallel using Promise.all
            const [respClientDetail, respVehicleFullDetail] = await Promise.all([
                getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle-client-map?filterAnd=${encodedFilter}`),
                getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/full-details?filterAnd=${encodedFilter}`)
            ]);
    
            const respObj = respClientDetail?.data?.items[0];
            const vehicleDetail = respVehicleFullDetail?.data?.items[0];
            
            const serviceTakenObj = {
                chargingOpted: respObj?.chargingOpted,
                chauffeurManagementOpted: respObj?.chauffeurManagementOpted,
                damageWaiverOpted: respObj?.damageWaiverOpted,
                pickupAndDropOpted: respObj?.pickupAndDropOpted,
                replacementCarOpted: respObj?.replacementCarOpted,
                rmtyOpted: respObj?.rmtyOpted !== 'No',
                rsaOpted: respObj?.rsaOpted,
                oemTelematicsAvailable: vehicleDetail?.oemTelematicsAvailable,
            };
            
            setServiceTakenList(generateServiceTakenList(serviceTakenObj));
        }
    
        if (vehicleId) {
            fetchServiceTakenList();
        }
    }, [vehicleId]);

    function generateServiceTakenList(serviceTakenObj: any) {
        let serviceTakenList = [];
    
        if (serviceTakenObj?.chargingOpted) {
            serviceTakenList.push('Charging');
        }
        
        if (serviceTakenObj?.chauffeurManagementOpted) {
            serviceTakenList.push('Chauffeur Management');
        }
    
        if (serviceTakenObj?.damageWaiverOpted) {
            serviceTakenList.push('Damage Waiver');
        }
    
        if (serviceTakenObj?.pickupAndDropOpted) {
            serviceTakenList.push('Pickup and Drop');
        }
    
        if (serviceTakenObj?.replacementCarOpted) {
            serviceTakenList.push('Replacement Car');
        }
    
        if (serviceTakenObj?.rmtyOpted) {
            serviceTakenList.push('RMTY');
        }
    
        if (serviceTakenObj?.rsaOpted) {
            serviceTakenList.push('RSA');
        }
        if (serviceTakenObj?.oemTelematicsAvailable) {
            serviceTakenList.push('VTS Subscription');
        }
        
    
        return serviceTakenList;
    }
    

    return(
        <>
            <Title style={{ marginTop: '20px', fontWeight: 700 }} level={5}>
                Service Taken
            </Title>

            <Card style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {serviceTakenList?.map((serviceName: string, index: number) => (
                    <span key={index} style={{ width: '25%' }}>
                        {serviceName}
                    </span>
                    ))}
                </div>
            </Card>
        </>
       
    );
}

export default ServiceTakenDetails;