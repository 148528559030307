import React, { Fragment, useEffect, useState } from "react";
import { Button, Tabs, Typography } from "antd";
const TabPane = Tabs.TabPane;
import "../../../styles/Pages.sass";
import AllCreditAssessmentRequest from "./Assessment/AllCreditAssessmentRequest";
import InProgressCreditAssessmentRequest from "./Assessment/InProgressCreditAssessmentRequest";
import ApprovedCreditAssessmentRequest from "./Assessment/ApprovedCreditAssessmentRequest";

import { menuSelector } from "../../../features/menu/menuSlice";
import { useAppSelector } from "../../../app/hooks";
import NoAccess from "../Prospect/utils/NoAccess";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { authSelector } from "../../../features/auth/authSlice";

function CreditAssessmentRequest() {
    const navigate = useNavigate();
    const [key, setKey] = useState("");
    const [searchParams] = useSearchParams();
    const menuActions = useAppSelector(menuSelector).menuActions;
    const allowedActionsForCreditAssessmentList: string[] | undefined = menuActions?.['Credit Assessment'];
    const allowedActionsForCreditAssessment = new Set(allowedActionsForCreditAssessmentList);
    const [downloading, setDownloading] = useState(false);
    const auth = useAppSelector(authSelector);
    if( !allowedActionsForCreditAssessment?.has('creditDashboard') )  return <NoAccess/>;
    const handleTabUrl = (tab: string) => {
        setKey(tab.toString());
        navigate(`?tab=${tab}`)
    }
    useEffect(() => {
        setKey(searchParams.get("tab") || "1");
    }, []);

    const handleDownload = async () => {
        setDownloading(true)
        let downloadUrl = ``
        let fileName = ``
        if(key === '1') {
            downloadUrl = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/downloadAllAssessments`
            fileName = `AllAssessments.xlsx`
        } else if(key === '2') {
            downloadUrl = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/downloadAllInProgressAssessments`
            fileName = `AllInProgressAssessments.xlsx`
        } else if(key === '3') {
            downloadUrl = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/downloadAllApprovedAssessments`
            fileName = `AllApprovedAssessments.xlsx`
        }
        try {
          const res = await axios.get(
          downloadUrl,
          {
              headers: {
                  "Authorization": `Bearer ${auth.accessToken}`
              },
              responseType: 'arraybuffer'
          }
          );
          const url =  window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.addEventListener('click', () => {
              setTimeout(() => {
                  window.URL.revokeObjectURL(url);
              }, 100);
          });
          link.click();
          document.body.parentNode?.removeChild(link);
      } catch (error) {
          console.error('Error fetching dbData:', error);
      }
      setDownloading(false)

    }
    return (
    <Fragment>
    <div className="parent-container">
        <div className="container-layout">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography style={{fontSize: 20, fontWeight: 700}}>Credit Assessment Requests</Typography>
                <Button loading={downloading} disabled={downloading} type="primary" onClick={handleDownload}>Download Data</Button>
            </div>
            <div className="container-body">
            <Tabs activeKey={key} defaultActiveKey={key} className="tab" onTabClick={(e) => handleTabUrl(e)}>
                <TabPane tab={<span className="tab-heading">All Assessments</span>} key="1">
                    <AllCreditAssessmentRequest />
                </TabPane>
                <TabPane tab={<span className="tab-heading">In progress</span>} key="2">
                    <InProgressCreditAssessmentRequest />
                </TabPane>
                {
                    allowedActionsForCreditAssessment?.has('approvedCreditRequest') &&
                    <TabPane tab={<span className="tab-heading">Approved Assessments</span>} key="3">
                        <ApprovedCreditAssessmentRequest />
                    </TabPane>
                }
                
            </Tabs>
            </div>
        </div>
    </div>
    </Fragment>
    );
}

export default CreditAssessmentRequest;