import { Col, Form, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { getFormattedDateToDisplayWithoutChangingTimeZone, getTicketServiceNumberText } from "../../../../../../utils/helpers/utils";
import ChangedLabel from "../Utils/ChangedLabel";

interface ShowTicketHistoryDetailsProps {
  ticket: any;
  ticketStatusId: number;
}

const { Title } = Typography;

const ShowTicketHistoryDetails: React.FC<ShowTicketHistoryDetailsProps> = ({ ticket, ticketStatusId }) => {
  const [form] = Form.useForm();
  const [formattedTicket, setFormattedTicket] = useState<any>({});

  useEffect(() => {
    setFormattedTicket({
      ticketCreatedAt: ticket?.createdAt,
      alertType: ticket?.serviceCriteriaType,
      serviceNumber: ticket?.serviceNumber,
      driverName: ticket?.driverName,
      driverContact: ticket?.driverMobileNumber,
      arrivalTimeAtWorkshop: ticket?.workshopArrivalTime,
      completionDateAndTime: ticket?.completionTime,
      deliveryHandoverTime: ticket?.deliveryHandoverTime,
      replacementCar: ticket?.isReplacementCar,
      replacementCarNo: ticket?.replacementCarNo,
      replacementDriverName: ticket?.replacementDriverName,
      replacementDriverNo: ticket?.replacementDriverNo,
      pickAndDrop: ticket?.isPickAndDrop,
      pickupSchedule: ticket?.pickupScheduledDate,
      actualPickup: ticket?.vehiclePickupDate,
      deliverySchedule: ticket?.deliveryScheduledDate,
      roadSideAssistance: ticket?.isRoadSideAssistance,
      serviceCriteriaType: ticket?.serviceCriteriaType,
      totalMonthlyCriteria: ticket?.totalMonthlyCriteria,
      totalKmCriteria: ticket?.totalKmCriteria,

      // formattedTicket?.serviceNumber, formattedTicket?.serviceCriteriaType, 
      //               formattedTicket?.totalMonthlyCriteria, formattedTicket?.totalKmCriteria

      // Adding Mod fields for each
      replacementCarMod: ticket?.replacementCarMod,
      replacementCarNoMod: ticket?.replacementCarNoMod,
      replacementDriverNameMod: ticket?.replacementDriverNameMod,
      replacementDriverNoMod: ticket?.replacementDriverNoMod,
      pickAndDropMod: ticket?.pickAndDropMod,
      pickupScheduleMod: ticket?.pickupScheduledDateMod,
      actualPickupMod: ticket?.vehiclePickupDateMod,
      deliveryScheduleMod: ticket?.deliveryScheduledDateMod,
      roadSideAssistanceMod: ticket?.roadSideAssistanceMod,
      driverNameMod: ticket?.driverNameMod,
      driverContactMod: ticket?.driverMobileNumberMod,
      arrivalTimeAtWorkshopMod: ticket?.workshopArrivalTimeMod,
      completionDateAndTimeMod: ticket?.completionTimeMod,
      deliveryHandoverTimeMod: ticket?.deliveryHandoverTimeMod,
    });
  }, []);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <hr
          style={{
            border: "0.01rem solid rgba(175, 175, 175, 1)",
            borderWidth: "1px",
          }}
        />
        <span
          style={{
            fontWeight: "700",
            fontSize: "16px",
            color: "#242424",
            padding: "1rem 1.5rem 1rem 1.5rem",
          }}
        >
          Ticket Details
        </span>
      </div>
      <Form
        name="dynamic_form_nest_item"
        form={form}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        style={{ padding: "0rem 1.5rem 1rem 1.5rem" }}
      >
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Ticket Created At
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.ticketCreatedAtMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              <span> { formattedTicket?.ticketCreatedAt ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.ticketCreatedAt) : 'NA'} </span>
            </Typography>
          </Col>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Alert Type
              {formattedTicket?.alertTypeMod && <ChangedLabel />}
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.alertTypeMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              {formattedTicket?.alertType ? formattedTicket?.alertType : 'NA'}
            </Typography>
          </Col>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Service Number
              {formattedTicket?.serviceNumberMod && <ChangedLabel />}
              
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.serviceNumberMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              {
                getTicketServiceNumberText(formattedTicket?.serviceNumber, formattedTicket?.serviceCriteriaType, 
                    formattedTicket?.totalMonthlyCriteria, formattedTicket?.totalKmCriteria)
              }
            </Typography>
          </Col>
        </Row>

        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Driver Name
              {formattedTicket?.driverNameMod && <ChangedLabel />}
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.driverNameMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              { formattedTicket?.driverName ? formattedTicket?.driverName : 'NA' }
            </Typography>
          </Col>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Driver Contact
              { formattedTicket?.driverContactMod && <ChangedLabel /> }
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.driverContactMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              { formattedTicket?.driverContact ? formattedTicket?.driverContact : 'NA' }
            </Typography>
          </Col>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Arrival Time at Workshop
              { formattedTicket?.arrivalTimeAtWorkshopMod && <ChangedLabel /> }
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.arrivalTimeAtWorkshopMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              { formattedTicket?.arrivalTimeAtWorkshop ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.arrivalTimeAtWorkshop) : 'NA'}
            </Typography>
          </Col>
        </Row>

        <Row style={{ display: "flex", justifyContent:'space-between' }}>
          

          <Col className="detail-description-item">
            <Typography className="description-label">
              Completion Date and Time
              { formattedTicket?.completionDateAndTimeMod && <ChangedLabel /> }
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.completionDateAndTimeMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              <span> { formattedTicket?.completionDateAndTime ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.completionDateAndTime) : 'NA'} </span>            
            </Typography>
          </Col>

          <Col className="detail-description-item" style={{width:'62.5%'}} >
            <Typography className="description-label">
              Delivery / Handover time
              { formattedTicket?.deliveryHandoverTimeMod && <ChangedLabel /> }
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.deliveryHandoverTimeMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              { formattedTicket?.deliveryHandoverTime ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.deliveryHandoverTime) : 'NA'}
            </Typography>
          </Col>
        </Row>

        <Row style={{ display: "flex", justifyContent: "space-between", backgroundColor: '#EDF6FD', 
            padding: '1.5rem 0.5rem 0rem 0.5rem', marginBottom:'1rem', borderRadius:'0.5rem' }}>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Replacement Car
              { formattedTicket?.replacementCarMod && <ChangedLabel /> }
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.replacementCarMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              { formattedTicket?.replacementCar ? "Yes" : "No" }
            </Typography>
          </Col>
          {
            formattedTicket?.replacementCar &&
            <>
              <Col className="detail-description-item">
                <Typography className="description-label">
                  Replacement Car Number
                  { formattedTicket?.replacementCarNoMod && <ChangedLabel /> }
                </Typography>

                <Typography
                  style={{
                    fontSize: 14,
                    color: formattedTicket?.replacementCarNoMod
                      ? "#FFA800"
                      : "rgba(64, 64, 64, 1)",
                  }}
                >
                  { formattedTicket?.replacementCarNo ? formattedTicket?.replacementCarNo : 'NA' }
                </Typography>
              </Col>
              <Col className="detail-description-item">
                <Typography className="description-label">
                  Replacement Driver Name
                  { formattedTicket?.replacementDriverNameMod && <ChangedLabel /> }
                </Typography>

                <Typography
                  style={{
                    fontSize: 14,
                    color: formattedTicket?.replacementDriverNameMod
                      ? "#FFA800"
                      : "rgba(64, 64, 64, 1)",
                  }}
                >
                  { formattedTicket?.replacementDriverName ? formattedTicket?.replacementDriverName : 'NA'}
                </Typography>
              </Col>
              <Col className="detail-description-item">
                <Typography className="description-label">
                  Replacement Driver Number
                  { formattedTicket?.replacementDriverNoMod && <ChangedLabel /> }
                </Typography>

                <Typography
                  style={{
                    fontSize: 14,
                    color: formattedTicket?.replacementDriverNoMod
                      ? "#FFA800"
                      : "rgba(64, 64, 64, 1)",
                  }}
                >
                  { formattedTicket?.replacementDriverNo ? formattedTicket?.replacementDriverNo : 'NA'}
                </Typography>
              </Col>
            </>

          }

          
        </Row>
        
        <Row style={{ display: "flex", justifyContent: "space-between", backgroundColor: '#EDF6FD',
            padding: '1.5rem 0.5rem 0rem 0.5rem', marginBottom:'1rem', borderRadius:'0.5rem' }}>
            <Col className="detail-description-item">
                <Typography className="description-label">
                Pick and Drop
                { formattedTicket?.pickAndDropMod && <ChangedLabel /> }
                </Typography>

                <Typography
                style={{
                    fontSize: 14,
                    color: formattedTicket?.pickAndDropMod
                    ? "#FFA800"
                    : "rgba(64, 64, 64, 1)",
                }}
                >
                { formattedTicket?.pickAndDrop ? "Yes" : "No" }
                </Typography>
            </Col>
            {
            formattedTicket?.pickAndDrop && 
            <>
              {
                ticketStatusId > 5 && 
               <Col className="detail-description-item">
                  <Typography className="description-label">
                    Pickup Schedule
                    { formattedTicket?.pickupScheduleMod && <ChangedLabel /> }
                  </Typography>

                  <Typography
                    style={{
                      fontSize: 14,
                      color: formattedTicket?.pickupScheduleMod
                        ? "#FFA800"
                        : "rgba(64, 64, 64, 1)",
                    }}
                  >
                    { formattedTicket?.pickupSchedule ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.pickupSchedule) : 'NA'}
                  </Typography>
                </Col>
              }
              {
                ticketStatusId > 5 && 
                <Col className="detail-description-item">
                  <Typography className="description-label">
                    Actual Pickup
                    { formattedTicket?.actualPickupMod && <ChangedLabel /> }
                  </Typography>

                  <Typography
                    style={{
                      fontSize: 14,
                      color: formattedTicket?.actualPickupMod
                        ? "#FFA800"
                        : "rgba(64, 64, 64, 1)",
                    }}
                  >
                    { formattedTicket?.actualPickup ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.actualPickup) : 'NA'}
                  </Typography>
                </Col>
              }
              {
                ticketStatusId > 8 && 
                <Col className="detail-description-item">
                  <Typography className="description-label">
                    Delivery Schedule
                    { formattedTicket?.deliveryScheduleMod && <ChangedLabel /> }
                  </Typography>

                  <Typography
                    style={{
                      fontSize: 14,
                      color: formattedTicket?.deliveryScheduleMod
                        ? "#FFA800"
                        : "rgba(64, 64, 64, 1)",
                    }}
                  >
                    { formattedTicket?.deliverySchedule ? getFormattedDateToDisplayWithoutChangingTimeZone(formattedTicket?.deliverySchedule) : 'NA'}
                  </Typography>
                </Col>
              }
            </>
            }
           
        </Row>

        <Row style={{ display: "flex", justifyContent: "space-between", backgroundColor: '#EDF6FD',
          padding: '1.5rem 0.5rem 0rem 0.5rem', marginBottom:'1rem', borderRadius:'0.5rem' }}>
          <Col className="detail-description-item">
            <Typography className="description-label">
              Road Side Assistance
              { formattedTicket?.roadSideAssistanceMod && <ChangedLabel /> }
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedTicket?.roadSideAssistanceMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              { formattedTicket?.roadSideAssistance ? "Yes" : "No" }
            </Typography>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ShowTicketHistoryDetails;
