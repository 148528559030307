import { Button, Modal, Typography } from "antd";
import { ReactComponent as CrossIcon } from '../../Dashboard/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../../Dashboard/SVGs/tick_icon.svg';
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;



interface ModalProps {
    title : string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    dateRange : any[];
    setDateRange : (newDateRange: any) => void;
    date : any
    setDate : (date : any) => void;
}

const RangePickerModal: React.FC<ModalProps> = ({title, showModal, setShowModal,  dateRange, setDateRange, date, setDate}) => {

    const handleOk = () => {
        const obj = {
            startDate : dayjs(date[0]).format("DD MMM YYYY"),
            endDate : dayjs(date[1]).format("DD MMM YYYY")
        }
        setDateRange([obj]);
        setShowModal(false);
    }

    return (
        <div>
            <Modal
                title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
                centered
                open={showModal}
                onCancel={() => {setShowModal(false); setDate(null);}}
                afterClose={() => {}}
                className="preview-modal"
                width={450}
                footer={<>

                    </>}
            >
                <div style={{color : 'grey',fontWeight : 500, fontSize : 20}}>
                        <div style={{}}>
                            <RangePicker
                            value={date}
                            onChange={(dates) => setDate(dates)}
                            style={{marginRight : 0, width: '100%'}}
                            />  
                        </div>   
                        <div style={{marginTop:'20px', textAlign: 'right',}}>                    
                            <Button
                                ghost
                                danger
                                onClick={_ => {setShowModal(false); setDate(null);}}
                                className="button-override danger"
                                style={{height: '2.2rem', width:'5rem'}}
                                icon={<CrossIcon />}
                                
                                >
                                Cancel
                            </Button>
                            <Button icon={<TickIcon color={'#FFFFFF'} />}
                                disabled = {!date}
                                style={{ color: '#FFFFFF', backgroundColor: date ? '#1777FF' : '#D3D3D3', height: '2.2rem', width:'5rem', marginLeft: '0.7rem', fontWeight: '400', fontSize: '14px' }}
                                key="confirm" type="primary" onClick={() => { handleOk()}}>
                                Ok
                            </Button>
                        </div>
                        
                        
                </div>

            </Modal>
        </div>
    );
}

export default RangePickerModal;
