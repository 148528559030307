// INPUT SHOULD BE IN ISO STRING FORMAT

export const getFormattedDate =  (inputInstant : string) => {
    const instant = new Date(inputInstant);
    const istInstant = new Date(instant.getTime() + (5.5 * 60 * 60 * 1000));
    
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = istInstant.getUTCDate();
    const month = months[istInstant.getUTCMonth()];
    const year = istInstant.getUTCFullYear();
    
    const hours = istInstant.getUTCHours();
    const minutes = istInstant.getUTCMinutes();

    const period = hours >= 12 ? ' PM' : ' AM';
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;

    const formattedDate = `${formattedTime}, ${day} ${month} ${year}`;
    return formattedDate;
}

export const getFormattedDateWithoutChangingTimezone = (inputInstant: string) => {
    const instant = new Date(inputInstant);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = instant.getUTCDate();
    const month = months[instant.getUTCMonth()];
    const year = instant.getUTCFullYear();

    const hours = instant.getUTCHours();
    const minutes = instant.getUTCMinutes();

    const period = hours >= 12 ? ' PM' : ' AM';
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;

    const formattedDate = `${formattedTime}, ${day} ${month} ${year}`;
    return formattedDate;
}

export const getFormatedDateTime = (selectedDate: string) => {
  const date = new Date(selectedDate);
  
  // Get current system time
  const currentTime = new Date();

  // Set the time of the selected date to the current system time
  date.setHours(currentTime.getHours());
  date.setMinutes(currentTime.getMinutes());
  date.setSeconds(currentTime.getSeconds());
  date.setMilliseconds(currentTime.getMilliseconds());

  // Extract and format the updated date and time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export const getFormatDateV2 = (dateString : string) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;
    
    return formattedDate;
  };

  export const getOrdinalSuffix = (number : number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100; // Handle cases like 11th, 12th, 13th
    if (value >= 11 && value <= 13) {
      return `${number}th`;
    }
    return `${number}${suffixes[(number % 10) < 4 ? number % 10 : 0]}`;
  }

  export const convertToISO = (dateString : string, timeString = "00:00:00")  => {
    const parsedDate = new Date(`${dateString} ${timeString} UTC`);

    // Convert to ISO string
    return parsedDate.toISOString().replace(".000", "");
  }

  export const formatDateToDDMMYYYY = (dateString : string) => {
    // Parse the input date string (e.g., "17 Jan 2024")
    const parsedDate = new Date(`${dateString} UTC`);
  
    // Extract day, month, and year
    const day = String(parsedDate.getUTCDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = parsedDate.getUTCFullYear();
  
    // Return formatted date
    return `${day}/${month}/${year}`;
  }

  export const  updateDateWithCurrentTime = (selectedDate: any) => {
    const selectedDateObj = new Date(selectedDate);  // Convert the selected date string into a Date object
    const currentTime = new Date();  // Get the current system time

    // Set the time of the selected date to the current system time
    selectedDateObj.setHours(currentTime.getHours());
    selectedDateObj.setMinutes(currentTime.getMinutes());
    selectedDateObj.setSeconds(currentTime.getSeconds());
    selectedDateObj.setMilliseconds(currentTime.getMilliseconds());

    // Return the updated date with current time, formatted in ISO 8601 format
    return selectedDateObj.toISOString();
}

export const getTicketServiceNumberText = (serviceNumber : number, alertCriteria: string, 
  totalMonthlyCriteria: number, totalKmCriteria: number) => {
    if(alertCriteria == null)  return 'NA';
    const serviceNumberText = getOrdinalSuffix(serviceNumber);
    let ticketServiceNumberText = serviceNumberText + ' | '
    if(alertCriteria  === "Time Based"){
      ticketServiceNumberText += totalMonthlyCriteria != null ? totalMonthlyCriteria + ' month' : 'NA';
    }
    else{
      ticketServiceNumberText += totalKmCriteria != null ? totalKmCriteria + ' km' : 'NA';
    }
    
    return ticketServiceNumberText;
}

 export const formatDateForTicket = (dateString:string) => {
  const date = new Date(dateString);

  // Get components of the date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return the formatted string in "yyyy-MM-dd HH:mm:ss" format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const getFormattedDateWithoutTime = (inputInstant: string) => {
  const instant = new Date(inputInstant);
  const istInstant = new Date(instant.getTime() + (5.5 * 60 * 60 * 1000));

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = istInstant.getUTCDate();
  const month = months[istInstant.getUTCMonth()];
  const year = istInstant.getUTCFullYear();

  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
}

export const getDaysCount = (date : string) => {
  const givenDate = new Date(date);
  const currentDate = new Date();
  const timeDifference =  givenDate.getTime() - currentDate.getTime();
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

export const getFormatedDateTimeWithoutChangingTime = (selectedDate: any) => {
  const date = new Date(selectedDate);
  
  // Get current system time
  const currentTime = new Date();

  // Set the time of the selected date to the current system time
  date.setHours(date.getHours());
  date.setMinutes(date.getMinutes());
  date.setSeconds(date.getSeconds());
  date.setMilliseconds(date.getMilliseconds());

  // Extract and format the updated date and time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const getFormattedDateToDisplayWithoutChangingTimeZone =  (inputInstant : any) => {
  const instant = new Date(inputInstant);
  const istInstant = new Date(instant.getTime());
  
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = istInstant.getUTCDate();
  const month = months[istInstant.getUTCMonth()];
  const year = istInstant.getUTCFullYear();
  
  const hours = istInstant.getUTCHours();
  const minutes = istInstant.getUTCMinutes();

  const period = hours >= 12 ? ' PM' : ' AM';
  const formattedHours = hours % 12 || 12;

  const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;

  const formattedDate = `${formattedTime}, ${day} ${month} ${year}`;
  return formattedDate;
}

export const getFormatedDateTimeWithChangingTimeToLocale = (selectedDate: any) => {
  const date = new Date(selectedDate);
  
  // Get current system time
  const currentTime = new Date();

  // Set the time of the selected date to the current system time
  date.setHours(date.getHours()-5);
  date.setMinutes(date.getMinutes()-30);
  date.setSeconds(date.getSeconds());
  date.setMilliseconds(date.getMilliseconds());

  // Extract and format the updated date and time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const getFormatedDateTimeWithDecreasedTime = (selectedDate: any) => {
  const date = new Date(selectedDate);
  
  date.setHours(date.getHours() - 5);
  date.setMinutes(date.getMinutes() - 30);
  

  // Extract and format the updated date and time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
