import { Button, Card, Col, DatePicker, Input, InputNumber, message, Modal, Row, Select, Space, Typography, Upload, Tooltip, Progress, Spin } from "antd";
import { Form } from "antd";
import { isArray } from "lodash";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { getRequest, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as EyeIcon } from '../../SVGs/eye.svg';
import { ReactComponent as DeleteIcon } from '../../SVGs/delete_icon.svg';
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import SelectSearch from "../../../../utils/SelectSearch";
import PreviewFileURL from "../../../../components/PreviewFileURL";
import { CheckOutlined, CloseOutlined, DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import ReadMoreText from "../../Client/ReadMoreText";
import currencyFormatter from "../../../../utils/NumberFormater";
import dayjs from "dayjs";
import axios from "axios";
import _ from "lodash";
import FilePreviewModal from "./FilePreviewModal";
import moment from "moment";

const btnAntIcon = <LoadingOutlined style={{ fontSize: 12, color: "white" }} spin />;
const fileAntIcon = <LoadingOutlined style={{ fontSize: 24, marginLeft: 6 }} spin />;

const { TextArea } = Input;
const { Option } = Select;

interface CreateClientQuoteProps {
    clientData: any;
    quoteData: any;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    files: any[];
    setFiles: (files: any[]) => void;
    isClientQuote?: boolean;
    quoteId?: number
}

const HARYANA_STATE: string = "Haryana";

const CreateClientQuote: React.FC<CreateClientQuoteProps> = ({ clientData, quoteData, showModal, setShowModal, files, setFiles, isClientQuote, quoteId }) => {
    const url: any = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loader, setLoader] = useState<boolean>(false);
    const [uploadingPercent, setUploadingPercent] = useState<number>(0);
    const [previewFile, setPreviewFile] = useState<any>(null);
    const [previewModal, setPreviewModal] = useState<any>(null);
    const [preview, setPreview] = useState<boolean>(false);
    const [fileType, setFileType] = useState<string>("");
    const [remarkFiles, setRemarkFiles] = useState<any>([]);
    const [previousRemarkFiles, setPreviousRemarkFiles] = useState<any>([]);
    const [fileUploadLoader, setFileUploadLoader] = useState<boolean>(false)
    const [gevlStates, setGEVLStates] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [editable, setEditable] = useState<boolean>(false);
    const [securityInput, setSecurityInput] = useState<boolean>(true);
    const [prospectDetails, setProspectDetails] = useState<any>();
    const sampleQuoteId = url.pathname.split("/").pop();
    const [additionalDetails, setAdditionalDetails] = useState<any>({});
    const [vts, setVts] = useState<boolean>(false);
    const auth = useAppSelector(authSelector);
    // const ownershipTypeList = [
    //     { value: 'Owned', label: 'Owned' },
    //     { value: 'Managed', label: 'Managed' },
    //     { value: 'Leased', label: 'Leased' },
    // ];
    const vtsList = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ];
    const paymentModeList = [
        { value: 'NACH Mandate', label: 'NACH Mandate' },
        { value: 'Direct Transfer', label: 'Direct Transfer' },
        { value: 'PDC', label: 'PDC' },
    ];
    const registeredToList = [
        { value: 'Lessor', label: 'Lessor' },
        { value: 'Lessee', label: 'Lessee' },
    ];
    const leaseTypeList = [
        { value: 'Wet', label: 'Wet' },
        { value: 'Dry', label: 'Dry' },
    ];
    // const billingFrequencyList = [
    //     { value: 'Monthly', label: 'Monthly' },
    //     { value: 'Quarterly', label: 'Quarterly' },
    //     { value: 'Half Yearly', label: 'Half Yearly' },
    //     { value: 'Yearly', label: 'Yearly' },
    // ];
    const insuranceTypeList = [
        { value: 'AYF', label: 'AYF' },
        { value: 'IYF', label: 'IYF' },
        { value: 'AYR', label: 'AYR' },
        { value: 'Not Managed', label: 'Not Managed' },
    ];
    const securityDepositValueTypeList = [
        { value: 'LR', label: 'LR' },
        { value: 'TIV', label: 'TIV' },
        { value: 'Fixed Value', label: 'Fixed Value' },
    ]
    const processingFeesValueTypeList = [
        { value: 'TIV', label: 'TIV' },
        { value: 'Fixed Value', label: 'Fixed Value' },
    ]

    const optionsToRender = (options: any[], identifier: any) => {
        if (isArray(options))
            return options?.map((value: any) => (
                <Option key={value.id} value={value[identifier]}>
                    {value?.[identifier]}
                </Option>
            ));
    };
    
    const handleAddFiles = async (file: any) => {
        setFileUploadLoader(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('prospectId', quoteData?.prospectId);
        formData.append('type', 'remark-doc');

        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadQuoteFile`;

        try {
            axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent: any) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadingPercent(percentCompleted);
                  },
            }).then(response => {
                if (response.status === 200) {
                    setRemarkFiles((prevState:any)=>{return [...prevState, {
                        name: file.name,
                        fileType: file.type,
                        url: response?.data?.DocumentUrl
                    }]});
                    setFileUploadLoader(false)
                }
                else {
                    setFileUploadLoader(false)
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            setFileUploadLoader(false)

            console.error('Upload error:', error);
        }
    }

    const handleRemoveFiles = (file: any, index: any) => {
        const fileList = [...remarkFiles];
        fileList.splice(index, 1)
        setRemarkFiles(fileList);
    }

    const handlePreviewFile = (file: any, index: any) => {
        console.log("fileType", previousRemarkFiles[index]?.url)
        setPreviewFile(remarkFiles[index]?.url || previousRemarkFiles[index]?.url);
        setFileType(remarkFiles[index]?.fileType || previousRemarkFiles[index]?.fileType)
        setPreview(true)
    }

    const handleCityList = () => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities`).then(res => {
                setCity(res.data)
            });
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    const handleStateList = () => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/states`).then(res => {
                setGEVLStates(res.data)
            });
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    const handleGEVLState = (e: any) => {
        const gevlStateId = city.filter((c: any) => c.cityName === e)[0]["stateCode"];
        const state = gevlStates.filter((state: any) => state?.stateCode === gevlStateId)
        console.log(state);

        if (!_.isEmpty(state)) {
            form.setFieldsValue({
                registrationState: state[0]["stateName"]
            })
        } else {
            form.setFieldsValue({
                registrationState: HARYANA_STATE
            })
        }
    }

    const error = (res: any) => Modal.confirm({
        title: <div>
            <img width={65} src={`${__dirname}assets/warning-icon.png`} />
            <div style={{fontWeight: 400, marginTop: 20}}>
                {res?.err?.response?.data?.errors[0]}
            </div>
        </div>,
        closable: true,
        centered: true,
        icon: null,
        bodyStyle: {
            textAlign: "center",
        },
        footer: false
    })

    const handleSubmit = async () => {
        try {
        await form.validateFields();
        setLoader(true);
        const payload = form.getFieldsValue(true);
        const manufacturingYrOnly = form.getFieldsValue(true)?.manufacturingYr ? new Date(form.getFieldsValue(true)?.manufacturingYr).getFullYear() : null;

        payload.insuranceRemarks = form.getFieldsValue(true)?.insurance?.remark;
        payload.insurance = form.getFieldsValue(true)?.insurance?.type;
        payload.vtsRemarks = form.getFieldsValue(true)?.vts?.remark;
        payload.vts = form.getFieldsValue(true)?.vts?.type;
        payload.numberOfVehicles = ~~form.getFieldsValue(true)?.numberOfVehicles;
        payload.batteryCapacity = ~~form.getFieldsValue(true)?.batteryCapacity;
        payload.securityDepositType = form.getFieldsValue(true)?.securityDepositValue?.type;
        payload.securityDepositValue = form.getFieldsValue(true)?.securityDepositValue?.value;
        payload.processingFeeType = form.getFieldsValue(true)?.processingFeesValue?.type;
        payload.processingFees = form.getFieldsValue(true)?.processingFeesValue?.value;
        payload.processingFeesValue = null,
        payload.manufacturingYr = manufacturingYrOnly,
        payload.roadTaxValidity = form.getFieldValue("roadTaxValidity"),
        payload.paymentDueDate = form.getFieldsValue(true)?.paymentDueDate;
        payload.color = form.getFieldsValue(true)?.color?.toUpperCase();
        payload.remarkFiles = remarkFiles;
        payload.vtsSubscriptionPeriod = form.getFieldValue("vtsSubscriptionPeriod");

        form.setFieldsValue({
            batteryCapacity: payload?.batteryCapacity,
            billingFrequency: payload?.billingFrequency,
            color: payload?.color?.toUpperCase(),
            leaseType: payload?.leaseType,
            numberOfVehicles: payload?.numberOfVehicles,
            ownershipType: payload?.ownershipType,
            paymentDueDate: payload?.paymentDueDate,
            paymentMode: payload?.paymentMode,
            // processingFees: payload?.processingFees,
            registeredTo: payload?.registeredTo,
            registrationCity: payload?.registrationCity,
            registrationState: payload?.registrationState,
            remarks: payload?.remarks,
            userCity: payload?.userCity,
            // securityDepositType: additionalDetails?.securityDepositType,
            isSecurityDeposit: payload?.isSecurityDeposit,
            vts: {
                type: payload?.vts,
                remark: payload?.vtsRemarks,
            },
            insurance: {
                type: payload?.insurance,
                remark: payload?.insuranceRemarks,
            },
            securityDepositValue: {
                type: payload?.securityDepositType,
                value: payload?.securityDepositValue,
            },
            processingFeesValue: {
                type: payload?.processingFeeType,
                value: payload?.processingFees,
            },
            // manufacturingYr : payload?.manufacturingYr,
            roadTaxValidity : payload?.roadTaxValidity,
            manufacturingYr : payload?.manufacturingYr ? 
                dayjs(`${payload?.manufacturingYr}-01-01`) : null,
            remarkFiles: remarkFiles
        })

        isClientQuote
            ? putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/additional_detail/${sampleQuoteId}`, payload).then(res => {
                message.destroy();
                if(res.status === 400) {
                    error(res);
                } else {
                    setShowModal(false);
                    setTimeout(() => window.location.reload(), 100)
                }
                setLoader(false);
            })
            : postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${quoteData?.quoteId}`, payload).then((res: any) => {
                message.destroy();
                if(res.status === 400) {
                    error(res);
                    setLoader(false);
                } else {
                    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirementQuoteMapping?requestId=${quoteData.requestId}&requirementId=${quoteData.requirementId}&quoteId=${quoteData?.quoteId}&statusChangeTypeId=${11}`, {}).then(e  => {
                        navigate(`/dashboard/client-based-quote/${res?.data?.clientQuoteId}`);
                        setLoader(false);
                        setShowModal(false);
                        setTimeout(() => window.location.reload(), 100)
                    })
                }
            });

        } catch (err) {
            setLoader(false);
            console.log("Create Client Quote Error: ", err);
        }
    }

    const handleUpdate = async () => {
        await form.validateFields();
        setEditable(true)
        setVts(additionalDetails?.vts === "Yes" ? true : false);
        setSecurityInput(additionalDetails?.isSecurityDeposit)
        const manufacturingYrDate = additionalDetails?.manufacturingYr ? 
            dayjs(`${additionalDetails?.manufacturingYr}-01-01`) : null;
        
        form.setFieldsValue({
            batteryCapacity: additionalDetails?.batteryCapacity,
            billingFrequency: additionalDetails?.billingFrequency,
            color: additionalDetails?.color?.toUpperCase(),
            leaseType: additionalDetails?.leaseType,
            numberOfVehicles: additionalDetails?.numberOfVehicles,
            ownershipType: additionalDetails?.ownershipType,
            paymentDueDate: additionalDetails?.paymentDueDate && additionalDetails?.paymentDueDate,
            paymentMode: additionalDetails?.paymentMode,
            // processingFees: additionalDetails?.processingFees,
            registeredTo: additionalDetails?.registeredTo,
            registrationCity: additionalDetails?.registrationCity,
            registrationState: additionalDetails?.registrationState,
            remarks: additionalDetails?.remarks,
            userCity: additionalDetails?.userCity,
            // securityDepositType: additionalDetails?.securityDepositType,
            isSecurityDeposit: additionalDetails?.isSecurityDeposit,
            vts: {
                type: additionalDetails?.vts,
                remark: additionalDetails?.vtsRemarks,
            },
            insurance: {
                type: additionalDetails?.insurance,
                remark: additionalDetails?.insuranceRemarks,
            },
            securityDepositValue: {
                type: additionalDetails?.securityDepositType,
                value: additionalDetails?.securityDepositValue,
            },
            processingFeesValue: {
                type: additionalDetails?.processingFeeType,
                value: additionalDetails?.processingFees,
            },
            manufacturingYr : manufacturingYrDate,
            roadTaxValidity : additionalDetails?.roadTaxValidity,
            vtsSubscriptionPeriod: additionalDetails?.vts === "Yes" ? additionalDetails?.vtsSubscriptionPeriod : null,
            remarkFiles: remarkFiles
        })
    }

    useEffect(() => {
        const manufacturingYrDate = clientData?.additionalQuoteDetails?.manufacturingYr ? 
            dayjs(`${clientData?.additionalQuoteDetails?.manufacturingYr}-01-01`) : null;
        quoteData && form.setFieldsValue({
            insurance: { type: quoteData?.insuranceType },
            billingFrequency: prospectDetails?.billingType,
            securityDepositValue: {
                type: clientData?.securityDepositType,
                value: clientData?.securityDepositCharges
            },
            processingFeesValue: {
                type: clientData?.additionalQuoteDetails?.processingFeeType,
                value: clientData?.additionalQuoteDetails?.processingFees,
            },
            manufacturingYr : manufacturingYrDate,
            roadTaxValidity : clientData?.additionalQuoteDetails?.roadTaxValidity,
            registeredTo: quoteData?.registeredTo,
            leaseType: quoteData?.leaseType,
            isSecurityDeposit: true,
        });

    }, [quoteData, clientData, showModal, prospectDetails])

    useEffect(() => {
        try {
            isClientQuote && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${sampleQuoteId}`).then(res => {
                setAdditionalDetails(res?.data?.additionalQuoteDetails);
                // setRemarkFiles(res?.data?.additionalQuoteDetails?.remarkFiles)
                setPreviousRemarkFiles(res?.data?.additionalQuoteDetails?.remarkFiles);
            })
        } catch(err) {
            console.log("Error: ", err);
        }
        
    }, [showModal]);

    useEffect(() => {
        document && document?.querySelector('.fileItem')?.scrollIntoView({behavior: "smooth"})
    }, [remarkFiles]);


    useEffect(() => {
        if(showModal){
            try {
                getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/states`).then(res => {
                    setGEVLStates(res.data)
                });
                !_.isEmpty(clientData) && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/searchClientByProspectId?prospectId=${clientData?.prospect?.prospectId || clientData?.prospectId}`).then(res => {
                    setProspectDetails(res.data);
                })
            } catch(err) {
                console.log("Error: ", err);
            }
        }
        
    }, [clientData, showModal]);

    return (<>
            <Modal
                title={<span style={{ fontWeight: 500 }}>{isClientQuote ? "Revise Client Quote" : "Enter the information to create client quote"}</span>}
                centered={false}
                open={showModal}
                closable={true}
                onCancel={() => { setShowModal(false); setEditable(false); form.resetFields(); }}
                width={"80%"}
                footer={(isClientQuote && !editable)
                    ? <Button ghost type="primary" className="button-override primary" icon={<TickIcon />} onClick={() => handleUpdate()}>Update Request</Button>
                    : <>
                        <Button danger ghost className="button-override danger" icon={<CrossIcon />} onClick={() => { setShowModal(false); setEditable(false); setEditable(false); form.resetFields(); }}>Cancel</Button>
                        <Button type="primary" className="button-override" icon={loader ? <Spin indicator={btnAntIcon} style={{color: "white", fontSize: 10}} />: <TickIcon />} onClick={() => !loader && handleSubmit()}>Proceed</Button>
                    </>}
            >

                {(isClientQuote && !editable)
                    ? <>
                        <Typography style={{ marginBottom: 20 }}>Revise the details before updating them.</Typography>
                        <Row style={{ display: 'flex', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                            {/* <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Ownership Type <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.ownershipType || "NA"}
                                </Typography>
                            </Col> */}
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Number of Vehicle <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.numberOfVehicles || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Color <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.color || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Battery Capacity</Typography>
                                <Typography>
                                    {additionalDetails?.batteryCapacity ? additionalDetails?.batteryCapacity + " kWh" : "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>VTS</Typography>
                                <Typography>
                                    {additionalDetails?.vts || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>VTS Remark</Typography>
                                <Typography>
                                    <ReadMoreText changed={false} text={additionalDetails?.vtsRemarks || "NA"} />
                                </Typography>
                            </Col>


                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>User City</Typography>
                                <Typography>
                                    {additionalDetails?.userCity || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Registration City <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.registrationCity || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Registration State <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.registrationState || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Payment Mode</Typography>
                                <Typography>
                                    {additionalDetails?.paymentMode || "NA"}
                                </Typography>
                            </Col>
                            {/* <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Billing Frequency <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.billingFrequency || "NA"}
                                </Typography>
                            </Col> */}
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Insurance <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.insurance || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Insurance Remarks</Typography>
                                <Typography>
                                    <ReadMoreText changed={false} text={additionalDetails?.insuranceRemarks || "NA"} />
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Payment Due Date <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.paymentDueDate || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Security Deposit Type <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.securityDepositType || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Security Deposit Value <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.securityDepositValue || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Processing Fees Type <span className="required-star"></span></Typography>
                                <Typography>
                                    {additionalDetails?.processingFeeType || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Processing Fees</Typography>
                                <Typography>
                                    {additionalDetails?.processingFees || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Manufacturing Year</Typography>
                                <Typography>
                                    {additionalDetails?.manufacturingYr || "NA"}
                                </Typography>
                            </Col>
                            <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Road Tax Validity Period</Typography>
                                <Typography>
                                    {additionalDetails?.roadTaxValidity || "NA"}
                                </Typography>
                            </Col>
                            { additionalDetails?.vts === "Yes" && <Col className="description-item" style={{ width: "25%" }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>VTS Subscription Period</Typography>
                                <Typography>
                                    {additionalDetails?.vtsSubscriptionPeriod || "NA"}
                                </Typography>
                            </Col>}
                            <Col>
                                <Typography style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Remarks <EyeIcon  onClick={_ => setPreviewModal(true)} style={{color: "#2c66e3", transform: "scale(0.9)"}} /></Typography>
                                <ReadMoreText changed={false} text={additionalDetails?.remarks || "NA"} />
                            </Col>
                        </Row>
                        
                    </>

                    : <div style={{ height: "60vh", overflow: "scroll" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: 20, backgroundColor: "#E8F1FF", borderRadius: 6, marginTop: 20 }}>
                            <div>
                                <div className="info-text-label">Approved Credit Limit</div>
                                <div className="info-text-data">₹ {currencyFormatter(prospectDetails?.creditLimit)}</div>
                            </div>
                            <div>
                                <div className="info-text-label">Utilized Credit Limit</div>
                                <div className="info-text-data">₹ {currencyFormatter(prospectDetails?.utilisedCreditLimit)}</div>
                            </div>
                            <div>
                                <div className="info-text-label">Approved no. of fleet</div>
                                <div className="info-text-data">{prospectDetails?.approvedNumberOfFleets || 0}</div>
                            </div>
                            <div>
                                <div className="info-text-label">Utilized no. of fleet</div>
                                <div className="info-text-data">{prospectDetails?.utilisedNumberOfFleets || 0}</div>
                            </div>
                            <div>
                                <div className="info-text-label">Credit Validity</div>
                                <div className="info-text-data">{prospectDetails?.approvedValidityTill ? dayjs(prospectDetails?.approvedValidityTill ).format("DD-MMM-YYYY") : "NA"}</div>
                            </div>
                        </div>

                        <Form
                            form={form}
                            layout="vertical"
                            // requiredMark={false}
                            style={{ marginTop: 10 }}
                            className="client-onboarding-form"
                        >
                            <Col>
                                <Row style={{ width: "100%" }}>
                                    {/* <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="ownershipType"
                                        label={<Typography className="info-text-label">Ownership Type</Typography>}
                                        rules={[{ required: true, message: 'Please enter ownership type!' }]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            className="select-input-override"
                                            placeholder="Select Ownership Type"
                                            options={ownershipTypeList}
                                        />
                                    </Form.Item> */}
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="numberOfVehicles"
                                        label={<Typography className="info-text-label">No. of Vehicles</Typography>}
                                        rules={[{ required: true, message: 'Please enter no. of vehicles!' }]}
                                    >
                                        <InputNumber
                                            placeholder="Enter No. of Vehicles"
                                            onChange={(value) => { }}
                                            className="input-number-override"
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="color"
                                        label={<Typography className="info-text-label">Color</Typography>}
                                        rules={[{ required: true, message: 'Please enter color!' }]}
                                    >
                                        <Input
                                            placeholder="Enter color of the car"
                                            onChange={(value) => { }}
                                            className="input-number-override"
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="batteryCapacity"
                                        label={<Typography className="info-text-label">Battery Capacity</Typography>}
                                        // rules={[{ required: true, message: 'Please enter battery capacity!' }]}
                                    >
                                        <Input
                                            placeholder="Enter Battery Capacity (in kWh)"
                                            onChange={(value) => { }}
                                            className="input-number-override"
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>

                                    <Form.Item label={<Typography className="info-text-label">VTS</Typography>} style={{ width: "33.33%", padding: "0 2px" }}>
                                        <Space.Compact style={{ width: "100%" }}>
                                            <Form.Item
                                                name={['vts', 'type']}
                                                noStyle
                                                // rules={[{ required: true, message: 'VTS is required' }]}
                                            >
                                                <Select
                                                    style={{ width: '40%' }}
                                                    className="double-override"
                                                    placeholder="Select VTS"
                                                    options={vtsList}
                                                    onChange={(e) => {setVts(e === "Yes" ? true : false); form.setFieldsValue({vtsSubscriptionPeriod: null})}}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name={['vts', 'remark']}
                                                noStyle
                                                // rules={[{ required: true, message: 'Remark is required' }]}
                                            >
                                                <Input style={{ width: '100%' }} className="double-override" placeholder="Enter Remark Here" />
                                            </Form.Item>
                                        </Space.Compact>
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="userCity"
                                        label={<Typography className="info-text-label">User City</Typography>}
                                        // rules={[{ required: true, message: 'Please enter user city!' }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            className="select-input-override"
                                            placeholder="Select User City"
                                            onClick={() => handleCityList()}
                                        >
                                            {optionsToRender(city, "cityName")}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="registrationCity"
                                        label={<Typography className="info-text-label">Registration City</Typography>}
                                        rules={[{ required: true, message: 'Please enter registration city!' }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            className="select-input-override"
                                            placeholder="Select Registration City"
                                            onClick={() => handleCityList()}
                                            onChange={e => handleGEVLState(e)}
                                        >
                                            {optionsToRender(city, "cityName")}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="registrationState"
                                        label={<Typography className="info-text-label">Registration State</Typography>}
                                        rules={[{ required: true, message: 'Please enter registration state!' }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            className="select-input-override"
                                            placeholder="Select Registration State"
                                            onClick={() => handleStateList()}
                                        >
                                            {optionsToRender(gevlStates, "stateName")}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="paymentMode"
                                        label={<Typography className="info-text-label">Payment Mode</Typography>}
                                        // rules={[{ required: true, message: 'Please enter payment mode!' }]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            className="select-input-override"
                                            placeholder="Select Payment Mode"
                                            options={paymentModeList}
                                        />
                                    </Form.Item>

                                    {/* <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="billingFrequency"
                                        label={<Typography className="info-text-label">Billing Frequency</Typography>}
                                        rules={[{ required: true, message: 'Please enter billing frequency!' }]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            className="select-input-override"
                                            placeholder="Select Billing Frequency"
                                            options={billingFrequencyList}
                                        />
                                    </Form.Item> */}

                                    <Form.Item label={<Typography className="info-text-label">Insurance</Typography>} style={{ width: "33.33%", padding: "0 2px" }} required={true}>
                                        <Space.Compact style={{ width: "100%" }}>
                                            <Form.Item
                                                name={['insurance', 'type']}
                                                noStyle
                                                rules={[{ required: true, message: 'Insurance type is required' }]}
                                            >
                                                <Select
                                                    disabled={true}
                                                    className="double-override"
                                                    style={{ width: '50%' }}
                                                    placeholder="Select Insurance"
                                                    options={insuranceTypeList}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name={['insurance', 'remark']}
                                                noStyle
                                                rules={[{ required: true, message: 'Remark is required' }]}
                                            >
                                                <Input style={{ width: '100%' }} className="double-override" placeholder="Enter Remark Here" />
                                            </Form.Item>
                                        </Space.Compact>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="paymentDueDate"
                                        label={<Typography className="info-text-label">Payment Due Date</Typography>}
                                        rules={[{ required: true, message: 'Please enter payment due date!' }]}
                                    >
                                        <Input style={{ width: '100%' }} className="input-override" placeholder="Enter Payment Due Date" />
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="isSecurityDeposit"
                                        label={<Typography className="info-text-label">Security Deposit</Typography>}
                                        rules={[{ required: true, message: 'Please enter security deposit!' }]}
                                    >
                                        <Select className="double-override" placeholder="Select Security Deposit" onChange={(e) => {setSecurityInput(e); form.setFieldsValue({securityDepositValue: {type: null, value: null}})}}>
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px", display: securityInput ? "block" : "none" }}
                                        label={<Typography className="info-text-label">Security Deposit Value</Typography>}
                                        rules={[{ required: true, message: 'Please enter security deposit value!' }]}
                                    >
                                        <Space.Compact style={{ width: "100%" }}>
                                            <Form.Item
                                                name={['securityDepositValue', 'type']}
                                                noStyle
                                                // rules={[{ required: true, message: 'Security deposit type is required' }]}
                                            >
                                                <Select
                                                    disabled={!securityInput}
                                                    style={{ width: '40%' }}
                                                    className="double-override"
                                                    placeholder="Select Deposit Value"
                                                    options={securityDepositValueTypeList}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name={['securityDepositValue', 'value']}
                                                noStyle
                                                // rules={[{ required: true, message: 'Security Deposit Value is required' }]}
                                            >
                                                <InputNumber
                                                    precision={2}
                                                    disabled={!securityInput}
                                                    style={{ width: '100%' }}
                                                    className="double-override"
                                                    placeholder="Enter Security Deposit Value"
                                                />
                                            </Form.Item>
                                        </Space.Compact>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        // name="processingFees"
                                        label={<Typography className="info-text-label">Processing Fees <span className="optional-label">(%)</span></Typography>}
                                        // rules={[{ required: true, message: 'Please enter processing fee!' }]}
                                    >
                                        <Space.Compact style={{ width: "100%" }}>
                                            <Form.Item
                                                name={['processingFeesValue', 'type']}
                                                noStyle
                                                // rules={[{ required: true, message: 'Security deposit type is required' }]}
                                            >
                                                <Select
                                                    disabled={!securityInput}
                                                    style={{ width: '40%' }}
                                                    className="double-override"
                                                    placeholder="Select Deposit Value"
                                                    options={processingFeesValueTypeList}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name={['processingFeesValue', 'value']}
                                                noStyle
                                                // rules={[{ required: true, message: 'Processing Fees is required' }]}
                                            >
                                                 <InputNumber
                                                    precision={2}
                                                    placeholder="Enter Processing Fees"
                                                    onChange={(value) => { }}
                                                    className="input-number-override"
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item>
                                        </Space.Compact>
                                       
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="manufacturingYr"
                                        label={<Typography className="info-text-label">Manufacturing Year</Typography>}
                                        rules={[{ required: true, message: 'Please enter Manufacturing Year!' }]}
                                    >
                                         <DatePicker picker="year" style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="roadTaxValidity"
                                        label={<Typography className="info-text-label">Road Tax Validity Period</Typography>}
                                        rules={[{ required: true, message: 'Please enter Road Tax Validity Period!' }]}
                                    >
                                        <Input className="input-override" style={{ width: "100%" }} />
                                    </Form.Item>





                                    { vts && <Form.Item
                                        style={{ width: "33.33%", padding: "0 2px" }}
                                        name="vtsSubscriptionPeriod"
                                        label={<Typography className="info-text-label">VTS Subscription Period</Typography>}
                                        rules={[{ required: true, message: 'Please enter VTS Subscription Period!' }]}
                                    >
                                        <Input className="input-override" style={{ width: "100%" }} />
                                    </Form.Item>}





                                    <Form.Item
                                        style={{ width: "100%", padding: "0 2px" }}
                                        name="remarks"
                                        label={<span style={{ display: "flex", justifyContent: "space-between", alignItems: "center", overflow: "visible", width: "100vw" }}>
                                            <Typography className="info-text-label">Remarks</Typography>
                                            <Upload
                                                fileList={[]}
                                                multiple={true}
                                                customRequest={(file) => {}}
                                                beforeUpload={(file) => {
                                                    handleAddFiles(file)
                                                    return;
                                                }}
                                            >
                                                <Button style={{ padding: 0 }} type="link" className="info-text-label">+ Add Files</Button>
                                            </Upload>
                                        </span>}
                                        // rules={[{ required: true, message: 'Please enter remark!' }]}
                                    >
                                        <TextArea
                                            placeholder="Enter remarks here"
                                            onChange={(value) => { }}
                                            className="input-number-override"
                                            style={{ width: "100%", height: 80, padding: "8px 5px" }}
                                        />
                                    </Form.Item>
                                </Row>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
                                    {fileUploadLoader && <><Spin indicator={fileAntIcon} /> <Typography>Uploading...</Typography></>}
                                </div>
                                {   remarkFiles.map((e: any, i: any) => {
                                        return <div className="fileItem" style={{ display: "flex", justifyContent: "space-between", border: "1px solid #f0f0f0", borderRadius: 8, padding: 10, lineHeight: 2.5, marginBottom: 5}}>
                                            <Tooltip title={e.name}>
                                                <p style={{width: "40%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{e.name}</p>
                                            </Tooltip>

                                            <div>
                                                <Button danger type="link" className="info-text-label" onClick={() => handleRemoveFiles(e, i)} style={{padding: 6}}><DeleteFilled /> Remove</Button>
                                                <Button type="link" className="info-text-label" onClick={() => handlePreviewFile(e, i)} style={{padding: 6}}><EyeIcon /> Preview</Button>
                                            </div>
                                        </div>
                                    })
                                }
                                {
                                    isClientQuote && previousRemarkFiles.map((e: any, i: any) => {
                                        return <div className="fileItem" style={{ display: "flex", justifyContent: "space-between", border: "1px solid #f0f0f0", borderRadius: 8, padding: 10, lineHeight: 2.5, marginBottom: 5}}>
                                            <Tooltip title={e.name}>
                                                <p style={{width: "40%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{e.name}</p>
                                            </Tooltip>
                                            <Button type="link" className="info-text-label" onClick={() => handlePreviewFile(e, i)}><EyeIcon /> Preview</Button>
                                        </div>
                                    })
                                    
                                }
                            </Col>
                        </Form>
                    </div>
                }
            </Modal>

            <PreviewFileURL fileType={fileType} url={previewFile} showModal={preview} setShowModal={setPreview}/>
            <FilePreviewModal data={clientData?.additionalQuoteDetails?.remarkFiles} showModal={previewModal} setShowModal={setPreviewModal} />
        </>
    );
}

export default CreateClientQuote;