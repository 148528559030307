import { Button, Card, DatePicker, Modal, message } from "antd";
import { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as ArrowLeft } from "./../../../../../utils/icons/arrow-left.svg";
import { ReactComponent as CheckWhite } from "./../../../../../utils/icons/CheckIconConfigurable.svg";
import dayjs from "dayjs";

interface ServiceScheduleTimeConfirmationModalProps{
    scheduleServiceFlag: number,
    setScheduleServiceFlag: (flag: number) => void,
    scheduleTime: Date | null,
    setScheduleTime: (date: Date | null) => void,
    handleScheduleServiceDateConfirm: () => void,
}

const ServiceScheduleTimeConfirmationModal: React.FC<ServiceScheduleTimeConfirmationModalProps> = ({ scheduleServiceFlag, setScheduleServiceFlag
        , scheduleTime, setScheduleTime, handleScheduleServiceDateConfirm }) => {
    
    return (
        <Modal 
            open={scheduleServiceFlag === 2} 
            onCancel={() => {
                    setScheduleServiceFlag(0);
                    setScheduleTime(null)
                }
            }
            width='30%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', marginTop:'1rem'}}>
                    <p style={{ fontWeight: '700', fontSize: '1rem', color:'#404040' }}>
                        Enter pickup scheduled date and time
                    </p>
                </div>
                <div style={{ display:'flex', flexDirection:'column', alignItems:'center', margin:'1rem 0rem', fontWeight: '500', fontSize: '0.875rem', color:'#333' }}>
                    <div style={{width:'100%'}}>
                    <DatePicker 
                        showTime format="YYYY-MM-DD hh:mm a" 
                        // onChange={(date: Date) => setScheduleTime(date.toISOString())} 
                        onChange={(date: Date) => setScheduleTime(date)} 
                        value = {scheduleTime}
                        style={{ width: '100%' }} 
                        />
                     </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', marginBottom:'0.8rem', height:'2.2rem' }}>
                    <Button style={{width:'45%', color:'#1777FF', borderColor: '#1777FF', backgroundColor: '#E0EDFF'}}
                        onClick={() => {
                                setScheduleServiceFlag(0);
                                setScheduleTime(null);
                            }
                        }>
                        <ArrowLeft style={{marginRight:'0.4rem'}} />
                        <span>
                            Go Back
                        </span>
                    </Button>

                    <Button style={{width:'45%', color: scheduleTime ? '#FFF' : '#AFAFAF', borderColor: scheduleTime ? '#2C66E3' : '#CCC', backgroundColor: scheduleTime ? '#2C66E3':'#CCC', height:'2.2rem'}}
                        onClick={()=> {
                            setScheduleServiceFlag(0);
                            handleScheduleServiceDateConfirm();
                        }}
                        disabled={!scheduleTime}
                        >
                        <CheckWhite style={{marginRight:'0.4rem'}} color = {scheduleTime ? '#fff': '#AFAFAF'}/>
                        <span>
                            Confirm 
                        </span>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ServiceScheduleTimeConfirmationModal;
