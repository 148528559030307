import { Card, Input, Typography, message } from "antd";
import { getFormattedDate } from "../../../../../utils/helpers/utils";
import { useState } from "react";
import { ReactComponent as CheckIcon } from "./../../../../../utils/icons/Check_Icon.svg";
import { ReactComponent as EmptyIcon } from "./../../../../../utils/icons/not_supported.svg";
import { postRequest } from "../../../../../utils/handler/apiHandler";
import { Empty } from 'antd';


interface VehicleDetailsRemarksProps {
    vehicleRamarksList: any[];
    vehicleId: number;
    setFetchTicketDataCnt: (cnt: number) => void;
    ticketStatusId: number,
}

const { Title } = Typography;

const VehicleDetailsRemarks: React.FC<VehicleDetailsRemarksProps> = ({
    vehicleRamarksList,
    vehicleId,
    setFetchTicketDataCnt,
    ticketStatusId,
}) => {
    const [showAddRemark, setShowAddRemark] = useState(false);
    const [newRemark, setNewRemark] = useState<string>("");
    const [loader, setLoader] = useState(false);
    const CANCELLED_ID = 11, SERVICE_RESOLVED_ID = 12;
    

    const saveRemark = async () => {
        if (!newRemark) {
            message.error("Please enter a valid Remark.");
            return;
        }

        try {
            setLoader(true);
            const encodedRemark = encodeURIComponent(newRemark);
            const response = await postRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/remark?vehicleId=${vehicleId}&remark=${encodedRemark}`,
                {}
            );

            setFetchTicketDataCnt(Math.random());
            if (response.status !== 201) {
                message.error(response.msg || "Failed to update odometer reading.");
            }
        } catch (error) {
            message.error("An error occurred while updating the odometer reading.");
        } finally {
            setLoader(false);
            setShowAddRemark(false);
            setNewRemark("");
        }
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "space-between",
                }}
            >
                <Title style={{ fontWeight: 700 }} level={5}>
                    Remarks
                </Title>
                {
                    !(ticketStatusId === CANCELLED_ID || ticketStatusId === SERVICE_RESOLVED_ID) &&
                    <div>
                    {showAddRemark ? (
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                saveRemark();
                            }}
                        >
                            <CheckIcon />
                            <span
                                style={{
                                    marginLeft: "7px",
                                    marginRight: "10px",
                                    color: "#2C66E3",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                }}
                            >
                                Save
                            </span>
                        </div>
                    ) : (
                        <span
                            style={{
                                marginLeft: "7px",
                                marginRight: "10px",
                                color: "#2C66E3",
                                fontSize: "12px",
                                fontWeight: "600",
                                cursor: "pointer",
                            }}
                            onClick={() => setShowAddRemark(true)}
                        >
                            + Add More
                        </span>
                    )}
                    </div>
                }
                
            </div>

            {showAddRemark && (
                <Card style={{ width: "100%", marginBottom: "0.6rem" }}>
                    <Title style={{ fontWeight: 600 }} level={5}>
                        Remarks
                    </Title>
                    <Input
                        style={{ minHeight: "4rem" }}
                        value={newRemark} 
                        placeholder="Enter Remarks"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNewRemark(e.target.value)
                        } 
                    />
                </Card>
            )}

            <Card style={{ width: "100%" }}>
                {
                    vehicleRamarksList && vehicleRamarksList.length === 0 ?
                    <div style={{ display:'flex', justifyContent:'center'}}>
                        <Empty/>
                        
                    </div> :
                    <div>
                        {vehicleRamarksList?.map((remark: any, index: number) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    marginTop: "0.6rem",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span style={{ fontWeight: "600", width:'65%' }}>
                                    {remark?.remark}
                                </span>
                                <span style={{ fontWeight: "500" }}>
                                    {`${getFormattedDate(remark?.auditInfo?.createdAt)}  ${"\u00A0|\u00A0"} ${JSON.parse(
                                        remark?.auditInfo?.createdBy
                                    )?.name}`}
                                </span>
                            </div>
                        ))}
                    </div>
                }
                
            </Card>
        </>
    );
};

export default VehicleDetailsRemarks;
