import { Card, Select, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../../../utils/handler/apiHandler";
import { useDebounce } from 'use-debounce';

interface AddIssuesProps {
    selectedIssues: any[],
    setSelectedIssues: (list: any[]) => void; // Explicitly typing the selected issues
    index: number; // New index prop to handle multiple AddIssues components
    ticketId: string;
}

export default function AddIssues({
    selectedIssues,
    setSelectedIssues,
    index,
    ticketId,
}: AddIssuesProps) {
    const [issuesList, setIssuesList] = useState<any[]>([]);
    const [subissuesList, setSubissuesList] = useState<any[]>([]);
    const [newIssue, setNewIssue] = useState<string>("");
    const [newSubIssue, setNewSubIssue] = useState<string>("");
    const [loader, setLoader] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // Holds the raw search term entered by user
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500); // Debounced search term to prevent excessive API calls
    
    useEffect(() => {
        const fetchIssues = async () => {
            try {
                setLoader(true);
                const response = await getRequest(
                    `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/issue`
                );
                if (response.status !== 200) {
                    message.error(response.msg || "Failed to fetch Issues list.");
                }
                setIssuesList(response?.data?.items);
            } catch (error) {
                message.error("An error occurred while fetching Issues list.");
            } finally {
                setLoader(false);
            }
        };
        fetchIssues();
    }, []);

    useEffect(() => {
        if (debouncedSearchTerm) {
            fetchIssuesForSearchedTerm(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    const fetchIssuesForSearchedTerm = async (search: string) => {
        if (search.trim().length < 1) {
            setIssuesList([]); // Clear options if search term is too short
            return;
        }

        setLoader(true); // Show loader while fetching
        try {
            const filter = encodeURIComponent(`name|like|${search}`);
            const response = await getRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/issue?filterAnd=${filter}`
            );
            setIssuesList(response?.data?.items || []); // Update options with response data
        } catch (error) {
            message.error('Failed to fetch Issues List based on search term.'); // Show error message on failure
        } finally {
            setLoader(false); // Hide loader
        }
    };

    const handleIssueSelection = (issueId: string) => {
        setNewIssue(issueId);
        setNewSubIssue('');
        fetchSubissueForIssueId(issueId);
        const updatedSelectedIssues = [...selectedIssues];
        updatedSelectedIssues[index] = { issueId: issueId, subIssueId: '' };
        setSelectedIssues(updatedSelectedIssues);
    };

    const fetchSubissueForIssueId = async (issueId: string) => {
        setLoader(true); // Show loader while fetching
        const filter = encodeURIComponent(`issue.id|eq|${issueId}`);
        try {
            const response = await getRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/sub-issue?filterAnd=${filter}`
            );
            setSubissuesList(response?.data?.items || []);
        } catch (error) {
            message.error('Failed to fetch subissues by id.'); // Show error message on failure
        } finally {
            setLoader(false); // Hide loader
        }
    };

    const handleSubissueSelection = (subissueId: string) => {
        setNewSubIssue(subissueId);
        // Pass the selected issue-subissue pair back to the parent, specifically for this `index`
        const updatedSelectedIssues = [...selectedIssues];
        updatedSelectedIssues[index] = { issueId: newIssue, subIssueId: subissueId };
        setSelectedIssues(updatedSelectedIssues);
    };

    return (
        <div style={{ width: "100%", marginBottom: "1.6rem", display: 'flex' }}>
            <div style={{ width: '20rem' }}>
                <Typography className="description-label" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Issue
                </Typography>
                <Select
                    style={{ width: '90%' }}
                    onChange={handleIssueSelection}
                    showSearch
                    loading={loader}
                    filterOption={false}
                    placeholder={'Select Issue'}
                    notFoundContent={loader ? 'Loading...' : null}
                    onSearch={(value) => setSearchTerm(value)} // Update searchTerm as the user types
                >
                    {issuesList &&
                        issuesList.map((issue) => (
                            <Select.Option key={issue.id} value={issue.id}>
                                {issue.name}
                            </Select.Option>
                        ))}
                </Select>
            </div>

            <div style={{ width: '100%' }}>
                <Typography className="description-label" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Sub - Issue
                </Typography>
                <Select
                    mode="multiple"
                    value={newSubIssue==='' ? null : newSubIssue }
                    style={{ width: '100%' }}
                    placeholder={'Select Subissue'}
                    onChange={handleSubissueSelection}
                >
                    {subissuesList &&
                        subissuesList.map((subissue) => (
                            <Select.Option key={subissue.id} value={subissue.id}>
                                {subissue.name}
                            </Select.Option>
                        ))}
                </Select>
            </div>
            
        </div>
    );
}
