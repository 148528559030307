import Icon, { LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select, Spin, Table, TablePaginationConfig, Tag, Tooltip, Typography } from "antd";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import { AlertListType as AlertListType, StatusCountData } from "./types/ServiceList";
import { useEffect, useRef, useState } from "react";
import { handlePageChange, handlePageSizeChange } from "../Prospect/utils/paginationFunctions";
import FilterDropdown from "../Client/FilterDropDown";
import UnionFilterIcon from "../SVGs/UnionFilterIcon";
import { getFormattedDate } from "../../../utils/helpers/utils";
import { getRequest } from "../../../utils/handler/apiHandler";
import { ReactComponent as DownloadIcon } from '../../../utils/icons/download_icon_white.svg';
import { ReactComponent as TaskIcon } from '../../Dashboard/SVGs/task_icon.svg';
import { ReactComponent as AlarmIcon } from '../../Dashboard/SVGs/alarm_icon.svg';
import { ReactComponent as ErrorIcon } from '../../Dashboard/SVGs/error_icon.svg';
import CreateTicketModal from "./CreateAlertTicketModal";
import axios from "axios";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";

const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
};

const statusCountDefaultData = 
    {
        "upcoming": 0,
        "ticketCreated": 0,
        "scheduled": 0,
        "jobCompleted": 0,
        "inServicing": 0,
        "skipped": 0,
        "ticketOverdue": 0,
        "due": 0,
        "cancelled": 0,
        "ticketMissing": 0,
        "dataInsufficient": 0,
        "serviceResolved": 0,
        "scheduledOverdue": 0
    }

const AlertList : React.FC = () => {
    const [pageSize, setPageSize] = useState<number>(10);
    const auth = useAppSelector(authSelector);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: AlertListType[] = [];
    const [data, setData] = useState<AlertListType[]>(defaultData);
    const [statusCountData, setStatusCountData] = useState<StatusCountData>(statusCountDefaultData);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(600);
    const [sortOrder, setSortOrder] = useState<string>(``); // Default sort
    const [assetList, setAssetList] = useState<any>([]);
    const [clientList, setClientList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const [alertTypeList, setAlertTypeList] = useState<any[]>([{ id: '1', name: 'Km Based' },{ id: '2', name: 'Time Based' }]);
    const [selectedAlertTypeList, setSelectedAlertTypeList] = useState<any[]>([]);
    const [statusList, setStatusList] = useState<any[]>([]);
    const [selectedStatusList, setSelectedStatusList] = useState<any[]>([]);
    const [showCreateTicketModal, setShowCreateTicketModal] = useState<boolean>(false);
    const [currentTicketId, setCurrentTicketId] = useState<number>(-1);
    const FILTER_ICON_COLOR = '#1777FF';

    const defaultSort = "serviceRequestDate|DESC"
    const ALERT_FILTER = "serviceStatus|in|Due,Upcoming&active|eqb|true"
    const defaultParam = `&sort=${encodeURIComponent(defaultSort)}&filterAnd=${encodeURIComponent(ALERT_FILTER)}`

    const filterIconAlertType = () => (
        <UnionFilterIcon fill={selectedAlertTypeList.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "10px" }} />
      );
    
    const filterIconStatus = () => (
        <UnionFilterIcon fill={selectedStatusList.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "10px" }} />
      );
  

    useEffect(() => {
        fetchAllAlertData();
    }, [currentPage, pageSize, sortOrder]);


    useEffect(() => {
        try{
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/status-count`;
            getRequest(getApi).then(res => {
                if(res.status === 200){
                    setStatusCountData(res.data);
                }else{
                    console.error('Error fetching Status Count Data:', res.msg);
                }
            })

        }catch(err){
            console.log("Error in fetching Status count Data : ", err);
        }finally {
            setLoading(false);
        }
        
    }, []);

    useEffect(() => {
        try{
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=ServiceStatus`;
            getRequest(getApi).then(res => {
                if(res.status === 200){
                    const filteredData = res.data.filter((item: any) => item.label === "Due" || item.label === "Upcoming");
                    setStatusList(filteredData);
                }else{
                    console.error('Error fetching Status List Data:', res.msg);
                }
            })

        }catch(err){
            console.log("Error in fetching Status List Data : ", err);
        }finally {
            setLoading(false);
        }
        
    }, []);

    const fetchAllAlertData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/full-details?page=${currentPage - 1}&size=${pageSize}${sortOrder ? sortOrder : defaultParam}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                setData(res.data.items);
                if ('totalPages' in res.data) {
                    setPageCount(res.data.totalPages);
                }
                if ('totalItems' in res.data) {
                    setTotalRecord(res.data.totalItems);
                }
            } else {
                console.error('Error fetching Alert List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Alert data:', error);
        } finally {
            setLoading(false);
        }
    };

    const downloadAlertData = async () => {
        try {
          const res = await axios.get(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/excel/alert`,
          {
              headers: {
                  "Authorization": `Bearer ${auth.accessToken}`
              },
              responseType: 'arraybuffer'
          }
          );
          const url =  window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Alert_Data.xlsx`);
          document.body.appendChild(link);
          link.addEventListener('click', () => {
              setTimeout(() => {
                  window.URL.revokeObjectURL(url);
              }, 100);
          });
          link.click();
          document.body.parentNode?.removeChild(link);
      } catch (error) {
          console.error('Error fetching AlertData:', error);
      }
      }


    const getColumns = (): ColumnsType<any> => [
        {
            title: "Asset ID",
            dataIndex: "assetNumber",
            key: "vehicleDetails.id",
            filters: assetList.map((option: any) => ({
                text: option.assetNumber,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle?page=${page}&size=10${name && `&filterAnd=${encodeURIComponent(`assetNumber|startwith|${name}`)}`}`}}
                    setBusinessNameOptions={setAssetList}
                    isKeyItems={true}
                />
            ),
            render: (text : number, record: any) => {
                return( <div className="table-text-style">{record?.vehicleDetails?.assetNumber}</div> )},
            // width: "7%",
        },
        {
            title: "Client Name",
            dataIndex: 'clientName',
            filters: clientList.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospectNames?page=${page-1}&size=10&name=${name}`}}
                    setBusinessNameOptions={setClientList}
                />
            ),
            key: 'prospect.prospectId',
            render: (text: string, record: any) => (
                    <a href={`/dashboard/prospect/${record?.prospect?.prospectId}`} className="table-text-style" style={{color : '#2C66E3', textDecoration : 'underline', width: 130, whiteSpace: 'wrap' }}>
                      {record?.prospect?.name}
                    </a>
                ),
            // width: '11%',
        },
        {
            title: "Vehicle Number",
            dataIndex: 'vehicleNumber',
            filters: vehicleList.map((option: any) => ({
                text: option.registrationNumber,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle?page=${page}&size=10${name && `&filterAnd=${encodeURIComponent(`registrationNumber|startwith|${name}`)}`}`}}
                    setBusinessNameOptions={setVehicleList}
                    isKeyItems={true}
                />
            ),
            key: 'vehicleNumber',
            render: (text : number, record: any) => {
                return( <div className="table-text-style">{record?.vehicleDetails?.registrationNumber}</div> )},
            // width: '9%',
        },
        {
            title: 'Alert Type',
            dataIndex: 'alertType',
            filters: alertTypeList.map((option: any) => ({
                text: option.name,
                value: option.name,
            })),
            filterIcon: filterIconAlertType,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    setBusinessNameOptions={() => {}}
                />
            ),


            render: (text : number, record: any) => {
              const alertType = record?.ticket?.serviceCriteriaType != null ? record?.ticket?.serviceCriteriaType : "NA";
              return( <div className="table-text-style">{alertType}</div> )},
            key: 'alertType',
            // width: '8%',
        },
        {
            title: "Location",
            dataIndex: 'location',
            filters: cityList.map((option: any) => ({
                text: option.cityName,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities_v2?page=${page-1}&size=10&name=${name}`}}
                    setBusinessNameOptions={setCityList}
                />
            ),
            key: 'location',
            render: (text : number, record: any) => {
                const city = record?.regCity?.cityName != null ? record?.regCity?.cityName : "NA";
                return( <div className="table-text-style">{city}</div> )},
            // width: '7%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: statusList.map((option: any) => ({
                text: option.label,
                value: option.label,
                color: option.color,
                bgColor: option.bgColor 
            })),
            filterIcon: filterIconStatus,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    setBusinessNameOptions={() => {}}
                />
            ),

            render: (text: string, record: any) => {
                const color = record?.status?.color;
                const backgroundColor = record?.status?.bgColor;
                  return(
                      <Tag style={{ color : color, backgroundColor : backgroundColor, border: "none", fontWeight: 500 }}>{record?.status?.label}</Tag>
                  );
              },
            key: 'status',
            // width: '8%',
        },
        {
            title: "Odometer Reading",
            dataIndex: "vehicleDetails.currentOdometerReading",
            key: "vehicleDetails.currentOdometerReading",
            sorter: { multiple: 2 },
            render: (text : number, record: any) => {
                const currentOdometerReading = record?.vehicleDetails?.currentOdometerReading != null ? `${record?.vehicleDetails?.currentOdometerReading} Km` : "NA";
                const lastUpdated = record?.vehicleDetails?.odometerUpdateDate != null ? `(Last Updated : ${getFormattedDate(record?.vehicleDetails?.odometerUpdateDate).split(',')[1]})` : "";
                return( <div className="table-text-style">{currentOdometerReading} <p style={{ color : 'rgba(97, 97, 97, 1)', fontFamily : 'roboto', fontWeight : 500, fontSize : '8px'}}>{lastUpdated}</p></div> )},
            // width: "10%",
        },
        {
            title: <>Next Service<br/> Criteria</>,
            dataIndex: "serviceDate",
            key: "serviceDate",
            // sorter: { multiple: 2 },
            render: (text : number, record: any) => {
                const kmLeft = record?.kmLeft != null ? `${record?.kmLeft} km left` : "";
                const serviceDate = record?.ticket?.serviceDate != null ? record?.ticket?.serviceDate  : "";
                const daysLeft = record?.daysLeft != null ?  `${record?.daysLeft} Days left` : "";
                return( <>
                    {
                        record?.kmLeft  &&
                        <div className="table-text-style">{kmLeft}</div> 
                    }
                    {
                        record?.ticket?.serviceDate  &&
                        <div className="table-text-style">{serviceDate} <p style={{ color : 'rgba(97, 97, 97, 1)', fontFamily : 'roboto', fontWeight : 500, fontSize : '8px'}}>{daysLeft}</p></div>
                    }
                    {
                        !record?.kmLeft && !record?.ticket?.serviceDate &&
                        <div className="table-text-style">NA</div> 
                    }
                </>)},

            // width: "10%",
        },
        {
            title: "Action",
            key: "action",
            fixed: "right",
            render: (text, record) => (
                <Tooltip title="Create Ticket">
                    <a onClick={() => {setCurrentTicketId(record?.ticket?.id); setShowCreateTicketModal(true); }}>
                      <span style={{color : 'rgba(44, 102, 227, 1)', fontFamily : 'roboto', fontWeight : 500, fontSize : '12px'}}><PlusOutlined/> Create Ticket</span>
                    </a>
                </Tooltip>
            ),
            // width: "8%",
        },
    ];

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<AlertListType> | SorterResult<AlertListType>[]
    ) => {
        let sortString = '';
        let filterString = "";
        let filterAndString = ALERT_FILTER;

        if (Array.isArray(sorter)) {
            // Handle multiple sorts
            sortString = sorter
                .filter(s => s.order) // Filter out columns without sorting
                .map(s => `${s.field}:${s.order === 'ascend' ? 'ASC' : 'DESC'}`) // Map each sorter to the format field:asc/desc
                .join(',');
        } else if (sorter.order) {
            // Handle single sort
            const sortField = sorter.field;
            const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
            sortString = `${sortField}|${sortOrder}`;
        }

        if (sortString && sortString !== "") {
            sortString = sortString
        }
        if (filters?.["vehicleDetails.id"]?.length > 0) {
            filterAndString = filterAndString +  `&vehicleDetails.id|in|${filters?.["vehicleDetails.id"]}`;
        }
        if(filters?.["prospect.prospectId"]?.length > 0){
            filterAndString = filterAndString + `&prospect.prospectId|in|${filters?.["prospect.prospectId"]}`;
        }
        if(filters?.["vehicleNumber"]?.length > 0){
            filterAndString = filterAndString + `&vehicleDetails.id|in|${filters?.["vehicleNumber"]}`;
        }
        if(filters?.["location"]?.length > 0){
            filterAndString = filterAndString + `&vehicleDetails.registrationCity.id|in|${filters?.["location"]}`;
        }
        if(filters?.["alertType"]?.length > 0){
            filterAndString = filterAndString + `&serviceCriteriaType|in|${filters?.["alertType"]}`;
        }
        if(filters?.["status"]?.length > 0){
            filterAndString = filterAndString + `&serviceStatus|in|${filters?.["status"]}`;
        }

        filterString = filterString + `&filterAnd=` + encodeURIComponent(filterAndString);
        if(sortString === ""){
            sortString = defaultSort;
        }
        sortString = "&sort=" + encodeURIComponent(`${sortString}`);
        
        // Set the sort order string for the API call
        setSortOrder(sortString + filterString);
    };

        return (
            <div className="parent-container">
                <div className="container-layout" style={{paddingTop: 16}}>
                    <Row justify="space-between" align="top" style={{ marginBottom: 20 }}>
                        <Col>
                            <Typography style={{ fontSize: 20, fontWeight: 700 }}>Alerts</Typography>
                        </Col>
                        <Col>
                        <Button
                            type="primary"
                            icon={<Icon component={DownloadIcon} />}
                            onClick={(_) => {downloadAlertData();}}
                            style={{ fontFamily : 'roboto',fontWeight: 450, fontSize : '11px',  marginLeft: 10, height: 40}}
                        >
                            Download Data
                        </Button>
                        </Col>
                    </Row>

                    <div style={{display : 'flex', justifyContent : 'space-between', alignItems : 'stretch', gap : '1rem', marginBottom : "25px", paddingLeft : "5px", paddingRight : "5px"}}>
                        <div style={{ display : 'flex', justifyContent : 'space-between', alignItems : 'center', height : '60px', flex : 1, backgroundColor : 'rgba(184, 223, 27, 0.3)', border: '1px solid rgba(142, 177, 0, 1)', borderRadius : '8px', padding : '1rem', textAlign : 'center'}}>
                            <div style={{display : 'flex', alignItems : 'center'}}><TaskIcon/> <div style={{marginLeft : '8px',color : 'rgba(97, 97, 97, 1)',fontFamily : 'roboto', fontWeight : 500, fontSize : '12px', lineHeight : '14px'}}>All Alerts</div> </div>
                            <div style={{color : 'rgba(33, 33, 33, 1)', width : '23px', fontFamily : 'roboto', fontWeight : 600, fontSize : '20px'}}>{statusCountData?.due + statusCountData?.upcoming}</div>
                        </div>
                        <div style={{display : 'flex', justifyContent : 'space-between', alignItems : 'center', height : '60px', flex : 1, backgroundColor : 'rgba(135, 44, 227, 0.1)', border: '1px solid rgba(135, 44, 227, 1)', borderRadius : '8px',  padding : '1rem', textAlign : 'center'}}>
                            <div style={{display : 'flex', alignItems : 'center'}}><AlarmIcon/> <div style={{marginLeft : '8px',color : 'rgba(97, 97, 97, 1)',fontFamily : 'roboto', fontWeight : 500, fontSize : '12px', lineHeight : '14px'}}>Upcoming Services</div> </div>
                            <div style={{color : 'rgba(33, 33, 33, 1)', width : '23px', fontFamily : 'roboto', fontWeight : 600, fontSize : '20px'}}>{statusCountData?.upcoming}</div>
                        </div>
                        <div style={{display : 'flex', justifyContent : 'space-between', alignItems : 'center', height : '60px', flex : 1, backgroundColor : 'rgba(0, 153, 173, 0.1)', border: '1px solid rgba(0, 153, 173, 1)', borderRadius : '8px',  padding : '1rem', textAlign : 'center'}}>
                            <div style={{display : 'flex', alignItems : 'center'}}><ErrorIcon/> <div style={{marginLeft : '8px',color : 'rgba(97, 97, 97, 1)',fontFamily : 'roboto', fontWeight : 500, fontSize : '12px', lineHeight : '14px'}}>Due Services</div> </div>
                            <div style={{color : 'rgba(33, 33, 33, 1)', width : '23px', fontFamily : 'roboto', fontWeight : 600, fontSize : '20px'}}>{statusCountData?.due}</div>
                        </div>
                    </div>
    
                    <div  className="parent-container">
                    <div  className="container-body" style={{ backgroundColor: '#F6FAFF', position : 'relative' }}>
                        <div ref={tableRef} style={{ height: 0.85 * tableHeight }}>
                            <Spin spinning={loading} size="large">
                                <Table
                                    className="lev-table"
                                    columns={getColumns()}
                                    dataSource={data || []}
                                    pagination={{
                                        pageSize: pageSize,
                                        position: ["bottomRight"],
                                        showSizeChanger: false,
                                        showTotal: (total, range) => (
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '-4px' }}>
                                                <div style={{ fontSize: '1rem' }}>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>Showing</span>
                                                    <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                                        <Select.Option value="10">10</Select.Option>
                                                        <Select.Option value="20">20</Select.Option>
                                                        <Select.Option value="50">50</Select.Option>
                                                        <Select.Option value="100">100</Select.Option>
                                                    </Select>
                                                    <span style={{ fontSize: 15, fontWeight: 400 }}>per page</span>
                                                    <span style={{ fontSize: 15, fontWeight: 400, marginRight: '10px', marginLeft: '10px', color: 'grey' }}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                                                </div>
                                            </div>
                                        ),
                                        onChange: handlePageChangeLocal,
                                        current: currentPage,
                                        total: totalRecord,
                                        itemRender: (current, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <LeftOutlined style={{ marginLeft: '4rem', fontSize: 12, fontWeight: 500 }} />;
                                            }
                                            if (type === 'next') {
                                                return <RightOutlined style={{ fontSize: 12, fontWeight: 500 }} />;
                                            }
                                            if (type === 'page') {
                                                return (
                                                    <span style={{ cursor: 'pointer', fontSize: 12, fontWeight: 500 }} onClick={() => handlePageChangeLocal(current)}>
                                                        {current}
                                                    </span>
                                                );
                                            }
                                            if (type === 'jump-next' || type === 'jump-prev') {
                                                return <a style={{ color: 'black', fontSize: 12, fontWeight: 500 }}><strong> ... </strong></a>;
                                            }
                                            return originalElement;
                                        },
                                    }}
                                    onChange={handleTableChange} // Handle sort
                                    // scroll={{ x: 1700, y: 0.80 * tableHeight }}
                                    size="small"
                                // components={{
                                //     header: {
                                //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                                //     },
                                //   }}
                                />
                            </Spin>
                        </div>
                    </div>
                    </div>
                    
                </div>
                <Modal width={900} footer={null}
                    centered style={{ textAlign: "center" }}
                    styles={{
                        content: {
                            background: "#F6FAFF",
                        },
                        body: {
                            background: "#F6FAFF",
                            margin: 0,
                            padding: 0
                        }
                    }}
                    onCancel={() => setShowCreateTicketModal(false)}
                    closable={true}
                    destroyOnClose
                    open={showCreateTicketModal}
                >
                    <CreateTicketModal setShowCreateTicketModal={setShowCreateTicketModal}  loader = {false} ticketId={currentTicketId}/>
                </Modal>
            </div>

        );
}

export default AlertList;