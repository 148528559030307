import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Select, DatePicker, InputNumber, Radio, Checkbox, Tooltip } from "antd";
import TagsInput from "../../../../components/TagInput";
import { titleCase } from "../../../../utils/helpers/titleCase";
import dayjs, { Dayjs } from "dayjs";
import { getRequest } from "../../../../utils/handler/apiHandler";
import moment from "moment";
// import numWords from "num-words";
import numToWords from "../../../../utils/helpers/numToWords"

type RangeValue = [Dayjs | null, Dayjs | null] | null;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const options = [
    {
        id: 1,
        status: "Approved",
        color: "green"
    },
    {
        id: 2,
        status: "Reject",
        color: "red"
    },
    {
        id: 3,
        status: "Pending",
        color: "orange"
    }
];

function Step1(props: any) {
    const form = props.form;
    const [creditStatus, setCreditStatus] = useState<any>();
    const [disabled, setDisabled] = useState(true);
    const [dates, setDates] = useState<RangeValue>(null);
    const [value, setValue] = useState<RangeValue>(null);
    const [amount, setAmount] = useState<any>(form.getFieldsValue(true).approvedAmount);
    const [creditLimit, setCreditLimit] = useState<any>(form.getFieldsValue(true).creditLimit);
    const optionsToRender = (options: any[], identifier: any) => {
        return options?.map((value: any) => (
            <Option key={value.id} value={value?.[identifier]} style={{color: value.color}}>
            {value?.[identifier]}
            </Option>
        ));
    };

    useEffect(() => {
        setDisabled(props.form.getFieldsValue(true).creditStatus === "Approved" ? false : true);
    }, [props]);

    useEffect(() => {
        form.getFieldsValue().creditStatus == "Approved" ? setDisabled(false) : setDisabled(true);
    }, [creditStatus]);

    const disabledDate = (current: Dayjs) => {
        if (!dates) {
          return false;
        }
        const validFrom = dates[0] && current.diff(dates[0], 'days') > 365;
        const validTill = dates[1] && dates[1].diff(current, 'days') >= 365;
        return !!validFrom || !!validTill;
      };
      const onOpenChange = (open: boolean) => {
        if (open) {
          setDates([null, null]);
        } else {
          setDates(null);
        }
      };

    return ( 
        <React.Fragment>
            <Row className="">
                <Col className="form-column">
                    <Form.Item
                        label="Request Date"
                        name="requestDate"
                        rules={[{ required: true, message: 'Please enter request date!' }]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Brand Name"
                        name="legalBusinessName"
                        rules={[{ required: true, message: 'Please enter Brand name!' }]}
                    >
                        <Input placeholder="Brand Name" />
                    </Form.Item>
                    <Form.Item
                        label="Approved Fleet Number"
                        name="approvedFleetNumber"
                        rules={!disabled ? [{ required: true, message: 'Please enter approved fleet number!' }] : []}
                    >
                        <InputNumber disabled={disabled} style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Approved Fleet Number" />
                    </Form.Item>

                    <Tooltip title={titleCase(numToWords(amount))} placement="bottomLeft">
                    <Form.Item
                        label="Approved Amount"
                        name="approvedAmount"
                        rules={!disabled ? [{ required: true, message: 'Please enter department!' }] : []}
                    >
                        <InputNumber disabled={disabled} style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Approved Amount" onChange={(e: any) => setAmount(e)} />
                    </Form.Item>
                    </Tooltip>
                    
                    <Form.Item
                        label="Validity"
                        name="validity"
                        rules={!disabled ? [{ required: true, message: 'Please enter valid date range!' }] : []}
                    >
                        <RangePicker 
                            disabled={disabled}
                            placeholder={["Valid From", "Valid Till"]}
                            value={dates || value}
                            disabledDate={disabledDate}
                            format="YYYY-MM-DD"
                            onChange={() => console.log(form.getFieldsValue(true).validTill)}
                            onCalendarChange={(val) => {
                                setDates(val);
                            }}
                            onOpenChange={onOpenChange}
                            changeOnBlur
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                </Col>
                <Col className="form-column">
                    <Form.Item
                        label="Entity Name"
                        name="entityName"
                        rules={!disabled ? [{ required: true, message: 'Please enter entity name!' }] : []}
                    >
                        <Input placeholder="Entity Name" />
                    </Form.Item>
                    <Form.Item
                        label="Credit Status"
                        name="creditStatus"
                        rules={[{ required: true, message: 'Please enter credit status!' }]}
                    >
                        <Select
                            showSearch={true}
                            style={{ width: '100%' }}
                            placeholder="Credit Status"
                            onChange={(value: any) => { setCreditStatus(value);  form.setFieldsValue({ creditStatus: value })}}
                            disabled={form.getFieldsValue().creditStatus == "Approved" ? true : false}
                        >
                            {optionsToRender(options, "status")}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Credit Approved Date"
                        name="creditApprovedDate"
                        rules={!disabled ? [{ required: true, message: 'Please enter credit approved date!' }] : []}
                    >
                        <DatePicker
                            disabled={disabled}
                            format="YYYY-MM-DD"
                            style={{ width: "100%"}}
                        />
                    </Form.Item>
                    <Tooltip title={titleCase(numToWords(creditLimit))} placement="bottomLeft">
                    <Form.Item
                        label="Credit Limit"
                        name="creditLimit"
                        rules={!disabled ? [{ required: true, message: 'Please enter credit limit!' }] : []}
                    >
                        <InputNumber disabled={disabled} style={{width: "100%"}} type="number" onWheel={event => event.currentTarget.blur()} placeholder="Credit Limit"  onChange={(e: any) => setCreditLimit(e)} />
                    </Form.Item>
                    </Tooltip>
                    <Form.Item
                        label="Credit Condition Imposed"
                        name="creditCondition"
                    >
                        <TextArea placeholder="Credit Condition Imposed" />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
     );
}

export default Step1;