import { Col, Form, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import ChangedLabel from "../../IssueDetails/Utils/ChangedLabel";

interface ShowBasicDetailsHistoryDetailsProps{
    basicDetails: any,
    vehicleBasicDetailsData: any,
}

const ShowBasicDetailsHistoryDetails: React.FC<ShowBasicDetailsHistoryDetailsProps> = ({basicDetails
  ,vehicleBasicDetailsData})=>{
    const [form] = Form.useForm();
    const [formattedBasicDetails, setFormattedBasicDetails] = useState<any> ({});
    useEffect(()=>{
        setFormattedBasicDetails({
            vehicleNo: vehicleBasicDetailsData?.vehicleNo,
            assetNo: vehicleBasicDetailsData?.assetNo,
            category: vehicleBasicDetailsData?.category,
            make: vehicleBasicDetailsData?.make,
            model: vehicleBasicDetailsData?.model,
            variant: vehicleBasicDetailsData?.variant,
            odometerReading: basicDetails?.currentOdometerReading,
            invoicingDate: vehicleBasicDetailsData?.invoicingDate,

            odometerReadingMod: basicDetails?.currentOdometerReadingMod,
        })
    },[]);
    
    return (
      <div >
        <div style={{display:'flex', flexDirection:'column'}}>
            <hr style={{ border: '0.01rem solid rgba(175, 175, 175, 1)', borderWidth: '1px' }} />
            <span style={{fontWeight:'700', fontSize:'16px', color:'#242424', padding:'1rem 1.5rem 1rem 1.5rem'}}>Basic Details</span>
        </div>
        <Form
          name="dynamic_form_nest_item"
          form={form}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          style={{padding:'0rem 1.5rem 1rem 1.5rem'}}
        >
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col className="detail-description-item">
                <Typography className="description-label">
                    Vehicle No.
                </Typography>
                <Typography style={{ fontSize: 14}}>
                  <span >
                      {formattedBasicDetails?.vehicleNo}
                  </span>
                </Typography>
            </Col>

            <Col className="detail-description-item">
              <Typography className="description-label">
                Asset No. 
              </Typography>
              <Typography  style={{ fontSize: 14 }}>
                  { formattedBasicDetails?.assetNo}
              </Typography>
            </Col>

            <Col className="detail-description-item">
              <Typography className="description-label">
                Category 
              </Typography>
                <Typography  style={{ fontSize: 14 }}>
                  {formattedBasicDetails?.category}
                </Typography>
            </Col>

            <Col className="detail-description-item">
              <Typography className="description-label">
                Make 
              </Typography>
                <Typography  style={{ fontSize: 14 }}>
                  {formattedBasicDetails?.make}
                </Typography>
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col className="detail-description-item">
                <Typography className="description-label">
                    Model
                </Typography>
                <Typography style={{ fontSize: 14}}>
                  <span >
                      {formattedBasicDetails?.model}
                  </span>
                </Typography>
            </Col>

            <Col className="detail-description-item">
              <Typography className="description-label">
                  Variant
              </Typography>
              <Typography  style={{ fontSize: 14 }}>
                  { formattedBasicDetails?.variant}
              </Typography>
            </Col>

            <Col className="detail-description-item">
            <Typography className="description-label">
                Odometer Reading
              {formattedBasicDetails?.odometerReadingMod && <ChangedLabel />}
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                color: formattedBasicDetails?.odometerReadingMod
                  ? "#FFA800"
                  : "rgba(64, 64, 64, 1)",
              }}
            >
              {formattedBasicDetails?.odometerReading ? formattedBasicDetails?.odometerReading : 'NA'}
            </Typography>
          </Col>

          <Col className="detail-description-item">
            <Typography className="description-label">
              Invoicing Date 
            </Typography>
              <Typography  style={{ fontSize: 14 }}>
                {formattedBasicDetails?.invoicingDate}
              </Typography>
          </Col>
          </Row>
  
        </Form>
      </div>
    );
}
export default ShowBasicDetailsHistoryDetails;