import { useState } from "react";
import QuotePriceDetails from "./QuoteDetailsForm";
import { Button, Card, Divider, Form, Progress, Row, Select, Tabs, Typography } from "antd";
import { ReactComponent as EditIcon } from "../../SVGs/edit_icon.svg";
import { useNavigate } from "react-router-dom";
import AdditionalQuoteDetails from "./AdditionalQuoteDetails";

interface CompleteQuoteDetailsProps {
    isPayments: boolean;
    isClientQuote: boolean;
    additionalDetailsLog: any;
    clientData: any;
    form: any;
    loader: boolean;
    quoteData : any;
    allowedActionsForSampleQuote: Set<string>;
}

const TabPane = Tabs.TabPane;
const { Title } = Typography;
const RUPEE_SYMBOL = "₹ ";

const CompleteQuoteDetailsSection: React.FC<CompleteQuoteDetailsProps> = ({ isPayments, isClientQuote, additionalDetailsLog, clientData, form, loader, quoteData, allowedActionsForSampleQuote }) => {
    const [editable, setEditable] = useState(false);
    const [disable, setDisable] = useState(false);

    console.log("quoteData", quoteData);
    return ( 
        <div>
        <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Quote Details</Title>
            <Card bordered={false} style={{marginTop: 20}}>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                <div className="requirement-card-info">
                <Typography className="description-label">Vehicle Type</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.vehicleType
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Vehicle Category</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.vehicle).name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Make</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.make).name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Model</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.model).name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Variant</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.vehicle && JSON.parse(quoteData?.variant).name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Buying City</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.buyingCityDetail && JSON.parse(quoteData?.buyingCityDetail).name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">GEVL State</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.gevlStateDetail && JSON.parse(quoteData?.gevlStateDetail).name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Registered To</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.registeredTo
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Registration Type</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.registrationType
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Payment Type</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.paymentType
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Insurance Type</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.insuranceType
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Road Tax Type</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.roadTaxType
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">No of Vehicles</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.vehicleCount || "NA"
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Tenure in Month</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.tenureInMonthDetail && JSON.parse(quoteData.tenureInMonthDetail)?.name
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Mileage per month(KM)</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.mileagePerMonth
                    }</span>
                </Typography>
                </div>
                <div className="requirement-card-info">
                <Typography className="description-label">Lease Type</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : quoteData?.leaseType || "NA"
                    }</span>
                </Typography>
                </div>
                {
                    quoteData?.prospectRequirementRemark && <div className="requirement-card-info" style={{width: "100%"}}>
                    <Typography className="description-label">Prospect Requirement Remark</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.prospectRequirementRemark
                        }</span>
                    </Typography>
                    </div>
                }
            </div>
            </Card>   

            
            {(isClientQuote || isPayments) && <AdditionalQuoteDetails additionalDetailsLog={additionalDetailsLog} clientData={clientData} />}

            {
                !editable &&
                <>
                <div style={{display: 'flex', marginTop: 30}}>
                <Title style={{fontWeight: 700}} level={5}>Ownership & Billing Details</Title>
                <div style={{marginLeft : 'auto', alignContent :'flex-end'}}>
                    {
                        ((!((isClientQuote ? quoteData?.quoteStatus?.id >= 12 : quoteData?.quoteStatus === "Client Quote Created")) && allowedActionsForSampleQuote?.has('editCompletedQuote')) && !isPayments) &&
                        <Button
                            type="primary"
                            ghost
                            icon={<EditIcon />}
                            disabled={disable}
                            onClick={(_) => {
                            setEditable(true);
                            }}
                            className={
                            disable
                                ? "button-override disable"
                                : "button-override primary"
                            }
                            style={{display: editable ? "none" : "" }}
                        >
                            Edit
                        </Button>
                    }
                    </div>
                </div>

                <Card bordered={false} style={{marginTop: 20}}>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Ownership Type</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.ownershipType 
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Billing Frequency</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.billingFrequency 
                        }</span>
                    </Typography>
                    </div>
                </div>
                </Card>

            <Title style={{fontWeight: 700}} level={5}>Price Details</Title>
            <Card bordered={false} style={{marginTop: 20}}>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Ex-showroom Price</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.exShowroom 
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Base Price</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.basePrice
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">GST @ {quoteData?.gstPercentOnVehicle}%</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.gstOnVehicle
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Fame Subsidy</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.fameSubsidy
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Discount</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.discount
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Road Tax</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.roadTaxTotal
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">Insurance Amount</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.insuranceAmountTotal
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">First year insurance premium</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.projectedPremium && JSON.parse(quoteData?.projectedPremium)["1"] ? RUPEE_SYMBOL + JSON.parse(quoteData?.projectedPremium)["1"] : RUPEE_SYMBOL + 0
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width: '25%'}}>
                    <Typography className="description-label">State Subsidy</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.stateSubsidy
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '75%'}}>
                    <Typography className="description-label">Fabrication Amount</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : <div style={{ width: '50%', display : 'flex', justifyContent : 'space-between'}}><span>{RUPEE_SYMBOL + (quoteData?.fabricationAmountTotal == null ? 0 : quoteData?.fabricationAmountTotal)}</span>{"GST : " + (quoteData?.fabricationGst == null ? 0 : quoteData?.fabricationGst) + '%'}<span></span><span>{"Base Amount : " + RUPEE_SYMBOL + (quoteData?.fabricationBaseAmountTotal == null ? 0 : quoteData?.fabricationBaseAmountTotal)}</span></div>
                        }</span>
                    </Typography>
                    </div>


                    {
                        quoteData?.accessories && JSON.parse(quoteData?.accessories).length !==0 &&
                            <div style={{width: "100%"}}>
                                <Divider orientation="left" orientationMargin={0} style={{margin: "0 0 18px 0"}} ><span style={{fontSize : 14, fontWeight : 'bold'}}>Accessories</span></Divider>
                                <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography className="description-label" style={{width :'25%'}}>Accessories</Typography>
                                    <Typography className="description-label" style={{width :'25%'}}>Price</Typography>
                                    <Typography className="description-label" style={{width :'25%'}}>GST</Typography>
                                    <Typography className="description-label" style={{width :'25%'}}>Base Amount</Typography>
                                </Row>
                                {JSON.parse(quoteData?.accessories).map((data:any) => (
                                    <Row key={data.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{data.name }</Typography>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{RUPEE_SYMBOL + (data.totalAmount == null ? 0 : data.totalAmount)}</Typography>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{data.gst == null ? 0 : data.gst}%</Typography>
                                            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'25%'}}>{RUPEE_SYMBOL + (data.baseAmount == null ? 0 : data.baseAmount)}</Typography>
                                    </Row>
                                ))}
                            </div>
                    }
                </div>
            </Card>

            <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Service Details</Title>

            <Card bordered={false} style={{marginTop: 20}}>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <div className="requirement-card-info" style={{width : '25%'}}>
                    <Typography className="description-label">Management Fees</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.managementFees 
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '25%'}}>
                    <Typography className="description-label">TCS</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.tcs
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '25%'}}>
                    <Typography className="description-label">RV%</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.rvPercentage+ "%"
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '25%'}}>
                    <Typography className="description-label">Cost of Fund%</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.costOfFundPercentage+"%"
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '25%'}}>
                    <Typography className="description-label">Margin Rate%</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : quoteData?.offerRatePercentage+"%"
                        }</span>
                    </Typography>
                    </div>
                    <div className="requirement-card-info" style={{width : '25%'}}>
                    <Typography className="description-label">Excess Mileage Charge (Per KM)</Typography>
                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                        <span style={{display: loader?"none":""}}>{
                            loader ? "" : RUPEE_SYMBOL + quoteData?.excessMileageChargePerKm
                        }</span>
                    </Typography>
                    </div>

                    {
                        quoteData?.services && JSON.parse(quoteData?.services).length !==0 &&
                            <div style={{width: "100%"}}>
                                <Divider orientation="left" orientationMargin={0} style={{margin: "0 0 18px 0"}} ><span style={{fontSize : 14, fontWeight : 'bold'}}>Service</span></Divider>
                                <div style={{width: "50%"}}>
                                    <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography className="description-label" style={{width :'50%'}}>Service</Typography>
                                        <Typography className="description-label" style={{width :'50%'}}>Price</Typography>
                                    </Row>
                                        {JSON.parse(quoteData?.services).map((data:any) => (
                                            <Row key={data.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'50%'}}>{data.name}</Typography>
                                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15, width :'50%'}}>{ data.name === "Replacement Vehicle" || data.name === "RMTY" ? RUPEE_SYMBOL + (data.value == null ? 0 : data.value) : RUPEE_SYMBOL+ (data.inMonth == null ? 0 : data.inMonth)}</Typography>
                                            </Row>
                                        ))}
                                </div>
                            </div>
                    }
                </div>
            </Card>
            {
                quoteData?.quoteRemarks &&
                <div>
                    <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Quote Remark</Title>
                        <Card bordered={false} style={{marginTop: 20}}>
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                <div className="requirement-card-info" style={{width : '100%'}}>
                                <Typography style={{fontSize: 14, minHeight: 15, marginTop : -15}}>
                                            {quoteData?.quoteRemarks}
                                </Typography>
                                </div>
                            </div>
                        </Card>
                </div>
            }
            </>
            }
            {
                editable && 
                <QuotePriceDetails isClientQuote={isClientQuote} clientData={clientData} details={quoteData} loader={loader} form={form} data={quoteData} 
                isEdit={true} setEditable = {setEditable} allowedActionsForSampleQuote={allowedActionsForSampleQuote}/>
            }
        </div>
     );
}

export default CompleteQuoteDetailsSection;