import Icon from "@ant-design/icons";
import { Button, Col, Modal, Row, Tabs, Tooltip, Typography } from "antd";
import { ReactComponent as AddIcon } from '../../Dashboard/SVGs/add_icon.svg';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AllTicketList from "./AllTicketList";
import { ReactComponent as OverdueIcon } from '../../Dashboard/SVGs/overdue.svg';
import { ReactComponent as CreatedIcon } from '../../Dashboard/SVGs/created_icon.svg';
import { ReactComponent as ScheduledIcon } from '../../Dashboard/SVGs/scheduled_icon.svg';
import { ReactComponent as InServicingIcon } from '../../Dashboard/SVGs/inservicing_icon.svg';
import { ReactComponent as JobCompletedIcon } from '../../Dashboard/SVGs/job_completed_icon.svg';
import { ReactComponent as ServiceResolvedIcon } from '../../Dashboard/SVGs/service_resolved_icon.svg';
import { ReactComponent as CalenderIcon } from '../../Dashboard/SVGs/calender.svg';
import { getRequest } from "../../../utils/handler/apiHandler";
import { StatusCountData } from "./types/ServiceList";
import CreateManualTicketModal from "./CreateManualTicket";
import RangePickerModal from "./RangePickerModal";
import { convertToISO } from "../../../utils/helpers/utils";
import InServiceTicketList from "./InServiceTicketList";
import OverdueTicketList from "./OverdueTicketList";

const TabPane = Tabs.TabPane;
const statusCountDefaultData = 
    {
        "upcoming": 0,
        "ticketCreated": 0,
        "scheduled": 0,
        "jobCompleted": 0,
        "inServicing": 0,
        "skipped": 0,
        "ticketOverdue": 0,
        "due": 0,
        "cancelled": 0,
        "ticketMissing": 0,
        "dataInsufficient": 0,
        "serviceResolved": 0,
        "scheduledOverdue": 0
    }

const TicketPageLayout: React.FC  = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialTab = query.get('tab');
  
    // State to manage the active tab
    const [activeKey, setActiveKey] = useState(initialTab || '1');
    const [statusCountData, setStatusCountData] = useState<StatusCountData>(statusCountDefaultData);
    const [showCreateTicketModal, setShowCreateTicketModal] = useState<boolean>(false);
    const [showRangePickerModal, setShowRangePickerModal] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [showVehicleDetailPage, setShowVehicleDetailPage] = useState<boolean>(false);

    const maintenanceScopeList: any[] = [
        {
            bgColor : "rgba(0, 152, 173, 0.31)",
            color :     "rgba(0, 104, 118, 1)",
            id  : 1,
            label : "Gensol",
        },
        {
            bgColor : "rgba(227, 0, 218, 0.1)",
            color :     "rgba(227, 0, 218, 1)",
            id  : 2,
            label : "Client",
        },
    ];

    useEffect(() => {
        if (initialTab) {
          setActiveKey(initialTab);
        }
    }, [initialTab]);

    useEffect(() => {
        try{
            let getApi;
            if(dateRange != null){
                getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/status-count?startDate=${convertToISO(dateRange[0]?.startDate)}&endDate=${convertToISO(dateRange[0]?.endDate)}`;
            }else{
                getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/status-count`;
            }
            
            getRequest(getApi).then(res => {
                if(res.status === 200){
                    setStatusCountData(res.data);
                }else{
                    console.error('Error fetching Status Count Data:', res.msg);
                }
            })

        }catch(err){
            console.log("Error in fetching Status count Data : ", err);
        }finally {
        }
        
    }, [dateRange]);
    
    return(
        <React.Fragment>
            <div style={{marginTop : '20px'}}>
                    <Row justify="space-between" align="top" style={{ marginLeft:'30px' , marginRight: '30px', marginBottom: 20 }}>
                        <Col>
                            <Typography style={{ fontSize: 20, fontWeight: 700 }}>Tickets</Typography>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                icon={<Icon component={AddIcon} />}
                                onClick={() => {setShowCreateTicketModal(true);}}
                                style={{ fontWeight: 450, marginLeft: 10, height: 35}}
                                >
                                    Create Ticket
                            </Button>
                        </Col>
                    </Row>

                    <Row gutter={10} style={{margin: "0 30px"}}>
                        <Col span={4}> 
                        <div style={{ backgroundColor : 'rgba(0, 153, 173, 0.1)', border: '1px solid rgba(0, 153, 173, 1)', borderRadius : '8px', padding: 8}}>
                            <div style={{display: "flex", alignItems: "center", gap: 8}}>
                                <div>
                                    <CreatedIcon/>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div>
                                        <Typography style={{ fontSize: 12, fontWeight: 700, color: "grey" }}>Ticket Created</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontSize: 18, fontWeight: 500 }}>{statusCountData?.ticketCreated}</Typography>
                                    </div>
                                </div>
                            </div>             
                            </div>
                        </Col>
                        
                        <Col span={4}> 
                        <div style={{ backgroundColor : 'rgba(222, 241, 228, 1)', border: '1px solid rgba(20, 190, 67, 1)', borderRadius : '8px', padding: 8}}>
                            <div style={{display: "flex", alignItems: "center", gap: 8}}>
                                <div>
                                    <ScheduledIcon/>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div>
                                        <Typography style={{ fontSize: 12, fontWeight: 700, color: "grey" }}>Scheduled</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontSize: 18, fontWeight: 500 }}>{statusCountData?.scheduled}</Typography>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Col>

                        <Col span={4}> 
                        <div style={{ backgroundColor : 'rgba(252, 245, 234, 1)', border: '1px solid rgba(255, 168, 0, 1)', borderRadius : '8px', padding: 8}}>
                            <div style={{display: "flex", alignItems: "center", gap: 8}}>
                                <div>
                                    <InServicingIcon/>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div>
                                        <Typography style={{ fontSize: 12, fontWeight: 700, color: "grey" }}>In Servicing</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontSize: 18, fontWeight: 500 }}>{statusCountData?.inServicing}</Typography>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Col>

                        <Col span={4}> 
                        <div style={{ backgroundColor : 'rgba(135, 44, 227, 0.1)', border: '1px solid rgba(135, 44, 227, 1)', borderRadius : '8px', padding: 8}}>
                            <div style={{display: "flex", alignItems: "center", gap: 8}}>
                                <div>
                                    <JobCompletedIcon/>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div>
                                        <Typography style={{ fontSize: 12, fontWeight: 700, color: "grey" }}>Job Completed</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontSize: 18, fontWeight: 500 }}>{statusCountData?.jobCompleted}</Typography>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Col>

                        <Col span={4}> 
                        <div style={{ backgroundColor : 'rgba(184, 223, 27, 0.3)', border: '1px solid rgba(142, 177, 0, 1)', borderRadius : '8px', padding: 8}}>
                            <div style={{display: "flex", alignItems: "center", gap: 8}}>
                                <div>
                                    <ServiceResolvedIcon/>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div>
                                        <Typography style={{ fontSize: 12, fontWeight: 700, color: "grey" }}>Service Resolved</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontSize: 18, fontWeight: 500 }}>{statusCountData?.serviceResolved}</Typography>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Tooltip placement="bottom" title={<>Ticket Overdue: {statusCountData?.ticketOverdue} <br /> Scheduled Overdue: {statusCountData?.scheduledOverdue}</>}>
                            <Col span={4}> 
                            <div style={{ backgroundColor : 'rgba(255, 77, 79, 0.1)', border: '1px solid rgba(255, 77, 79, 1)', borderRadius : '8px', padding: 8}}>
                                <div style={{display: "flex", alignItems: "center", gap: 8}}>
                                    <div>
                                        <OverdueIcon/>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div>
                                            <Typography style={{ fontSize: 12, fontWeight: 700, color: "grey" }}>Overdue</Typography>
                                        </div>
                                        <div>
                                            <Typography style={{ fontSize: 18, fontWeight: 500 }}>{statusCountData?.ticketOverdue + statusCountData?.scheduledOverdue}</Typography>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Tooltip>
                    </Row>

                <div className="container-body" style={{position : 'relative',marginLeft : '30px', marginRight : '30px'}}>
                    {activeKey === '1' && <div onClick={() => {setShowRangePickerModal(true);}} style={{display: 'flex', zIndex : 999, justifyContent : 'space-between', position : 'absolute', cursor :'pointer', right : 20, top : 14}}><CalenderIcon/> <div style={{marginLeft:7, color : 'rgba(44, 102, 227, 1)',fontFamily : 'roboto', fontWeight : 500, fontSize : '12px',}}>{dateRange != null ? `${dateRange[0]?.startDate} - ${dateRange[0]?.endDate}` : "Date Range"}</div></div> }
                  <Tabs size="small" activeKey={activeKey} onChange={setActiveKey} className="tab" onTabClick={(e) => navigate(`?tab=${e}`)}>
                    <TabPane tab={<span style={{fontSize: 14, fontWeight: "bold"}}>All Tickets</span>} key="1">
                        <AllTicketList dateRange={dateRange} maintenanceScopeList={maintenanceScopeList}/>
                    </TabPane>
                    <TabPane tab={<span style={{fontSize: 14, fontWeight: "bold"}}>In Servicing</span>} key="2">
                        <InServiceTicketList maintenanceScopeList={maintenanceScopeList}/>
                    </TabPane>
                    <TabPane tab={<span style={{fontSize: 14, fontWeight: "bold"}}>Overdue</span>} key="3">
                        <OverdueTicketList maintenanceScopeList={maintenanceScopeList}/>
                    </TabPane>
                  </Tabs>
                </div>
                <Modal 
                    width={!showVehicleDetailPage ? 420 : 900}
                    footer={null}
                    centered style={{ textAlign: "center" }}
                    styles={{
                        content: {
                            background: "#F6FAFF",
                        },
                        body: {
                            background: "#F6FAFF",
                            margin: 0,
                            padding: 0
                        }
                    }}
                    onCancel={() => {setShowCreateTicketModal(false); setShowVehicleDetailPage(false);}}
                    closable={true}
                    destroyOnClose
                    open={showCreateTicketModal}
                >
                    <CreateManualTicketModal setShowCreateTicketModal={setShowCreateTicketModal} showVehicleDetailPage = {showVehicleDetailPage} setShowVehicleDetailPage={setShowVehicleDetailPage} loader = {false}/>
                </Modal>
                <RangePickerModal title="Select Start & End Date" showModal={showRangePickerModal} setShowModal={setShowRangePickerModal}  dateRange={dateRange} setDateRange={setDateRange} date={date} setDate={setDate} />
            </div>
        </React.Fragment>
    )
}

export default TicketPageLayout;