import React from "react";

const ChangedLabel: React.FC = () => {
  return (
    <span
      style={{
        marginLeft: "0.6rem",
        fontSize: "12px",
        fontWeight: "500",
        color: "rgba(255, 168, 0, 1)", // Constant color
        backgroundColor: "rgba(255, 243, 224, 1)", // Constant background color
        borderRadius: "0.25rem",
        padding: "0.2rem 0.5rem 0.2rem 0.5rem",
      }}
    >
      {"Changed"} {/* Constant text */}
    </span>
  );
};

export default ChangedLabel;
