import { Button, Card, Modal, Spin, Table, Typography, message } from "antd";
import { ReactComponent as DownloadSheetIcon } from '../../Prospect/utils/SVGs/DownloadSheet.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import * as ExcelJS from 'exceljs';
import { useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
const RUPEE_SYMBOL = "₹ ";

interface ModalProps {
    isClientQuote: boolean;
    clientData: any;
    data: any;
    requestData: any;
    showModal: boolean;
    formData: any;
    isEdit : boolean;
    setShowModal: (showModal: boolean) => void;
    allowedActionsForSampleQuote: Set<string>;
}

const FinalQuoteModal: React.FC<ModalProps> = ({ isClientQuote, clientData, data, requestData, showModal, formData, isEdit, 
        setShowModal, allowedActionsForSampleQuote }) => {
    const url: any = useLocation().pathname.split("/").pop();
    const excelLink = useRef<HTMLAnchorElement | null>(null);
    const clientQuoteId = useLocation().pathname.split("/").pop();
    const [createQuoteLoader, setCreateQuoteLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    
    const finalQuoteCalculation = [
        ['Base Price', 'Vehicle Base Price', data?.basePrice, ''],
        ['GST @5%', 'GST on Vehicle', data?.gstOnVehicle, ''],
        ['Ex Showroom', 'Vehicle Price', data?.exShowroom, ''],
        ['Fame Subsidy', 'Subsidy', data?.fameSubsidy, ''],
        ['State Subsidy', '', data?.stateSubsidy, ''],
        ['TCS', '', data?.tcs, ''],
        ['Discount (Ex Showroom)', 'Discount', data.discount, ''],
        [data.roadTaxType, 'Total EMI for Road Tax with Fastag', data?.roadTaxTotal, data?.roadTaxInMonth],
        ['Insurance', 'Total EMI for Insurance', data?.insuranceAmountTotal, data?.insuranceAmountInMonth],
        ['Fabrication', 'Fabrication', data?.fabricationBaseAmountTotal, data?.fabricationBaseAmountInMonth],
    ];
    const totalCharges = [
        ['Management Fees', 'Management Fees', '', data?.managementFeeInMonth],
        ['GST on Services', 'FMGST', '', data?.gstOnServicesInMonth],
        ['Fund Blockage Charges', 'FBC', '', data?.fbcInMonth],
        ['GST on FBC', '', '', data?.gstOnFbcInMonth],
    ];
    const totalCalculation = [
        ['Total Lease Rental(without tax)', 'Base Lease Rental', '', data?.leaseRentWithoutTaxInMonth],
        ['Fleet Management', 'Services and Management Fees', '', data?.fleetManagementInMonth],
        ['Total Taxes', 'GST(Finance + Services + FBC)', '', data?.totalTaxInMonth],
        ['Total Lease Rental(with tax)', 'Total EMI', '', data?.leaseRentWithTaxInMonth],
    ];
    const tenure = !isEdit ? JSON.parse(requestData?.tenure)["name"] : JSON.parse(requestData?.tenureInMonthDetail)["name"];
    const mileage = !isEdit ? JSON.parse(requestData?.mileage) : JSON.parse(requestData?.mileagePerMonth);
    const requirementData = [
        ['Prospect Name', requestData?.prospectName, 'IRR', (formData?.costOfFund + formData?.marginRate) + '%'],
        ['Vehicle Details', `${JSON.parse(requestData?.make)["name"]} - ${JSON.parse(requestData?.model)["name"]}, ${JSON.parse(requestData?.variant)["name"]}`],
        ['Tenure / Contracted Mileage', `${tenure} Months / ${mileage*parseInt(tenure)} KM`],
    ]

    let accessoriesList: any[] = [];
    if(data.accessories) {
        accessoriesList = [...data.accessories];
    }

    let serviceList: any[] = [];
    if(data.services) {
        serviceList = [...data.services];
    }

    const handleQuoteSubmit = async () => {
        setCreateQuoteLoader(true);
        try {
            const stateDetail = !isEdit ? requestData.state : requestData.gevlStateDetail;
            const buyingCity = !isEdit ? requestData.buyingCity: requestData.buyingCityDetail;
            const tenureDetail = !isEdit ? requestData.tenure: requestData.tenureInMonthDetail;
            const mileage = !isEdit ? requestData.mileage: requestData.mileagePerMonth;
            const date = !isEdit ?  new Date(requestData.pickedOn) : new Date();
            let payload = {...requestData, ...data};

            payload["accessories"] = JSON.stringify(payload["accessories"]);
            payload["gevlStateDetail"] = payload["state"];
            payload["otherAccessoriesDescription"] = formData.otherAccessoriesDescription;
            payload["gevlStateDetail"] = stateDetail;
            payload["gevlStateId"] = JSON.parse(stateDetail).id;
            payload["buyingCityDetail"] = buyingCity;
            payload["buyingCityName"] = JSON.parse(buyingCity).name;
            payload["buyingCityId"] = JSON.parse(buyingCity).id;
            payload["baseAccessoriesAmount"] = payload["accessoriesAmountTotal"];
            payload["accessoriesAmountTotal"] = payload["accessoriesIncludingGst"];
            payload["costOfFundPercentage"] = payload["costOfFund"];
            payload["firstYearInsurancePremium"] = payload["projectedPremium"]["1"];
            payload["excessMileageChargePerKm"] = formData.excessMileageCharge;
            payload["makeId"] = JSON.parse(payload["make"]).id;
            payload["managementFees"] = payload["managementFeeInMonth"];
            payload["mileagePerMonth"] = mileage;
            payload["modelId"] = JSON.parse(payload["model"]).id;
            payload["offerRatePercentage"] = payload["offerRate"];
            payload["projectedPremium"] = JSON.stringify(payload["projectedPremium"]);
            payload["services"] = JSON.stringify(payload["services"]);
            payload["tenureInMonthDetail"] = tenureDetail;
            payload["tenureInMonth"] = JSON.parse(tenureDetail).name;
            payload["tenureInMonthId"] = JSON.parse(tenureDetail).id;
            payload["variantId"] = JSON.parse(payload["variant"]).id;
            payload["vehicleId"] = JSON.parse(payload["vehicle"]).id;
            payload["requestId"] =  parseInt(url);
            payload["pickedOn"] = date.toISOString();
            payload["quoteRemarks"] = formData.quoteRemarks ? formData.quoteRemarks : null;
            payload["parentQuoteId"] = !isEdit ? null : requestData.quoteId;

            delete payload["state"];
            delete payload["tenure"];
            delete payload["statusBackgroundColor"];
            delete payload["statusTextColor"];
            delete payload["quoteId"];
            delete payload["quoteStatus"];
            delete payload["quoteStatusId"];
            delete payload["requirementNumber"];
            delete payload["mileage"];
            delete payload["createdBy"];
            delete payload["createdAt"];
            delete payload["buyingCity"];
            delete payload["proformaDocId"];
            delete payload["otherServicesDescription"];
            delete payload["additionalQuoteDetails"];
            delete payload["clientFileDetails"];

            isClientQuote
            ? putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${clientQuoteId}?statusId=${11}`, payload).then(res => {
                setTimeout(() => window.location.reload(), 1000);
            })
            : postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote`, payload).then(res => {
                message.destroy();
                if(res.status === 201){
                    if(!isEdit){
                        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirementQuoteMapping?requestId=${url}&requirementId=${requestData.requirementId}&quoteId=${res.data}&statusChangeTypeId=${5}`, {}).then(e  => {
                            setCreateQuoteLoader(false);
                            message.success("Quote created successfully.")
                            navigate(`?quoteId=${res.data}`);
                            setTimeout(() => window.location.reload(), 2000);
                        })
                    }else{
                        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/updateRequirementQuoteMapping?requestId=${url}&requirementId=${requestData.requirementId}&quoteId=${res.data}&requirementLogId=${requestData.requirementLogId}&prospectName=${requestData.prospectName}`, {}).then(e  => {
                            setCreateQuoteLoader(false);
                            message.success("Quote created successfully.")
                            navigate(`?quoteId=${res.data}`);
                            setTimeout(() => window.location.reload(), 1000);
                        })
                    }
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    const handleSheetDownload = async () => {
        const buyingCity = !isEdit ? JSON.parse(requestData.buyingCity).name : JSON.parse(requestData.buyingCityDetail).name;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Quote');
    
        worksheet.addRow(['Quote No. ', 'Sample Quote', 'RV',data.rvPercentage+'%']).font = { bold: true };
        worksheet.addRow(requirementData[0]).font = { bold: true };
        worksheet.addRow(['City', buyingCity]).font = { bold : true};
        worksheet.addRow(requirementData[1]).font = { bold: true };
        worksheet.addRow(requirementData[2]).font = { bold: true };
        worksheet.addRow(requirementData[3]).font = { bold: true };
        // worksheet.addRow([]); 
        worksheet.addRow(['Component', 'Description', 'Total', 'Monthly']).font = { bold: true };
    
        // Add data
        finalQuoteCalculation.forEach((item: any, ind: any) => {
            if(item[2] !== null ){
                worksheet.addRow([item[0], item[1], item[2] === "" ? item[2] : RUPEE_SYMBOL + formatRupee(item[2]), item[3] === "" ? item[3] : RUPEE_SYMBOL + formatRupee(item[3])]);
            }
        });

        accessoriesList.forEach((item: any, ind: any) => {
            if(item.baseAmount != null){
                worksheet.addRow([item.name, "Accessory", item.baseAmount === "" ? item.baseAmount : RUPEE_SYMBOL + formatRupee(item.baseAmount), item.inMonth === "" ? item.inMonth : RUPEE_SYMBOL + formatRupee(item.inMonth)]);
            }
         });

        
        serviceList.forEach((item: any, ind: any) => {
            if(item.value != null){
                worksheet.addRow([item.name, "Service", item.value === "" ? item.value : RUPEE_SYMBOL + formatRupee(item.value), item.inMonth === "" ? item.inMonth : RUPEE_SYMBOL + formatRupee(item.inMonth)]);
            }
         });

        totalCharges.forEach((item: any, ind: any) => {
            worksheet.addRow([item[0], item[1], item[2] === "" ? item[2] : RUPEE_SYMBOL + formatRupee(item[2]), item[3] === "" ? item[3] : RUPEE_SYMBOL + formatRupee(item[3])]);
         });

        totalCalculation.forEach((item: any, ind: any) => {
            const row = worksheet.addRow([item[0], item[1], item[2] === "" ? item[2] : RUPEE_SYMBOL + formatRupee(item[2]), item[3] === "" ? item[3] : RUPEE_SYMBOL + formatRupee(item[3])]);
            // Make the third column (index 2, 0-based index) bold
          
            if(ind===totalCalculation.length-2){
                worksheet.addRow([]);
            }
            else if(ind===totalCalculation.length-1){
                row.font = {bold : true};
            }
        });
        
        // Set column widths
        worksheet.columns = [
          { width: 30 },
          { width: 30 },
          { width: 15 },
          { width: 12 }
        ];

    
        // Create a Blob from the ExcelJS workbook
        const blob = await workbook.xlsx.writeBuffer();
        const url = window.URL.createObjectURL(new Blob([blob]));
    
        // Trigger download
        if (excelLink.current) {
          excelLink.current.href = url;
          excelLink.current.download = `${requestData.prospectName}.xlsx`;
          excelLink.current.click();
        }
    }

    const formatRupee = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 20 }).format(amount);
    };

    return ( 
        <Modal 
            title={<div>
                <Typography style={{fontSize: 16, fontWeight: 500}}>Review Component</Typography>
                <Typography style={{fontSize: 14, fontWeight: 400, margin: "10px 0"}}>Review the details before finalizing sample quotes</Typography>
            </div>}
            centered
            open={showModal}
            afterClose={() => { setCreateQuoteLoader (false); }}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            footer={<>
                <div className="edit-section">
                    {
                        allowedActionsForSampleQuote?.has('downloadSheet') &&
                        <Button
                            ghost
                            type="primary"
                            icon={<DownloadSheetIcon />}
                            onClick={_=> handleSheetDownload()}
                            className="button-override primary"
                            >
                            Download Sheet
                        </Button>
                    }
                    
                    <a href="#" ref={excelLink} style={{ display: 'none' }} />
                    <a
                        href="#"
                        ref={excelLink}
                        style={{ display: 'none' }} // Hide the anchor element
                    />
                    <Button
                        type="primary"
                        onClick={_ => !createQuoteLoader && handleQuoteSubmit()}
                        className="button-override"
                        >
                        Complete Quote
                        { createQuoteLoader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 10}} /> }
                    </Button>
                </div>
            </>}
            closable={true}
            width={"70vw"}
            style={{ overflow: "scroll", borderRadius: 8, position: "relative"}}
            className="preview-modal"
        >
        <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
            <div>
                <div style={{display: "flex", backgroundColor: "#CEE6FA"}}>
                    <Typography className="quote-text" style={{fontWeight: 500}}>Details</Typography>
                </div>
                {
                    requirementData.map(item => {
                        return <div style={{display: "flex", backgroundColor: "#ffffff", borderTop: "1px solid #E8E8E8"}}>
                        <Typography className="quote-text" style={{fontWeight: 600}}>{item[0]}</Typography>
                        <Typography style={{fontSize: 14, padding: 15}}>{item[1]}</Typography>
                    </div>
                    })   
                }

                <div style={{display: "flex", backgroundColor: "#CEE6FA"}}>
                    <Typography className="quote-text" style={{fontWeight: 500}}>Component</Typography>
                    <Typography className="quote-text" style={{fontWeight: 500}}>Description</Typography>
                    <Typography className="quote-text" style={{fontWeight: 500}}>Total</Typography>
                    <Typography className="quote-text" style={{fontWeight: 500}}>Monthly</Typography>
                </div>
                {
                    finalQuoteCalculation.map(item => {
                        return <>
                            {  item[2] != null && 
                                <div style={{display: "flex", backgroundColor: "#ffffff", borderTop: "1px solid #E8E8E8"}}>
                                    <Typography className="quote-text">{item[0]}</Typography>
                                    <Typography className="quote-text">{item[1]}</Typography>
                                    <Typography className="quote-text">{item[2] === "" ? item[2] : RUPEE_SYMBOL + formatRupee(item[2])}</Typography>
                                    <Typography className="quote-text">{item[3] === "" ? item[3] : RUPEE_SYMBOL + formatRupee(item[3])}</Typography>
                                </div>
                            }
                        </>
                    })
                }
                {
                    accessoriesList.map(item => {
                        return <>
                            { item.baseAmount != null &&
                                <div style={{display: "flex", backgroundColor: "#ffffff", borderTop: "1px solid #E8E8E8"}}>
                                    <Typography className="quote-text">{item.name}</Typography>
                                    <Typography className="quote-text">Accessory</Typography>
                                    <Typography className="quote-text">{item.baseAmount === "" ? item.baseAmount : RUPEE_SYMBOL + formatRupee(item.baseAmount)}</Typography>
                                    <Typography className="quote-text">{item.inMonth === "" ? item.inMonth : RUPEE_SYMBOL + formatRupee(item.inMonth)}</Typography>
                                </div>
                            }
                        </>
                    })
                }
                {
                    serviceList.map(item => {
                        return <>
                            {  item.value != null && 
                                <div style={{display: "flex", backgroundColor: "#ffffff", borderTop: "1px solid #E8E8E8"}}>
                                    <Typography className="quote-text">{item.name}</Typography>
                                    <Typography className="quote-text">Service</Typography>
                                    <Typography className="quote-text">{item.value === "" ? item.value : RUPEE_SYMBOL + formatRupee(item.value)}</Typography>
                                    <Typography className="quote-text">{item.inMonth === "" ? item.inMonth : RUPEE_SYMBOL + formatRupee(item.inMonth)}</Typography>
                                </div>
                            }
                        </>
                    })
                }
                {
                    totalCharges.map(item => {
                        return <div style={{display: "flex", backgroundColor: "#ffffff", borderTop: "1px solid #E8E8E8"}}>
                        <Typography className="quote-text">{item[0]}</Typography>
                        <Typography className="quote-text">{item[1]}</Typography>
                        <Typography className="quote-text">{item[2] === "" ? item[2] : RUPEE_SYMBOL + formatRupee(item[2])}</Typography>
                        <Typography className="quote-text">{item[3] === "" ? item[3] : RUPEE_SYMBOL + formatRupee(item[3])}</Typography>
                    </div>
                    })
                }
                {
                    totalCalculation.map(item => {
                        return <div style={{display: "flex", backgroundColor: "#ffffff", borderTop: "1px solid #E8E8E8"}}>
                        <Typography className="quote-text" style={{fontWeight: 600}}>{item[0]}</Typography>
                        <Typography className="quote-text" style={{fontWeight: 600}}>{item[1]}</Typography>
                        <Typography className="quote-text" style={{fontWeight: 600}}>{item[2] === "" ? item[2] : RUPEE_SYMBOL + formatRupee(item[2])}</Typography>
                        <Typography className="quote-text" style={{fontWeight: 600}}>{item[3] === "" ? item[3] : RUPEE_SYMBOL + formatRupee(item[3])}</Typography>
                    </div>
                    })
                }
            </div>
        </Card>
        </Modal>
     );
}

export default FinalQuoteModal;