import { Button, DatePicker, Form, Modal, Typography } from "antd";
import { modalButtonStyle } from "../../../Client/OnboardNewClient";
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { putRequest } from "../../../../../utils/handler/apiHandler";

export default function CompleteJobModal({ open, setOpen, ticketId }: {
    open: boolean
    setOpen: (value: boolean) => void,
    ticketId: string
}) {

    const [confirmed, setConfirmed] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [form] = Form.useForm();
    const [date, setDate] = useState<any>(null)


    const markJobComplete = async () => {
        setSubmitting(true)
        const params = {
            "serviceStatus": "Job Completed"
            ,
            "genericJobCardDto": {
                //  "completionTime": "2025-01-23 15:32:18"
                "completionTime": form.getFieldValue("completionTime").format("YYYY-MM-DD HH:mm:ss")

            }
        }
        await putRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket?id=${ticketId}`,
            params
        ).then((res) => {
            setOpen(false)
        }).catch((err) => {
            console.log(err)
        })
        window.location.reload();
        setSubmitting(false)

    }

    return (<Modal footer={
        null
    }
        centered style={{ textAlign: "center" }}
        styles={{
            footer: {
                paddingInline: "10px",
                marginBottom: 16
            }
        }}
        width={450}
        closable={false} title={!confirmed ? <div style={{ marginTop: 20 }}><img width={65} src={`${__dirname}assets/warning-icon.png`} /></div> : ""} open={open}>

        {/* <img width={65} src="/assets/warning-icon.png" /> */}
        {!confirmed ? <>
            <Typography style={{ fontSize: 14, fontWeight: "bold", marginTop: 8 }}>Are you sure your reported issues are <br /> resolved?</Typography>

            <div style={{ display: "flex", gap: 12, marginTop: 24, justifyContent: "center" }}>
                <Button style={{ ...modalButtonStyle, width: 200 }}
                    onClick={() => setOpen(false)}
                >
                    <CloseOutlined /> Cancel
                </Button>
                <Button style={{ width: 200 }} onClick={() => setConfirmed(true)} type="primary"
                // onClick={() => setShowNotFound(false)}
                >
                    <CheckOutlined /> Confirm
                </Button>
            </div>
        </> : <>
            <Typography style={{ fontSize: 14, fontWeight: "bold", marginTop: 8, textAlign: "left" }}>Enter completion date & time</Typography>

            <Form form={form}>
                <Form.Item name="completionTime" style={{ marginTop: 10, marginBottom: 10 }}>
                    <DatePicker showTime format="YYYY-MM-DD hh:mm a"  onChange={(date)=>setDate(date)} size="large" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />
                </Form.Item>
            </Form>

            <div style={{ display: "flex", gap: 12, marginTop: 24, justifyContent: "center" }}>
                <Button style={{ ...modalButtonStyle, width: 200 }}
                    onClick={() => setConfirmed(false)}
                >
                    <ArrowLeftOutlined />Go Back
                </Button>
                <Button loading={submitting} disabled={submitting || !date} style={{ width: 200 }} type="primary"
                    onClick={() => markJobComplete()}
                >
                    <CheckOutlined /> Confirm
                </Button>
            </div>

        </>}


    </Modal>)
}