
import { useNavigate, useLocation } from "react-router-dom";
import TicketDetailsHeader from "../Header/TicketsDetailsHeader";
import VehicleBasicDetail from "./BasicDetails/VehicleBasicDetails";
import ContractualDetails from "./ContractualDetails";
import ServiceTakenDetails from "./ServiceTakenDetails";
import VehicleDetailsRemarks from "./VehicleDetailsRemarks";

interface VehicleDetailsProps{
    // ticketNumber: string,
    vehicleBasicDetailsData: any,
    contractualDetailsData: any,
    vehicleRamarksList: any,
    vehicleId: number,
    setFetchTicketDataCnt: (cnt:number) => void,
    jobCardId: number,
    fetchTicketDataCnt: number,
    ticketStatusId: number,
}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({vehicleBasicDetailsData, 
        contractualDetailsData, vehicleRamarksList, vehicleId, setFetchTicketDataCnt, jobCardId
        , fetchTicketDataCnt, ticketStatusId}) => {
    const url = useLocation();
    const ticketNumber = url.pathname.split("/").pop();
    const ticketDetail = {
        ticketNumber: 'PMS/AS2400001/12',
        prospectName: 'Prospect Name',
        lastUpdatedAt: '7:59 PM, 20 Nov 2024',
        ticketStatus: 'Ticket Created',
        ticketStatusColor: 'rgba(86, 1, 193, 1)',
        ticketStatusBackgroundColor: 'rgba(86, 1, 193, 0.10)'
    } 
    // const serviceTakenList: string[] = ['Charging', '24*7 Assistance', 'Damage Wavier', 'VTS Subscription'];
    console.info(`vehicleId=%o`,vehicleId)
    return (
        <div style={{margin:'2rem'}}>
            
            <VehicleBasicDetail vehicleId={vehicleId} vehicleBasicDetailsData={vehicleBasicDetailsData} 
                setFetchTicketDataCnt={setFetchTicketDataCnt} jobCardId={jobCardId} fetchTicketDataCnt={fetchTicketDataCnt}
                ticketStatusId={ticketStatusId}/>
            <ContractualDetails ticketNumber="PMS/AS2400001/12" contractualDetailsData={contractualDetailsData}/>
            <ServiceTakenDetails  vehicleId={vehicleId}/>
            <VehicleDetailsRemarks vehicleRamarksList = {vehicleRamarksList} vehicleId={vehicleId} 
            setFetchTicketDataCnt={setFetchTicketDataCnt} ticketStatusId={ticketStatusId}/>        
        </div>
        
    );
}
export default VehicleDetails;