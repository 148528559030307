// useTicketAndVehicleDetails.ts

import { useState, useEffect } from 'react';

export const useTicketAndVehicleDetails = (vehicleDetails: any, ticketDetails: any, issueDetails: any) => {
  const [ticketHeaderDetails, setTicketHeaderDetail] = useState<any>(null);
  const [vehicleBasicDetailsData, setVehicleBasicDetailsData] = useState<any>(null);
  const [contractualDetailsData, setContractualDetailsData] = useState<any>(null);
  const [vehicleRamarksList, setVehicleRamarksList] = useState<any>(null);
  const [workshopDetailsData, setWorkshopDetailsData] = useState<any>(null);
  const [ticketDetailsData, setTicketDetailsData] = useState<any>(null);
  const [issueSubissueTableData, setIssueSubissueTableData] = useState<any>(null);
  const [jobCardId, setJobCardId] = useState<number> (0);
  const [ticketStatusId, setTicketStatusId] = useState<number> (0);
  const [serviceStatusUpdateDate, setServiceStatusUpdateDate] = useState<string> ('');
  const [ticketRemark, setTicketRemark] = useState<any>({});
  useEffect(() => {
    // Set ticket header details
    setJobCardId(ticketDetails?.jobCard?.id);
    setTicketHeaderDetail({
        ticketNo: `${ticketDetails?.ticket?.serviceType}/${ticketDetails?.vehicleDetails?.registrationNumber}/${ticketDetails?.ticket?.id}`,
        prospectName: ticketDetails?.prospect?.name,
        lastUpdatedAt: ticketDetails?.ticket?.auditInfo?.updatedAt,
        ticketStatus: ticketDetails?.status?.label,
        ticketStatusId: ticketDetails?.status?.id,
        ticketStatusColor: ticketDetails?.status?.color,
        ticketStatusBackgroundColor: ticketDetails?.status?.bgColor,
    });

    setTicketStatusId(ticketDetails?.status?.id);
    setServiceStatusUpdateDate(ticketDetails?.ticket?.serviceStatusUpdateDate);
    setTicketRemark({
      ticketRemark: ticketDetails?.ticket?.ticketRemark,
      ticketRemarkUpdatedAt: ticketDetails?.ticket?.ticketRemarkUpdatedAt,
      ticketRemarkUpdatedBy: ticketDetails?.ticket?.ticketRemarkUpdatedBy,
    })
  
    // Set vehicle basic details
    setVehicleBasicDetailsData({
        vehicleNo: vehicleDetails?.vehicleDetails?.registrationNumber,
        assetNo: vehicleDetails?.vehicleDetails?.assetNumber,
        category: vehicleDetails?.vehicleMaster?.name,
        make: vehicleDetails?.maker?.name,
        model: vehicleDetails?.model?.name,
        variant: vehicleDetails?.variant?.name,
        odometerReading: vehicleDetails?.vehicleDetails?.currentOdometerReading,
        invoicingDate: vehicleDetails?.vehicleDetails?.purchaseDate,
    });
    
    // Set contractual details
    setContractualDetailsData({
        registrationCity: vehicleDetails?.registrationCity?.cityName,
        userCity: vehicleDetails?.purchaseCity?.cityName,
        maintenanceScope: vehicleDetails?.vehicleDetails?.maintenanceScope,
        contractualDetailsList: vehicleDetails?.contractualDetailList.map((contractualDetail: any) => ({
        warrenty: contractualDetail?.warrantyName,
        available: contractualDetail?.available,
        tenure: contractualDetail?.tenure,
        mileage: contractualDetail?.mileage,
        })),
    });
  
    // Set vehicle remarks list
    setVehicleRamarksList(vehicleDetails?.vehicleRemarks);

    // Set workshop details
    setWorkshopDetailsData({
      scheduleServiceSlot: ticketDetails?.ticket?.scheduledDate,
      workshopName: ticketDetails?.workshopMaster?.name,
      isLocalVendor: ticketDetails?.workshopMaster?.isLocalVendor,
      workshopId: ticketDetails?.workshopMaster?.id,
      spocName: ticketDetails?.workshopMaster?.spoc,
      spocNumber: ticketDetails?.workshopMaster?.contact,
      emailId: ticketDetails?.workshopMaster?.email,
      workshopCity: ticketDetails?.workshopMaster?.city,
      serviceSite: ticketDetails?.jobCard?.serviceSite,
      address: ticketDetails?.workshopMaster?.address,
    });

    // Set ticket details data
    setTicketDetailsData({
      ticketCreatedAt: ticketDetails?.ticket?.ticketCreatedAt ? ticketDetails?.ticket?.ticketCreatedAt : ticketDetails?.ticket?.ticketCreatedAt,
      alertType: ticketDetails?.ticket?.serviceCriteriaType,
      serviceNumber: ticketDetails?.ticket?.serviceNumber,
      driverName: ticketDetails?.jobCard?.driverName,
      driverContact: ticketDetails?.jobCard?.driverMobileNumber,
      arrivalTimeAtWorkshop: ticketDetails?.jobCard?.workshopArrivalTime,
      completionDateAndTime: ticketDetails?.jobCard?.completionTime,
      deliveryHandoverTime: ticketDetails?.jobCard?.deliveryHandoverTime,
      replacementCar: ticketDetails?.jobCard?.isReplacementCar,
      replacementCarNo: ticketDetails?.jobCard?.replacementCarNo,
      replacementDriverName: ticketDetails?.jobCard?.replacementDriverName,
      replacementDriverNo: ticketDetails?.jobCard?.replacementDriverNo,
      pickAndDrop: ticketDetails?.jobCard?.isPickAndDrop,
      pickupSchedule: ticketDetails?.jobCard?.pickupScheduledDate,
      actualPickup: ticketDetails?.jobCard?.vehiclePickupDate,
      deliverySchedule: ticketDetails?.jobCard?.deliveryScheduledDate,
      roadSideAssistance: ticketDetails?.jobCard?.isRoadSideAssistance,
      serviceCriteriaType: ticketDetails?.ticket?.serviceCriteriaType,
      totalMonthlyCriteria: ticketDetails?.ticket?.totalMonthlyCriteria,
      totalKmCriteria: ticketDetails?.ticket?.totalKmCriteria,


    });
    
    if(issueDetails) {
    setIssueSubissueTableData(
      issueDetails?.map((job: any) => {
        return {
          jobId: job?.id,
          issue: job?.subIssueMaster?.issue?.name,
          issueId: job?.subIssueMaster?.issue?.id,
          subissue: job?.subIssueMaster?.name,
          subissueId: job?.subIssueMaster?.id,
          reportedAt: job?.auditInfo?.createdAt,
          status: job?.status,
          statusColor: job?.statusWithColor?.color,
          backgroundColor: job?.statusWithColor?.bgColor,
          updatedAt: job?.auditInfo?.updatedAt,
        };
      })
    );
    }
  }, [vehicleDetails, ticketDetails, issueDetails, jobCardId]);

  return {
    ticketHeaderDetails,
    vehicleBasicDetailsData,
    contractualDetailsData,
    vehicleRamarksList,
    workshopDetailsData,
    setWorkshopDetailsData,
    setTicketDetailsData,
    ticketDetailsData,
    issueSubissueTableData,
    jobCardId,
    ticketStatusId,
    serviceStatusUpdateDate,
    ticketRemark,
  };
};
