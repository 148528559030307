import React, { useState } from 'react';
import { Modal, Form, Input, Button, message, Row, Col, Typography, InputNumber } from 'antd';
import axios from 'axios';

interface AddNewWorkshopProps {
  onClose: () => void;
  onChange: (value: string) => void;
  workshopCity: string;
}

const AddNewWorkshop: React.FC<AddNewWorkshopProps> = ({ onClose, onChange, workshopCity }) => {
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values: any) => {
    setLoading(true);
    const payload = {
      ...values,
      isLocalVendor: false,
      city: workshopCity,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop/add-workshop-master`,
        payload
      );

      if (response.data) {
        message.success('New Workshop added successfully');
        onChange(response?.data?.id);
        onClose(); // Close the modal
      }
    } catch (error) {
      message.error('Failed to add New Workshop');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Add Workshop Details"
      centered
      open
      onCancel={onClose}
      footer={null}
      width={800}
      styles={{
        content: {
            background: "#F6FAFF",
        },
        header: {
            background: "#F6FAFF",
            textAlign: "left",
        },
        body: {
            background: "#F6FAFF",
            margin: 0,
            padding: 0,
            maxHeight: '40rem',  // Set a max height for the body
            overflowY: 'auto',  // Enable vertical scrolling
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        }
      }}

    >
      <Form onFinish={handleFinish} style={{ marginTop: '1rem' }}>
        <Row gutter={14} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Workshop Name */}
          <Col className="detail-description-item" xs={24} sm={8} style={{ paddingBottom: '0px' }}>
            <Typography className="description-label">
              Workshop Name
              <span style={{ marginLeft: '0.2rem', color: "red" }}>*</span>
            </Typography>
            <Form.Item
              name="name"
              className="form-label"
              rules={[{ required: true, message: 'Please input the Workshop Name!' }]}
            >
              <Input placeholder="Enter Name" style={{ backgroundColor: "#F6FAFF" }} />
            </Form.Item>
          </Col>

          {/* SPOC Name */}
          <Col className="detail-description-item" xs={24} sm={8}>
            <Typography className="description-label">
              SPOC Name
            </Typography>
            <Form.Item
              name="spoc"
            >
              <Input placeholder="Enter SPOC Name" style={{ backgroundColor: "#F6FAFF" }} />
            </Form.Item>
          </Col>

          {/* SPOC Number */}
          <Col className="detail-description-item" xs={24} sm={8}>
            <Typography className="description-label">
              SPOC No.
            </Typography>
            <Form.Item
              name="contact"
            >
              <InputNumber
                style={{width:'100%', backgroundColor: '#F6FAFF' }}
                placeholder="Enter No."
                maxLength={10} 
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={14}>
          {/* Email ID */}
          <Col className="detail-description-item" xs={24} sm={8}>
            <Typography className="description-label">
              Email ID
              <span style={{ marginLeft: '0.2rem', color: "red" }}>*</span>
            </Typography>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input the email!' }]}
            >
              <Input placeholder="Enter Email Id" style={{ backgroundColor: "#F6FAFF" }} />
            </Form.Item>
          </Col>

          {/* Address */}
          <Col className="detail-description-item" xs={24} sm={12} lg={16}>
            <Typography className="description-label">
              Address
              <span style={{ marginLeft: '0.2rem', color: "red" }}>*</span>
            </Typography>
            <Form.Item
              name="address"
              rules={[{ required: true, message: 'Please input the address!' }]}
            >
              <Input placeholder="Enter Address" style={{ width: '100%', backgroundColor: "#F6FAFF" }} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" htmlType="submit" loading={loading} block style={{ width: '5rem' }}>
            Confirm
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddNewWorkshop;
