import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import { ProspectDetailsType } from "./types/Prospect";
import { getRequest, postRequest, putRequest } from "../../../utils/handler/apiHandler";
import { useEffect, useState } from "react";

export default function BasicDetailsForm({ setCurrentStep, prospectDetails, formSteps, currentStep, prospectId, disabled }: { setCurrentStep: any, prospectDetails: ProspectDetailsType | null, formSteps: any, currentStep: number, prospectId: any, disabled: boolean }) {

    const [buissnessSegement, setBusinessSegment] = useState([])
    const [addNewSegment, setAddNewSegment] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        getBusinessSegment()
    }, [])

    const getBusinessSegment = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
            console.log("res.data", res.data)
            setBusinessSegment(res.data);
        })
    };

    const handleSubmit = async () => {
        const formValues = form.getFieldsValue();
        console.log("formValues", formValues);
        const payload = {
            ...formValues, segment: {
                "id": formValues.segment.label === "Other" ? null : formValues.segment.value,
                "name": formValues.segment.label === "Other" ? formValues.segmentName : formValues.segment.label,
            },
            isOtherSegment: formValues.segment.label === "Other",
            clientStateStatus: formSteps[currentStep - 1].value,
            // creditLimit: prospectDetails?.creditLimit,
        }
        setSubmitting(true)
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateDetails/${prospectDetails?.clientOnBoardingId}`, payload).then(res => {
            console.log(res)
            setSubmitting(false)
            if (res?.status == 200) {
                setCurrentStep(2)
            }
        })

    }

    return (
        <>
            <Typography.Text strong style={{ fontSize: 16 }}>Basic Details</Typography.Text>

            <div style={{
                background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>
                <Form
                    disabled={disabled}
                    onFinish={() => handleSubmit()}
                    className="client-onboarding-form"
                    form={form}
                    initialValues={{
                        "segment": {
                            "label": prospectDetails?.prospect.segment?.name,
                            "value": prospectDetails?.prospect.segment?.id,
                            "key": prospectDetails?.prospect.segment?.id
                        },
                        "entityName": prospectDetails?.prospect.legalBusinessName?.entityName,
                        "legalBusinessName": prospectDetails?.prospect.legalBusinessName?.name,
                        "primaryContactNumber": prospectDetails?.primaryContactNumber,
                        "primaryEmailId": prospectDetails?.primaryEmailId,
                        "entityPan": prospectDetails?.entityPan,
                        "entityCin": prospectDetails?.entityCin
                    }}
                    layout="vertical" name="basic-details">
                    <Row gutter={12}>
                        <Col span={8}>
                            <div>
                                <Typography.Text strong>Prospect</Typography.Text>
                            </div>
                            <div style={{ marginTop: 16 }}>
                                <Typography.Text>{prospectId} - {prospectDetails?.prospect.name}</Typography.Text>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div>
                                <Typography.Text strong>Credit Limit</Typography.Text>
                            </div>
                            <div style={{ marginTop: 16 }}>
                                <Typography.Text>₹ {prospectDetails?.creditLimit}</Typography.Text>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{ width: "100%", display: addNewSegment ? "flex" : "block", gap: 8 }}>
                                <Form.Item
                                    rules={[{ required: true, message: 'Please input Business Segment!' }]}
                                    name="segment" label="Business Segment">
                                    <Select labelInValue onChange={(value) => setAddNewSegment(
                                        value.label == "Other"
                                    )}>
                                        {buissnessSegement.map((i: any) => {
                                            return <Select.Option value={i.id}>
                                                {i.name}
                                            </Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                {addNewSegment && <Form.Item
                                    rules={[{ required: true, message: 'Please input your Segment Name!' }]}
                                    style={{ flex: 1 }} name="segmentName" label=" ">
                                    <Input />
                                </Form.Item>}
                            </div>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input Entity Name!' }]}
                                name="entityName" label="Entity Name">
                                <Input placeholder="Enter Entity Name" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input Brand Name!' }]}
                                name="legalBusinessName" label="Brand Name">
                                <Input placeholder="Enter Brand Name" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { required: true, message: 'Please input Primary Contact No.!' },
                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' },
                                    { len: 10, message: 'Contact No. must be 10 digits!' }
                                ]}
                                name="primaryContactNumber" label="Primary Contact No.">
                                <Input maxLength={10} placeholder="Enter Primary Contact No." />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input Primary Email ID!' }, {
                                    type: 'email', message: 'Please input valid email!'
                                }]}
                                name="primaryEmailId" label="Primary Email ID">
                                <Input placeholder="Enter Primary Email ID" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input PAN no.!' },
                                { len: 10, message: 'PAN No. must be 10 characters!' },
                                { pattern: /^[A-Z0-9]+$/, message: 'only alphabets and numbers allowed!' }
                                ]}
                                name="entityPan" label="Entity PAN no.">
                                <Input placeholder="Enter Entity PAN no." />
                            </Form.Item>
                        </Col>
                        {/* <Col span={8}>
                            <Form.Item
                        rules={[{ required: true, message: 'Please input your contact number!' }]}
                            label="Entity GST No.">
                                <Input placeholder="Enter Entity GST No." name="entityGst" />
                            </Form.Item>
                        </Col> */}
                        <Col span={8}>
                            <Form.Item
                                rules={[{ required: true, message: 'Please input CIN no.!' }]}
                                name="entityCin" label="Entity CIN no.">
                                <Input placeholder="Enter Entity CIN no." />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!disabled && <div style={{ textAlign: "right", marginTop: 28, paddingBottom: 20 }}>
                        <Button loading={submitting} disabled={submitting} htmlType="submit" size="large" type="primary">Proceed <RightOutlined /></Button>
                    </div>}

                </Form>
                {disabled && <div style={{ textAlign: "right", marginTop: 28, paddingBottom: 20 }}>
                    <Button onClick={() =>
                        setCurrentStep(2)
                    } size="large" type="primary">Proceed <RightOutlined /></Button>
                </div>}
            </div>
        </>

    )
}