// src/hooks/useFetchVehicleAndTicketDetails.ts
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../../../utils/handler/apiHandler";

// Define the hook that will manage the state for vehicle and ticket details
export const useFetchVehicleAndTicketDetails = (vehicleId: string, ticketId: string, fetchTicketDataCnt: number) => {
  const [vehicleDetails, setVehicleDetails] = useState<any>(null);
  const [ticketDetails, setTicketDetails] = useState<any>(null);
  const [issueDetails, setIssueDetails] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const getEncodedFilterAnd = (filterAnd: string) => {
    return encodeURIComponent(filterAnd);  // Use encodeURIComponent to encode dynamically
  };

  useEffect(() => {
    const fetchVehicleAndTicketDetails = async () => {
      setLoader(true);

      const filterForVehicle = `id|eq|${vehicleId}`;
      const filterForTicket = `id|eq|${ticketId}`;

      try {
        // Make both API calls concurrently
        const [vehicleRes, ticketRes
          // , issueRes
        ] = await Promise.all([
          getRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/full-details?filterAnd=${getEncodedFilterAnd(filterForVehicle)}`
          ),
          getRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/full-details?filterAnd=${getEncodedFilterAnd(filterForTicket)}`
          ),
          // getRequest(
          //   `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/full-details?filterAnd=${getEncodedFilterAnd(filterForTicket)}`
          // ),
          
        ]);
        

        // Handle vehicle API response
        if (vehicleRes.status === 404 || vehicleRes.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }

        if (vehicleRes.status !== 403) {
          setVehicleDetails(Array.isArray(vehicleRes?.data?.items)  ? vehicleRes?.data?.items[0] : null); 
          // we are expecting only 1 vehicle in response
        }

        // Handle ticket API response
        if (ticketRes.status === 404 || ticketRes.status === 400) {
          navigate("/dashboard/prospect");
          return;
        }

        if (ticketRes.status !== 403) {
          setTicketDetails(Array.isArray(ticketRes?.data?.items)  ? ticketRes?.data?.items[0] : null);
          // we are expecting only 1 ticket in response
        
      }
      
       
    
    
      } catch (error) {
        // Handle any error that occurred during the API request
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchVehicleAndTicketDetails();
  }, [vehicleId, ticketId, navigate, fetchTicketDataCnt]);

  useEffect(()=>{
    const jobCardId = ticketDetails?.jobCard?.id;
    if(!jobCardId) return;
    const fetchIssues = async () => {
      setLoader(true);
      try{
        const issueFilter = getEncodedFilterAnd(`jobCard.id|eq|${jobCardId}&active|eqb|true`);
        const issueRes:any  =  await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/sub-issue-job-card?filterAnd=${issueFilter}`
          );
          if (issueRes.status !== 403) {
            setIssueDetails(issueRes?.data?.items);
          }  
      } catch (error) {
        console.error("Error fetching issue data:", error);
      } finally {
        setLoader(false);
      }
      
    }
    fetchIssues();
  },[ticketDetails])

  return { vehicleDetails, ticketDetails, issueDetails, loader };
};
