import { Col, Row } from "antd";
import { Empty } from 'antd';

interface ContractualDetailsListProps {
  contractualDetailsList: any[];
}

const ContractualDetailsList: React.FC<ContractualDetailsListProps> = ({ contractualDetailsList }) => {
  return (
    <>
      <Row
        style={{
          display: 'flex', 
          fontWeight: '700',
          width: '100%',  // Make sure Row takes full width
          marginLeft: '5px'
        }}
      >
        <Col style={{ width: '25%' }}>Warranty</Col>
        <Col style={{ width: '25%' }}>Available</Col>
        <Col style={{ width: '25%' }}>Tenure</Col>
        <Col style={{ width: '25%' }}>Mileage</Col>
      </Row>

      {
      contractualDetailsList && contractualDetailsList.length === 0 ?
        <Empty style={{backgroundColor:'rgba(206, 230, 250, 0.37)', margin:'1rem 0rem',
          borderRadius:'0.5rem'}}/> :
        contractualDetailsList?.map((contractualDetails: any, index: number) => (
          <Row
            key={index}
            style={{
              minHeight: 50,
              marginTop: '10px',
              padding: '10px',
              backgroundColor: 'rgba(206, 230, 250, 0.37)',
              borderRadius: '8px',
              display: 'flex',  // Ensure the rows also align in a flex container
              width: '100%',    // Ensure Row takes full width
            }}
          >
            <Col style={{ width: '25%' }}> {contractualDetails?.warrenty} </Col>
            <Col style={{ width: '25%' }}> {contractualDetails?.available} </Col>
            <Col style={{ width: '25%' }}> {contractualDetails?.tenure} </Col>
            <Col style={{ width: '25%' }}> {contractualDetails?.mileage || '--'} </Col>
          </Row>
        ))
      }
    </>
  );
};

export default ContractualDetailsList;
