export const convertUtcToIst = (date: string) => {
    var dateIST = new Date(date);
    dateIST.setHours(dateIST.getHours() + 5); 
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    return dateIST.toString();
}

export const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
    }

    // Get hours, minutes, and AM/PM
    let hours: number = date.getHours();
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const amPm: string = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Format day, month, and year
    const day: number = date.getDate();
    const month: string = date.toLocaleString("en-US", { month: "short" });
    const year: number = date.getFullYear();

    // Final formatted string
    const formattedDate: string = `${hours}:${minutes} ${amPm}, ${day} ${month} ${year}`;

    return formattedDate;
}
