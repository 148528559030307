import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, message, Row, Col, Typography, InputNumber } from 'antd';
import axios from 'axios';

interface AddNewLocalVendorProps {
  onClose: () => void;
  onChange: (value: string) => void;
  workshopCity: string;
  isLocalVendor: boolean;
  selectedWorkshopDetailsData: any;
}

const AddNewLocalVendor: React.FC<AddNewLocalVendorProps> = ({
  onClose,
  onChange,
  workshopCity,
  isLocalVendor,
  selectedWorkshopDetailsData,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm(); // Initialize Form.useForm
  
  useEffect(() => {
    if (isLocalVendor && selectedWorkshopDetailsData) {
      form.setFieldsValue({
        name: selectedWorkshopDetailsData.workshopName,
        spoc: selectedWorkshopDetailsData.spocName,
        contact: selectedWorkshopDetailsData.spocNumber,
        email: selectedWorkshopDetailsData.emailId,
        address: selectedWorkshopDetailsData.address,
      });
    } else {
      form.resetFields();
    }
  }, [isLocalVendor, selectedWorkshopDetailsData, form]);
 
  const handleFinish = async (values: any) => {
    setLoading(true);
    const payload = {
      ...values,
      isLocalVendor: true,
      city: workshopCity,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop/add-workshop-master`,
        payload
      );

      if (response.data) {
        message.success('Local Vendor added successfully');
        onChange(response.data.id);
        onClose();
      }
    } catch (error) {
      message.error('Failed to add Local Vendor');
    } finally {
      setLoading(false);
    }
  };

  const clearOtherVendorForm = () => {
    form.resetFields(); // Reset all form fields
  };

  return (
    <Modal
      title="Add Local Vendor Details"
      centered
      open
      onCancel={onClose}
      footer={null}
      width={800}
      styles={{
        content: {
          background: '#F6FAFF',
        },
        header: {
          background: '#F6FAFF',
          textAlign: 'left',
        },
        body: {
          background: '#F6FAFF',
          margin: 0,
          padding: 0,
          maxHeight: '40rem',
          overflowY: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        },
      }}
    >
      <Form form={form} onFinish={handleFinish} style={{ marginTop: '1rem' }}>
        <Row gutter={14} style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Workshop Name */}
          <Col
            className="detail-description-item"
            xs={24}
            sm={8}
            style={{ paddingBottom: '0px' }}
          >
            <Typography className="description-label">
              Workshop Name
              <span style={{ marginLeft: '0.2rem', color: 'red' }}>*</span>
            </Typography>
            <Form.Item
              name="name"
              className="form-label"
              rules={[
                { required: true, message: 'Please input the Workshop Name!' },
              ]}
            >
              <Input
                placeholder="Enter Name"
                style={{ backgroundColor: '#F6FAFF' }}
              />
            </Form.Item>
          </Col>

          {/* SPOC Name */}
          <Col className="detail-description-item" xs={24} sm={8}>
            <Typography className="description-label">SPOC Name</Typography>
            <Form.Item name="spoc">
              <Input
                placeholder="Enter SPOC Name"
                style={{ backgroundColor: '#F6FAFF' }}
              />
            </Form.Item>
          </Col>

          {/* SPOC Number */}
          <Col className="detail-description-item" xs={24} sm={8}>
            <Typography className="description-label">SPOC No.</Typography>
            <Form.Item name="contact">
              <InputNumber
                style={{width:'100%', backgroundColor: '#F6FAFF' }}
                placeholder="Enter No."
                maxLength={10} 
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={14}>
          {/* Email ID */}
          <Col className="detail-description-item" xs={24} sm={8}>
            <Typography className="description-label">Email ID</Typography>
            <Form.Item name="email">
              <Input
                placeholder="Enter Email Id"
                style={{ backgroundColor: '#F6FAFF' }}
              />
            </Form.Item>
          </Col>

          {/* Address */}
          <Col className="detail-description-item" xs={24} sm={12} lg={16}>
            <Typography className="description-label">
              Address
              <span style={{ marginLeft: '0.2rem', color: 'red' }}>*</span>
            </Typography>
            <Form.Item
              name="address"
              rules={[
                { required: true, message: 'Please input the address!' },
              ]}
            >
              <Input
                placeholder="Enter Address"
                style={{ width: '100%', backgroundColor: '#F6FAFF' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isLocalVendor && (
            <Button
              type="primary"
              loading={loading}
              style={{
                color: 'rgba(255, 77, 79, 1)',
                backgroundColor: 'rgba(255, 77, 79, 0.1)',
                borderColor: 'rgba(255, 77, 79, 1)',
                width: '5rem',
                marginRight: '0.6rem',
              }}
              onClick={clearOtherVendorForm}
            >
              Clear
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: '5rem' }}
          >
            Confirm
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddNewLocalVendor;
