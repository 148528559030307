import { Button, Card, Modal, message } from "antd";

import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as Caution } from "./../../../../../utils/icons/Caution.svg";
import { ReactComponent as CloseBlue } from "./../../../../../utils/icons/CloseBlue.svg";
import { ReactComponent as CheckWhite } from "./../../../../../utils/icons/CheckWhite.svg";

interface ScheduleServiceConfirmationModalProps{
    scheduleServiceFlag: number,
    setScheduleServiceFlag: (flag: number) => void,
    handleScheduleServiceDateConfirm: () => void,
    isPickupAndDrop: boolean,
}

const ScheduleServiceConfirmationModal: React.FC<ScheduleServiceConfirmationModalProps> = ({ scheduleServiceFlag, setScheduleServiceFlag,
    handleScheduleServiceDateConfirm, isPickupAndDrop }) => {
    
    return (
        <Modal 
            open={scheduleServiceFlag === 1} 
            onCancel={()=>setScheduleServiceFlag(0)}
            width='30%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', justifyContent:'center', marginTop:'2rem'}}>
                    <Caution/>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',  
                        alignItems: 'center',     
                        margin: '1rem 0',          
                        fontWeight: '500',
                        fontSize: '0.875rem',
                        color: '#333',
                        width: '100%',           
                    }}
                    >
                    <div style={{ textAlign: 'center', width:'content-fit' }}>
                        <p> Are you sure you want to Schedule Service with Issue & sub - Issues Reported? </p>
                    </div>
                </div>




                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', marginBottom:'0.8rem' }}>
                    <Button style={{width:'45%', color:'#1777FF', borderColor: '#1777FF', backgroundColor:'#E0EDFF', height:'2.2rem'}}
                        onClick={()=> setScheduleServiceFlag(0)}>
                        <CloseBlue style={{marginRight:'0.4rem'}} />
                        <span>
                            Cancel
                        </span>
                    </Button>

                    <Button style={{width:'45%', color:'#FFF', borderColor: '#2C66E3', backgroundColor:'#2C66E3', height:'2.2rem'}}
                        onClick={()=> {
                                if(isPickupAndDrop){
                                    setScheduleServiceFlag(2)
                                }
                                else{
                                    handleScheduleServiceDateConfirm();
                                    setScheduleServiceFlag(0);
                                }
                            }
                        }>
                        <CheckWhite style={{marginRight:'0.4rem'}} />
                        <span>
                            Confirm 
                        </span>
                    </Button>

                    
                    
                </div>
            </div>
        </Modal>
    );
};

export default ScheduleServiceConfirmationModal;
