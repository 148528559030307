import { LeftOutlined, MinusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Select, Spin, Table, TablePaginationConfig, Tag} from "antd";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import { AlertListType as AlertListType } from "./types/ServiceList";
import { useEffect, useRef, useState } from "react";
import { handlePageChange, handlePageSizeChange } from "../Prospect/utils/paginationFunctions";
import FilterDropdown from "../Client/FilterDropDown";
import { getRequest } from "../../../utils/handler/apiHandler";
import UnionFilterIcon from "../SVGs/UnionFilterIcon";

const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
};
interface InServiceTicketListProps {
    maintenanceScopeList: any[],
}
interface InServiceTicketListProps {
    maintenanceScopeList: any[],
}

const InServiceTicketList : React.FC<InServiceTicketListProps> = ({maintenanceScopeList}) => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: AlertListType[] = [];
    const [data, setData] = useState<AlertListType[]>(defaultData);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(600);
    const [sortOrder, setSortOrder] = useState<string>(``); // Default sort
    const [assetList, setAssetList] = useState<any>([]);
    const [ticketList, setTicketList] = useState<any>([]);
    const [clientList, setClientList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [workshopNameList, setWorkshopNameList] = useState<any>([]);
    const [workshopLocationList, setWorkshopLocationList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);

    const defaultSort = "serviceStatusUpdateDate|DESC"
    const ALERT_FILTER = "serviceStatus|eq|In Servicing&active|eqb|true"
    const defaultParam = `&sort=${encodeURIComponent(defaultSort)}&filterAnd=${encodeURIComponent(ALERT_FILTER)}`
  
    const filterIconStatus = () => (
        <UnionFilterIcon fill={ '#2C2C2C' } style={{ marginLeft: "10px" }} />
      );
    

    useEffect(() => {
        fetchAllAlertData();
    }, [currentPage, pageSize, sortOrder]);

    const fetchAllAlertData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/full-details?page=${currentPage - 1}&size=${pageSize}${sortOrder ? sortOrder : defaultParam}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                setData(res.data.items);
                if ('totalPages' in res.data) {
                    setPageCount(res.data.totalPages);
                }
                if ('totalItems' in res.data) {
                    setTotalRecord(res.data.totalItems);
                }
            } else {
                console.error('Error fetching Alert List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Alert data:', error);
        } finally {
            setLoading(false);
        }
    };


    const getColumns = (): ColumnsType<any> => [
        {
            title: "Ticket ID",
            dataIndex: "ticketId",
            key: "ticketId",
            filters: ticketList.map((option: any) => ({
                text: `${option?.ticket?.serviceType}|${option?.vehicleDetails?.registrationNumber}|${option?.ticket?.id}`,
                value: option?.ticket?.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    fetchApiUrl={(name:any, page:any) => {
                        let filterQuery = '';
                        let filterType = 'filterOr';  // Default to filterOr if no "|" is found
                    
                        // Check if the name contains "|"
                        if (name && name.includes('|')) {
                            filterType = 'filterAnd';  // Use filterAnd if "|" is present
                        }
                    
                        if (name) {
                            const parts = name.split('|');
                    
                            if (parts.length === 1) {
                                // If there's only one part, it's treated as a registration number or ID
                                filterQuery = `vehicleDetails.registrationNumber|like|${name}&serviceType|startwith|${name}&id|startwith|${name}`;
                            } else if (parts.length === 2) {
                                // If there are two parts, figure out if the first part is serviceType or vehicleDetails.registrationNumber
                                if (parts[0] === 'GMS' || parts[0] === 'PMS') {
                                    // It's a valid serviceType, so first part is serviceType, second part is vehicleDetails.registrationNumber
                                    filterQuery = `serviceType|startwith|${parts[0]}&vehicleDetails.registrationNumber|like|${parts[1]}&id|startwith|${parts[1]}`;
                                } else {
                                    // The first part is vehicleDetails.registrationNumber, second part is id
                                    filterQuery = `vehicleDetails.registrationNumber|like|${parts[0]}&id|startwith|${parts[1]}`;
                                }
                            } else if (parts.length === 3) {
                                // If there are three parts, we assume this follows the pattern serviceType|vehicleDetails.registrationNumber|id
                                if (parts[0] === 'GMS' || parts[0] === 'PMS') {
                                    filterQuery = `serviceType|startwith|${parts[0]}&vehicleDetails.registrationNumber|like|${parts[1]}&id|startwith|${parts[2]}`;
                                }
                            }
                        }
                    
                        // Return the full URL with the appropriate filter type (filterOr or filterAnd)
                        return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/full-details?page=${page}&size=10${filterQuery && `&${filterType}=${encodeURIComponent(filterQuery)}`}`;
                    }}
                    setBusinessNameOptions={setTicketList}
                    isKeyItems={true}
                />
            ),
            render: (text: string, record: any) => (
                <a href={`/dashboard/service-ticket/tickets-details/${record?.ticket?.id}/${record?.vehicleDetails?.id}`} className="table-text-style" style={{color : '#2C66E3', textDecoration : 'underline', width: 130, whiteSpace: 'wrap' }}>
                  {record?.ticket?.serviceType}<i>|</i>{record?.vehicleDetails?.registrationNumber}<i>|</i>{record?.ticket?.id}
                </a>
            ),
            // width: "3.5%",
        },
        {
            title: "Asset ID",
            dataIndex: "assetNumber",
            key: "assetNumber",
            filters: assetList.map((option: any) => ({
                text: option.assetNumber,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle?page=${page}&size=10${name && `&filterAnd=${encodeURIComponent(`assetNumber|startwith|${name}`)}`}`}}
                    setBusinessNameOptions={setAssetList}
                    isKeyItems={true}
                />
            ),
            render: (text : number, record: any) => {
                return( <div className="table-text-style">{record?.vehicleDetails?.assetNumber}</div> )},
            // width: "2.5%",
        },
        {
            title: "Vehicle Number",
            dataIndex: 'vehicleNumber',
            filters: vehicleList.map((option: any) => ({
                text: option.registrationNumber,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle?page=${page}&size=10${name && `&filterAnd=${encodeURIComponent(`registrationNumber|startwith|${name}`)}`}`}}
                    setBusinessNameOptions={setVehicleList}
                    isKeyItems={true}
                />
            ),
            key: 'vehicleNumber',
            render: (text : number, record: any) => {
                return( <div className="table-text-style">{record?.vehicleDetails?.registrationNumber}</div> )},
            // width: '3%',
        },
        {
            title: "Client Name",
            dataIndex: 'clientName',
            filters: clientList.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospectNames?page=${page-1}&size=10&name=${name}`}}
                    setBusinessNameOptions={setClientList}
                />
            ),
            key: 'prospect.prospectId',
            render: (text: string, record: any) => (
                    <a href={`/dashboard/prospect/${record?.prospect?.prospectId}`} className="table-text-style" style={{color : '#2C66E3', textDecoration : 'underline', width: 130, whiteSpace: 'wrap' }}>
                      {record?.prospect?.name}
                    </a>
                ),
            // width: '5%',
        },
        {
            title: "Location",
            dataIndex: 'location',
            filters: cityList.map((option: any) => ({
                text: option.cityName,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities_v2?page=${page-1}&size=10&name=${name}`}}
                    setBusinessNameOptions={setCityList}
                />
            ),
            key: 'location',
            render: (text : number, record: any) => {
                const city = record?.regCity?.cityName != null ? record?.regCity?.cityName : "NA";
                return( <div className="table-text-style">{city}</div> )},
            // width: '3%',
        },
        {
            title: "Workshop",
            dataIndex: 'workshopName',
            filters: workshopNameList.map((option: any) => ({
                text: option?.name,
                value: option?.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop?filterAnd=${encodeURIComponent(`name|like|${name}`)}`}}
                    setBusinessNameOptions={setWorkshopNameList}
                    isKeyItems={true}
                />
            ),
            key: 'workshopName',
            render: (text : number, record: any) => {
                const workshopName = record?.workshopMaster != null ? record?.workshopMaster?.name : "NA";
                return( <div className="table-text-style">{workshopName}</div> )},
            // width: '4%',
        },
        {
            title: "Workshop Location",
            dataIndex: 'workshopLocation',
            filters: workshopLocationList.map((option: any) => ({
                text: option?.city,
                value: option?.city,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop/distinct-city?page=${page}&size=10&city=${name}`}}
                    setBusinessNameOptions={setWorkshopLocationList}
                    isKeyItems={true}
                />
            ),
            key: 'workshopLocation',
            render: (text : number, record: any) => {
                const workshopLocation = record?.workshopMaster != null ? record?.workshopMaster?.location  : "NA";
                return( <div className="table-text-style">{workshopLocation}</div> )},
            // width: '3%',
        },
        {
            title: 'Maintenance Scope',
            dataIndex: 'maintenanceScope',
            filters: maintenanceScopeList.map((option: any) => ({
                text: option.label,
                value: option.label,
                // color: option.color,
                // bgColor: option.bgColor 
            })),
            filterIcon: filterIconStatus,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    page={1}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    setBusinessNameOptions={() => {}}
                />
            ),

            render: (text: string, record: any) => {
                return(
                    <div className="table-text-style">{record?.vehicleDetails?.maintenanceScope ? record?.vehicleDetails?.maintenanceScope : 'NA'}</div> 
                );
              },
            key: 'maintenanceScope',
            // width: '2%',
        },
    ];

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<AlertListType> | SorterResult<AlertListType>[]
    ) => {
        let sortString = '';
        let filterString = "";
        let filterAndString = ALERT_FILTER;

        if (Array.isArray(sorter)) {
            // Handle multiple sorts
            sortString = sorter
                .filter(s => s.order) // Filter out columns without sorting
                .map(s => `${s.field}:${s.order === 'ascend' ? 'ASC' : 'DESC'}`) // Map each sorter to the format field:asc/desc
                .join(',');
        } else if (sorter.order) {
            // Handle single sort
            const sortField = sorter.field;
            const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
            sortString = `${sortField}|${sortOrder}`;
        }

        if (sortString && sortString !== "") {
            sortString = sortString
        }
        if (filters?.["ticketId"]?.length > 0) {
            filterAndString = filterAndString +  `&id|in|${filters?.["ticketId"]}`;
        }
        if (filters?.["assetNumber"]?.length > 0) {
            filterAndString = filterAndString +  `&vehicleDetails.id|in|${filters?.["assetNumber"]}`;
        }
        if(filters?.["prospect.prospectId"]?.length > 0){
            filterAndString = filterAndString + `&prospect.prospectId|in|${filters?.["prospect.prospectId"]}`;
        }
        if(filters?.["vehicleNumber"]?.length > 0){
            filterAndString = filterAndString + `&vehicleDetails.id|in|${filters?.["vehicleNumber"]}`;
        }
        if(filters?.["location"]?.length > 0){
            filterAndString = filterAndString + `&vehicleDetails.registrationCity.id|in|${filters?.["location"]}`;
        }
        if(filters?.["workshopName"]?.length > 0){
            filterAndString = filterAndString + `&jobCard.workshopMaster.id|in|${filters?.["workshopName"]}`;
        }
        if(filters?.["workshopLocation"]?.length > 0){
            filterAndString = filterAndString + `&jobCard.workshopMaster.location|in|${filters?.["workshopLocation"]}`;
        }
        if(filters?.["maintenanceScope"]?.length > 0){
            filterAndString = filterAndString + `&vehicleDetails.maintenanceScope|in|${filters?.["maintenanceScope"]}`;
        }

        filterString = filterString + `&filterAnd=` + encodeURIComponent(filterAndString);
        if(sortString === ""){
            sortString = defaultSort;
        }
        sortString = "&sort=" + encodeURIComponent(`${sortString}`);
        
        // Set the sort order string for the API call
        setSortOrder(sortString + filterString);
    };

        return (
            <div className="parent-container">
                <div>
                    <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
                        <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                            <Spin spinning={loading} size="large">
                                <Table
                                    className="lev-table"
                                    columns={getColumns()}
                                    dataSource={data || []}
                                    pagination={{
                                        pageSize: pageSize,
                                        position: ["bottomRight"],
                                        showSizeChanger: false,
                                        showTotal: (total, range) => (
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '-4px' }}>
                                                <div style={{ fontSize: '1rem' }}>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>Showing</span>
                                                    <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                                        <Select.Option value="10">10</Select.Option>
                                                        <Select.Option value="20">20</Select.Option>
                                                        <Select.Option value="50">50</Select.Option>
                                                        <Select.Option value="100">100</Select.Option>
                                                    </Select>
                                                    <span style={{ fontSize: 15, fontWeight: 400 }}>per page</span>
                                                    <span style={{ fontSize: 15, fontWeight: 400, marginRight: '10px', marginLeft: '10px', color: 'grey' }}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                                                </div>
                                            </div>
                                        ),
                                        onChange: handlePageChangeLocal,
                                        current: currentPage,
                                        total: totalRecord,
                                        itemRender: (current, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <LeftOutlined style={{ marginLeft: '4rem', fontSize: 12, fontWeight: 500 }} />;
                                            }
                                            if (type === 'next') {
                                                return <RightOutlined style={{ fontSize: 12, fontWeight: 500 }} />;
                                            }
                                            if (type === 'page') {
                                                return (
                                                    <span style={{ cursor: 'pointer', fontSize: 12, fontWeight: 500 }} onClick={() => handlePageChangeLocal(current)}>
                                                        {current}
                                                    </span>
                                                );
                                            }
                                            if (type === 'jump-next' || type === 'jump-prev') {
                                                return <a style={{ color: 'black', fontSize: 12, fontWeight: 500 }}><strong> ... </strong></a>;
                                            }
                                            return originalElement;
                                        },
                                    }}
                                    onChange={handleTableChange} // Handle sort
                                    // scroll={{ x: 1700, y: 0.80 * tableHeight }}
                                    size="middle"
                                // components={{
                                //     header: {
                                //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                                //     },
                                //   }}
                                />
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>

        );
}

export default InServiceTicketList;