import { Button, DatePicker, Modal, Table, Typography, TableProps, message, Tag } from "antd";
import { ArrowLeftOutlined, CheckCircleFilled, CheckCircleOutlined, CheckOutlined, CloseOutlined, EyeFilled, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { modalButtonStyle } from "../../../../Client/OnboardNewClient";
import moment from "moment";
import { getRequest, postRequest, putRequest } from "../../../../../../utils/handler/apiHandler";
import { EditableCell, EditableRow, IssueDataType, saveDetailsParams } from "./IssueJobForm";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

const components = {
    body: {
        row: EditableRow,
        cell: EditableCell,
    },
};

interface OptionType {
    "id": number,
    "label": string,
    "color": string,
    "bgColor": string
}

interface TicketType {
    "id": number,
    ticketId: string,
    vehicleId: number
}

const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
        title: 'Issue',
        dataIndex: 'issue',
    },
    {
        title: 'Sub Issue',
        dataIndex: 'subIssue',
    },
    {
        title: 'Work Done',
        dataIndex: 'workDone',
    },

    {
        title: 'Status',
        dataIndex: 'status',
        editable: true,
    },

    {
        title: 'Last Updated At',
        dataIndex: 'lastUpdatedAt',
    },
    // {
    //     title: 'operation',
    //     dataIndex: 'operation',
    //     render: (_, record) =>
    //         fullDetails.length >= 1 ? (
    //             !record.sumRow && <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
    //                 <a>Delete</a>
    //             </Popconfirm>
    //         ) : null,
    // },
];

const ticketColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
        title: 'Issue',
        dataIndex: 'issue',
    },
    {
        title: 'Sub Issue',
        dataIndex: 'subIssue',
    },
    {
        title: 'Work Done',
        dataIndex: 'workDone',
    },

    {
        title: 'Status',
        dataIndex: 'status',
        render: (item, record) => <Tag color={"rgba(0 , 153 , 173,0.1)"} style={{ color: "rgba(0 , 153 , 173,1)" }} >
            {item}
        </Tag>
    },
    {
        title: 'Last Updated At',
        dataIndex: 'lastUpdatedAt',
    },
    // {
    //     title: 'operation',
    //     dataIndex: 'operation',
    //     render: (_, record) =>
    //         fullDetails.length >= 1 ? (
    //             !record.sumRow && <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
    //                 <a>Delete</a>
    //             </Popconfirm>
    //         ) : null,
    // },
];

type ColumnTypes = Exclude<TableProps<IssueDataType>['columns'], undefined>;

export default function ResolveJobModal({ open, setOpen, jobCardId, prospectId }: {
    open: boolean
    setOpen: (value: boolean) => void,
    jobCardId: number,
    prospectId: number,
}) {

    const navigate = useNavigate();


    const [viewState, setViewState] = useState<string>("date");
    const [statuses, setStatuses] = useState<[] | OptionType[]>([])
    const [workTypes, setWorkTypes] = useState<[] | OptionType[]>([])
    const [resetting, setResetting] = useState(false)
    const [fullDetails, setFullDetails] = useState<IssueDataType[] | []>([]);
    const [originalData, setOriginalData] = useState<IssueDataType[] | []>([]);
    const [selectedIssues, setSelectedIssues] = useState<IssueDataType[] | []>([]);
    const { ticketId, vehicleId } = useParams();
    const [ticketsCreated, setTicketsCreated] = useState<TicketType[] | []>([])
    const [updating, setUpdating] = useState(false)
    const [deliveryHandOverDate, setDeliveryHandOverDate] = useState("")

    useEffect(() => {
        if (!open) {
            setSelectedIssues([])
            setFullDetails([])
            setViewState("date")
            setTicketsCreated([])
        }
    }, [open])


    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: IssueDataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                statuses,
                workTypes
            }),
        };
    });



    const handleSave = (row: IssueDataType) => {
        console.log(row);
        const newData = [...fullDetails];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
            needsAction: row.status === "Reported" || row.status === "In Progress",
        });
        setFullDetails(newData);
    };

    const handleProceed = (params: any) => {
        console.log('fullDetails', fullDetails)
        const hasNeedsAction = fullDetails.some(
            (item) => item.status === "Reported" || item.status === "In Progress"
        );
        if (!hasNeedsAction) {
            const hasNotResolved = fullDetails.some(
                (item) => item.status === "Not Resolved"
            );

            handleSaveDetails(params)


            if (hasNotResolved) {
                setViewState("new-ticket")
            } else {
                handleResolve()
            }

        } else {
            setFullDetails((prevState) =>
                prevState.map((item) => ({
                    ...item,
                    needsAction: item.status === "Reported" || item.status === "In Progress",
                }))
            );
        }


    }

    const handleResolve = async () => {
        const response = await putRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/handover-time?ticketId=${ticketId}&prospectId=${prospectId}&handoverTime=${deliveryHandOverDate}`,
            {}
        );
        if (viewState !== "new-ticket") {
            // after 2 secs
            setOpen(false)
            window.location.reload()
        }
    }

    useEffect(() => {
        if (open && jobCardId) {
            setResetting(true)
            getStatuses()
        }
    }, [open, jobCardId])

    const getStatuses = async () => {
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=SubIssueStatus`).then((res) => {
            console.log("res", res)
            setStatuses(res.data)
            getWorkTypes()
        }).catch((err) => {
            console.log(err)
        })
    }

    const getWorkTypes = async () => {
        setResetting(true)
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=WorkType`).then((res) => {
            console.log("resxx", res)
            setWorkTypes(res.data)
            getIssueItems()
        }).catch((err) => {
            console.log(err)
        })
    }


    const handleCreateNewTicket = async () => {
        try {
            const payload = selectedIssues.map((item) => {
                return {
                    "id": item?.id,
                    "subIssueId": item?.subIssueId,
                    "serviceType": item?.serviceType,
                    "status": item?.status,
                    "isDemandedJob": item?.isDemandedJob,
                    "workType": item?.workType,
                    "quantity": item?.quantity,
                    "unitPrice": item?.unitPrice,
                    // "estimatedAmount": item?.estimatedAmount,
                    "discountedAmount": item?.discountedAmount,
                    "basePrice": item?.basePrice,
                    "gst": item?.gst,
                    "gstAmount": item?.gstAmount,
                    "amountPayable": item?.amountPayable,
                    "isRecoverable": item?.isRecoverable,
                    "workDone": item?.workDone,
                }
            });
            const response = await postRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/transfer-actual-job-card-issue?vehicleId=${vehicleId}&prospectId=${prospectId}&ticketId=${ticketId}`,
                payload
            );
            console.log("response", response)
            const newTicket = {
                ticketId: `${response?.data.ticket?.serviceType}/${response?.data?.vehicleDetails?.assetNumber}/${response?.data.ticket?.id}`,
                id: response?.data.ticket?.id,
                vehicleId: response?.data?.vehicleDetails?.id
            };
            getIssueItems()
            setTicketsCreated([...ticketsCreated, newTicket])
            setSelectedIssues([]);
        } catch (error) {
            message.error('Failed to create new ticket');
        }
    }


    const getIssueItems = async () => {
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-sub-issue-job-card?filterAnd=${encodeURIComponent(`jobCard.id|eq|${jobCardId}&active|eqb|true`)}`).then((res) => {
            console.log("resxxvv", res)
            const issueItems = res.data.actualJob.items.map((item: any) => {
                return {
                    "id": item?.id,
                    "subIssueId": item?.subIssueMaster.id,
                    "serviceType": item?.serviceType,
                    "status": item?.status,
                    "isDemandedJob": !!item?.isDemandedJob,
                    "workType": item?.workType,
                    "quantity": item?.quantity,
                    "unitPrice": item?.unitPrice,
                    // "estimatedAmount": item?.estimatedAmount,
                    "discountedAmount": item?.discountedAmount,
                    "basePrice": item?.basePrice,
                    "gst": item?.gst,
                    "gstAmount": item?.gstAmount,
                    "amountPayable": item?.amountPayable,
                    "isRecoverable": item?.isRecoverable,
                    "workDone": item?.workDone,
                    "issue": item?.subIssueMaster.issue.name,
                    "subIssue": item?.subIssueMaster.name,
                    "reportedAt": moment(item?.auditInfo?.createdAt)?.format('h:mm A, D MMM YYYY'),
                    "lastUpdatedAt": moment(item?.auditInfo?.updatedAt)?.format('h:mm A, D MMM YYYY')
                }
            })
            setFullDetails(issueItems)
            setOriginalData(issueItems)
            const notResolvedItems = res.data.actualJob.items.filter((item: IssueDataType) => item?.status == "Not Resolved")
            if (notResolvedItems.length == 0 && viewState == "new-ticket") {
                handleResolve()
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSaveDetails = async (values: saveDetailsParams[]) => {
        const filteredValues = values.filter(value => {
            // Find the corresponding item in originalData by matching 'id'
            const originalItem = originalData.find(item => item.id === value.id);

            // If no corresponding item found, we assume it's not the same, so exclude it
            if (!originalItem) {
                return false;
            }

            // Compare the status in both arrays
            return value.status !== originalItem.status;
        });
        setUpdating(true)
        const response = await postRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-job-card-issue?ticketId=${ticketId}`,
            filteredValues
        );

        setUpdating(false)
    }

    const handleClose = () => {
        setFullDetails([]);
        setSelectedIssues([]);
        setTicketsCreated([])
        setUpdating(false)
        setDeliveryHandOverDate("")
        setViewState("date");
        setOpen(false)
        if (viewState !== "date") {
            window.location.reload()
        }
    }

    const handleBack = () => {
        getIssueItems()
        setSelectedIssues([]);
        setTicketsCreated([])
        setUpdating(false)
        setDeliveryHandOverDate("")
        setViewState("date")
    }

    return (<Modal footer={
        null
    }
        centered
        styles={{
            footer: {
                paddingInline: "10px",
                marginBottom: 16
            }
        }}
        destroyOnClose
        onCancel={handleClose}
        maskClosable={false}
        width={viewState == "update-issue" ? 1000 : "unset"}
        title={null} open={open}>

        {/* <img width={65} src="/assets/warning-icon.png" /> */}
        {viewState == "date" && <>
            <Typography style={{ fontSize: 14, fontWeight: "bold", marginTop: 8, textAlign: "left" }}>Enter delivery / handover date & time</Typography>

            <DatePicker showTime format="YYYY-MM-DD hh:mm a" onChange={(value) => setDeliveryHandOverDate(dayjs(value).format('YYYY-MM-DD HH:mm:ss'))} size="large" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />

            <div style={{ display: "flex", gap: 12, marginTop: 24, justifyContent: "center" }}>
                <Button style={{ ...modalButtonStyle, width: 200 }}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button disabled={!deliveryHandOverDate} style={{ width: 200 }} onClick={() => setViewState("update-issue")} type="primary"
                // onClick={() => setShowNotFound(false)}
                >
                    <CheckOutlined /> Confirm
                </Button>
            </div>

        </>}

        {viewState == "update-issue" && <>
            <Typography.Text strong>Please update status of sub-isssues</Typography.Text><br />
            {fullDetails.filter((item) => item.needsAction).length > 0 && <Typography style={{ color: "red" }}>Change the status to proceed ahead</Typography>}
            <div style={{ marginTop: 16 }}>
                {/* <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
            </Button> */}
                <Table<IssueDataType>
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    size='small'
                    pagination={false}
                    dataSource={fullDetails}
                    columns={columns as ColumnTypes}
                />

                <div style={{ textAlign: "right", marginTop: 10 }}>
                    <Button style={{ ...modalButtonStyle, width: 150, marginRight: 10 }}
                        onClick={handleBack}
                    >
                        <ArrowLeftOutlined />Go Back
                    </Button>
                    <Button onClick={() => {
                        const params = fullDetails.map((item) => {
                            return {
                                "id": item?.id,
                                "subIssueId": item.subIssueId,
                                "serviceType": item?.serviceType,
                                "status": item?.status,
                                "isDemandedJob": item?.isDemandedJob,
                                "workType": item?.workType,
                                "quantity": Number(item?.quantity),
                                "unitPrice": Number(item?.unitPrice),
                                // "estimatedAmount": Number(item?.estimatedAmount),
                                "discountedAmount": Number(item?.discountedAmount),
                                "basePrice": Number(item?.basePrice),
                                "gst": item?.gst,
                                "gstAmount": Number(item?.gstAmount),
                                "amountPayable": Number(item?.amountPayable),
                                "isRecoverable": item?.isRecoverable,
                                "workDone": item?.workDone
                            }
                        });
                        handleProceed(params as any)
                    }}
                        loading={updating}
                        disabled={fullDetails.filter((item) => item.needsAction).length > 0 || updating} type="primary">Proceed</Button>
                </div>
            </div>

        </>}

        {viewState == "new-ticket" && <>
            <Typography.Text strong>Please update status of sub-isssues</Typography.Text><br />
            {ticketsCreated.map((item) => {
                return (
                    <div style={{ background: "white", padding: "20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", width: 800, marginTop: 10 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <CheckCircleFilled style={{ color: "green", fontSize: 20 }} />
                                <Typography.Text>
                                    Ticket Id: {item?.ticketId} <Tag color="green">Added in New Ticket</Tag>
                                </Typography.Text>
                            </div>
                            <Button onClick={() => {
                                const url = `/dashboard/service-ticket/tickets-details/${item?.id}/${item?.vehicleId}`;
                                const anchor = document.createElement("a");
                                anchor.href = url;
                                anchor.target = "_blank"; // Open in a new tab
                                document.body.appendChild(anchor);
                                anchor.click();
                                document.body.removeChild(anchor);
                                // navigate(`/dashboard/service-ticket/tickets-details/${item?.id}/${item?.vehicleId}`)
                            }} type="text" style={{ color: "#1677ff" }} icon={<EyeFilled />}>
                                View Details
                            </Button>
                        </div>
                    </div>
                )
            })}

            <div style={{ marginTop: 16 }}>
                {/* <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
            </Button> */}
                {fullDetails.filter((item) => item.status == "Not Resolved").length > 0 && <Table<IssueDataType>
                    rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IssueDataType[]) => {
                            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            setSelectedIssues(selectedRows)
                        },
                    }}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    rowKey="id"
                    bordered
                    size='small'
                    pagination={false}
                    dataSource={fullDetails.filter((item) => item.status == "Not Resolved")}
                    columns={ticketColumns as ColumnTypes}
                />}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", gap: 12, marginTop: 30 }}>
                {fullDetails.some(
                    (item) => item.status === "Not Resolved"
                ) && <Button style={{ ...modalButtonStyle, width: 200 }}
                    onClick={() => { setTicketsCreated([]); setSelectedIssues([]); getIssueItems(); setViewState("update-issue") }}
                >
                        <ArrowLeftOutlined />Go Back
                    </Button>}
                <Button disabled={selectedIssues.length == 0} onClick={() => handleCreateNewTicket()} type="primary">
                    <PlusOutlined /> Create Ticket for Selected Sub- Issues
                </Button>
            </div>

        </>}


    </Modal>)
}