import { Button, Card, Col, DatePicker, Divider, Empty, Form, Input, Modal, Radio, Row, Select, Typography, message, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";
import { formatDateForTicket, getFormatedDateTimeWithoutChangingTime, getFormattedDateToDisplayWithoutChangingTimeZone, getTicketServiceNumberText } from "../../../../../../utils/helpers/utils";
import dayjs from "dayjs";
import { postRequest } from "../../../../../../utils/handler/apiHandler";
import moment from "moment";

interface TicketFormProps {
    ticketDetailsData: any,
    setTicketDetailsData: (data:any) => any,
    setFetchTicketDataCnt: (cnt:number) => void,
    ticketId: string,
    editable: boolean,
    setEditable: (flag: boolean) => void,
    showCancelTicketDetails: boolean,
    setShowCancelTicketDetails: (flag: boolean) => void,
    form: any,
    ticketStatusId: number,
}

const { Title } = Typography;

const TicketForm: React.FC<TicketFormProps> = ({ ticketDetailsData,setTicketDetailsData, setFetchTicketDataCnt,
    ticketId, editable, setEditable, showCancelTicketDetails, setShowCancelTicketDetails, form, ticketStatusId}) => {

    const [loader, setLoader] = useState(false);
    
    useEffect(()=>{
        if(editable){
            form.setFieldsValue({
            driverName: ticketDetailsData?.driverName,
            driverContact: ticketDetailsData?.driverContact,
            arrivalTimeAtWorkshop: ticketDetailsData?.arrivalTimeAtWorkshop
                ? dayjs(ticketDetailsData?.arrivalTimeAtWorkshop) : null,
            completionDateAndTime: ticketDetailsData?.completionDateAndTime
                ? dayjs(ticketDetailsData?.completionDateAndTime) : null,
            deliveryHandoverTime: ticketDetailsData?.deliveryHandoverTime
            ? dayjs(ticketDetailsData?.deliveryHandoverTime) : null,

            replacementCar: ticketDetailsData?.replacementCar ? true : false,
            replacementCarNo: ticketDetailsData?.replacementCarNo,
            replacementDriverName: ticketDetailsData?.replacementDriverName,
            replacementDriverNo: ticketDetailsData?.replacementDriverNo,
            pickAndDrop: ticketDetailsData?.pickAndDrop ? true : false,
            pickupSchedule: ticketDetailsData?.pickupSchedule
            ? dayjs(ticketDetailsData?.pickupSchedule).subtract(5, 'hour').subtract(30, 'minute') : null,
            actualPickup: ticketDetailsData?.actualPickup
                ? dayjs(ticketDetailsData?.actualPickup).subtract(5, 'hour').subtract(30, 'minute') : null,
            deliverySchedule: ticketDetailsData?.deliverySchedule
                ? dayjs(ticketDetailsData?.deliverySchedule).subtract(5, 'hour').subtract(30, 'minute') : null,

            roadSideAssistance: ticketDetailsData?.roadSideAssistance ? true : false,
            });
        }
      },[editable]);

    const submitTicketForm = async () => {
        setLoader(true);
        setShowCancelTicketDetails(false);
        setEditable(false);
      
        try {
          const payload = formatPayload(form.getFieldsValue(true));
          const response = await postRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/ticket-optional-detail?ticketId=${ticketId}`,
            payload
          );
        } catch (error) {
          message.error('Failed to Submit ticket details');
        } finally {
          setLoader(false);
          setFetchTicketDataCnt(Math.random());
        }
    }; 
      
    const formatPayload = (payload:any) => {
        
        return {
            driverName: payload?.driverName,
            driverMobileNumber: payload?.driverContact,
            workshopArrivalTime: payload?.arrivalTimeAtWorkshop,
            completionTime: payload?.completionDateAndTime,
            deliveryHandoverTime: payload?.deliveryHandoverTime,
            isReplacementCar: payload?.replacementCar,
            replacementCarNo: payload?.replacementCarNo,
            replacementDriverName: payload?.replacementDriverName,
            replacementDriverNo: payload?.replacementDriverNo,
            isPickAndDrop: payload?.pickAndDrop,
            pickupScheduledDate: payload?.pickupSchedule ? getFormatedDateTimeWithoutChangingTime(payload?.pickupSchedule) : '',
            vehiclePickupDate: payload?.actualPickup ? getFormatedDateTimeWithoutChangingTime(payload?.actualPickup) : '',
            deliveryScheduledDate: payload?.deliverySchedule ? getFormatedDateTimeWithoutChangingTime(payload?.deliverySchedule) : '',
            isRoadSideAssistance: payload?.roadSideAssistance,
        }
    }
    
    return (
        <>
            <div>
                <Form
                    name="dynamic_form_nest_item"
                    form={form}
                    autoComplete="off"
                    layout="vertical"
                    requiredMark={false}
                >
                    <Row style={{display:'flex', justifyContent:'space-between'}} >
                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Ticket Created at
                            </Typography>
                            <Typography
                                className={`${loader ? "shimmer-ui" : ""}`}
                                style={{ fontSize: 14 }}
                            >
                                <span style={{ display: loader ? "none" : "" }}>
                                    {loader ? "" : ticketDetailsData?.ticketCreatedAt ?
                                     moment(ticketDetailsData?.ticketCreatedAt).format("DD MMM YYYY, hh:mm a")
                                    :'NA'}
                                </span>
                            </Typography>
                        </Col>

                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Alert Type
                            </Typography>
                            <Typography
                                className={`${loader ? "shimmer-ui" : ""}`}
                                style={{ fontSize: 14 }}
                            >
                                {loader ? "" : ticketDetailsData?.alertType ? ticketDetailsData?.alertType : 'NA'}
                            </Typography>
                        </Col>

                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Service Number
                            </Typography>
                            <Typography
                                className={`${loader ? "shimmer-ui" : ""}`}
                                style={{ fontSize: 14 }}
                            >
                                {loader ? "" : getTicketServiceNumberText(ticketDetailsData?.serviceNumber, ticketDetailsData?.serviceCriteriaType, 
                    ticketDetailsData?.totalMonthlyCriteria, ticketDetailsData?.totalKmCriteria)}
                            </Typography>
                        </Col>
                    </Row>
                    <Row 
                        // style={{display:'flex', justifyContent: editable ? 'start': 'space-between'}}
                         >
                        <Col className="detail-description-item" style={{width: '37.5%'}}>
                            <Typography className="description-label">
                                Driver Name
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="driverName"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                >
                                    <Input 
                                    value={ticketDetailsData?.driverName} 
                                    placeholder="Driver Name"
                                    style={{width:'70%'}}
                                    />
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{ fontSize: 14 }}>
                                    {loader ? "" : ticketDetailsData?.driverName
                                         ? ticketDetailsData?.driverName : 'NA'}
                                </Typography>
                            )}
                        </Col>

                        <Col className="detail-description-item" style={{ width: '37.5%'}}>
                            <Typography className="description-label">
                                Driver Contact 
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="driverContact"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                    
                                >
                                    <InputNumber
                                    style={{width:'70%'}}
                                    value={ticketDetailsData?.driverContact} 
                                    maxLength={10} 
                                    placeholder="Driver Contact"
                                    />
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{ fontSize: 14 }}>
                                    {loader ? "" : ticketDetailsData?.driverContact
                                        ? ticketDetailsData?.driverContact : 'NA'}
                                </Typography>
                            )}
                        </Col>

                        {
                            !editable && 
                            <Col className="detail-description-item"  style={{}}>
                                <Typography className="description-label">
                                    Arrival time at workshop
                                </Typography>
                                {editable ? (
                                    <Form.Item
                                        name="arrivalTimeAtWorkshop"
                                        className="form-label"
                                        style={{ margin: 0, fontSize: 12 }}
                                        required={true}
                                    >
                                        <DatePicker showTime format="YYYY-MM-DD hh:mm a" />
                                    </Form.Item>
                                ) : (
                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{ fontSize: 14 }}>
                                        {loader ? "" : 
                                        ticketDetailsData?.arrivalTimeAtWorkshop ? 
                                            getFormattedDateToDisplayWithoutChangingTimeZone(ticketDetailsData?.arrivalTimeAtWorkshop)
                                        : 'NA'}
                                    </Typography>
                                )}
                            </Col>
                        }
                        
                    </Row>

                    <Row style={{display:'flex', justifyContent:'space-between'}}>
                        {
                            !editable && 
                            <Col className="detail-description-item">
                                <Typography className="description-label">
                                    Completion date & time
                                </Typography>
                                {editable ? (
                                    <Form.Item
                                        name="completionDateAndTime"
                                        className="form-label"
                                        style={{ margin: 0, fontSize: 12 }}
                                        required={true}
                                    >
                                        <DatePicker showTime format="YYYY-MM-DD hh:mm a" />
                                    </Form.Item>
                                ) : (
                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{ fontSize: 14 }}>
                                        {loader ? "" :
                                        ticketDetailsData?.completionDateAndTime ? 
                                            getFormattedDateToDisplayWithoutChangingTimeZone(ticketDetailsData?.completionDateAndTime)
                                    : 'NA'}
                                    </Typography>
                                )}
                            </Col>
                        }

                        {
                            !editable && 
                            <Col className="detail-description-item" style={{width:'62.5%'}}>
                                <Typography className="description-label">
                                    Delivery / Handover time
                                </Typography>
                                {editable ? (
                                    <Form.Item
                                        name="deliveryHandoverTime"
                                        className="form-label"
                                        style={{ margin: 0, fontSize: 12 }}
                                        required={true}
                                    >
                                        <DatePicker showTime format="YYYY-MM-DD hh:mm a" />
                                    </Form.Item>
                                ) : (
                                    <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{ fontSize: 14 }}>
                                        {loader ? "" : 
                                        ticketDetailsData?.deliveryHandoverTime ? 
                                            getFormattedDateToDisplayWithoutChangingTimeZone(ticketDetailsData?.deliveryHandoverTime)
                                        : 'NA'}
                                    </Typography>
                                )}
                            </Col>
                        }
                    </Row>


                    <Row style={{backgroundColor:'rgba(237, 246, 253, 1)', padding:'20px 20px 0px 20px', borderRadius: '6px', 
                        marginBottom:'10px'}}>
                        
                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Replacement Car
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="replacementCar"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                    initialValue={ticketDetailsData?.replacementCar ? ticketDetailsData?.replacementCar : false} // Set initial value to sync the form field
                                >
                                    <Radio.Group 
                                        value={ticketDetailsData?.replacementCar}
                                        buttonStyle="solid"
                                        onChange={(e) => {
                                            setTicketDetailsData((prevState:any) => ({
                                                ...prevState,
                                                replacementCar: e.target.value,
                                            }));
                                        }}
                                    >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                    {loader ? '' : ticketDetailsData?.replacementCar ? 'Yes' : 'No'}
                                </Typography>
                            )}
                        </Col>

                        {
                            ticketDetailsData?.replacementCar &&
                            <>
                                <Col className="detail-description-item">
                            <Typography className="description-label">
                                Replacement Car No
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="replacementCarNo"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                >
                                    <Input 
                                    value={ticketDetailsData?.replacementCarNo} 
                                    placeholder="Replacement Car No"
                                    />
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                {loader ? '' : ticketDetailsData?.replacementCarNo
                                    ? ticketDetailsData?.replacementCarNo : 'NA'}
                                </Typography>
                            )}
                        </Col>

                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Replacement Driver Name
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="replacementDriverName"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                    
                                >
                                    <Input 
                                    value={ticketDetailsData?.replacementDriverName} 
                                    placeholder="Replacement Driver Name"
                                    />
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                {loader ? '' : ticketDetailsData?.replacementDriverName
                                    ? ticketDetailsData?.replacementDriverName : 'NA'}
                                </Typography>
                            )}
                        </Col>

                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Replacement Driver No.
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="replacementDriverNo"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                    
                                >
                                    <InputNumber 
                                    style={{width:'100%'}}
                                    maxLength={10} 
                                    value={ticketDetailsData?.replacementDriverNo} 
                                    placeholder="Replacement Driver No."
                                    />
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                {loader ? '' : ticketDetailsData?.replacementDriverNo
                                    ? ticketDetailsData?.replacementDriverNo : 'NA'}
                                </Typography>
                            )}
                        </Col>
                            </>
                        }
                    </Row>

                    <Row style={{backgroundColor:'rgba(237, 246, 253, 1)', padding:'20px 20px 0px 20px', borderRadius: '6px', 
                                            marginBottom:'10px'}}>
                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Pick & Drop
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="pickAndDrop"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                    initialValue={ticketDetailsData?.pickAndDrop ? ticketDetailsData?.pickAndDrop : false} // Set initial value to sync the form field
                                >
                                    <Radio.Group 
                                        value={ticketDetailsData?.pickAndDrop}
                                        buttonStyle="solid"
                                        onChange={(e) => {
                                            // Update state when the radio button is changed
                                            setTicketDetailsData((prevState: any) => ({
                                                ...prevState,
                                                pickAndDrop: e.target.value,
                                            }));
                                        }}
                                    >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                    {loader ? "" : ticketDetailsData?.pickAndDrop ? 'Yes' : 'No'}
                                </Typography>
                            )}
                        </Col>


                        {
                            ticketDetailsData?.pickAndDrop &&
                            <>
                                {
                                     ticketStatusId > 5 && 
                                     <Col className="detail-description-item" style={{width:'25%'}}>
                                        <Typography className="description-label">
                                            Pickup Schedule
                                        </Typography>
                                        { editable ? (
                                            <Form.Item
                                                name="pickupSchedule"
                                                className="form-label"
                                                style={{ margin: 0, fontSize: 12 }}
                                                required={true}
                                            >
                                                <DatePicker showTime format="YYYY-MM-DD hh:mm a"  style={{width:'100%'}}/>
                                            </Form.Item>
                                        ) : (
                                            <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                                {loader ? '' :
                                                ticketDetailsData?.pickupSchedule ? 
                                                getFormattedDateToDisplayWithoutChangingTimeZone(ticketDetailsData?.pickupSchedule)
                                                : 'NA'}
                                            </Typography>
                                        ) }
                                    </Col>
                                }
                                
                               
                                </>
                        }
                        <>
                         {
                            ticketStatusId > 5 && 
                            <Col className="detail-description-item" style={{width:'25%'}}>
                                <Typography className="description-label">
                                    Actual Pickup
                                </Typography>
                                {editable ? (
                                    <Form.Item
                                        name="actualPickup"
                                        className="form-label"
                                        style={{ margin: 0, fontSize: 12 }}
                                        required={true}
                                    >
                                        <DatePicker showTime format="YYYY-MM-DD hh:mm a"  style={{width:'100%'}}/>
                                    </Form.Item>
                                ) : (
                                    <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                        {loader ? '' :
                                        ticketDetailsData?.actualPickup ? 
                                        getFormattedDateToDisplayWithoutChangingTimeZone(ticketDetailsData?.actualPickup)
                                        : 'NA'}
                                    </Typography>
                                )}
                            </Col>
                        }
                        {
                                ticketStatusId >= 8 && 
                            <Col className="detail-description-item" style={{width:'25%'}}>
                                <Typography className="description-label">
                                    Delivery Schedule
                                </Typography>
                                {editable ? (
                                    <Form.Item
                                        name="deliverySchedule"
                                        className="form-label"
                                        style={{ margin: 0, fontSize: 12 }}
                                        required={true}
                                    >
                                        <DatePicker showTime format="YYYY-MM-DD hh:mm a"  style={{width:'100%'}}/>
                                    </Form.Item>
                                ) : (
                                    <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                        {loader ? '' : 
                                        ticketDetailsData?.deliverySchedule ? 
                                        getFormattedDateToDisplayWithoutChangingTimeZone(ticketDetailsData?.deliverySchedule)
                                        : 'NA'
                                    }
                                    </Typography>
                                )}
                            </Col>
                        }
                        </>
                    </Row>
                    <Row style={{backgroundColor:'rgba(237, 246, 253, 1)', padding:'20px 20px 0px 20px', borderRadius: '6px', 
                                            marginBottom:'10px'}}>
                        <Col className="detail-description-item">
                            <Typography className="description-label">
                                Road Side Assistance
                            </Typography>
                            {editable ? (
                                <Form.Item
                                    name="roadSideAssistance"
                                    className="form-label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    required={true}
                                    initialValue={ticketDetailsData?.roadSideAssistance ? ticketDetailsData?.roadSideAssistance : false} // Set initial value to sync the form field
                                >
                                    <Radio.Group 
                                        value={ticketDetailsData?.roadSideAssistance}
                                        buttonStyle="solid"
                                        onChange={(e) => {
                                            // Update state when the radio button is changed
                                            setTicketDetailsData((prevState: any) => ({
                                                ...prevState,
                                                roadSideAssistance: e.target.value,
                                            }));
                                        }}
                                    >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                <Typography className={`${loader ? 'shimmer-ui' : ''}`} style={{ fontSize: 14 }}>
                                    {loader ? "" : ticketDetailsData?.roadSideAssistance ? 'Yes' : 'No'}
                                </Typography>
                            )}
                        </Col>
                    </Row>
                    {
                        editable && 
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
                        <Button 
                            style={{ color: 'rgba(255, 255, 255, 1)', backgroundColor: '#1777FF', borderColor: '#1777FF' }}
                            onClick={submitTicketForm}
                        >
                            Save Ticket Details
                        </Button>
                        </div>
                    }            
                </Form>
            </div>
                
        </>
    );
};

export default TicketForm;
